import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";

const loyaltyDistributionList_by_store_url = API.apiEndpoint + API.loyaltyCardsDistributionByStore;
const loyaltyDistributionList_by_store_statistics_url = API.apiEndpoint + API.loyaltyCardsDistributionByStoreStatistics;

export default class StationLoyaltyDistributionReportListing extends React.Component {
  
  state = {
    distributionListByStore: [],
    statistics: {
      totalAssignedCards: 0,
      totalDistributedCards: 0,
    },
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (pageNumber = 1, limit = 10, filter = false, key = "", value = "") => {
    let options = {
      url:
      loyaltyDistributionList_by_store_url +
        "?stationLoyaltyDistributionListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      const statisticsResponse = await ApiRequest.get({ url: loyaltyDistributionList_by_store_statistics_url });
      if (statisticsResponse.error) {
        this.setState({
          loading: false,
          error: true,
          message: statisticsResponse.message,
        });
        return;
      }

      this.setState({
        distributionListByStore: api_response.data.results,
        statistics: statisticsResponse.data,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(pageNumber, this.state.limit, this.state.filter, this.state.search_select_value, this.state.search_input_value);
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, event.target.value, this.state.search_input_value);
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, this.state.search_select_value, event.target.value);
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  render() {
    const {
      loading,
      error,
      message,
      success,
      success_message,
      distributionListByStore,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;

    return (
      <Content title="Stations Loyalty Cards Distribution By Store" browserTitle="Stations Loyalty Cards Distribution By Store">
        <LoadingAjax />
        <Row>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-flag-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Assigned Cards</span>
                <span className="info-box-number">
                  {this.state.statistics.totalAssignedCards}
                </span>
              </div>
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-flag-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Distributed Cards</span>
                <span className="info-box-number">
                  {this.state.statistics.totalDistributedCards}
                </span>
              </div>
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-green">
                <i className="fa fa-flag-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Distributed Cards Activated</span>
                <span className="info-box-number">
                  {this.state.statistics.totalDistributedCardsActivated}
                </span>
              </div>
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-red">
                <i className="fa fa-flag-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Distributed Cards Non Activated</span>
                <span className="info-box-number">
                  {this.state.statistics.totalDistributedCardsNonActivated}
                </span>
              </div>
            </div>
          </Col>
        </Row>    
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              {/* <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Stations Loyalty Cards Distribution By Store</h3>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="Station Locator"
                    selectableRows={false}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.distributionListByStore}
                    paginationPerPage={3}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
              </div> */}
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select value={limit} className="form-control input-sm mr-5 ml-5" onChange={this.handleLimitChange}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>

                    <div className="cusSearch">
                      Search{" "}
                      <select onChange={this.handleSearchChange} value={search_select_value} className="form-control input-sm mr-5 ml-5">
                        <option value="">Select Search by </option>
                        <option value={"store_code"}>Store Code</option>
                      </select>
                      <input
                        type="search"
                        class="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                      <button className="btn btn-primary cusSearchBtn" onClick={this.handleSearchClear}>
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>Store Name</th>
                            <th>Store Code</th>
                            <th>Total Assigned Cards</th>
                            <th>Total Distributed Cards</th>
                            <th>Total Distributed Cards Activated</th>
                            <th>Total Distributed Cards Non Activated</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {distributionListByStore.length > 0 ? (
                            distributionListByStore.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.store_name)}</td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>{this.rescue_value(data.total_assigned_cards)}</td>
                                  <td>{this.rescue_value(data.total_distributed_cards)}</td>
                                  <td>{this.rescue_value(data.total_distributed_cards_activated)}</td>
                                  <td>{this.rescue_value(data.total_distributed_cards_non_activated)}</td>
                                  <td>
                                    {ApiRequest.can_access("station", "can_view_single") && (
                                      <div>
                                      <a class="btn btn-primary btn-xs mr-5" href="/stations_loyalty_distributions">
                                        {" "}
                                        <i class="fa fa-eye" />
                                        {"    "}
                                      </a>
                                    </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to {pagination.currentPage * limit} of {pagination.size} entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
