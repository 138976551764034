import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./style.css";

const configuration_url = API.apiEndpoint + API.configuration;
//const filter_url = API.apiEndpoint + API.filters;

export default class StationReview extends React.Component {
  state = {
    configuration: {},
    loading: true,
    filters: [],
    error: false,
    message: ""
  };

  async componentDidMount() {
    const response1 = await axios.get(configuration_url);

    if (response1.status === 200) {
      const data = response1.data.data;
      this.setState({ configuration: data[0], loading: false });
    }

    // const response2 = await axios.get(filter_url);

    // if (response2.status === 200) {
    //   const filters = response2.data.data;
    //   this.setState({ filters: filters, loading: false });
    // }
  }

  changeValue = event => {
    let configuration = { ...this.state.configuration };
    configuration[event.target.name] = event.target.value;
    this.setState({ configuration });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const form = event.target;
    console.log(form)
    const data = new FormData(form);
    // data.set("filerIdEnableOnNFC", data.set("filerIdEnableOnNFC") === "true")
    this.setState({ loading: true });
    let response = await axios.put(
      configuration_url + "/" + this.state.configuration.id,
      data
    );

    if (response.status) {
      this.setState({ loading: false });
    }
  };

  parentClick = (data, isChild, key) => {
    let obj = {
      child: isChild,
      name: data.name,
      key: key,
      unique_identifier: key
    };
  };
  childClick = () => {};

  renderParent = parent => {
    var fitlerParent = [];
    for (var property in parent) {
      if (property !== "station_range") {
        fitlerParent.push(
          <li key={property}>
            {property}
            <button
              onClick={this.parentClick.bind(
                this,
                parent[property],
                false,
                property
              )}
            >
              {" "}
              Add{" "}
            </button>
          </li>
        );
        if (parent[property].data) {
          let filterChild = [];
          parent[property].data.map(child => {
            filterChild.push(
              <li key={child.unique_identifier}>
                {child.name.en}
                <button
                  onClick={this.parentClick.bind(
                    this,
                    child,
                    true,
                    child.unique_identifier
                  )}
                >
                  {" "}
                  Add{" "}
                </button>
              </li>
            );
          });
          fitlerParent.push(<ul>{filterChild}</ul>);
        }
      }
    }

    return fitlerParent;
  };

  render() {
    const { configuration, loading, filters } = this.state;

    return (
      <Content title="Setting" browserTitle="Setting">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Configuration </h3>
              </div>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form onSubmit={this.handleSubmit}>
                  <div className="box-body">
                    <div className="form-group">
                      <label htmlFor="name">Contact Us Support Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contactSupportEmail"
                        name="contactSupportEmail"
                        value={configuration.contactSupportEmail}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Complaint Support Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="complaintSupportEmail"
                        name="complaintSupportEmail"
                        value={configuration.complaintSupportEmail}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Old Basma Card Support Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="OldCardSupportEmail"
                        name="OldCardSupportEmail"
                        value={configuration.OldCardSupportEmail}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Android Version</label>
                      <input
                        type="text"
                        className="form-control"
                        id="androidVersion"
                        name="androidVersion"
                        value={configuration.androidVersion}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">iOS Version</label>
                      <input
                        type="text"
                        className="form-control"
                        id="iosVersion"
                        name="iosVersion"
                        value={configuration.iosVersion}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Huawei Version</label>
                      <input
                        type="text"
                        className="form-control"
                        id="huaweiVersion"
                        name="huaweiVersion"
                        value={configuration.huaweiVersion}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">App signature</label>
                      <input
                        type="text"
                        className="form-control"
                        id="signatureForSms"
                        name="signatureForSms"
                        value={configuration.signatureForSms}
                        onChange={this.changeValue.bind(this)}
                      />
                    </div>
                    
                    <div className="form-group">
                      <label htmlFor="name">Payment Gateway</label>
                      <select
                        className="form-control"
                        id="paymentGateway"
                        name="paymentGateway"
                        value={configuration.paymentGateway}
                        onChange={this.changeValue.bind(this)}>
                        <option value={"NBO"}>NBO</option>
                        <option value={"THAWANI"}>Thawani</option>
                        <option value={"MIX"}>Balance</option>
                        <option value={"BALANCE"}>BALANCE</option>
                      </select>
                    </div>
                  </div>
                  
                  <div className="box-footer clearfix no-border">
                    {ApiRequest.can_access("setting", "can_update") && (
                      <button
                        // onClick={this.saveRole}
                        className="btn btn-primary pull-right "
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="row hidden">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Quick Search </h3>
              </div>
              <div className="box-body">
                <ul className="flitersList">{this.renderParent(filters)}</ul>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
