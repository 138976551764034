import React, { Component } from "react";

import { Content, Row, Col } from "adminlte-2-react";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";

import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
require("bootstrap/less/bootstrap.less");

const limit_hitting_recording = API.apiEndpoint + API.limitHittingRecording;

export default class LimitHittingRecording extends Component {
	state = {
		data: [],
		date_from: new Date(),
		date_to: new Date(),
		type: "",
		oomcoId: "",
		stats: {},
		activePage: 1,
		error: false,
		success: false,
		message: "",
		success_message: "",
		loading: false,
		limit: 20,
		callApi: false,
		enableBtn: false,
		pagination: {},
	};

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData(
		pageNumber = 1,
		limit = 20,
		date_from = null,
		date_to = null,
		type = null,
		oomcoId = null
	) {
		this.setState({ loading: true });
		let url =
			limit_hitting_recording + "?page=" + pageNumber + "&limit=" + limit;

		if (this.state.date_from) {
			url =
				url +
				"&date_from=" +
				(date_from !== null ? date_from : this.state.date_from);
		}
		if (this.state.date_to) {
			url =
				url +
				"&date_to=" +
				(date_to !== null ? date_to : this.state.date_to);
		}
		if (this.state.type) {
			url = url + "&type=" + (type !== null ? type : this.state.type);
		}
		if (this.state.oomcoId) {
			url =
				url +
				"&oomcoId=" +
				(oomcoId !== null ? oomcoId : this.state.oomcoId);
		}

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { data, pagination, ...stats } = response.data;
			this.setState({
				loading: false,
				error: false,
				callApi: true,
				data,
				stats,
				pagination: pagination,
			});
		}
	}

	handleSubmit = async () => {
		await this.getReportData();
	};

	handlePageChange = async (pageNumber) => {
		await this.getReportData(pageNumber);
	};

	handleChangeType = (event) => {
		this.setState({
			type: event.target.value,
			enableBtn: true,
			// pagination: {},
			limit: 20,
		});
	};

	handleOomcoIdChange = (event) => {
		this.setState({
			oomcoId: event.target.value,
			// pagination: {},
			limit: 20,
		});
	};

	byDateStart = (date) => {
		this.setState({
			date_from: date,
			// pagination: {},
		});
	};

	byDateEnd = (date) => {
		this.setState({
			date_to: date,
			// pagination: {},
		});
	};

	handleSearchClear = async () => {
		this.setState({
			date_from: new Date(),
			date_to: new Date(),
			type: "",
			oomcoId: "",
			enableBtn: false,
			// pagination: {},
			limit: 20,
		});
		await this.getReportData(1, 20, new Date(), new Date(), "", "");
	};

	noResultText() {
		return "N/A";
	}

	// todo: implement export
	async downloadData() {
		console.log("Not Implemented!!");
	}

	render() {
		const {
			data,
			loading,
			error,
			message,
			success,
			success_message,
			callApi,
			pagination,
			limit,
		} = this.state;
		return (
			<Content
				title="Limit Hitting Recording"
				browserTitle="Limit Hitting Recording"
			>
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<Col xs={12}>
						<div className="box box-primary">
							<ExportButton />
							<FilterForm
								date_to={this.state.date_to}
								date_from={this.state.date_from}
								byDateStart={this.byDateStart}
								byDateEnd={this.byDateEnd}
								type={this.state.type}
								oomcoId={this.state.oomcoId}
								handleOomcoIdChange={this.handleOomcoIdChange}
								card_number={this.state.card_number}
								handleChangeType={this.handleChangeType}
								handleSubmit={this.handleSubmit}
								handleSearchClear={this.handleSearchClear}
							/>
							<Statistics
								loading={loading}
								noResultText={this.noResultText}
								stats={{
									...this.state.stats,
									total: pagination.size,
								}}
							/>
							<Table
								handlePageChange={this.handlePageChange}
								pagination={pagination}
								loading={loading}
								limit={limit}
								callApi={callApi}
								data={data}
							/>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}

class ExportButton extends Component {
	render() {
		return (
			<div className="box-header with-border box-table-cus">
				<h3 className="box-title">Report</h3>
				<div className="box-tools">
					<button
						className="btn btn-sm btn-primary"
						disabled={true}
						onClick={this.props.downloadData}
					>
						Export
					</button>
				</div>
			</div>
		);
	}
}

class FilterForm extends Component {
	render() {
		return (
			<div
				className="row clearfix"
				style={{ marginTop: "5px", padding: "10px" }}
			>
				<div className="col-xs-4">
					<div className="form-group rangeFilter">
						<label>By Date</label>
						<DatePicker
							dateFormat="MM/dd/yyyy"
							selected={this.props.date_from}
							selectsStart
							date_from={this.props.date_from}
							date_to={this.props.date_to}
							onChange={this.props.byDateStart}
							placeholderText="From"
						/>
						<DatePicker
							dateFormat="MM/dd/yyyy"
							selected={this.props.date_to}
							selectsEnd
							date_from={this.props.date_from}
							date_to={this.props.date_to}
							onChange={this.props.byDateEnd}
							minDate={this.props.date_from}
							placeholderText="To"
						/>
					</div>
				</div>
				<div className="col-xs-4">
					<div className="form-group">
						<label>Type</label>
						<select
							className="form-control"
							value={this.props.type}
							onChange={this.props.handleChangeType}
						>
							<option value="">Select Type</option>
							<option value="refueling">Refueling</option>
							<option value="card-activation">
								Card Activation
							</option>
						</select>
					</div>
				</div>
				<div className="col-xs-4">
					<div className="form-group">
						<label>Search by Oomco ID</label>
						<input
							type="search"
							class="form-control input-md mr-5 ml-5"
							value={this.props.oomcoId}
							onChange={this.props.handleOomcoIdChange}
							placeholder=""
						/>
					</div>
				</div>
				<div
					className="text-right col-xs-12"
					style={{
						display: "flex",
						gap: "1em",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<button
						onClick={this.props.handleSubmit}
						className="btn btn-primary cusSearchBtn"
					>
						Submit
					</button>
					<button
						className="btn btn-primary cusSearchBtn"
						onClick={this.props.handleSearchClear}
					>
						Clear
					</button>
				</div>
			</div>
		);
	}
}

class Statistics extends Component {
	getStatCards = () => {
		const { potentialBusinessUsersCount, total } = this.props.stats;
		return [
			{
				title: "Total User Hits Limit",
				value: potentialBusinessUsersCount,
			},
			{
				title: "Total Limit Hit",
				value: total,
			},
		];
	};
	render() {
		return (
			<div className="box-body">
				<div className="row">
					{this.getStatCards().map((item) => (
						<div key={item.title} className="col-md-4 col-sm-6">
							<div className="info-box-custom">
								<div className="info-box-content-custom">
									<h4 className="info-box-text-custom">
										{item.title}
									</h4>
									<span className="info-box-number-custom">
										{this.props.loading ? (
											<LoadingSpinner />
										) : (
											<div>
												<span className="info-box-number-custom">
													{this.props.loading ? (
														<LoadingSpinner />
													) : item.value ? (
														<div>
															<span className="info-box-number-custom">
																{item.value
																	.toString()
																	.replace(
																		/\B(?=(\d{3})+(?!\d))/g,
																		","
																	)}
															</span>
														</div>
													) : (
														<div>
															{this.props.noResultText()}
														</div>
													)}
												</span>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

class Table extends Component {
	rescue_value(val) {
		return val === undefined || val === null || val.length <= 0 ? "-" : val;
	}
	render() {
		return (
			<div className="box-body">
				<div className="table-responsive">
					{this.props.loading ? (
						<LoadingSpinner />
					) : (
						<div>
							<table
								className="table no-margin"
								id="export-table-inventory"
							>
								<thead>
									<tr>
										<th>Id</th>
										<th>type</th>
										<th>OOMCO Id</th>
										<th>Date</th>
										<th>Time</th>
										<th>Count</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.props.data &&
									this.props.data.length > 0 ? (
										this.props.data.map((data) => {
											return (
												<tr key={data.id}>
													<td>
														{this.rescue_value(
															data.id
														)}
													</td>
													<td>
														{this.rescue_value(
															data.type
														)}
													</td>
													<td>
														<a
															class=""
															href={
																"/app_view/" +
																data.user?.id
															}
														>
															{this.rescue_value(
																data.oomcoId
															)}
														</a>
													</td>
													<td>
														{ApiRequest.date_format(
															data.createdAt
														)}
													</td>
													<td>
														{ApiRequest.time_format(
															data.createdAt
														)}
													</td>
													<td>{data?.rpsTransactions?.length || 'N/A'}</td>
													<td>
														<a
														 	class="btn btn-primary btn-xs mr-5"
															href={
																"/reports/limit_hitting_view/" +
																data.id
															}
														>
															<i class="fa fa-eye" />
														</a>
													</td>
												</tr>
											);
										})
									) : (
										<td colSpan="10" className="noRecord">
											{this.props.callApi
												? "No Record Found"
												: "Please Select Filter"}
										</td>
									)}
								</tbody>
							</table>
							{this.props.pagination.size > 0 && (
								<div className="tableFooter">
									<div>
										<div className="dataTables_info">
											Showing{" "}
											{(this.props.pagination
												.currentPage -
												1) *
												this.props.limit +
												1}{" "}
											to{" "}
											{this.props.pagination.currentPage *
												this.props.limit}{" "}
											of {this.props.pagination.size}{" "}
											entries
										</div>
									</div>
									<div style={{ textAlign: "right" }}>
										<Pagination
											prevPageText={"<"}
											nextPageText={">"}
											activePage={
												this.props.pagination
													.currentPage
											}
											itemsCountPerPage={
												this.props.pagination.limit
											}
											totalItemsCount={
												this.props.pagination.size
											}
											pageRangeDisplayed={5}
											onChange={
												this.props.handlePageChange
											}
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
