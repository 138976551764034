import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import LoadingAjax from "../../components/Loading";
import { Chart } from "react-google-charts";

const refueling_url = API.apiEndpoint + API.reportRefueling;
const store_url = API.apiEndpoint + API.store;
const analyticsRefueling_url = API.apiEndpoint + API.analyticsRefueling;

export default class RefuelingTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submitUrl: "",
      store_code: "",
      product: "",
      amount_from: "",
      amount_to: "",
      transaction_date_from: "",
      transaction_date_to: "",
      card_number: "",
      refuelings: [],
      stores: [],
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      show_payment_method: false,
      show_amount_range: false,
      show_payment_options: [],
      callApi: false,
      enableBtn: true,
      loadingCart: false,
      graphData: [],
      refuelingData: [],
      transaction_type: "rps",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStoreName = this.handleChangeStoreName.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.handleChangeAmountFrom = this.handleChangeAmountFrom.bind(this);
    this.handleChangeAmountTo = this.handleChangeAmountTo.bind(this);
    this.byDateStart = this.byDateStart.bind(this);
    this.byDateEnd = this.byDateEnd.bind(this);
    // this.handleChangeCardNumber = this.handleChangeCardNumber.bind(this);
  }

  handleActivationSource = async (event) => {
    this.setState({
      transaction_type: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  };
  handleChangeStoreName(event) {
    this.setState({
      store_code: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeProduct(event) {
    this.setState({
      product: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeAmountFrom(event) {
    this.setState({
      amount_from: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeAmountTo(event) {
    this.setState({
      amount_to: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  byDateStart(date) {
    this.setState({
      transaction_date_from: date,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  byDateEnd(date) {
    this.setState({
      transaction_date_to: date,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  }
  // handleChangeCardNumber(event) {
  //   this.setState({ card_number: event.target.value });
  // }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = refueling_url + "?page=" + pageNumber;
    let analyticsRefuelingReport =
      analyticsRefueling_url + "?page=" + pageNumber;

    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
      analyticsRefuelingReport =
        analyticsRefuelingReport + "&store_code=" + this.state.store_code;
    }

    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;
      analyticsRefuelingReport =
        analyticsRefuelingReport +
        "&transaction_type=" +
        this.state.transaction_type;
    }

    if (this.state.product) {
      url = url + "&product=" + this.state.product;
      analyticsRefuelingReport =
        analyticsRefuelingReport + "&product=" + this.state.product;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
      analyticsRefuelingReport =
        analyticsRefuelingReport + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
      analyticsRefuelingReport =
        analyticsRefuelingReport + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
      analyticsRefuelingReport =
        analyticsRefuelingReport +
        "&transaction_date_from=" +
        this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
      analyticsRefuelingReport =
        analyticsRefuelingReport +
        "&transaction_date_to=" +
        this.state.transaction_date_to;
    }
    if (this.state.card_number) {
      url = url + "&card_number=" + this.state.card_number;
      analyticsRefuelingReport =
        analyticsRefuelingReport + "&card_number=" + this.state.card_number;
    }
    let options = { url: url };
    let analyticsRefuelingReportUrl = {
      url: analyticsRefuelingReport,
    };

    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        refuelings: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    let analyticsRefuelingReport_response = await ApiRequest.get(
      analyticsRefuelingReportUrl,
      true,
      false
    );
    this.setState({
      loadingCart: false,
    });

    if (!analyticsRefuelingReport_response.error) {
      if (analyticsRefuelingReport_response.data) {
        let refuelingData = analyticsRefuelingReport_response.data;

        this.setState({ refuelingData: refuelingData });
      }

      if (
        analyticsRefuelingReport_response.data &&
        analyticsRefuelingReport_response.data.graphData
      ) {
        let graphData = analyticsRefuelingReport_response.data.graphData;

        this.setState({ graphData: graphData });
      }
    }
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  async componentDidMount() {
    let optionsUsers = { url: refueling_url };
    let optionsStore = { url: store_url };

    let api_response = await ApiRequest.get(optionsUsers);
    let store_response = await ApiRequest.get(optionsStore);

    if (api_response.error || store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({ value: store.store_code, label: store.store_name });
      });

      this.setState({
        refuelings: api_response.data.results,
        pagination: api_response.data.pagination,
        stores: stores,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        let api_response = await ApiRequest.get(optionsUsers);
        this.setState({
          refuelings: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  handleChangeCardNumber = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({ error: false, card_number: event.target.value });
    }
  };
  rescue_value(val) {
    // return val === undefined || val === null || val.length <= 0 ? "-" : val;
    return val ? val : "-";
  }
  downloadData = async (pageNumber = 1) => {
    let url = refueling_url + "?csv=" + 1;
    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }

    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;
    }

    if (this.state.product) {
      url = url + "&product=" + this.state.product;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
    }
    if (this.state.card_number) {
      url = url + "&card_number=" + this.state.card_number;
    }
    let options = {
      url: url,
      file_name:
        "Refueling Transaction - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };

    await ApiRequest.download(options);
  };
  noResultText() {
    return "N/A";
  }
  graphData(graphData) {
    let data = [];
    let defaultData = ["x", "Volume", "Amount"];
    data.push(defaultData);
    if (graphData) {
      graphData.map(function (item, i) {
        const { volume, amount, startDate, endDate } = item;
        data.push([(++i).toString(), volume, amount]);
      });
      return data;
    }
    return data;
  }

  render() {
    const { Select2 } = Inputs;
    const {
      pagination,
      stores,
      refuelings,
      loading,
      error,
      limit,
      message,
      callApi,
      enableBtn,
      loadingCart,
      graphData,
      refuelingData,
    } = this.state;
    return (
      <Content
        title="Refueling Transaction"
        browserTitle="Refueling Transaction"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4 cusSelect cusSelect-14">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                  />
                </div>

                {/*<div className="col-xs-4">
                  <div className="form-group">
                    <label>Store </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeStation}
                      className="form-control"
                      placeholder="Enter keyword"
                    />
                  </div>
          </div>*/}
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Product </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeProduct}
                      className="form-control"
                      placeholder="Enter product"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Amount{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_from}
                      onChange={this.handleChangeAmountFrom}
                      className="form-control"
                      placeholder="From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_to}
                      onChange={this.handleChangeAmountTo}
                      className="form-control"
                      placeholder="To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      selected={this.state.transaction_date_from}
                      selectsStart
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      selected={this.state.transaction_date_to}
                      selectsEnd
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.transaction_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Card Serial Number </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeCardNumber}
                      className="form-control"
                      placeholder="Enter card serial number"
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Transaction Type </label>
                    <select
                      className="form-control"
                      value={this.state.transaction_type}
                      onChange={this.handleActivationSource}
                    >
                      <option value="rps">RPS Transactions</option>
                      <option value="app">Virtual Transactions</option>
                    </select>
                  </div>
                </div>

                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Transactions
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : refuelingData.total_transactions ? (
                            <div>
                              <span className="info-box-number-custom">
                                {refuelingData.total_transactions}
                              </span>
                            </div>
                          ) : (
                            <div>{this.noResultText()}</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Total Volume</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : refuelingData.total_volume ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {refuelingData.total_volume}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Unique Cards</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : refuelingData.unique_cards ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {refuelingData.unique_cards}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Weekly Transactions</h3>
              </div>
              <div className="box-body">
                {loadingCart ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    {graphData.length > 0 ? (
                      <Chart
                        width={"100%"}
                        height={"300px"}
                        chartType="LineChart"
                        data={this.graphData(graphData)}
                      />
                    ) : (
                      <div style={{ textAlign: "center", padding: "50px 0" }}>
                        {this.noResultText()}
                      </div>
                    )}
                    <div className="table-responsive">
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Index</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Volume</th>
                            <th>Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {graphData.length > 0 && graphData ? (
                            graphData.map((data, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.startDate)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.endDate)}
                                  </td>
                                  <td>{this.rescue_value(data.volume)}</td>
                                  <td>{this.rescue_value(data.amount)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>

              <div class="box-body">
                <div class="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        class="table no-margin"
                        id="export-table-refueling"
                      >
                        <thead>
                          <tr>
                            <th>Store Code</th>
                            <th>Store Name</th>
                            <th>volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          {refuelingData.top_stores &&
                          refuelingData.top_stores.length > 0 ? (
                            refuelingData.top_stores.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {this.rescue_value(data.store.store_code)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.store.store_name)}
                                  </td>
                                  <td>{this.rescue_value(data.volume)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="9" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                    disabled={!enableBtn}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div class="box-body">
                <div class="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        class="table no-margin"
                        id="export-table-refueling"
                      >
                        <thead>
                          <tr>
                            {/*<th>Station name/code</th>*/}
                            <th>Store ID</th>
                            <th>Transaction ID</th>
                            <th>OOMCO ID</th>
                            <th>Card Serial Number </th>
                            <th>Filler ID </th>
                            <th>Product</th>
                            <th>Amount</th>
                            <th>Volume</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {refuelings.length > 0 && refuelings ? (
                            refuelings.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>
                                    {this.rescue_value(data.transaction_id)}
                                  </td>

                                  <td>{this.rescue_value(data.oomco_id)}</td>
                                  <td>{this.rescue_value(data.card_number)}</td>
                                  <td>{this.rescue_value(data.fillerID)}</td>
                                  <td>
                                    {this.rescue_value(data.product_code)}
                                  </td>
                                  <td>{this.rescue_value(data.amount)}</td>
                                  <td>{this.rescue_value(data.volume)}</td>
                                  {/*<td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>*/}
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="9" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
