import React from "react";
import { Col } from "adminlte-2-react";
import "./filter.css";
import DatePicker from "react-datepicker";
import $ from "jquery";

import "react-datepicker/dist/react-datepicker.css";

export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submitUrl: "",
      user_name: "",

      register_from: "",
      register_to: "",

      last_used_from: "",

      fuel_from: "",
      fuel_to: "",

      c_store_from: "",
      c_store_to: "",

      cafe_amazon_from: "",
      cafe_amazon_to: "",

      topup_from: "",
      topup_to: "",

      valueType: "",
      citySelect: "",

      valueRangeFrom: "",
      valueRangeTo: ""
    };

    //this.handleChange = this.handleChange.bind(this);
    //this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.regStart = this.regStart.bind(this);
    this.regEnd = this.regEnd.bind(this);
    this.lastUsedStart = this.lastUsedStart.bind(this);
    this.fuelStart = this.fuelStart.bind(this);
    this.fuelEnd = this.fuelEnd.bind(this);
    this.cStoreStart = this.cStoreStart.bind(this);
    this.cStoreEnd = this.cStoreEnd.bind(this);
    this.cafeAmazonStart = this.cafeAmazonStart.bind(this);
    this.cafeAmazonEnd = this.cafeAmazonEnd.bind(this);
    this.topupStart = this.topupStart.bind(this);
    this.topupEnd = this.topupEnd.bind(this);
    this.handleChangeRangeFrom = this.handleChangeRangeFrom.bind(this);
    this.handleChangeRangeTo = this.handleChangeRangeTo.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ value: event.target.value });
  // }
  // handleChangeType(event) {
  //   this.setState({ value: event.target.value });
  // }

  handleChangeAmount(event) {
    this.setState({ valueType: event.target.value });
  }
  handleChangeKeyword(event) {
    this.setState({ user_name: event.target.value });
  }
  regStart(date) {
    this.setState({ register_from: date });
  }
  regEnd(date) {
    this.setState({ register_to: date });
  }

  lastUsedStart(date) {
    this.setState({ last_used_from: date });
  }

  fuelStart(date) {
    this.setState({ fuel_from: date });
  }
  fuelEnd(date) {
    this.setState({ fuel_to: date });
  }

  cStoreStart(date) {
    this.setState({ c_store_from: date });
  }
  cStoreEnd(date) {
    this.setState({ c_store_to: date });
  }

  cafeAmazonStart(date) {
    this.setState({ cafe_amazon_from: date });
  }
  cafeAmazonEnd(date) {
    this.setState({ cafe_amazon_to: date });
  }

  topupStart(date) {
    this.setState({ topup_from: date });
  }
  topupEnd(date) {
    this.setState({ topup_to: date });
  }

  handleCity(event) {
    this.setState({ citySelect: event.target.value });
  }

  handleChangeRangeFrom(event) {
    this.setState({ valueRangeFrom: event.target.value });
  }

  handleChangeRangeTo(event) {
    this.setState({ valueRangeTo: event.target.value });
  }

  // handleSubmit(event) {
  //   event.preventDefault();
  // }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      type: "info",
      message: "Sending…"
    });

    $.ajax({
      url: "php/mailer.php",
      type: "POST",
      data: {
        user_name: this.state.user_name,

        register_from: this.state.register_from,
        register_to: this.state.register_to,

        last_used_from: this.state.last_used_from,

        fuel_from: this.state.fuel_from,
        fuel_to: this.state.fuel_to,

        c_store_from: this.state.c_store_from,
        c_store_to: this.state.c_store_to,

        cafe_amazon_from: this.state.cafe_amazon_from,
        cafe_amazon_to: this.state.cafe_amazon_to,

        topup_from: this.state.topup_from,
        topup_to: this.state.topup_to,

        valueType: this.state.valueType,
        citySelect: this.state.citySelect,

        valueRangeFrom: this.state.valueRangeFrom,
        valueRangeTo: this.state.valueRangeTo

        //form_msg: this.state.contactMessage
      },
      cache: false,
      success: function(data) {
        // Success..
        this.setState({
          type: "success",
          message:
            "We have received your message and will get in touch shortly. Thanks!"
        });
      }.bind(this),
      error: function(xhr, status, err) {
        this.setState({
          type: "danger",
          message:
            "Sorry, there has been an error.  Please try again later or visit us at SZB 438."
        });
      }.bind(this)
    });
  }

  render() {
    const { filter_options } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ marginTop: "5px", padding: "10px" }}
        className="row clearfix"
      >
        {filter_options.users && (
          <Col xs={4}>
            <div className="form-group">
              <label>User</label>
              <input
                type="text"
                value={this.state.value}
                onChange={this.handleChangeKeyword}
                className="form-control"
                placeholder="Enter keyword"
              />
            </div>
          </Col>
        )}
        {filter_options.amount && (
          <Col xs={4}>
            <div className="form-group">
              <label>Amount</label>
              <input
                type="text"
                value={this.state.valueType}
                onChange={this.handleChangeAmount}
                className="form-control"
                placeholder="Enter Amount"
              />
            </div>
          </Col>
        )}

        {filter_options.location && (
          <Col xs={4}>
            <div className="form-group">
              <label>Location</label>
              <select
                className="form-control"
                value={this.state.citySelect}
                onChange={this.handleCity}
              >
                <option value="Oman1">Oman 1</option>
                <option value="Oman2">Oman 2</option>
                <option value="Oman3">Oman 3</option>
              </select>
            </div>
          </Col>
        )}
        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>Registration</label>
              <DatePicker
                selected={this.state.register_from}
                selectsStart
                register_from={this.state.register_from}
                register_to={this.state.register_to}
                onChange={this.regStart}
              />
              <DatePicker
                selected={this.state.register_to}
                selectsEnd
                register_from={this.state.register_from}
                register_to={this.state.register_to}
                onChange={this.regEnd}
                minDate={this.state.register_from}
              />
            </div>
          </Col>
        )}

        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group">
              <label>Last Used</label>
              <DatePicker
                selected={this.state.last_used_from}
                selectsStart
                last_used_from={this.state.last_used_from}
                onChange={this.lastUsedStart}
              />
            </div>
          </Col>
        )}
        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>Fuel</label>
              <DatePicker
                selected={this.state.fuel_from}
                selectsStart
                fuel_from={this.state.fuel_from}
                fuel_to={this.state.fuel_to}
                onChange={this.fuelStart}
              />
              <DatePicker
                selected={this.state.fuel_to}
                selectsEnd
                fuel_from={this.state.fuel_from}
                fuel_to={this.state.fuel_to}
                onChange={this.fuelEnd}
                minDate={this.state.fuel_from}
              />
            </div>
          </Col>
        )}
        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>C-Store</label>
              <DatePicker
                selected={this.state.c_store_from}
                selectsStart
                c_store_from={this.state.c_store_from}
                c_store_to={this.state.c_store_to}
                onChange={this.cStoreStart}
              />
              <DatePicker
                selected={this.state.c_store_to}
                selectsEnd
                c_store_from={this.state.c_store_from}
                c_store_to={this.state.c_store_to}
                onChange={this.cStoreEnd}
                minDate={this.state.c_store_from}
              />
            </div>
          </Col>
        )}
        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>Cafe Amazon </label>
              <DatePicker
                selected={this.state.cafe_amazon_from}
                selectsStart
                cafe_amazon_from={this.state.cafe_amazon_from}
                cafe_amazon_to={this.state.cafe_amazon_to}
                onChange={this.cafeAmazonStart}
              />
              <DatePicker
                selected={this.state.cafe_amazon_to}
                selectsEnd
                cafe_amazon_from={this.state.cafe_amazon_from}
                cafe_amazon_to={this.state.cafe_amazon_to}
                onChange={this.cafeAmazonEnd}
                minDate={this.state.cafe_amazon_from}
              />
            </div>
          </Col>
        )}
        {filter_options.date_range && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>Topup</label>
              <DatePicker
                selected={this.state.topup_from}
                selectsStart
                topup_from={this.state.topup_from}
                topup_to={this.state.topup_to}
                onChange={this.topupStart}
              />
              <DatePicker
                selected={this.state.topup_to}
                selectsEnd
                topup_from={this.state.topup_from}
                topup_to={this.state.topup_to}
                onChange={this.topupEnd}
                minDate={this.state.topup_from}
              />
            </div>
          </Col>
        )}

        {filter_options.rage_field && (
          <Col xs={4}>
            <div className="form-group rangeFilter">
              <label>Range Field</label>
              <input
                type="text"
                value={this.state.valueRangeFrom}
                onChange={this.handleChangeRangeFrom}
                className="form-control"
                placeholder="From"
              />
              <input
                type="text"
                value={this.state.valueRangeTo}
                onChange={this.handleChangeRangeTo}
                className="form-control"
                placeholder="To"
              />
            </div>
          </Col>
        )}

        {/* <Col xs={3}>
          <div className="form-group">
            <select
              value={this.state.value}
              onChange={this.handleChange}
              className="form-control"
            >
              {data.map(data => (
                <option type={data.type} value={data.key}>
                  {data.title}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col xs={3}>
          <div className="form-group">
            <input
              type="text"
              value={this.state.valueType}
              onChange={this.handleChangeType}
              className="form-control"
              placeholder="Enter search..."
            />
          </div>
        </Col>*/}
        <Col xs={3}>
          {/*<button className="btn btn-primary mr-5">Add</button>
              <button className="btn btn-primary mr-5">Delete</button>*/}
        </Col>
        <Col xs={12} className="text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleFilter}
          >
            Submit
          </button>
        </Col>
      </form>
    );
  }
}
