import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
// import "./User.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment";

const CardRepository_url = API.apiEndpoint + API.cardsRepositoryList;
const uploadCardsRepository_url = API.apiEndpoint + API.uploadCardsRepository;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class CardRepository extends Component {
  state = {
    CardRepository_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    tableData: [],
    totalData: "",
    search_date: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        CardRepository_url +
        "?CardRepositoryListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        CardRepository_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }
  openModal = async (data) => {
    this.setState({ totalData: data });
    this.setState({ tableData: data.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
  };
  deleteCardRepository = async (val, event) => {
    confirmAlert({
      title: "Card Repository",
      message: "Are you sure you want to delete Card Repository?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmDeleteCardRepository(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmDeleteCardRepository = async (val, event) => {
    let api_response = {};
    let options = {
      url: CardRepository_url + "/" + val,
    };

    api_response = await ApiRequest.delete(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/cash_back_promotion";
        }.bind(this),
        100
      );
      // sessionStorage.clear();
    }
  };
  searchDate = async (date) => {
    this.setState({
      search_clear: false,
      search_date: date,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "createdAt", date);
  };
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      status_input_value: "",
      search_date: "",
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      search_date: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };
  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(uploadCardsRepository_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Card successfully uploaded",
        });
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          5000
        );
        this.openModal(rsp.data);
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };
  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const {
      loading,
      error,
      totalData,
      message,
      CardRepository_list,
      pagination,
      limit,
      search_select_value,
      search_input_value,
      status_input_value,
    } = this.state;
    return (
      <div>
        <Content title="Card Repository" browserTitle="Card Repository">
          <LoadingAjax />
          <Row>
            {error && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}

            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Card Repository </h3>
                  <div className="box-tools">
                    <div>
                      <a
                        className="btn btn-sm btn-primary mr-5"
                        href="Sample-Card-Repository.xlsx"
                      >
                        Sample CSV
                      </a>
                      <a
                        class="btn btn-sm btn-primary mr-5"
                        href={"/card_repository_form/"}
                      >
                        Add to Basma Wishlist
                      </a>
                      <input
                        id="uploadList"
                        type="file"
                        name="file"
                        ref={(ref) => (this.uploadList = ref)}
                        onChange={this.onClickHandler}
                        style={{ display: "none" }}
                        accept=".xlsx, .csv, .xls"
                      />
                      {ApiRequest.can_access(
                        "basma-white-list",
                        "can_upload"
                      ) && (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={(e) => this.uploadList.click()}
                          // onClick={this.openModal}
                        >
                          {"Upload into Card Repository"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>

                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"store_name"}>Store Name</option>
                          <option value={"store_code"}>Store Code</option>
                          <option value={"card_number"}>Card Number</option>
                          <option value={"serial_number"}>Serial Number</option>
                          <option value={"createdAt"}>Date</option>
                          <option value={"status"}>Status</option>
                        </select>
                        {search_select_value === "status" ? (
                          <select
                            onChange={this.handleStatusChange}
                            value={status_input_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value={""}>Select</option>
                            <option value={"1"}>Active</option>
                            <option value={"0"}>Inactive</option>
                          </select>
                        ) : search_select_value === "createdAt" ? (
                          <DatePicker
                            className="filterDate"
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.search_date}
                            selectsStart
                            onChange={this.searchDate}
                            placeholderText=""
                          />
                        ) : (
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                        )}
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>Card Number</th>
                              <th>Serial Number</th>
                              <th>Store Name</th>
                              <th>Store Code</th>
                              <th>Date Added</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {CardRepository_list.length > 0 ? (
                              CardRepository_list.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.card_number)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.serial_number)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.store_name)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.store_code)}
                                    </td>
                                    <td>
                                      {ApiRequest.date_format_utc(
                                        data.createdAt
                                      )}
                                    </td>
                                    <td>{this.rescue_status(data.status)}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Inserted</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Serial Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.data.serial_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
