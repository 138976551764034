import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const view_survey_url = API.apiEndpoint + API.survey;

export default class SurveyView extends React.Component {
  state = {
    survey_view: {},
    loading: true,
    error: false,
    message: "",
    base_url: API,
  };

  async componentDidMount() {
    let url = view_survey_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        survey_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { survey_view, loading } = this.state;
    return (
      <Content title={"Survey"} browserTitle="Survey">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Survey Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Name</b>{" "}
                      <span className="pull-right">{survey_view.name.en} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Description</b>
                      <span className="pull-right">
                        {survey_view.description.en}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>
                      <span className="pull-right">
                        {survey_view.active == 1 ? "Active" : "Inactive"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Start</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(survey_view.start)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>End</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(survey_view.end)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Loyalty GameId</b>
                      <span className="pull-right">
                        {survey_view.loyaltyGameId}
                      </span>
                    </li>
                    {/*<li className="list-group-item">
                      <b>Weightage</b>
                      <span className="pull-right">
                        {survey_view.passingWeightage}
                      </span>
                   </li>
                    <li className="list-group-item">
                      <b>Trigger</b>
                      <span className="pull-right">{survey_view.trigger}</span>
                    </li>
                    {survey_view.triggerValue ? (
                      <li className="list-group-item">
                        <b>Trigger Value</b>
                        <span className="pull-right">
                          {survey_view.triggerValue}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}*/}
                    {survey_view.sponsor_name ? (
                      <li className="list-group-item">
                        <b>Sponsor Name</b>
                        <span className="pull-right">
                          {survey_view.sponsor_name}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {survey_view.sponsor_image ? (
                      <li className="list-group-item sponsor_image_li">
                        <b>Sponsor Image</b>
                        <span className="pull-right">
                          <img
                            className="thumnailImg"
                            src={
                              this.state.base_url.apiEndpoint +
                              "/" +
                              survey_view.sponsor_image
                            }
                          />
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/*<li className="list-group-item">
                  <b>Question</b>
                  <span className="pull-right">
                    {survey_view.questions.length > 0 &&
                      survey_view.questions.map((item, key) => (
                        <div>
                          <div>{item.id}</div> <div>{item.weightage}</div>
                        </div>
                      ))}
                  </span>
                      </li>*/}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
