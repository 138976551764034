import React from "react";
import CmsListing from "./listing";
import CmsForm from "./form";

export default class cms extends React.Component {
  state = {
    action: "listing",
    page: {}
  };

  handleClick = (page, action) => {
    this.setState({ action: action, page: page });
  };

  render() {
    const { action, page } = this.state;
    if (action === "edit" || action === "add")
      return (
        <CmsForm action={action} page={page} handleClick={this.handleClick} />
      );
    else return <CmsListing handleClick={this.handleClick} />;
  }
}
