import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
//import { Inputs } from "adminlte-2-react";
import LoadingAjax from "../../components/Loading";
const role_url = API.apiEndpoint + API.role;
const getModule_url = API.apiEndpoint + API.getModule;
const store_url = API.apiEndpoint + API.store;

let defaultPaymentMethodsArr = [
  { value: "Cash", checked: true, label: "Cash" },
  { value: "Visa", checked: true, label: "Visa" },
  { value: "BankTransfer", checked: true, label: "BankTransfer" },
  // {
  //   value: "TransferBal",
  //   checked: true,
  //   label: "TransferBal",
  // },
  { value: "ePayment", checked: true, label: "Thawani" },
  { value: "3rd_Party_Loyalty", checked: true, label: "3rd Party Loyalty" }
];

export default class RolesForm extends React.Component {
  state = {
    page: {},
    action: "add",
    name: "",
    modules: [],
    error: false,
    message: "",
    loading: true,
    alias: "",
    success: false,
    success_message: "",
    store_code: "",
    store_token: "",
    stores: [],
    allowed_payment_methods: defaultPaymentMethodsArr,
    terminal_id: "",
    topupLimitMin: "",
    topupLimitMax: "",
  };

  handleChangeStoreName = async (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var store_token = optionElement.getAttribute("store_token");
    this.setState({
      store_code: event.target.value,
      store_token: store_token,
    });
  };
  async componentDidMount() {
    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        name: this.props.page.name,
        terminal_id: this.props.page.terminal_id,
        topupLimitMin: this.props.page.topupLimitMin,
        topupLimitMax: this.props.page.topupLimitMax,
        store_code: this.props.page.store_code,
        store_token: this.props.page.store_token,
        stores: store_response.data,
        allowed_payment_methods: this.props.page.allowed_payment_methods
          ? JSON.parse(this.props.page.allowed_payment_methods)
          : defaultPaymentMethodsArr,
        modules:
          typeof this.props.page.permissions === "string"
            ? JSON.parse(this.props.page.permissions)
            : this.props.page.permissions,
        page: this.props.page,
        alias: this.props.page.alias,
        loading: false,
      });
    } else {
      let options = { url: getModule_url };
      let api_response = await ApiRequest.get(options);

      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          modules: api_response.data,
          stores: store_response.data,
          store_code: store_response.data[0].store_code,
          store_token: store_response.data[0].store_token,
          loading: false,
          error: false,
          message: api_response.message,
        });
      }
    }
  }
  permissionChange = (action_name, module_index, event) => {
    let modules = this.state.modules;
    modules[module_index]["permissions"][action_name] = event.target.checked;
    this.setState({ modules });
  };
  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  terminalIdChange = (event) => {
    this.setState({ terminal_id: event.target.value });
  };
  topupLimitMinChange = (event) => {
    this.setState({ topupLimitMin: event.target.value });
  };
  topupLimitMaxChange = (event) => {
    this.setState({ topupLimitMax: event.target.value });
  };

  saveRole = async (event) => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("terminal_id", this.state.terminal_id);
    formData.append("topupLimitMin", this.state.topupLimitMin);
    formData.append("topupLimitMax", this.state.topupLimitMax);
    formData.append("permissions", JSON.stringify(this.state.modules));
    formData.append("store_code", this.state.store_code);
    formData.append("store_token", this.state.store_token);
    formData.append(
      "allowed_payment_methods",
      JSON.stringify(this.state.allowed_payment_methods)
    );

    let api_response = {};
    let options = { url: role_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      formData.append("alias", this.state.alias);
      options = {
        url: role_url + "/" + this.state.page.id,
        form_data: formData,
      };
      api_response = await ApiRequest.put(options);
    }
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/roles";
        }.bind(this),
        10000
      );
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("terminal_id", this.state.terminal_id);
    formData.append("topupLimitMin", this.state.topupLimitMin);
    formData.append("topupLimitMax", this.state.topupLimitMax);
    // formData.append("modules", this.state.modules);
  };

  backRole = () => {
    window.location = "/roles";
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTopupMethod(e, i) {
    let allowed_payment_method = this.state.allowed_payment_methods;
    allowed_payment_method[i].checked = e.target.checked ? true : false;
    this.setState({ allowed_payment_method });
  }

  render() {
    const {
      modules,
      error,
      message,
      stores,
      success,
      success_message,
      allowed_payment_methods,
    } = this.state;
    return (
      <Content title="Roles Page" browserTitle="Roles Page">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Roles Form </h3>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="box-body">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.nameChange}
                      />
                    </div>
                    <div className="col-xs-4 cusSelect">
                      {
                        <div className="form-group">
                          <label>Store</label>
                          <select
                            className="form-control"
                            id="store"
                            name="store"
                            onChange={this.handleChangeStoreName}
                            value={this.state.store_code}
                          >
                            {stores.length > 0 &&
                              stores.map((item, key) => (
                                <option
                                  value={item.store_code}
                                  store_token={item.store_token}
                                >
                                  {item.store_name + " - " + item.store_code}
                                </option>
                              ))}
                          </select>
                          {/*<input
                            type="hidden"
                            value={this.state.selectedStoreToken}
                            name="storeToken"
                          />*/}
                        </div>
                      }

                      {/*<Select2
                        label="Stores"
                        className="form-control"
                        value={this.state.selectedOption}
                        onChange={this.handleChangeStoreName}
                        options={stores}
                        placeholder="Select"
                        selectedValue={this.state.selectedStore}
                      />*/}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="terminalId">Terminal ID</label>
                      <input
                        min="0"
                        type="number"
                        className="form-control"
                        id="terminalId"
                        placeholder="Enter terminal id"
                        //name="name"
                        value={this.state.terminal_id}
                        onChange={this.terminalIdChange}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="topupLimitMin">Topup limit Min</label>
                      <input
                        min="0"
                        type="number"
                        className="form-control"
                        id="topupLimitMin"
                        placeholder="Enter Topup Limit"
                        //name="name"
                        value={this.state.topupLimitMin}
                        onChange={this.topupLimitMinChange}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="topupLimitMax">Topup limit Max</label>
                      <input
                        min="0"
                        type="number"
                        className="form-control"
                        id="topupLimit"
                        placeholder="Enter Topup Limit"
                        //name="name"
                        value={this.state.topupLimitMax}
                        onChange={this.topupLimitMaxChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <h3 className="box-title">Permissions</h3>
                  </div>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table style={{ width: 525 }}>
                        <tr>
                          <td>Topup Method</td>
                          {allowed_payment_methods.map((item, i) => (
                            <td>
                              <label>
                                {item.label}
                                <input
                                  onChange={(e) => this.handleTopupMethod(e, i)}
                                  value={item.value}
                                  type="checkbox"
                                  checked={item.checked}
                                />
                              </label>
                            </td>
                          ))}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <h3 className="box-title">Permissions</h3>
                  </div>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <tbody>
                          {modules.map((e, i) => (
                            <tr>
                              <td>{e.name}</td>
                              {Object.keys(e.permissions).map((item, key) => (
                                <td>
                                  <label>
                                    {ApiRequest.string_humanize_format(item)}
                                    <input
                                      type="checkbox"
                                      checked={e.permissions[item]}
                                      onChange={this.permissionChange.bind(
                                        null,
                                        item,
                                        i
                                      )}
                                    />
                                  </label>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.saveRole}
                    className="btn btn-primary pull-right "
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.backRole}
                    className="btn btn-default pull-right mr-10"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
