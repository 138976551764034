import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import AdminLTE, { Inputs } from "adminlte-2-react";
import DatePicker from "react-datepicker";
//import axios from "axios";
import ApiRequest from "./../../ApiRequest";
import API from "./../../constants";
import LoadingSpinner from "./../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import LoadingAjax from "../../components/Loading";
import { Chart } from "react-google-charts";
require("bootstrap/less/bootstrap.less");

const cardTransactions_url = API.apiEndpoint + API.reportCardTransactions;
const cardTransactionsType_url = API.apiEndpoint + API.cardTransactionsType;
const cardTransactionsTopupMethods_url =
  API.apiEndpoint + API.cardTransactionsTopupMethods;
const store_url = API.apiEndpoint + API.store;
const requestFrom_url = API.apiEndpoint + API.requestFrom;
const analyticsCardTransaction_url =
  API.apiEndpoint + API.analyticsCardTransaction;
const city_url = API.apiEndpoint + API.city;

export default class CardTransactionsReports extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    transaction_type: "",
    transaction_date_from: "",
    transaction_date_to: "",
    topup_method: "",
    request_from: "",
    card_trans_report: [],
    card_trans_type: [],
    card_trans_method: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    stores: [],
    store_code: "",
    reqFrom: [],
    graphSalesDate: [],
    topupTotalData: [],
    loadingCart: false,
    cities: [],
  };

  async componentDidMount() {
    let options = { url: cardTransactionsType_url };
    let optionsTopupMethods = { url: cardTransactionsTopupMethods_url };
    //let optionsCardTrans = { url: cardTransactions_url };
    let optionsStore = { url: store_url };
    let optionsReqFrom = { url: requestFrom_url };
    let optionsCity = { url: city_url };

    let api_response = await ApiRequest.get(options);
    let topupMethod_response = await ApiRequest.get(optionsTopupMethods);
    //let cardTrans_response = await ApiRequest.get(optionsCardTrans);
    let store_response = await ApiRequest.get(optionsStore);
    let ReqFrom_response = await ApiRequest.get(optionsReqFrom);
    let city_response = await ApiRequest.get(optionsCity);

    if (api_response.error || store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({ value: store.store_code, label: store.store_name });
      });

      let cities = [];
      city_response.data.map((city) => {
        cities.push({ value: city.cityId, label: city.name });
      });

      let card_trans_type = [];
      api_response.data.map((transType) => {
        card_trans_type.push({
          label: ApiRequest.string_humanize_format(transType),
          value: transType,
        });
      });

      let card_trans_method = [];
      topupMethod_response.data.map((tansMethod) => {
        card_trans_method.push({ label: tansMethod, value: tansMethod });
      });

      let reqFrom = [];
      ReqFrom_response.data.map((tansMethod) => {
        reqFrom.push({ label: tansMethod, value: tansMethod });
      });

      this.setState({
        stores: stores,
        card_trans_type: card_trans_type,
        card_trans_method: card_trans_method,
        reqFrom: reqFrom,
        cities: cities,
        // card_trans_report: cardTrans_response.data.results,
        // pagination: cardTrans_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          card_trans_report: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  handleChangeRegion = async (selectedRegion) => {
    this.setState({
      cityId: selectedRegion.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = cardTransactions_url + "?page=" + pageNumber;

    let analyticsCardTransactionReport =
      analyticsCardTransaction_url + "?page=" + pageNumber;

    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;

      analyticsCardTransactionReport =
        analyticsCardTransactionReport +
        "&transaction_type=" +
        this.state.transaction_type;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport +
        "&transaction_date_from=" +
        this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport +
        "&transaction_date_to=" +
        this.state.transaction_date_to;
    }
    if (this.state.topup_method) {
      url = url + "&topup_method=" + this.state.topup_method;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport +
        "&topup_method=" +
        this.state.topup_method;
    }
    if (this.state.request_from) {
      url = url + "&request_from=" + this.state.request_from;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport +
        "&request_from=" +
        this.state.request_from;
    }
    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport + "&store_code=" + this.state.store_code;
    }
    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
      analyticsCardTransactionReport =
        analyticsCardTransactionReport + "&cityId=" + this.state.cityId;
    }

    let options = { url: url };
    let analyticsCardTransactionReportUrl = {
      url: analyticsCardTransactionReport,
    };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        card_trans_report: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }

    let analyticsCardTransactionReport_response = await ApiRequest.get(
      analyticsCardTransactionReportUrl,
      true,
      false
    );
    this.setState({
      loadingCart: false,
    });

    if (!analyticsCardTransactionReport_response.error) {
      if (analyticsCardTransactionReport_response.data) {
        let topupTotalData = analyticsCardTransactionReport_response.data;
        this.setState({ topupTotalData: topupTotalData });
      }

      if (
        analyticsCardTransactionReport_response.data &&
        analyticsCardTransactionReport_response.data.graphSalesDate
      ) {
        let graphSalesDate =
          analyticsCardTransactionReport_response.data.graphSalesDate;
        this.setState({ graphSalesDate: graphSalesDate });
      }
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = cardTransactions_url + "?csv=" + 1;

    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
    }
    if (this.state.topup_method) {
      url = url + "&topup_method=" + this.state.topup_method;
    }
    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }
    if (this.state.request_from) {
      url = url + "&request_from=" + this.state.request_from;
    }
    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
    }

    let options = {
      url: url,
      file_name:
        "Card Transaction Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };

    await ApiRequest.download(options);
  };
  handleByTransactionType = (event) => {
    this.setState({
      transaction_type: event.target.value,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  byDateStart = (date) => {
    this.setState({
      transaction_date_from: date,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      transaction_date_to: date,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  handleTopupMethod = (event) => {
    this.setState({
      topup_method: event.target.value,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  handleRequestForm = (event) => {
    this.setState({
      request_from: event.target.value,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  handleChangeStoreName = async (selectedOption) => {
    this.setState({
      store_code: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };
  rescue_value(value) {
    return value === undefined || value === "" ? "-" : value;
  }
  noResultText() {
    return "N/A";
  }
  graphData(graphSalesDate) {
    let data = [];
    let defaultData = ["x", "Top Up", "Redemption", "Refuel Count"];
    data.push(defaultData);
    if (graphSalesDate) {
      graphSalesDate.map(function (item, i) {
        const { topupsCount, redemptionCount, refuelCount } = item;
        data.push([
          (++i).toString(),
          topupsCount,
          redemptionCount,
          refuelCount,
        ]);
      });
      return data;
    }
    return data;
  }

  render() {
    const { Select2 } = Inputs;
    const {
      pagination,
      card_trans_report,
      card_trans_type,
      card_trans_method,
      loading,
      error,
      callApi,
      enableBtn,
      limit,
      message,
      stores,
      reqFrom,
      topupTotalData,
      graphSalesDate,
      loadingCart,
      cities,
    } = this.state;
    
    return (
      <Content
        title="Card Transactions Reports"
        browserTitle="Card Transactions Reports"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Transaction Type"
                    className="form-control"
                    value={this.state.transaction_type}
                    onChange={this.handleByTransactionType}
                    options={card_trans_type}
                    placeholder="Select"
                  />
                </div>

                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      selected={this.state.transaction_date_from}
                      selectsStart
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      selected={this.state.transaction_date_to}
                      selectsEnd
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.transaction_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>

                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Payment Method"
                    className="form-control"
                    value={this.state.topup_method}
                    onChange={this.handleTopupMethod}
                    options={card_trans_method}
                    placeholder="Select"
                  />
                </div>

                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Request From"
                    className="form-control"
                    value={this.state.request_from}
                    onChange={this.handleRequestForm}
                    options={reqFrom}
                    placeholder="Select"
                  />
                </div>

                {/*<div className="col-xs-4">
                  <div className="form-group">
                    <label>Request From</label>
                    <select
                      className="form-control"
                      value={this.state.request_from}
                      onChange={this.handleRequestForm}
                    >
                      <option value="">Select</option>
                      <option value="ahlain">Ahlain</option>
                      <option value="mobile_app">Mobile App</option>
                      <option value="fuel">Fuel</option>
                      <option value=" regional_offices">
                        Regional/Ho Offices
                      </option>
                    </select>
                  </div>
                </div>*/}
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Region"
                    value={this.state.selectedRegion}
                    onChange={this.handleChangeRegion}
                    options={cities}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Total Topup</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.topupSum ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {topupTotalData.topupSum}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Refueling
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.RefuelingSum ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {topupTotalData.RefuelingSum}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Loyalty Redemption
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.TotalLoyaltyRedemption ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {topupTotalData.TotalLoyaltyRedemption}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total ePayment (Topup)
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.TotalEPayments ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {topupTotalData.TotalEPayments}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Station (Topup)
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.TotalStationTopups ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {topupTotalData.TotalStationTopups}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Admin Panel Topup
                        </h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : topupTotalData.TotalAdminPanelTopupsOtherMethods ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {
                                        topupTotalData.TotalAdminPanelTopupsOtherMethods
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Weekly Transactions</h3>
              </div>
              <div className="box-body">
                {loadingCart ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    {graphSalesDate.length > 0 ? (
                      <Chart
                        width={"100%"}
                        height={"300px"}
                        chartType="LineChart"
                        data={this.graphData(graphSalesDate)}
                      />
                    ) : (
                      <div style={{ textAlign: "center", padding: "50px 0" }}>
                        {this.noResultText()}
                      </div>
                    )}
                    <div className="table-responsive">
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Index</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Topups Count</th>
                            <th>Redemption Count</th>
                            <th>Refuel Count</th>
                          </tr>
                        </thead>

                        <tbody>
                          {graphSalesDate.length > 0 && graphSalesDate ? (
                            graphSalesDate.map((data, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.startDate)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.endDate)}
                                  </td>
                                  <td>{this.rescue_value(data.topupsCount)}</td>
                                  <td>
                                    {this.rescue_value(data.redemptionCount)}
                                  </td>
                                  <td>{this.rescue_value(data.refuelCount)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                    disabled={!enableBtn}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div class="box-body">
                <div class="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        class="table no-margin"
                        id="export-table-card-trans"
                      >
                        <thead>
                          <tr>
                            <th>Transaction Type</th>
                            <th>Card Serial Number</th>
                            <th>Card Number</th>
                            <th>Car Number</th>
                            <th>Payment Method</th>
                            <th>Request From</th>
                            <th>Volume</th>
                            <th>Store Code</th>
                            <th>Amount</th>
                            <th>Terminal ID</th>
                            <th>Reference ID</th>
                            <th>Transaction ID</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {card_trans_report.length > 0 && card_trans_report ? (
                            card_trans_report.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {" "}
                                    {ApiRequest.string_humanize_format(
                                      this.rescue_value(data.operation)
                                    )}
                                  </td>

                                  <td>{this.rescue_value(data.card_number)}</td>
                                  <td>
                                    {this.rescue_value(data.serial_number)}
                                  </td>
                                  <td>{this.rescue_value(data.car_plate)}</td>
                                  <td>
                                    {this.rescue_value(data.topup_method)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.request_from)}
                                  </td>
                                  <td>{this.rescue_value(data.volume)}</td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>
                                    {this.rescue_value(data.topup_amount)}
                                  </td>
                                  <td>{this.rescue_value(data.terminal_id)}</td>
                                  <td>
                                    {this.rescue_value(data.reference_id)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.transaction_id)}
                                  </td>
                                  {/*<td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>*/}
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="9" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
