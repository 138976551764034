import React, { Component } from "react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const user_url = API.apiEndpoint + API.adminUser;

export default class UserDelete extends Component {
  async componentDidMount() {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      let options = { url: user_url + "/" + this.props.match.params.id };

      let api_response = await ApiRequest.delete(options);
      if (api_response.error) {
        window.alert(api_response.message);
        window.location = "/admin_user";
      } else {
        window.alert(api_response.message);
        window.location = "/admin_user";
      }
    } else {
      window.location = "/admin_user";
    }
  }
  render() {
    return <LoadingAjax />;
  }
}
