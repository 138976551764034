import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";

const businessUserRequestsList_url = API.apiEndpoint + API.businessUserRequests;
const businessUserRequests_change_status_url =
  API.apiEndpoint + API.businessUserRequests_change_status_url;

export default class BusinessUserRequestsListing extends React.Component {
  constructor(props) {
    super(props);
    this.changeStatus = this.changeStatus.bind(this);
  }
  state = {
    businessUserRequestsList: [],
    pagination: {},
    limit: 10,
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    date_from: new Date(),
    date_to: new Date(),
    loading: true,
    error: false,
    message: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = "",
    date_from = null,
    date_to = null
  ) => {
    let options = {
      url:
      businessUserRequestsList_url +
      "?page=" +
      pageNumber +
      "&limit=" +
      limit +
      "&filter=" +
      filter +
      "&key=" +
      key +
      "&value=" +
      value,
    };
    
    if (this.state.date_from) {
      options.url = options.url + "&date_from=" + (date_from !== null ? date_from : this.state.date_from);
    }
    
    if (this.state.date_to) {
      options.url = options.url + "&date_to=" + (date_to !== null ? date_to : this.state.date_to);
    }
    
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {

      this.setState({
        businessUserRequestsList: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    this.getData();
  }

  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value, this.state.filter, this.state.search_select_value, this.state.search_input_value, this.state.date_from, this.state.date_to);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value,
      this.state.date_from,
      this.state.date_to
    );
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value,
      this.state.date_from,
      this.state.date_to
    );
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      date_from: new Date(),
      date_to: new Date(),
      filter: false,
    });
    await this.getData(1, this.state.limit, false, "", "", new Date(), new Date());
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  renderStatus(status) {
    if (status === "pending") {
      return (
        <span
          style={{
            background: "rgba(255,191,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    } else if (status === "accepted") {
      return (
        <span
          style={{
            background: "rgba(0,255,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    } else if (status === "declined") {
      return (
        <span
          style={{
            background: "rgba(255,0,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    }
  }
  renderRequestType(request_type) {
    if (request_type === "refueling_limit") {
      return (
        <span>
          Refueling
        </span>
      );
    } else if (request_type === "card_limit") {
      return (
        <span>
          Card Activation
        </span>
      );
    } else if (request_type === "card_refueling_limit") {
      return (
        <span>
          Card Activation & Refueling
        </span>
      );
    } else {
      return (
        <span>
          -
        </span>
      )
    }
  }

  changeStatus = (businessUserRequestId, newStatus) => {
    confirmAlert({
      title: "Business User Request",
      message: `Are you sure you want to ${
        newStatus === "accepted" ? "accept" : "decline"
      } this user's request to become a business user?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const formData = new FormData();
            formData.append("id", businessUserRequestId);
            formData.append("status", newStatus);
            formData.append("acceptedBy", localStorage.getItem("user_id"));

            let api_response = {};
            let options = {
              url: businessUserRequests_change_status_url,
              form_data: formData,
            };
            this.setState({ loading: true });
            api_response = await ApiRequest.post(options);
            if (api_response.error) {
              this.setState({
                loading: false,
                error: true,
                message: api_response.message,
              });
            } else {
              this.setState({
                loading: false,
                success: true,
                success_message: api_response.message,
              });
              await this.getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  renderRequestDocuments = (data) => {
    if(!data.user_type || data.user_type === "none") {
      return (
        <div>-</div>
      )
    }
    if (data.user_type === "individual") {
      return (
        <div>
          <a
            href={data.mulkia_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Mulkia
          </a>
          <a
            href={data.car_picture_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Car Picture
          </a>
        </div>
      )
    } else if (data.user_type === "business") {
      return (
        <div>
          <a  
            href={data.business_cr_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Business CR
          </a>
          <a
           href={data.letter_head_url}
           target="_blank"
           rel="noreferrer"
           className="btn btn-primary btn-xs mr-5"
           >
            Letter Head
           </a>
        </div>
      )
    }
  };

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;
    return (
      <Content
        title="Business User Requests"
        browserTitle="Business User Requests"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Business User Requests</h3>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>
                      <div className="col-xs-4" style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "3px"}}>
                        <div className="form-group rangeFilter">
                          <label>By Date</label>
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.date_from}
                            selectsStart
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            onChange={this.byDateStart}
                            placeholderText="From"
                          />
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.date_to}
                            selectsEnd
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            onChange={this.byDateEnd}
                            minDate={this.state.date_from}
                            placeholderText="To"
                          />
                        </div>
                        <button
                          onClick={this.getData.bind(this, 1, limit, this.state.filter, this.state.search_select_value, this.state.search_input_value, this.state.date_from, this.state.date_to)}
                          className="btn btn-primary"
                          style={{marginTop: "9px"}}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-xs-4">
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"oomcoId"}>Oomco ID</option>
                          </select>
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                          <button
                            className="btn btn-primary cusSearchBtn"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Oomco ID</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Requested At</th>
                            <th>Status Updated At</th>
                            <th>Status Changed By</th>
                            <th>Change Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.businessUserRequestsList.length > 0 &&
                          this.state.businessUserRequestsList ? (
                            this.state.businessUserRequestsList.map(
                              (data, i) => {
                                return (
                                  <tr>
                                    <td>{this.rescue_value(data.id)}</td>
                                    <td>
                                      {data?.oomcoId ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={"/app_view/" + data.user_id}
                                        >
                                          {data.oomcoId}
                                        </a>
                                      ) : (
                                        <p>-</p>
                                      )}
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {this.renderStatus(data.status)}
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {this.renderRequestType(data.request_type)}
                                    </td>
                                    <td>
                                      {ApiRequest.date_time_format(
                                        data.createdAt
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {data.acceptedAt
                                        ? ApiRequest.date_time_format(
                                            data.acceptedAt
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {
                                        !data.acceptedByData?.id ? (
                                          <div style={{textAlign: "center"}}>-</div>
                                        ) : (
                                          <a
                                          class="btn btn-primary btn-xs mr-5"
                                          href={
                                            "/admin_user_view/" +
                                            data.acceptedByData.id
                                          }
                                        >
                                          {data.acceptedByData.name}
                                        </a>
                                        )
                                      }
                                    </td>
                                    <td> 
                                      <select
                                        className="form-control"
                                        value={
                                          data.status !== "pending"
                                            ? data.status
                                            : ""
                                        }
                                        onChange={(e) =>
                                          this.changeStatus(
                                            data.id,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="" disabled>
                                          Select Status
                                        </option>
                                        <option
                                          value="accepted"
                                          style={{
                                            background: "rgba(0,255,0,0.15)",
                                            fontSize: "16px"
                                          }}
                                        >
                                         Accepted
                                        </option>
                                        <option
                                          value="declined"
                                          style={{
                                            background: "rgba(255,0,0,0.15)",
                                            fontSize: "16px"
                                          }}
                                        >
                                          Declined
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/business_user_requests_view/" + data.id}
                                      >
                                        <i class="fa fa-eye" />
                                      </a>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
