import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import LoadingAjax from "../../components/Loading";
import Select from "react-select";

require("bootstrap/less/bootstrap.less");

const topupPromReport_url = API.apiEndpoint + API.TopUpReport;
const topupPromotion_url = API.apiEndpoint + API.TopupRewardPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;
const subTiers_for_parent_tier_url = API.apiEndpoint + API.getSubTiersForParentTier;

export default class OnlineTopupRewardsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      topupPromReport: [],
      availableParentTiers: [],
      selectedParentTier: null,
      availableLoyaltySubTiers: [],
      selectedLoyaltySubTier: null,
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      callApi: false,
      enableBtn: false,
      loadingCart: false,
    };
  }
  handleSubmit = async (event) => {
    await this.getReportData(1, this.state.topup_promotion_id, this.state.selectedParentTier, this.state.selectedLoyaltySubTier);
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber, this.state.topup_promotion_id, this.state.selectedParentTier, this.state.selectedLoyaltySubTier);
  };
  getReportData = async (pageNumber = 1, topup_promotion_id = null, selectedParentTier = null, selectedLoyaltySubTier = null ) => {
    this.setState({ loading: true, loadingCart: true });

    let url = topupPromReport_url + "?page=" + pageNumber;

    if (topup_promotion_id) {
      url =
        url +
        "&filter=true&key=promotion_id&value=" +
        topup_promotion_id;
    }

    if (selectedParentTier?.value) {
      url = url + "&parentTier=" + selectedParentTier.value;
    }

    if (selectedLoyaltySubTier?.value) {
      url = url + "&subTier=" + selectedLoyaltySubTier.value;
    }

    let options = { url: url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        topupPromReportCard: api_response.data,
        topupPromReport: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }
    this.setState({
      loadingCart: false,
    });
  };
  async componentDidMount() {
    let options = { url: topupPromReport_url };
    let api_response = await ApiRequest.get(options);

    let cashBackPromotion_options = {
      url: topupPromotion_url + "?allRecords=1",
    };
    let cashBackPromotion_options_response = await ApiRequest.get(
      cashBackPromotion_options
    );

    // Get Parent Tiers
    let parentTierOptions = { url: tierTargetList_url };
    let parentTierOptions_response = await ApiRequest.get(parentTierOptions);
    let parentTiers = [];
    parentTierOptions_response.data.forEach((store) => {
      parentTiers.push({
        value: store.CardTypeName,
        label: store.CardTypeName,
      });
    });


    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        cashBackTierName: cashBackPromotion_options_response.data.results,
        topupPromReportCard: api_response.data,
        topupPromReport: api_response.data.results,
        availableParentTiers: parentTiers,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          topupPromReportCard: api_response.data,
          topupPromReport: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }
  downloadData = async (pageNumber = 1) => {
    let url = topupPromReport_url + "?csv=" + 1;

    if (this.state.topup_promotion_id) {
      url = url + "&topup_promotion_id=" + this.state.topup_promotion_id;
    }

    let options = {
      url: url,
      file_name:
        "Topup Promotions Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };

    await ApiRequest.download(options);
  };
  handleTierChange = (event) => {
    this.setState({ topup_promotion_id: event.target.value, enableBtn: true });
  };
  handleParentTierChange = async (selectedParentTier) => {
    // Get Available Sub Tiers for Parent Tier
    let options = { url: subTiers_for_parent_tier_url + "/" + selectedParentTier.value };
    let subTiers_response = await ApiRequest.get(options);
    if (subTiers_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: subTiers_response.message,
      });
    } else {
      let availableSubTiers = [];
      subTiers_response.data.forEach((subTier) => {
          availableSubTiers.push({
            value: subTier.id,
            label: subTier.name,
          });
        });
      this.setState({
        selectedParentTier,
        availableLoyaltySubTiers: availableSubTiers,
        enableBtn: true
      });
    }
  };
  handleChangeSubTier = (selectedSubTier) => {
    this.setState({ selectedLoyaltySubTier: selectedSubTier, enableBtn: true });
  }
  handleClearFilter = async () => {
    this.setState({
      topup_promotion_id: "",
      selectedParentTier: null,
      selectedLoyaltySubTier: null,
      enableBtn: false,
    });
    await this.getReportData(1, null, null, null);
  };
  noResultText() {
    return "N/A";
  }

  renderLoader() {
    return <LoadingSpinner />;
  }

  render() {
    const {
      pagination,
      topupPromReport,
      topupPromReportCard,
      loading,
      loadingCart,
      error,
      limit,
      message,
      callApi,
      enableBtn,
      cashBackTierName,
      availableParentTiers,
      selectedParentTier,
      availableLoyaltySubTiers,
      selectedLoyaltySubTier,
    } = this.state;
    return (
      <Content
        title="Topup Promotions Report"
        browserTitle="Topup Promotions Report"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Name</label>

                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.selectedTierNameOption}
                      onChange={this.handleTierChange}
                    >
                      <option value="">Select Name</option>
                      {cashBackTierName &&
                        cashBackTierName.map((data) => {
                          return (
                            <option value={data.id}>
                              {data.name ? data.name : "No Name"}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Parent Tier Target</label>
                    <Select
                      value={selectedParentTier}
                      onChange={this.handleParentTierChange}
                      options={availableParentTiers}
                      // isMulti
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="subTier">Loyalty Sub Tier Target</label>
                    <Select
                        value={selectedLoyaltySubTier}
                        onChange={this.handleChangeSubTier}
                        options={availableLoyaltySubTiers}
                        isSearchable
                      />
                  </div>
                </div>
                <div className="text-right col-xs-12" style={{display: "flex", gap: ".7em", alignItems: "center", justifyContent: "flex-end"}}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleClearFilter}
                    disabled={!enableBtn}
                  >
                    Clear
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          TOTAL ELIGIBLE TOP-UPS
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : topupPromReportCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {topupPromReportCard.totalEligibleTopups}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          VALUE OF TOP-UPS (OMR)
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : topupPromReportCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {topupPromReportCard.totalTopups}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                {/*<div className="box-tools">
                    <button
                      className="btn btn-sm btn-primary "
                      onClick={this.downloadData}
                    >
                      Export
                    </button>
                          </div>*/}
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Card Serial Number</th>
                            <th>Date/time</th>
                            <th>Promotion Name</th>
                            <th>Parent Tier</th>
                            <th>Sub Tier</th>
                            <th>Top-up value</th>
                            <th>Points Awarded</th>
                            <th>OOMCO ID</th>
                          </tr>
                        </thead>

                        <tbody>
                          {topupPromReport.length > 0 && topupPromReport ? (
                            topupPromReport.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/card/" + data.card_number}>
                                          {this.rescue_value(data.card_number)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a
                                          href={
                                            "/topup_promotion_view/" +
                                            data.promotion_id
                                          }
                                        >
                                          {data?.promotion?.name
                                            ? this.rescue_value(
                                                data.promotion.name
                                              )
                                            : " No Name "}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {this.rescue_value(data?.promotion?.parentTierCardTypeName)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data?.promotion?.loyaltySubTier?.name)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.topup_amount)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.pointsAwarded)}
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user_id}>
                                          {this.rescue_value(data.oomco_id)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="11" className="noRecord">
                              {callApi ? "No Record Found" : "No Record Found"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
