import React from "react";
import { Content } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment";

const stamp_code_url = API.apiEndpoint + API.stampCard;
const uploadVoucherCodes_url = API.apiEndpoint + API.uploadVoucherCodes;
const stamp_code_edit = API.apiEndpoint + API.stampCard;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class UploadVoucherEdit extends React.Component {
  state = {
    page: {},
    action: "add",
    cash_back_promotion_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    card_from: "",
    card_to: "",
    stampCode: [],
    stamp_code: "",
    tableData: [],
    totalData: "",
    status: "",
  };

  async componentDidMount() {
    let optionsStore = { url: stamp_code_url };
    let store_response = await ApiRequest.get(optionsStore);

    let url = stamp_code_edit + "/" + this.props.match.params.id;
    let options = { url: url };

    let api_response = await ApiRequest.get(options);
    let data = api_response.data;

    let res_data = data[0];

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      this.setState({
        stamp_code_id: res_data.id,
        stamp_code_title: res_data.title,
        voucher_data: res_data.voucher_code,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  openModal = async (cards_not) => {
    this.setState({ totalData: cards_not.fulldata });
    this.setState({ tableData: cards_not.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    window.location = "/upload_voucher";
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  onChange = async (selectedOption) => {
    this.setState({
      stamp_code: selectedOption.target.value,
    });
  };

  onClickHandler = async (event) => {
    this.setState({ voucher_code: ("file", event.target.files[0]) });
  };

  saveBulkBlank = async (event) => {
    const formData = new FormData();

    formData.append("stamp_id", this.state.stamp_code_id);
    formData.append("file", this.state.voucher_code);
    formData.append(
      "voucher_expire_date",
      moment.utc(this.state.vdate).add(1, "days").startOf("day").valueOf()
    );

    let api_response = {};
    let options = {
      url: uploadVoucherCodes_url,
      form_data: formData,
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let cards_not = api_response;
      this.openModal(cards_not);
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
        }.bind(this),
        10000
      );
    }
  };
  byDateStart = async (date) => {
    this.setState({
      vdate: date,
      enableBtn: true,
    });
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  backUser = () => {
    window.location = "/upload_voucher_add";
  };

  render() {
    let {
      error,
      totalData,
      message,
      success,
      success_message,
      stamp_code_id,
      stamp_code_title,
      voucher_data,
    } = this.state;

    return (
      <div>
        <Content title="Upload Voucher Add " browserTitle="Upload Voucher Add ">
          <LoadingAjax />
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Upload Voucher Add Form </h3>
                </div>

                <div className="box-body">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Stamp Code</label>
                      <select
                        className="form-control"
                        id="stamp_code"
                        name="stamp_code"
                        onChange={this.onChange}
                        // value={this.state.stamp_code}
                        disabled
                      >
                        <option value={stamp_code_id}>
                          {stamp_code_title}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Date</label>
                      <DatePicker
                        className="form-control"
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.vdate}
                        selectsStart
                        minDate={moment().toDate()}
                        // date_from={this.state.date_from}
                        // date_to={this.state.date_to}
                        onChange={this.byDateStart}
                        placeholderText="From"
                      />

                      {/*<DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.date_to}
                        selectsEnd
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        onChange={this.byDateEnd}
                        minDate={this.state.date_from}
                        placeholderText="To"
                      />*/}
                    </div>
                  </div>
                  {/*<div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        value={this.state.status}
                        onChange={this.statusChange}
                      >
                        <option value="1">Inactive</option>
                        <option value="0">Active</option>
                      </select>
                    </div>
            </div>*/}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="voucher_codes">
                        Upload Voucher Codes
                      </label>
                      <input
                        id={this.state.id}
                        type="file"
                        name="file"
                        ref={(ref) => (this.uploadList = ref)}
                        onChange={this.onClickHandler}
                        accept=".xlsx"
                        style={{ display: "none" }}
                      />
                      <div style={{ display: "block" }}>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary mr-5"
                          onClick={(e) => this.uploadList.click()}
                        >
                          {"Choose File"}
                        </button>
                        <a
                          className="btn btn-sm btn-primary "
                          href="../Stamp_card_vouchers.xlsx"
                        >
                          Sample CSV
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.saveBulkBlank}
                    className="btn btn-primary pull-right "
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.backUser}
                    className="btn btn-default pull-right mr-10"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="box box-primary">
                <div class="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>Stamp ID</th>
                          <th>Voucher Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {voucher_data ? (
                          voucher_data.map((data) => {
                            return (
                              <tr key={data.id}>
                                <td>{this.rescue_value(data.stamp_id)}</td>
                                <td>{this.rescue_value(data.voucher_code)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <td colSpan="2" className="noRecord">
                            No Record Found
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Proceed</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
