import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import DatePicker from "react-datepicker";

// import Modal from "react-modal";
// import DatePicker from "react-datepicker";
// import moment from "moment";

const loyaltyPartner = API.apiEndpoint + API.loyaltyPartner;
//const uploadbulkbarcode_url = API.apiEndpoint + API.uploadbulkbarcode;

const uploadCardsRepository_url = API.apiEndpoint + API.uploadCardsRepository;

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     width: "50%",
//   },
// };

export default class Partner extends Component {
  state = {
    campaign_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    tableData: [],
    totalData: "",
    search_date: "",
    success: "",
    success_message: "",
    error_message: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        loyaltyPartner +
        "?CampaignListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: false,
        //message: "api_response.message",
      });
    } else {
      this.setState({
        campaign_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        // message: "api_response.message 12",
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }
  // openModal = async (data) => {
  //   this.setState({ totalData: data });
  //   this.setState({ tableData: data.data });
  //   this.setState({ modalIsOpen: true });
  // };
  // closeModal = async () => {
  //   this.setState({ modalIsOpen: false });
  // };

  searchDate = async (date) => {
    this.setState({
      search_clear: false,
      search_date: date,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "createdAt", date);
  };
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      status_input_value: "",
      search_date: "",
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      search_date: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };
  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(uploadCardsRepository_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Card successfully uploaded",
        });
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          5000
        );
        this.openModal(rsp.data);
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };
  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Visible" : "Hidden";
  }
  rescue_mechanic(value) {
    return value == 1 ? "Enable" : "Disable";
  }

  deletePatner = async (val, event) => {
    confirmAlert({
      title: "Partner",
      message: "Are you sure you want to delete Partner?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmdeletePatner(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmdeletePatner = async (val, event) => {
    const formData = new FormData();
    formData.append("status", "1");

    let api_response = {};
    let options = {
      url: loyaltyPartner + "/" + val,
      form_data: formData,
    };

    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/partner_listing";
        }.bind(this),
        100
      );
      // sessionStorage.clear();
    }
  };

  render() {
    const {
      loading,
      error_message,
      message,
      campaign_list,
      pagination,
      limit,
      search_select_value,
      search_input_value,
      status_input_value,
      success,
      success_message,
    } = this.state;

    return (
      <div>
        <Content title="Partner" browserTitle="Partner">
          <LoadingAjax />
          <Row>
            {error_message && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {error_message}
              </div>
            )}
            {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}

            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Partner Listing </h3>
                  <div className="box-tools">
                    <div>
                      <a
                        class="btn btn-sm btn-primary mr-5"
                        href={"/partner_form/"}
                      >
                        Add Partner
                      </a>
                      {/*<a
                        className="btn btn-sm btn-primary mr-5"
                        href="Sample-Card-Repository.xlsx"
                      >
                        Sample CSV
                      </a>
                      <input
                        id="uploadList"
                        type="file"
                        name="file"
                        ref={(ref) => (this.uploadList = ref)}
                        onChange={this.onClickHandler}
                        style={{ display: "none" }}
                        accept=".xlsx, .csv, .xls"
                      />
                      {ApiRequest.can_access(
                        "basma-white-list",
                        "can_upload"
                      ) && (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={(e) => this.uploadList.click()}
                          // onClick={this.openModal}
                        >
                          {"Upload Bulk Partner"}
                        </button>
                      )}*/}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>

                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"partner_code"}>Partner Code</option>
                          <option value={"partner_name"}>Partner Name</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>Partner Code</th>
                              <th>Partner Name</th>
                              <th>Secret Key</th>
                              <th>IP Address</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {campaign_list.length > 0 ? (
                              campaign_list.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.partner_code)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.partner_name)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.secret_key)}
                                    </td>
                                    <td>
                                      <span>
                                        {data.ip_address
                                          .replace(/[\/\\]+,/g, "")
                                          .replace(/['"]+/g, "")
                                          .replace(/[\[\]']+/g, "")
                                          .split(",")
                                          .toString()}
                                      </span>
                                    </td>
                                    <td>
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/partner_form_edit/" + data.id}
                                      >
                                        <i class="fa fa-edit" />
                                      </a>

                                      <button
                                        className="btn btn-primary btn-xs mr-5"
                                        onClick={this.deletePatner.bind(
                                          null,
                                          data.id
                                        )}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/partner_view/" + data.id}
                                      >
                                        <i class="fa fa-eye" />
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>

        {/*<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Inserted</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Serial Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.data.serial_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
                </Modal>*/}
      </div>
    );
  }
}
