import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
const garage_url = API.apiEndpoint + API.myGarage;

//import { CSVLink } from "react-csv";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
require("bootstrap/less/bootstrap.less");

const all_vehicles_garage_url = API.apiEndpoint + API.myGarage;
const update_vehicle = API.apiEndpoint + API.updateGarage;
const cardplateTypes_url = API.apiEndpoint + API.carplateTypes;
const brandsInfo_url = API.apiEndpoint + API.brands;

export default class MyGarage extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    date_from: "",
    date_to: "",
    oomco_id: "",
    all_vehicle_garage: [],
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    search_clear: true,
    pagination: {},
    cardplateTypes: [],
    brands: [],
    modelYears: ["2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008", "2007", "2006", "2005", "2004", "2003", "2002", "2001", "2000", "1999", "1998", "1997", "1996", "1995", "1994", "1993", "1992", "1991", "1990", "1989", "1988", "1987", "1986", "1985", "1984", "1983", "1982", "1981", "1980", "1979", "1978", "1977", "1976", "1975", "1974", "1973", "1972", "1971", "1970", "1969", "1968", "1967", "1966", "1965", "1964", "1963", "1962", "1961", "1960", "1959", "1958", "1957", "1956", "1955", "1954", "1953", "1952", "1951", "1950"],
  };

  async componentDidMount() {
    let options = { url: all_vehicles_garage_url };
    let api_response = await ApiRequest.get(options);

    let CarPlateOptions = { url: cardplateTypes_url };
    let brandsOptions = { url: brandsInfo_url };
    let carPlateType = await ApiRequest.get(CarPlateOptions);
    let brandsInfo = await ApiRequest.get(brandsOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        all_vehicle_garage: api_response.data.results,
        pagination: api_response.data.pagination,
        cardplateTypes: carPlateType.data,
        brands: brandsInfo.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          all_vehicle_garage: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });
    let url = all_vehicles_garage_url + "?page=" + pageNumber;

    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.brand) {
      url = url + "&brand=" + this.state.brand;
    }
    if (this.state.model) {
      url = url + "&model=" + this.state.model;
    }
    if (this.state.model_year) {
      url = url + "&model_year=" + this.state.model_year;
    }
    if (this.state.my_garage_date_from) {
      url = url + "&my_garage_date_from=" + this.state.my_garage_date_from;
    }
    if (this.state.my_garage_date_to) {
      url = url + "&my_garage_date_to=" + this.state.my_garage_date_to;
    }
    if (this.state.vno) {
      url = url + "&vno=" + this.state.vno;
    }
    if (this.state.vchar) {
      url = url + "&vchar=" + this.state.vchar;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        all_vehicle_garage: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = all_vehicles_garage_url + "?csv=" + 1;
    
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.brand) {
      url = url + "&brand=" + this.state.brand;
    }
    if (this.state.model) {
      url = url + "&model=" + this.state.model;
    }
    if (this.state.my_garage_date_from) {
      url = url + "&my_garage_date_from=" + this.state.my_garage_date_from;
    }
    if (this.state.my_garage_date_to) {
      url = url + "&my_garage_date_to=" + this.state.my_garage_date_to;
    }
    if (this.state.vno) {
      url = url + "&vno=" + this.state.vno;
    }
    if (this.state.vchar) {
      url = url + "&vchar=" + this.state.vchar;
    }

    let options = {
      url: url,
      file_name:"Garage -" + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  handleChangeCarPlateType = async (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      vchar: event.target.value,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  byDateStart = (date) => {
    this.setState({
      my_garage_date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      my_garage_date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeCarPlate = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      brand: event.target.value,
    });
  };
  handleChangeCarType = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      vno: event.target.value,
    });
  };
  handleChangeBrand = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      brand: event.target.value,
    });
  };
  handleChangeModel = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      model: event.target.value,
    });
  };
  handleChangeModelYear = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      model_year: event.target.value,
    });
  };
  handleChangeOomcoID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      oomco_id: event.target.value,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      oomco_id: "",
      vno: "",
      model: "",
      brand: "",
      vchar: "",
    });
  };
  deleteMyGarage = async (val, event) => {
    confirmAlert({
      title: "My Garage",
      message:
        "Are you sure you want to delete Carplate # : " + val.car_plate + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmdeleteMyGarage(val.id),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  confirmdeleteMyGarage = async (val, event) => {
    const formData = new FormData();
    formData.append("is_deleted", "1");

    let api_response = {};
    let options = {
      url: update_vehicle + "/" + val,
      form_data: formData,
    };

    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/my-garage";
        }.bind(this),
        100
      );
      // sessionStorage.clear();
    }
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }

  render() {
    const { Select2 } = Inputs;
    const {
      all_vehicle_garage,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
      cardplateTypes,
      brands,
      modelYears,
    } = this.state;

    return (
      <Content title="My Garage" browserTitle="My Garage">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">My Garage Report</h3>
                {/*<div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                    disabled={!enableBtn}
                  >
                    Export
                  </button>
          </div>*/}

                <div className="box-tools">
                  {/* <a
                    className="btn btn-sm btn-primary mr-5"
                    href="Stores-Tokens.xlsx"
                  >
                    Sample CSV
                  </a> */}
                  
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>OOMCO ID </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeOomcoID}
                      className="form-control"
                      placeholder="Enter Oomco ID"
                    />
                  </div>
                </div>
                {/*<div className="col-xs-4">
                  <div className="form-group">
                    <label>Car Plate </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeCarPlate}
                      className="form-control"
                      placeholder="Enter Car Plate"
                    />
                  </div>
        </div>*/}
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Car Plate </label>

                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeCarType}
                      className="form-control"
                      placeholder="Enter Car Type"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Car Type </label>
                    <select
                      className="form-control "
                      id="carplatetype"
                      name="carplatetype"
                      onChange={this.handleChangeCarPlateType}
                      value={this.state.vchar}
                    >
                      <option value="">Select</option>
                      {cardplateTypes.length > 0 &&
                        cardplateTypes.map((item, key) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      selected={this.state.my_garage_date_from}
                      selectsStart
                      my_garage_date_from={this.state.my_garage_date_from}
                      my_garage_date_to={this.state.my_garage_date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      selected={this.state.my_garage_date_to}
                      selectsEnd
                      my_garage_date_from={this.state.my_garage_date_from}
                      my_garage_date_to={this.state.my_garage_date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.my_garage_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  {/*<div className="form-group">
                    <label>Brand </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeCarPlate}
                      className="form-control"
                      placeholder="Enter Brand"
                    />
        </div>*/}

                  <div className="form-group">
                    <label>Brand </label>
                    <select
                      className="form-control"
                      value={this.state.completed}
                      onChange={this.handleChangeBrand}
                    >
                      <option value="">Select</option>
                      {brands.length > 0 &&
                        brands.map((item, key) => (
                          <option value={item.brand_value}>{item.brand_label.en}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Model </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeModel}
                      className="form-control"
                      placeholder="Enter Model"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Model Year </label>
                    <select
                      className="form-control"
                      value={this.state.value}
                      onChange={this.handleChangeModelYear}
                    >
                      <option value="">Select</option>
                      {modelYears.length > 0 &&
                        modelYears.map((item, key) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>Oomco ID</th>
                            <th>Brand</th>
                            <th>Model</th>
                            <th>Model Year</th>
                            <th>Car Plate #</th>
                            <th>Car Type</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {all_vehicle_garage.length > 0 &&
                            all_vehicle_garage ? (
                            all_vehicle_garage.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.oomco_id)}</td>
                                  <td>{this.rescue_value(data.brand)}</td>
                                  <td>{this.rescue_value(data.model)}</td>
                                  <td>{this.rescue_value(data.model_year)}</td>
                                  <td>{this.rescue_value(data.vno)}</td>
                                  <td>{this.rescue_value(data.vchar)}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>
                                  <td>
                                    <a
                                      class="btn btn-primary btn-xs mr-5"
                                      href={"/my_garage_view/" + data.id}
                                    >
                                      {" "}
                                      <i class="fa fa-eye" />
                                      {"    "}
                                    </a>
                                    <button
                                      className="btn btn-primary btn-xs mr-5"
                                      onClick={this.deleteMyGarage.bind(null, {
                                        id: data.id,
                                        car_plate: data.vno + data.model,
                                      })}
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
