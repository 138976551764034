import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
//import { useParams } from "react-router";

const getCampaign = API.apiEndpoint + API.getCampaign;
const campaingEdit = API.apiEndpoint + API.campaingEdit;
const campaignbarcodelist_url = API.apiEndpoint + API.campaignbarcodelist;
const exportbarcode = API.apiEndpoint + API.exportbarcode;
const updateBarcode_url = API.apiEndpoint + API.updateBarcode;
const removeBarcode_url = API.apiEndpoint + API.removeBarcode;

export default class Campaign_view extends React.Component {
  state = {
    page: {},
    action: "add",
    promo_code_list: [],
    gameId: "",
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    loading: true,
    success: false,
    success_message: "",
    badge_management: [],
  };
  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let amp_options = {
      url:
        campaignbarcodelist_url +
        "/" +
        this.props.match.params.id +
        "?campaignbarcodelistPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let amp_api_response = await ApiRequest.get(amp_options);

    if (amp_api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: amp_api_response.message,
      });
    } else {
      this.setState({
        campaignbarcodelist_list: amp_api_response.data.results,
        loading: false,
        error: false,
        pagination: amp_api_response.data.pagination,
        message: amp_api_response.message,
      });
    }
  };
  async componentDidMount() {
    await this.getData();
    let url = getCampaign + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    let data = api_response.data;
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        campaignData: api_response.data.results,
        status: data.status,
        title: data.campaign_title,
        badgetitle: data.badge_title,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  titleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };
  saveTopupPromotions = async (event) => {
    // let error = false;
    // if (!this.state.title) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Title is required",
    //   });
    // }

    // if (!this.state.badgeNameValue) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Badge Name is required",
    //   });
    //   return;
    // }

    // const formData = new FormData();

    let data = {
      campaign_title: this.state.title,
      // badge_title: this.state.badgeTitle,
      // badge_id: this.state.badgeId,
      // badge_action: this.state.badgeAction,
      // mechanic_id: this.state.mechanic_id,
      status: this.state.status,
    };

    // formData.append("title", this.state.title);
    // formData.append("status", this.state.status);

    let api_response = {};
    let options = { url: campaingEdit, form_data: data };

    options.url = campaingEdit + "/" + this.props.match.params.id;
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/campaign";
        }.bind(this),
        10000
      );
    }
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value == 1 ? "Active" : "InActive";
  }
  rescue_code_used(value) {
      return value === undefined ? "-" : ApiRequest.date_format_utc(value);
  }
  backUser = () => {
    window.location = "/campaign";
  };
  uploadBarcode = (id, status) => async (event) => {
    let api_uploadBar = {};
    let obj = { status: !status };

    let options = { url: updateBarcode_url, form_data: obj };
    options.url = updateBarcode_url + "/" + id;
    api_uploadBar = await ApiRequest.post(options);

    if (api_uploadBar.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_uploadBar.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_uploadBar.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/campaign_view/" + this.props.match.params.id;
        }.bind(this),
        5000
      );
    }
  };

  // removeBarcode = (id) => async (val, event) => {
  //   confirmAlert({
  //     title: "Delete Barcode",
  //     message: "Are you sure you want to do this?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => this.confirmRemoveBarcode(id),
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {},
  //       },
  //     ],
  //   });
  // };

  removeBarcode = (id) => async (event) => {
    let api_removeBar = {};
    let options = { url: updateBarcode_url };
    options.url = removeBarcode_url + "/" + id;
    api_removeBar = await ApiRequest.post(options);

    if (api_removeBar.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_removeBar.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_removeBar.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/campaign_view/" + this.props.match.params.id;
        }.bind(this),
        5000
      );
    }
  };
  downloadCsvData = async (pageNumber = 1) => {
    let url = exportbarcode + "/" + this.props.match.params.id;

    // if (this.state.filter) {
    //   url =
    //     url +
    //     "&filter=true&key=" +
    //     this.state.search_select_value +
    //     "&value=" +
    //     this.state.search_input_value;
    // }
    let options = {
      url: url,
      file_name:
        "Export Bar Code - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };

    let response = await ApiRequest.download(options);

    if (response && !response.data.success) {
      this.setState({
        loading: false,
        error: true,
        message: response.data.message,
      });
    }
  };

  render() {
    let {
      error,
      message,
      loading,
      success,
      pagination,
      limit,
      success_message,
      campaignData,
      campaignbarcodelist_list,
      badgetitle,
    } = this.state;
    
    return (
      <div>
        <Content title="Campaign View" browserTitle="Campaign View">
          <LoadingAjax />
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Campaign View </h3>
                </div>

                <div className="box-body">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="title">Campaign Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        value={this.state.title}
                        onChange={this.titleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        value={this.state.status}
                        onChange={this.statusChange}
                      >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.saveTopupPromotions}
                    className="btn btn-primary pull-right "
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.backUser}
                    className="btn btn-default pull-right mr-10"
                  >
                    Cancel
                  </button>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4 col-sm-4"></div>
                    <div className="col-md-4 col-sm-4">
                      <div
                        className="info-box-custom"
                        style={{ minHeight: "auto" }}
                      >
                        <div className="info-box-content-custom">
                          <h4 className="info-box-text-custom">Badge Name</h4>
                          <div>
                            <span className="info-box-number-custom">
                              {badgetitle}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Barcode List </h3>
                  <div className="box-tools">
                    <button
                      className="btn btn-sm btn-primary "
                      onClick={this.downloadCsvData}
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>

                      {/*<div className="cusSearch">
                    Search{" "}
                    <select
                      onChange={this.handleSearchChange}
                      value={search_select_value}
                      className="form-control input-sm mr-5 ml-5"
                    >
                      <option value="">Select Search by </option>
                      <option value={"topup_amount"}>Topup Amount</option>
                      <option value={"description"}>Description</option>
                      <option value={"createdAt"}>Date</option>
                      <option value={"status"}>Status</option>
                    </select>
                    {search_select_value === "status" ? (
                      <select
                        onChange={this.handleStatusChange}
                        value={status_input_value}
                        className="form-control input-sm mr-5 ml-5"
                      >
                        <option value={""}>Select</option>
                        <option value={"1"}>Complete</option>
                        <option value={"0"}>Pending</option>
                      </select>
                    ) : search_select_value === "createdAt" ? (
                      <DatePicker
                        className="filterDate"
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.search_date}
                        selectsStart
                        onChange={this.searchDate}
                        placeholderText=""
                      />
                    ) : (
                      <input
                        type="search"
                        class="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                    )}
                    <button
                      className="btn btn-primary cusSearchBtn"
                      onClick={this.handleSearchClear}
                    >
                      Clear
                    </button>
                    </div>*/}
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>Product Barcode</th>
                              <th>Product Name</th>
                              <th>Code Created At</th>
                              <th>OOMCO Id</th>
                              <th>Code Used</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {campaignbarcodelist_list ? (
                              campaignbarcodelist_list.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.product_barcode)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.product_name)}
                                    </td>
                                    <td>
                                      {ApiRequest.date_format_utc(
                                              data.created_at
                                      )}
                                    </td>
                                    <td>{this.rescue_value(data.oomcoId)}</td>
                                    <td>
                                      {this.rescue_code_used(data.code_used)}
                                    </td>
                                    <td>{this.rescue_status(data.status)}</td>

                                    <td>
                                      <button
                                        class={`btn btn-primary btn-xs mr-5 ${
                                          data.status ? "" : "btn-danger"
                                        }`}
                                        onClick={this.uploadBarcode(
                                          data.id,
                                          data.status
                                        )}
                                      >
                                        Change Status
                                      </button>

                                      <button
                                        class="btn btn-primary btn-xs mr-5"
                                        onClick={this.removeBarcode(data.id)}
                                      >
                                        <i class="fa fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="7" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}
