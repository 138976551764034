import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import ApiRequest from "../../ApiRequest";

const badgeManagementView_url = API.apiEndpoint + API.badgeManagement;

export default class BadgesStatisticsView extends React.Component {
  state = {
    badge_management_view: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        badgeManagementView_url +
        +"/" +
        this.props.match.params.id +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value
    };

    this.setState({ loading: true });

    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token")
    };

    const response = await axios.get(
      badgeManagementView_url +
        "/" +
        this.props.match.params.id +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
      { headers: headers }
    );
    if (response.status === 200) {
      const data = response.data.data;
      this.setState({
        badge_management_view: data,
        loading: false,
        error: false,
        pagination: response.data.data.pagination,
        message: response.message
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }
  handlePageChange = async pageNumber => {
    await this.getData(pageNumber, this.state.limit);
  };

  handleLimitChange = async event => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    let { badge_management_view, loading, pagination, limit } = this.state;
    return (
      <Content
        title={"Badges Statistics View"}
        browserTitle="Badges Statistics View"
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                </div>
                <div className="box-body">
                  <div
                    style={{ textAlign: "center", margin: "40px 0px 50px 0px" }}
                  >
                    <div>
                      <img
                        style={{ width: "100px" }}
                        src={badge_management_view.results.image}
                        alt={"img"}
                      />
                    </div>
                    <h3>
                      <b>{badge_management_view.results.title.en}</b>
                    </h3>
                  </div>
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>
                      {/*<div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"card_number"}>
                            Card Serial Number
                          </option>
                          <option value={"request_from"}>Request From</option>
                          <option value={"topup_amount"}>Amount</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>*/}
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Badge Frequency </th>
                              <th>Issue Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {badge_management_view.results.awardedToUsers
                              .length > 0 ? (
                              badge_management_view.results.awardedToUsers.map(
                                data => {
                                  return (
                                    <tr key={data.id}>
                                      <td>
                                        {data.name ? (
                                          <span>
                                            <a href={"/app_view/" + data.id}>
                                              {data.name}
                                            </a>
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {this.rescue_value(data.frequency)}
                                      </td>
                                      <td>
                                        {ApiRequest.date_time_format(
                                          data.createdAt
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
