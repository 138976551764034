import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "./../../ApiRequest";
import API from "./../../constants";
import LoadingSpinner from "./../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
require("bootstrap/less/bootstrap.less");

const city_url = API.apiEndpoint + API.city;
const users_url = API.apiEndpoint + API.reportUsers;
const analyticsUser_url = API.apiEndpoint + API.analyticsUser;

export default class UserReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submitUrl: "",
      cityId: "",
      age: "",
      gender: "",
      no_of_vehicles_from: "",
      no_of_vehicles_to: "",
      nss: "",
      usersReports: [],
      cities: [],
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      show_payment_method: false,
      show_amount_range: false,
      show_payment_options: [],
      callApi: false,
      enableBtn: false,
      age_from: "",
      age_to: "",
      loadingCart: false,
      graphData: [],
      UserData: [],
      join_date_from: "",
      join_date_to: "",
      user_without_card: false,
      status: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeAge = this.handleChangeAge.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleChangeVehiclesCardFrom = this.handleChangeVehiclesCardFrom.bind(
      this
    );
    this.handleChangeVehiclesCardTo = this.handleChangeVehiclesCardTo.bind(
      this
    );
    this.handleChangeNssCard = this.handleChangeNssCard.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }
  async componentDidMount() {
    let optionsCity = { url: city_url };
    let optionsUsers = { url: users_url };
    let api_response = await ApiRequest.get(optionsUsers);
    let city_response = await ApiRequest.get(optionsCity);
    if (api_response.error || city_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let cities = [];
      city_response.data.map((cityId) => {
        cities.push({ value: cityId.cityId, label: cityId.name });
      });

      this.setState({
        cities: cities,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        let api_response = await ApiRequest.get(optionsUsers);
        this.setState({
          usersReports: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    const { user_without_card } = this.state;

    let url = users_url + "?page=" + pageNumber;
    let analyticsUserReport = analyticsUser_url + "?page=" + pageNumber;

    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
      analyticsUserReport =
        analyticsUserReport + "&cityId=" + this.state.cityId;
    }
    if (this.state.age) {
      url = url + "&age=" + this.state.age;
      analyticsUserReport = analyticsUserReport + "&age=" + this.state.age;
    }
    if (this.state.age_from) {
      url = url + "&age_from=" + this.state.age_from;
      analyticsUserReport =
        analyticsUserReport + "&age_from=" + this.state.age_from;
    }
    if (this.state.age_to) {
      url = url + "&age_to=" + this.state.age_to;
      analyticsUserReport =
        analyticsUserReport + "&age_to=" + this.state.age_to;
    }
    if (this.state.gender) {
      url = url + "&gender=" + this.state.gender;
      analyticsUserReport =
        analyticsUserReport + "&gender=" + this.state.gender;
    }
    if (this.state.join_date_from) {
      url = url + "&join_date_from=" + this.state.join_date_from;
      analyticsUserReport =
        analyticsUserReport + "&join_date_from=" + this.state.join_date_from;
    }
    if (this.state.join_date_to) {
      url = url + "&join_date_to=" + this.state.join_date_to;
      analyticsUserReport =
        analyticsUserReport + "&join_date_to=" + this.state.join_date_to;
    }
    if (this.state.no_of_vehicles_from && !user_without_card) {
      url = url + "&no_of_vehicles_from=" + this.state.no_of_vehicles_from;
      analyticsUserReport =
        analyticsUserReport +
        "&no_of_vehicles_from=" +
        this.state.no_of_vehicles_from;
    }
    if (this.state.no_of_vehicles_to && !user_without_card) {
      url = url + "&no_of_vehicles_to=" + this.state.no_of_vehicles_to;
      analyticsUserReport =
        analyticsUserReport +
        "&no_of_vehicles_to=" +
        this.state.no_of_vehicles_to;
    }

    if (user_without_card) {
      url = url + "&user_without_card=" + 1;
      analyticsUserReport = analyticsUserReport + "&user_without_card=" + 1;
    }

    if (this.state.nss) {
      url = url + "&nss=" + this.state.nss;
      analyticsUserReport = analyticsUserReport + "&nss=" + this.state.nss;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
      analyticsUserReport =
        analyticsUserReport + "&status=" + this.state.status;
    }
    let options = { url: url };
    let analyticsUserReportUrl = {
      url: analyticsUserReport,
    };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        usersReports: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }

    let analyticsUserReport_response = await ApiRequest.get(
      analyticsUserReportUrl,
      true,
      false
    );
    this.setState({
      loadingCart: false,
    });

    if (!analyticsUserReport_response.error) {
      if (analyticsUserReport_response.data) {
        let UserData = analyticsUserReport_response.data;
        this.setState({ UserData: UserData });
      }

      if (
        analyticsUserReport_response.data &&
        analyticsUserReport_response.data.graphData
      ) {
        let graphData = analyticsUserReport_response.data.graphData;
        this.setState({ graphData: graphData });
      }
    }
  };
  downloadData = async (event) => {
    let url = users_url + "?csv=" + 1;

    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
    }
    if (this.state.age) {
      url = url + "&age=" + this.state.age;
    }
    if (this.state.gender) {
      url = url + "&gender=" + this.state.gender;
    }
    if (this.state.join_date_from) {
      url = url + "&join_date_from=" + this.state.join_date_from;
    }
    if (this.state.join_date_to) {
      url = url + "&join_date_to=" + this.state.join_date_to;
    }
    if (this.state.no_of_vehicles_from) {
      url = url + "&no_of_vehicles_from=" + this.state.no_of_vehicles_from;
    }
    if (this.state.no_of_vehicles_to) {
      url = url + "&no_of_vehicles_to=" + this.state.no_of_vehicles_to;
    }
    if (this.state.nss) {
      url = url + "&nss=" + this.state.nss;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = {
      url: url,
      file_name:
        "User Report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };

    await ApiRequest.download(options);
  };
  saleStart = async (date) => {
    this.setState({
      join_date_from: date,
      enableBtn: true,
      sales: [],
      pagination: {},
      limit: 10,
    });
  };
  saleEnd = async (date) => {
    this.setState({
      join_date_to: date,
      enableBtn: true,
      sales: [],
      pagination: {},
      limit: 10,
    });
  };
  handleChangeCity(event) {
    this.setState({
      cityId: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeAge(event) {
    this.setState({
      age: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleGender(event) {
    this.setState({
      gender: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeVehiclesCardFrom(event) {
    this.setState({
      no_of_vehicles_from: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeVehiclesCardTo(event) {
    let value = event.target.value;

    this.setState({
      no_of_vehicles_to: value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeNssCard(event) {
    this.setState({
      nss: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleChangeStatus(event) {
    this.setState({
      status: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  }
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  handleChangeAge = (event) => {
    if (event.target.value.length > 3) {
      this.setState({
        error: true,
        message: "Age limited to 3 digit",
      });
    } else {
      this.setState({ error: false, age: event.target.value });
    }
  };
  rescue_city_name(value) {
    return value === undefined
      ? "-"
      : typeof value === "object"
      ? value.name.en
      : "-";
  }
  rescue_gender(value) {
    let val = parseInt(value);
    switch (val) {
      case 0:
        return "Un specified";
      case 1:
        return "Male";
      case 2:
        return "Female";
      default:
        return "-";
    }
  }
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  not_age_from = (event) => {
    if (event.target.value.length > 3) {
      this.setState({
        error: true,
        message: "Age limited to 3 digit",
      });
    } else {
      this.setState({ error: false, age_from: event.target.value });
    }
  };
  not_age_to = (event) => {
    if (event.target.value.length > 3) {
      this.setState({
        error: true,
        message: "Age limited to 3 digit",
      });
    } else {
      this.setState({ error: false, age_to: event.target.value });
    }
  };
  noResultText() {
    return "N/A";
  }
  graphData(graphData) {
    let data = [];
    let defaultData = ["X", "Count"];
    data.push(defaultData);

    if (graphData) {
      graphData.map(function (item, i) {
        const { count } = item;
        data.push([(++i).toString(), count]);
      });

      return data;
    }

    return data;
  }

  onChangeCardCheck = () => {
    const { user_without_card } = this.state;
    this.setState({
      user_without_card: !user_without_card,
      enableBtn: true,
    });
  };

  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const { Select2 } = Inputs;
    const {
      pagination,
      cities,
      usersReports,
      loading,
      error,
      message,
      limit,
      callApi,
      enableBtn,
      loadingCart,
      graphData,
      UserData,
      user_without_card,
    } = this.state;
    return (
      <Content title="Users Report" browserTitle="Users Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4 cusSelect cusSelect-14">
                  <Select2
                    label="City"
                    value={this.state.value}
                    onChange={this.handleChangeCity}
                    options={cities}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Age </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeAge}
                      className="form-control"
                      placeholder="Enter age"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Gender </label>
                    <select
                      className="form-control"
                      value={this.state.gender}
                      onChange={this.handleGender}
                    >
                      <option value="">Select</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="0">Un specified</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Join Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.join_date_from}
                      selectsStart
                      join_date_from={this.state.join_date_from}
                      join_date_to={this.state.join_date_to}
                      onChange={this.saleStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.join_date_to}
                      selectsEnd
                      join_date_from={this.state.join_date_from}
                      join_date_to={this.state.join_date_to}
                      onChange={this.saleEnd}
                      minDate={this.state.join_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <div className="form-group">
                    <label>NSS Card </label>
                    <select
                      className="form-control"
                      value={this.state.nss}
                      onChange={this.handleChangeNssCard}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={this.state.status}
                      onChange={this.handleChangeStatus}
                    >
                      <option value="">Select</option>
                      <option value={"1"}>Active</option>
                      <option value={"0"}>InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Number of Vehicles/Card
                    </label>
                    <input
                      type="number"
                      min={1}
                      value={this.state.no_of_vehicles_from}
                      onChange={this.handleChangeVehiclesCardFrom}
                      className="form-control"
                      placeholder="From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                      disabled={user_without_card}
                    />
                    <input
                      type="number"
                      min={1}
                      value={this.state.no_of_vehicles_to}
                      onChange={this.handleChangeVehiclesCardTo}
                      className="form-control"
                      placeholder="To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                      disabled={user_without_card}
                    />
                  </div>
                  <label>
                    {"User Without Card"}
                    <input
                      className="ml-5"
                      onChange={this.onChangeCardCheck}
                      type="checkbox"
                      checked={user_without_card}
                    />
                  </label>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Total Users</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.total_users ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.total_users}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Male</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.male ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.male}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Female</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.female ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.female}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Unspecified</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.unspecified ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.unspecified}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">NSS Users</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.nss_users ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.nss_users}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Card Owners</h4>
                        <span className="info-box-number-custom">
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loadingCart ? (
                                  <LoadingSpinner />
                                ) : UserData.card_owners ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {UserData.card_owners}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Weekly Transactions</h3>
              </div>
              <div className="box-body">
                {loadingCart ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    {graphData.length > 0 ? (
                      <Chart
                        width={"100%"}
                        height={"300px"}
                        chartType="LineChart"
                        data={this.graphData(graphData)}
                      />
                    ) : (
                      <div style={{ textAlign: "center", padding: "50px 0" }}>
                        {this.noResultText()}
                      </div>
                    )}
                    <div className="table-responsive">
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Index</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Count</th>
                          </tr>
                        </thead>

                        <tbody>
                          {graphData.length > 0 && graphData ? (
                            graphData.map((data, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.startDate)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.endDate)}
                                  </td>
                                  <td>{this.rescue_value(data.count)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>

                <button
                  className="btn btn-sm btn-primary "
                  onClick={this.downloadData}
                  disabled={!enableBtn}
                >
                  Export
                </button>

                {/*<div class="box-tools">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-sm btn-primary "
                    table="export-table-user"
                    filename="User Report"
                    sheet="User Report"
                    buttonText="Export"
                  />
                </div>*/}
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table className="table no-margin" id="export-table-user">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Oomco ID</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>City</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Number of Vehicles/cards</th>
                            <th>NSS Card number</th>
                            <th>Status</th>
                            <th>Civil Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersReports.length > 0 && usersReports ? (
                            usersReports.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.name)}</td>
                                  <td>{this.rescue_value(data.oomcoId)}</td>
                                  <td>
                                    {this.rescue_value(data.country_code)}{" "}
                                    {this.rescue_value(data.mobile_no)}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.email_address)}
                                  </td>
                                  <td>{this.rescue_city_name(data.city)}</td>
                                  <td>{this.rescue_value(data.age)}</td>
                                  <td>{this.rescue_gender(data.gender)}</td>
                                  <td>
                                    {this.rescue_value(data.numberOfCards)}
                                  </td>
                                  <td>{this.rescue_value(data.nss_number)}</td>
                                  <td>{this.rescue_status(data.status)}</td>
                                  <td>
                                    {this.rescue_value(data.civil_number)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="10" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
