import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
const store_url = API.apiEndpoint + API.store;
const storeUpload_url = API.apiEndpoint + API.storeUpload;

//const storeDownload_url = API.apiEndpoint + API.storeReport;

export default class WhiteListListing extends React.Component {
  state = {
    stores: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    success: false,
    success_message: "",
  };
  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        store_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        stores: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };
  async componentDidMount() {
    await this.getData();
  }
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    let rsp = await axios.post(storeUpload_url, data, {});
    if (rsp.status === 200) {
      this.setState({
        success: true,
        loading: false,
        success_message: "Store successfully uploaded",
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/store";
        }.bind(this),
        10000
      );
    }
  };

  downloadData = async () => {
    let url = store_url + "?storeListingPage=1&csv=1";
    let options = {
      url: url,
      file_name: "Store - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };

  render() {
    const {
      loading,
      stores,
      error,
      message,
      pagination,
      limit,
      search_select_value,
      search_input_value,
      success,
      success_message,
    } = this.state;
    return (
      <Content title="Stores " browserTitle="Store">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Stores</h3>
                <div className="col-sm-6">
                  <div className="dataTables_filter hide">
                    <label>
                      Store Search:
                      <input
                        type="search"
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </label>
                  </div>
                </div>
                <div className="box-tools">
                  <a
                    className="btn btn-sm btn-primary mr-5"
                    href="Stores-Tokens.xlsx"
                  >
                    Sample CSV
                  </a>
                  {ApiRequest.can_access("store", "can_add") && (
                    <button
                      className="btn btn-sm btn-primary mr-5"
                      onClick={this.props.viewStore.bind(null, {}, "add")}
                    >
                      {" "}
                      Add New{" "}
                    </button>
                  )}

                  <input
                    id="uploadList"
                    type="file"
                    name="file"
                    ref={(ref) => (this.uploadList = ref)}
                    onChange={this.onClickHandler}
                    style={{ display: "none" }}
                    accept=".xlsx, .csv, .xls"
                  />
                  {ApiRequest.can_access("basma-white-list", "can_upload") && (
                    <button
                      type="button"
                      className="btn btn-sm btn-primary mr-5"
                      onClick={(e) => this.uploadList.click()}
                    >
                      {"Upload"}
                    </button>
                  )}
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>

                    <div className="cusSearch">
                      Search{" "}
                      <select
                        onChange={this.handleSearchChange}
                        value={search_select_value}
                        className="form-control input-sm mr-5 ml-5"
                      >
                        <option value="">Select Search by </option>
                        {/*<option value={"id"}>ID</option>*/}
                        <option value={"store_name"}>Store Name</option>
                        <option value={"store_code"}>Store Code</option>
                        {/*<option value={"city"}>City</option>*/}
                      </select>
                      <input
                        type="search"
                        class="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                      <button
                        className="btn btn-primary cusSearchBtn"
                        onClick={this.handleSearchClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Store Name</th>
                            <th>Store Code </th>
                            <th>City</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stores.length > 0 ? (
                            stores.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.store_name)}</td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>{this.rescue_value(data.city)}</td>
                                  <td>
                                    {ApiRequest.can_access(
                                      "store",
                                      "can_view_single"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/store/view/" + data.id}
                                      >
                                        <i class="fa fa-eye" />
                                      </a>
                                    )}
                                    {ApiRequest.can_access(
                                      "store",
                                      "can_edit"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs"
                                        href={"/store/edit/" + data.id}
                                      >
                                        <i class="fa fa-edit" />
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/*<div className="box-header with-border box-table-cus">
                <h3 className="box-title">Stores List</h3>
                <div className="box-tools">
                  {ApiRequest.can_access("store", "can_add") && (
                    <button
                      className="btn btn-sm btn-primary mr-5"
                      onClick={this.props.viewStore.bind(null, {}, "add")}
                    >
                      {" "}
                      Add New{" "}
                    </button>
                  )}

                  <input
                    id="uploadList"
                    type="file"
                    name="file"
                    ref={ref => (this.uploadList = ref)}
                    onChange={this.onClickHandler}
                    style={{ display: "none" }}
                    accept=".xlsx, .csv, .xls"
                  />
                  {ApiRequest.can_access("basma-white-list", "can_upload") && (
                    <button
                      type="button"
                      className="btn btn-sm btn-primary mr-5"
                      onClick={e => this.uploadList.click()}
                    >
                      {"Upload"}
                    </button>
                  )}
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="White List Cards"
                    selectableRows={false}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={stores}
                    paginationPerPage={3}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
                </div>*/}
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
