import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import moment from "moment";

const reconciliationList_url = API.apiEndpoint + API.reconciliation_url;

const StatusList = {
  pending: "Pending",
  completed: "Completed",
  failed: "Failed",
}

export default class ReconciliationListing extends React.Component {
  state = {
    reconcilationsList: [],
    status: "",
    pagination: {},
    limit: 10,
    activePage: 1,
    date_from: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1, 
      new Date().getDate()
  ),
    date_to: new Date(),
    loading: true,
    error: false,
    message: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    date_from = null,
    date_to = null,
    status = "",
  ) => {
    let options = {
      url:
        reconciliationList_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit
    };

    if (this.state.date_from) {
      options.url =
        options.url +
        "&date_from=" +
        (date_from !== null ? date_from : this.state.date_from);
    }

    if (this.state.date_to) {
      options.url =
        options.url +
        "&date_to=" +
        (date_to !== null ? date_to : this.state.date_to);
    }

    if (this.state.status) {
      options.url =
        options.url +
        "&status=" +
        (status !== "" ? status : this.state.status);
    }

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        reconcilationsList: api_response.data.reconciliations,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    this.getData();
  }

  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      limit: 10,
    });
  };

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.date_from,
      this.state.date_to,
      this.state.status
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(
      1,
      event.target.value,
      this.state.date_from,
      this.state.date_to,
      this.state.status
    );
  };

  handleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
      enableBtn: true,
      limit: 20,
    });
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  renderStatus(status) {
    if (status === "pending") {
      return (
        <span
          style={{
            background: "rgba(255,191,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
            textTransform: "capitalize"
          }}
        >
          {status}
        </span>
      );
    } else if (status === "completed") {
      return (
        <span
          style={{
            background: "rgba(0,255,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
            textTransform: "capitalize"
          }}
        >
          {status}
        </span>
      );
    } else if (status === "failed") {
      return (
        <span
          style={{
            background: "rgba(255,0,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
            textTransform: "capitalize"
          }}
        >
          {status}
        </span>
      );
    }
  }

  renderDay(day) {
    let d = moment(day);
    return d.format("DD-MM-YYYY");
  }

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
    } = this.state;
    return (
      <Content title="Reconciliations" browserTitle="Reconciliations">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Reconciliations</h3>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>
                      <div
                        className="col-xs-4"
                      >
                        <div className="form-group rangeFilter">
                          <label>By Date</label>
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.date_from}
                            selectsStart
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            onChange={this.byDateStart}
                            placeholderText="From"
                          />
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.date_to}
                            selectsEnd
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            onChange={this.byDateEnd}
                            minDate={this.state.date_from}
                            placeholderText="To"
                          />
                        </div>
                      </div>
                      <div className="col-xs-4"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1em",
                      }}>
                        <div className="form-group" style={{width: "100%"}}>
                          <label>Status</label>
                          <select
                            className="form-control"
                            value={this.state.status}
                            onChange={this.handleChangeStatus}
                          >
                            <option value="">Select</option>
                            <option value="pending">{StatusList["pending"]}</option>
                            <option value="completed">{StatusList["completed"]}</option>
                            <option value="failed">{StatusList["failed"]}</option>
                          </select>
                        </div>
                        <button
                          onClick={this.getData.bind(
                            this,
                            1,
                            limit,
                            this.state.date_from,
                            this.state.date_to,
                            this.state.status
                          )}
                          className="btn btn-primary"
                          style={{ marginTop: "9px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Day</th>
                            <th>Status</th>
                            <th>Transactions</th>
                            <th>Successful</th>
                            {/* <th>UnCompleted</th> */}
                            {/* <th>Failed</th> */}
                            <th>Total Amount</th>
                            <th>Discrepancy Amount</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.reconcilationsList.length > 0 &&
                          this.state.reconcilationsList ? (
                            this.state.reconcilationsList.map(
                              (data, i) => {
                                return (
                                  <tr>
                                    <td>{this.rescue_value(data.id)}</td>
                                    <td>{this.renderDay(data.day)}</td>
                                    <td>{this.renderStatus(data.status)}</td>
                                    <td>{this.rescue_value(data.totalTransactions)}</td>
                                    <td>{this.rescue_value(data.successfulTransactions)}</td>
                                    {/* <td>{this.rescue_value(data.pendingTransactions)}</td> */}
                                    {/* <td>{this.rescue_value(data.failedTransactions)}</td> */}
                                    <td>{this.rescue_value(data.totalAmount)}</td>
                                    <td>{this.rescue_value(data.discrepancyAmount)}</td>
                                    <td>
                                        <a
                                          class="btn btn-primary btn-xs mr-5"
                                          href={"/reports/reconciliation_view/" + data.id}
                                        >
                                          <i class="fa fa-eye" />
                                        </a>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
