import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";

const stampCardAdd = API.apiEndpoint + API.stampCard;
const store_url = API.apiEndpoint + API.store;
const uploadVoucherCodes_url = API.apiEndpoint + API.uploadVoucherCodes;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class StampCardAdd extends React.Component {
  state = {
    page: {},
    action: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    date_from: "",
    date_to: "",
    title: "",
    card_name: { en: "", ar: "" },
    notification: { en: "", ar: "" },
    description: { en: "", ar: "" },
    status: "0",
  };

  async componentDidMount() {
    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code,
          label: store.store_code + " - " + store.store_name,
        });
      });

      this.setState({
        stores: stores,
        //stores: store_response.data,
        //store_code: store_response.data[0].store_code,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  openModal = async (cards_not) => {
    this.setState({ totalData: cards_not.fulldata });
    this.setState({ tableData: cards_not.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    window.location = "/badges_statistics";
  };
  card_display_name = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let card_name = Object.assign({}, prevState.card_name); // creating copy of state variable tip
      card_name[lang] = value; // update the name property, assign a new value
      return { card_name }; // return new object tip object
    });
    return true;
  };
  card_display_description = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let description = Object.assign({}, prevState.description); // creating copy of state variable tip
      description[lang] = value; // update the name property, assign a new value
      return { description }; // return new object tip object
    });
    return true;
  };
  notification = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let notification = Object.assign({}, prevState.notification); // creating copy of state variable tip
      notification[lang] = value; // update the name property, assign a new value
      return { notification }; // return new object tip object
    });
    return true;
  };
  stampCardImage = async (event) => {
    this.setState({ stamp_display_image: ("file", event.target.files[0]) });
  };
  stampImage = async (event) => {
    this.setState({ stamp_image: ("file", event.target.files[0]) });
  };
  stampImageBlank = async (event) => {
    this.setState({ stamp_image_blank: ("file", event.target.files[0]) });
  };
  byDateStart = async (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
    });
  };
  byDateEnd = async (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
    });
  };
  tranTypeSourceChange = (event) => {
    this.setState({ type_source: event.target.value });
  };
  productChange = (event) => {
    this.setState({ product: event.target.value });
  };
  tittleChange = (event) => {
    this.setState({ tittle: event.target.value });
  };
  amountChange = (event) => {
    this.setState({ amount: event.target.value });
  };
  purchaseItemChange = (event) => {
    this.setState({ purchaseItems: event.target.value });
  };
  CategoryChange = (event) => {
    this.setState({ category: event.target.value });
  };
  CategoryOneChange = (event) => {
    this.setState({ category_1: event.target.value });
  };
  CategoryTwoChange = (event) => {
    this.setState({ category_2: event.target.value });
  };
  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  // onClickHandler = async (event) => {
  //   const data = new FormData();
  //   data.append("file", event.target.files[0]);
  //   data.append("stamp_id", event.target.id);
  //   this.setState({ loading: true });
  //   try {
  //     let rsp = await axios.post(uploadVoucherCodes_url, data, {
  //       headers: { "X-Access-Token": localStorage.getItem("user_token") },
  //     });
  //     if (rsp.status === 200) {
  //       this.setState({
  //         success: true,
  //         loading: false,
  //         success_message: "Card successfully uploaded",
  //       });
  //       setTimeout(
  //         function () {
  //           this.setState({ success: false });
  //         }.bind(this),
  //         5000
  //       );
  //       this.openModal(rsp.data);
  //     } else {
  //       let message = rsp.data.message;
  //       this.setState({
  //         error: true,
  //         loading: false,
  //         error_message: message.param_missing
  //           ? message.param_missing + " is required"
  //           : message,
  //       });
  //     }
  //   } catch (e) {
  //     let message = "Error Uploading file";
  //     this.setState({
  //       error: true,
  //       loading: false,
  //       message: message,
  //     });
  //   }
  // };

  saveBulkBlank = async (event) => {
    const formData = new FormData();

    let error = false;
    if (!this.state.notification) {
      error = true;
      this.setState({
        error: true,
        message: "notification  is required",
      });
    }
    if (!this.state.notification) {
      error = true;
      this.setState({
        error: true,
        message: "notification  is required",
      });
    }
    if (!this.state.category) {
      error = true;
      this.setState({
        error: true,
        message: "Category is required",
      });
    }
    /* OW-283
    if (!this.state.product) {
      error = true;
      this.setState({
        error: true,
        message: "Product is required",
      });
    }


    if (!this.state.amount) {
      error = true;
      this.setState({
        error: true,
        message: "Amount is required",
      });
    }
    */
    if (!this.state.purchaseItems) {
      error = true;
      this.setState({
        error: true,
        message: "Purchase Items is required",
      });
    }
    if (!this.state.type_source) {
      error = true;
      this.setState({
        error: true,
        message: "Type Source is required",
      });
    }

    if (!this.state.tittle) {
      error = true;
      this.setState({
        error: true,
        message: "Title is required",
      });
    }

    if (error) {
      return;
    }

    let image = {};
    image.badge_en = this.state.badge_en;

    formData.append("title", this.state.tittle);
    formData.append("display_name", JSON.stringify(this.state.card_name));
    formData.append("description", JSON.stringify(this.state.description));

    formData.append(
      "from_date",
      moment.utc(this.state.date_from).add(1, "days").startOf("day").valueOf()
    );
    formData.append(
      "to_date",
      moment.utc(this.state.date_to).add(1, "days").endOf("day").valueOf()
    );

    formData.append("amount", this.state.amount);
    formData.append("type_source", this.state.type_source);
    formData.append("number_items", this.state.purchaseItems);
    formData.append("product", this.state.product);
    formData.append("category", this.state.category);
    formData.append("sub_category_1", this.state.category_1);
    formData.append("sub_category_2", this.state.category_2);
    formData.append("notification", JSON.stringify(this.state.notification));
    formData.append("status", this.state.status);
    formData.append("display_image", this.state.stamp_display_image);
    formData.append("stamp_image", this.state.stamp_image);
    formData.append("stamp_image_blank", this.state.stamp_image_blank);

    let api_response = {};
    let options = {
      url: stampCardAdd,
      //form_data: data,
      form_data: formData,
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let cards_not = api_response;
      this.openModal(cards_not);
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/stamp_card";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/badges_statistics";
  };

  render() {
    let engImgSquare = "";
    if (typeof this.state.engImgSquare !== "undefined")
      engImgSquare = this.state.engImgSquare.file;

    // let arImgSquare = "";
    // if (typeof this.state.arImgSquare !== "undefined")
    //   arImgSquare = this.state.engImgSquare.file;

    let { error, totalData, message, success, success_message } = this.state;

    return (
      <div>
        <Content title="Add Stamp Card " browserTitle="Add Stamp Card ">
          <LoadingAjax />
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Stamp Card Form </h3>
                </div>

                <div className="box-body">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="tittle">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tittle"
                        placeholder="Name"
                        value={this.state.tittle}
                        onChange={this.tittleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group rangeFilter">
                      <label>Date</label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.date_from}
                        selectsStart
                        minDate={moment().toDate()}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        onChange={this.byDateStart}
                        placeholderText="From"
                      />

                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.date_to}
                        selectsEnd
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        onChange={this.byDateEnd}
                        minDate={this.state.date_from}
                        placeholderText="To"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="stamp_display_image">
                          Stamp Card Image{" "}
                        </label>
                        <input
                          type="file"
                          id="stamp_display_image"
                          placeholder="Name english"
                          ref={(ref) => (this.stampcardimage = ref)}
                          onChange={this.stampCardImage}
                          accept=".png, .jpeg"
                        />
                      </div>
                      {engImgSquare === "" ? (
                        ""
                      ) : (
                        <img
                          src={engImgSquare}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="stamp_image">Stamp Image </label>
                        <input
                          type="file"
                          id="stamp_image"
                          placeholder="Name english"
                          ref={(ref) => (this.stampimage = ref)}
                          onChange={this.stampImage}
                          accept=".png, .jpeg"
                        />
                      </div>
                      {/*engImgSquare === "" ? (
                        ""
                      ) : (
                        <img
                          src={engImgSquare}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )*/}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="price_image">Stamp Image Blank </label>
                        <input
                          type="file"
                          id="price_image"
                          placeholder="Name english"
                          ref={(ref) => (this.stampimageblank = ref)}
                          onChange={this.stampImageBlank}
                          accept=".png, .jpeg"
                        />
                      </div>
                      {/*engImgSquare === "" ? (
                        ""
                      ) : (
                        <img
                          src={engImgSquare}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )*/}
                    </div>
                  </div>
                  <div className="col-sm-12 p0">
                    <div className="col-sm-12">
                      <label>Card Display Name </label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_en">English</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carddisplayname_en"
                          placeholder="Card Name english"
                          value={this.state.card_name.en}
                          onChange={this.card_display_name.bind(null, "en")}

                          // value={this.state.card_name_en}
                          // onChange={(e) =>
                          //   this.setState({ card_name_en: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_ar">Arabic</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carddisplayname_ar"
                          placeholder="Card Name arabic"
                          value={this.state.card_name.ar}
                          onChange={this.card_display_name.bind(null, "ar")}

                          // value={this.state.card_name_ar}
                          // onChange={(e) =>
                          //   this.setState({ card_name_ar: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 p0">
                    <div className="col-sm-12">
                      <label>Card Description </label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_en">English</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carddisplayname_en"
                          placeholder="Description english"
                          value={this.state.description.en}
                          onChange={this.card_display_description.bind(
                            null,
                            "en"
                          )}

                          // value={this.state.card_name_en}
                          // onChange={(e) =>
                          //   this.setState({ card_name_en: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_ar">Arabic</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carddisplayname_ar"
                          placeholder="Description arabic"
                          value={this.state.description.ar}
                          onChange={this.card_display_description.bind(
                            null,
                            "ar"
                          )}

                          // value={this.state.card_name_ar}
                          // onChange={(e) =>
                          //   this.setState({ card_name_ar: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="status">Transaction Type/Source</label>
                      <select
                        className="form-control"
                        value={this.state.type_source}
                        onChange={this.tranTypeSourceChange}
                      >
                        <option value="">Select Source Type</option>
                        <option value="Ahlain">Ahlain</option>
                        <option value="Fuel">Fuel</option>
                        <option value="Cafe_Amazon">Cafe Amazon</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        value={this.state.status}
                        onChange={this.statusChange}
                      >
                        <option value="1">Inactive</option>
                        <option value="0">Active</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="amount">Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        placeholder="Amount"
                        value={this.state.amount}
                        onChange={this.amountChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="purchase_items ">Purchase Items</label>
                      <input
                        type="number"
                        className="form-control"
                        id="purchase_items"
                        placeholder="Purchase Items"
                        value={this.state.purchaseItems}
                        onChange={this.purchaseItemChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="status">Product</label>
                      <select
                        className="form-control"
                        value={this.state.product}
                        onChange={this.productChange}
                      >
                        <option value="MOGAS91">MOGAS91</option>
                        <option value="MOGAS95">MOGAS95</option>
                        <option value="ultimax">Ultimax</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="category">Category</label>
                      <input
                        type="text"
                        className="form-control"
                        id="category"
                        placeholder="Category"
                        value={this.state.category}
                        onChange={this.CategoryChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="sub_category_1">Sub Category 1</label>
                      <input
                        type="text"
                        className="form-control"
                        id="sub_category_1"
                        placeholder="Sub Category 1"
                        value={this.state.category_1}
                        onChange={this.CategoryOneChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="sub_category_2">Sub Category 2</label>
                      <input
                        type="text"
                        className="form-control"
                        id="sub_category_2"
                        placeholder="Sub Category 2"
                        value={this.state.category_2}
                        onChange={this.CategoryTwoChange}
                      />
                    </div>
                  </div>
                  {/*<div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="voucher_codes">
                        Upload Voucher Codes
                      </label>
                      <input
                        id={this.state.id}
                        type="file"
                        name="file"
                        ref={(ref) => (this.uploadList = ref)}
                        onChange={this.onClickHandler}
                        style={{ display: "none" }}
                        accept=".xls"
                      />
                      {ApiRequest.can_access(
                        "basma-white-list",
                        "can_upload"
                      ) && (
                        <button
                          style={{ display: "block" }}
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={(e) => this.uploadList.click()}
                          
                        >
                          {"Choose File"}
                        </button>
                      )}
                    </div>
                      </div>*/}
                  <div className="col-sm-12 p0">
                    <div className="col-sm-12">
                      <label>Notification </label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="notification_en">English</label>
                        <input
                          type="text"
                          className="form-control"
                          id="notification_en"
                          placeholder="Notification English"
                          value={this.state.notification.en}
                          onChange={this.notification.bind(null, "en")}

                          // value={this.state.card_name_en}
                          // onChange={(e) =>
                          //   this.setState({ card_name_en: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="notification_ar">Arabic</label>
                        <input
                          type="text"
                          className="form-control"
                          id="notification_ar"
                          placeholder="Notification Arabic"
                          value={this.state.notification.ar}
                          onChange={this.notification.bind(null, "ar")}

                          // value={this.state.card_name_ar}
                          // onChange={(e) =>
                          //   this.setState({ card_name_ar: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="box-footer clearfix no-border">
                    <button
                      onClick={this.saveBulkBlank}
                      className="btn btn-primary pull-right "
                    >
                      Submit
                    </button>
                    <button
                      onClick={this.backUser}
                      className="btn btn-default pull-right mr-10"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}
