import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const whiteList_url = API.apiEndpoint + API.addCard;
const store_url = API.apiEndpoint + API.store;

export default class BasmaForm extends React.Component {
  state = {
    action: "add",
    card_number: "",
    serial_number: "",
    isLoyaltyCard: false,
    stores: [],
    card: {},
    error: false,
    message: "",
    store_code: "",
    success: false,
    success_message: ""
  };

  async componentDidMount() {
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        card_number: this.props.card.card_number,
        serial_number: this.props.card.serial_number,
        store_code: this.props.card.store_code,
        card: this.props.card,
        isLoyaltyCard: this.props.card.isLoyaltyCard ?? false,
      });
    }

    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message
      });
    } else {
      this.setState({
        stores: store_response.data,
        store_code: store_response.data[0].store_code,
        isLoyaltyCard: false,
        loading: false,
        error: false,
        message: optionsStore.message
      });
    }
  }

  card_numberChange = event => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card serial number limited to 16 digit"
      });
    } else {
      this.setState({ error: false, card_number: event.target.value });
    }
  };

  serial_numberChange = event => {
    this.setState({ error: false, serial_number: event.target.value });
  };

  onChange = e => {
    this.setState({ error: false, [e.target.name]: e.target.value });
  };

  saveBasma = async event => {
    const formData = new FormData();
    formData.append("card_number", this.state.card_number);
    formData.append("serial_number", this.state.serial_number);
    formData.append("store_code", this.state.store_code);
    formData.append("isLoyaltyCard", this.state.isLoyaltyCard);

    let api_response = {};
    let options = { url: whiteList_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      options = {
        url: whiteList_url + "/" + this.state.page.id,
        form_data: formData
      };
      api_response = await ApiRequest.put(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message
      });
      setTimeout(
        function() {
          this.setState({ success: false });
          window.location = "/basma_white_list";
        }.bind(this),
        10000
      );
    }
  };

  backCMS = () => {
    window.location = "/WhiteList";
  };

  render() {
    let {
      card_number,
      serial_number,
      error,
      message,
      stores,
      success,
      success_message
    } = this.state;
    return (
      <Content title="Add Basma Card" browserTitle="Basma Card">
        <LoadingAjax />

        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}

          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Basma Form </h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="card_number">Card Serial Number </label>
                  <input
                    type="number"
                    className="form-control"
                    id="card_number"
                    placeholder="0000000000000000"
                    value={card_number}
                    onChange={this.card_numberChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="card_number">Card Number </label>
                  <input
                    type="text"
                    className="form-control"
                    id="serail_number"
                    placeholder="000000"
                    value={serial_number}
                    onChange={this.serial_numberChange}
                  />
                </div>
                <div className="form-group">
                  <label>Store Code</label>
                  <select
                    className="form-control"
                    id="store_code"
                    name="store_code"
                    onChange={this.onChange}
                    value={this.state.store_code}
                  >
                    {stores.length > 0 &&
                      stores.map((item, key) => (
                        <option value={item.store_code}>
                          {item.store_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Loyalty Card ?</label>
                  <select
                    className="form-control"
                    id="isLoyaltyCard"
                    name="isLoyaltyCard"
                    onChange={this.onChange}
                    value={this.state.isLoyaltyCard}
                  >
                    <option value={true}>
                      Yes
                    </option>
                    <option value={false}>
                      No
                    </option>
                  </select>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveBasma}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.props.viewWhiteList.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
