import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../css/builder.css";
import "../../css/jquery-ui.css";
import $ from "jquery";

const edit_Question_form_url = API.apiEndpoint + API.questionBank;

export default class QuestionEditForm extends React.Component {
  state = {
    action: "add",
    creation_question: {},
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
  };

  async componentDidMount() {
    sessionStorage.isNew = false;
    let url = edit_Question_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    let scripts = [
      { src: "../../../js/jquery-ui.js" },
      { src: "../../../js/CubixFormBuilder.js" },
      //{ src: "../../../js/custom.js" },
    ];
    scripts.map((item) => {
      const script = document.createElement("script");
      script.src = item.src;
      script.async = true;
      document.body.appendChild(script);
    });

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let data = api_response.data.html;
      this.setState(
        {
          loading: false,
          error: false,
          message: api_response.message,
        },
        () => {
          $("#stage").html(data);
          setTimeout(function () {
            $(".field_block").trigger("click");
          }, 1200);
        }
      );
      this.setState({ question_type: api_response.data.question_type });
      $("#question-type")
        .find("option[value='" + api_response.data.question_type + "']")
        .attr("selected", true);

      var correct = [];
      $("#stage").on("click", ".field_block", function () {
        if (sessionStorage.isNew == "true") {
          return false;
        }
        var english_title = api_response.data.questionText.en;
        $("#title_en").val(english_title);

        var arabic_title = api_response.data.questionText.ar;
        $("#title_ln").val(arabic_title);

        var english_name = api_response.data.name.en;
        $("#name_en").val(english_name);

        var arabic_name = api_response.data.name.ar;
        $("#name_ln").val(arabic_name);

        if (!api_response.data.options) {
          return false;
        }
        var options = api_response.data.options;
        for (var i = 0; i < options.length; i++) {
          if (options[i].correct == 1) {
            correct[i] = options[i].correct;
          }
        }
        setTimeout(function () {
          $("#radio_elements_append")
            .find(".items")
            // .eq(i)
            .find(
              "input[type='radio'][data-created='" +
                api_response.data.correctAnswer +
                "']"
            )
            .prop("checked", true);
          var checkboxCorrectAnswer = api_response.data.correctAnswer;
          if (checkboxCorrectAnswer && checkboxCorrectAnswer.length > 0) {
            for (var i = 0; i < checkboxCorrectAnswer.length; i++) {
              $("#radio_elements_append")
                .find(".items")
                //.eq(i)
                .find(
                  "input[type='radio'][data-created='" +
                    api_response.data.correctAnswer[i] +
                    "']"
                )
                .prop("checked", true);
            }
          }
        }, 1000);
        setTimeout(function () {
          $("#checkbox_elements_append")
            .find(".items")
            // .eq(i)
            .find(
              "input[type='checkbox'][data-created='" +
                api_response.data.correctAnswer +
                "']"
            )
            .prop("checked", true);
          var checkboxCorrectAnswer = api_response.data.correctAnswer;
          if (checkboxCorrectAnswer && checkboxCorrectAnswer.length > 0) {
            for (var i = 0; i < checkboxCorrectAnswer.length; i++) {
              $("#checkbox_elements_append")
                .find(".items")
                //.eq(i)
                .find(
                  "input[type='checkbox'][data-created='" +
                    api_response.data.correctAnswer[i] +
                    "']"
                )
                .prop("checked", true);
            }
          }
        }, 1000);
        if (sessionStorage.isNew == "true") {
          $(".properties").find("input[type='text']").val("");
          $("#radio_elements").find("radio").removeAttr("checked");
          $("#checkbox_elements").find("radio").removeAttr("checked");
        }
      });

      // setTimeout(function () {
      //   $(".field_block").trigger("click");
      // }, 1000);

      $(window).on("load", function () {
        $(".field_block").trigger("click");
      });
    }
  }
  saveQuestion = async (event) => {
    const formData = new FormData();
    let error = false;
    var category = $("#question-type").find("option:selected").val();
    if (!category) {
      error = true;
      this.setState({
        error: true,
        message: "Question category required",
      });
    }

    if (category == "quiz") {
      if ($("input[name='option']").length) {
        if (!$("input[name='option']:checked").val()) {
          error = true;
          this.setState({
            error: true,
            message: "Nothing is checked!",
          });
        }
      }
    }

    if (error) {
      return;
    }

    var jsonObj = JSON.parse(sessionStorage.obj);
    jsonObj.question_type = this.state.question;

    formData.append("questions", sessionStorage.obj);

    let api_response = {};
    let options = {
      url: edit_Question_form_url + "/" + this.props.match.params.id,
      form_data: JSON.stringify(jsonObj),
    };

    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/oomco_fun/question_bank";
        }.bind(this),
        5000
      );
      sessionStorage.clear();
    }
  };
  questionCat = (event) => {
    this.setState({ question_type: event.target.value });
    $("#stage").html("");
  };
  backQuestion = () => {
    window.location = "/oomco_fun/question_bank";
  };

  render() {
    let { error, message, success, success_message, loading } = this.state;
    return (
      <Content title="Question Edit" browserTitle="Question Edit">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Question Edit</h3>
                </div>
                <div className="box-body">
                  <div className="col-sm-3 col-lg-2 p-0">
                    <div className="navbar navbar-default navbar-fixed-rightside">
                      <div className="elements">
                        <h3>
                          <i className="fa fa-wpforms"></i> Form Elements
                        </h3>
                        <hr />
                        <ul className="element_list element_list_cus">
                          <li
                            style={
                              this.state.question_type === "quiz"
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <a href="#" data-element="input">
                              <svg viewBox="0 0 16 16" id="icon-text">
                                <path d="M2 1v3h1V3h4v10H5v1h6v-1H9V3h4v1h1V1H2z"></path>
                              </svg>
                              <span>Single Text</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-element="mcq">
                              <svg viewBox="0 0 16 16" id="icon-radiogroup">
                                <path d="M3 1C1.3 1 0 2.3 0 4s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm0 4c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zM8 3v2h8V3H8zm0 10h8v-2H8v2z"></path>
                              </svg>
                              <span>Radio Group</span>
                            </a>
                          </li>
                          <li
                            style={
                              this.state.question_type === "quiz"
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <a href="#" data-element="multiselect">
                              <svg viewBox="0 0 16 16" id="icon-checkbox">
                                <path d="M1 1v14h14V1H1zm6 10L4 8l1-1 2 2 4-4 1 1-5 5z"></path>
                              </svg>
                              <span>Checkbox</span>
                            </a>
                          </li>
                          <li
                            style={
                              this.state.question_type === "quiz"
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <a href="#" data-element="rating">
                              <svg viewBox="0 0 512 512">
                                <path
                                  className="cls-1"
                                  d="M415.29,511.82a5.71,5.71,0,0,1-3.31-1.06L256,399.34,100,510.77a5.69,5.69,0,0,1-8.82-6l44.73-178.92L1.67,191.58a5.69,5.69,0,0,1,4-9.71h178.1L250.67,3.51c1.67-4.44,9-4.44,10.66,0l66.88,178.35h178.1a5.69,5.69,0,0,1,4,9.71L376.08,325.83,420.8,504.75a5.69,5.69,0,0,1-5.52,7.07ZM256,386.67a5.71,5.71,0,0,1,3.31,1.06L406,492.52,364.26,325.47a5.68,5.68,0,0,1,1.49-5.4L492.58,193.24H324.27a5.69,5.69,0,0,1-5.33-3.69L256,21.71,193.06,189.56a5.69,5.69,0,0,1-5.33,3.69H19.42L146.24,320.07a5.68,5.68,0,0,1,1.49,5.4L106,492.52l146.72-104.8A5.71,5.71,0,0,1,256,386.67Z"
                                />
                              </svg>
                              <span>Rating</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5 col-lg-7">
                    <div className="builder">
                      <div id="stage"></div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-lg-3 p-0">
                    <div className="navbar-default navbar-fixed-side navibar">
                      <div className="elementz">
                        <div className="form-group">
                          <label
                            htmlFor="questionCategory"
                            className="rightLabel"
                          >
                            Question Category
                          </label>
                          <select
                            className="form-control"
                            id="question-type"
                            value={this.state.question_type}
                            onChange={this.questionCat}
                            style={{ pointerEvents: "none" }}
                          >
                            <option value="">Select Question</option>
                            <option value="quiz">Quiz</option>
                            <option value="survey">Survey</option>
                          </select>
                        </div>
                        <h3 className="rightLabel"> Properties</h3>
                        <div className="properties">
                          <h5>
                            Name <i className="fa fa-caret-down pull-right"></i>
                          </h5>
                          <div className="fields">
                            <input
                              type="text"
                              id="name_en"
                              className="form-element"
                              placeholder="Name (Default En)"
                            />
                            <input
                              type="text"
                              id="name_ln"
                              className="form-element"
                              placeholder="Name (Default Ar)"
                            />
                          </div>
                          <h5>
                            Question Statement{" "}
                            <i className="fa fa-caret-down pull-right"></i>
                          </h5>
                          <div className="fields">
                            <input
                              type="text"
                              id="title_en"
                              className="form-element"
                              placeholder="Title (Default En)"
                            />
                            <input
                              type="text"
                              id="title_ln"
                              className="form-element"
                              placeholder="Title (Default Ar)"
                            />
                          </div>

                          <div className="textbox_elements element_properties"></div>
                          <div className="radio_elements element_properties">
                            <label>Radio Properties</label>
                            <div id="radio_elements">
                              <div id="radio_elements_append"></div>
                              <input
                                type="button"
                                className="add_element_radio btn btn-success"
                                value="Add Item"
                              />
                            </div>
                          </div>
                          <div className="checkbox_elements element_properties">
                            <label>Checkbox Properties</label>
                            <div id="checkbox_elements">
                              <div id="checkbox_elements_append"></div>
                              <input
                                type="button"
                                className="add_element_checkbox btn btn-success"
                                value="Add Item"
                              />
                            </div>
                          </div>

                          <div className="dropdown_elements element_properties"></div>

                          <div className="rating_elements element_properties"></div>

                          <div className="matrix_elements element_properties"></div>

                          <div className="slider_elements element_properties"></div>

                          <hr />

                          <div className="box-footer clearfix no-border">
                            <button
                              id="save_btn"
                              onClick={this.saveQuestion}
                              className="btn btn-primary pull-right "
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered cus-modal-dialog"
            role="document"
          >
            <div className="modal-content cusModalStyle">
              <h1>Delete</h1>
              Are you sure you want to delete ?
              <div className="react-confirm-alert-button-group">
                <button
                  type="button"
                  className="btn btn-primary deleteQuest"
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
