import React, { Component } from "react";
import PropTypes from "prop-types";
import { Content, Row, Col } from "adminlte-2-react";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import "./station_review.css";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";

export default class StationReviewView extends Component {
  static propTypes = {
    station_review: PropTypes.object.isRequired
  };

  render() {
    const { station_review } = this.props;
    return (
      <Content title={"Station - Review "} browserTitle="Station Review">
        <LoadingAjax />
        <Row>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">View </h3>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>Review ID</b>{" "}
                    <span className="pull-right">{station_review.id}</span>
                  </li>
                  {station_review.station && (
                    <li className="list-group-item">
                      <b>Station Name</b>{" "}
                      <span className="pull-right">
                        {station_review.station.name.en}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item">
                    <b>User Name</b>{" "}
                    <span className="pull-right">{station_review.name}</span>
                  </li>
                  <li className="list-group-item">
                    <b>User Mobile Number </b>{" "}
                    <span className="pull-right">
                      {station_review.mobileNumber}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <b>Reaction</b>{" "}
                    <span className="pull-right ">
                      <span className="icon-spacing">
                        <i
                          className={
                            station_review.smiley === "surprised"
                              ? "fa fa-lg fa-meh-o icon-filled"
                              : "fa fa-lg fa-meh-o "
                          }
                        >
                          {" "}
                        </i>
                        <i
                          className={
                            station_review.smiley === "happy"
                              ? "fa fa-lg fa-smile-o icon-filled"
                              : "fa fa-lg fa-smile-o"
                          }
                        />
                        <i
                          className={
                            station_review.smiley === "sad"
                              ? "fa fa-lg fa-frown-o icon-filled"
                              : "fa fa-lg fa-frown-o"
                          }
                        />
                      </span>
                    </span>
                  </li>
                  {station_review.message && (
                    <li className="list-group-item">
                      <b>Message</b>{" "}
                      <span className="pull-right">
                        {station_review.message}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item">
                    <b>Reviewed At</b>{" "}
                    <span className="pull-right">
                      {ApiRequest.date_format_utc(station_review.updatedAt)}
                    </span>
                  </li>
                  {/*
                    <li className="list-group-item">
                      <b>Image </b>{" "}
                      <span className="pull-right">
                        {station_review.image ? (
                          <ReactFancyBox
                            // thumbnail={station_review.image}
                            image={station_review.image}
                            defaultThumbnailWidth={50}
                            defaultThumbnailHeight={50}
                          />
                        ) : (
                          "No Image"
                        )}
                      </span>
                    </li>
                        */}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
