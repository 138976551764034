import React from "react";
import StationReviewView from "./view";
import StationReviewListing from "./listing";
import axios from "axios";
import API from "../../constants";
const station_review_url = API.apiEndpoint + API.stationReview;

export default class StationReview extends React.Component {
  state = {
    action: "listing",
    station_review: {}
  };

  async componentDidMount() {
    if (this.props.view) {
      const response = await axios.get(
        station_review_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewStationReview(response.data.data, "view");
      }
    }
  }

  viewStationReview = (station_review, action) => {
    this.setState({ action: action, station_review: station_review });
  };

  render() {
    const { action, station_review } = this.state;

    if (action === "view")
      return (
        <StationReviewView
          station_review={station_review}
          viewStationReview={this.viewStationReview}
        />
      );
    else
      return (
        <StationReviewListing viewStationReview={this.viewStationReview} />
      );
  }
}
