import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";

const promo_code_form_url = API.apiEndpoint + API.PromoCode;
const PromoCodeGen_url = API.apiEndpoint + API.PromoCodeGen;
const badgeManagement_url = API.apiEndpoint + API.badgeManagement;

export default class PromoCodeEditForm extends React.Component {
  state = {
    promo_code_list: [],
    loyaltyGameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    active: "",
    start: "",
    end: "",
  };

  async componentDidMount() {
    let url = promo_code_form_url + "/" + this.props.match.params.id;

    let options = { url: url };

    let api_response = await ApiRequest.get(options);

    let data = api_response.data;

    let badgeOptions = {
      url: badgeManagement_url + "?allRecords=1",
    };
    let badge_response = await ApiRequest.get(badgeOptions);
    if (api_response.error || badge_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      var startDate = new Date(data.from);
      var endDate = new Date(moment.utc(data.to).format("MM/DD/YYYY"));

      this.setState({
        badgeData: badge_response.data.results,
        promo_code_date_from: startDate,
        promo_code_date_to: endDate,
        codeGen: data.code,
        badgeNameValue: data.badge_name,
        badgeAction: data.badge_action,
        badgeId: data.badge_id,
        capLimitValue: data.limit,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  promoCodeStart = async (date) => {
    this.setState({
      promo_code_date_from: date,
      enableBtn: true,
    });
  };
  promoCodeEnd = async (date) => {
    this.setState({
      promo_code_date_to: date,
      enableBtn: true,
    });
  };
  badgeNameChange = (event) => {
    var rule_id = $("option:selected", event.target).attr("data-id");
    var action = $("option:selected", event.target).attr("data-action");
    this.setState({
      badgeNameValue: event.target.value,
      badgeId: rule_id,
      badgeAction: action,
    });
  };
  codeGenChange = (event) => {
    this.setState({ codeGen: event.target.value });
  };
  capLimitChange = (event) => {
    this.setState({ capLimitValue: event.target.value });
  };
  callCodeGenerate = async () => {
    let options = { url: PromoCodeGen_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        codeGen: api_response.data,
      });
    }
  };

  savePromoCode = async (event) => {
    let error = false;
    if (!this.state.codeGen) {
      error = true;
      this.setState({
        error: true,
        message: "Code Generator is required",
      });
      return;
    }
    if (!this.state.badgeNameValue) {
      error = true;
      this.setState({
        error: true,
        message: "Badge Name is required",
      });
      return;
    }
    if (!this.state.promo_code_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }
    if (!this.state.promo_code_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.capLimitValue) {
      error = true;
      this.setState({
        error: true,
        message: "Cap/Limit is required",
      });
      return;
    }

    var moment = require("moment");

    const formData = new FormData();
    formData.append(
      "from",
      moment.utc(this.state.promo_code_date_from).startOf("day").valueOf()
    );
    //formData.append("to", this.state.cash_back_promotion_date_to);
    formData.append(
      "to",
      moment
        .utc(this.state.promo_code_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );
    formData.append("badge_name", this.state.badgeNameValue);
    formData.append("badge_id", this.state.badgeId);
    formData.append("badge_action", this.state.badgeAction);
    formData.append("code", this.state.codeGen);
    formData.append("limit", this.state.capLimitValue);

    let api_response = {};
    let options = { url: promo_code_form_url, form_data: formData };

    options.url = promo_code_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/promo_code";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/promo_code";
  };

  render() {
    let { error, message, success, success_message, badgeData } = this.state;

    return (
      <Content title="Promo Code" browserTitle="Promo Code">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Promo Code Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Code Generator</label>
                    <input
                      type="text"
                      min="0"
                      disabled
                      className="form-control"
                      placeholder="Code Generator"
                      value={this.state.codeGen}
                      onChange={this.codeGenChange}
                    />
                    {/* <small className="pull-right">
                      <a
                        href="JavaScript:Void(0);"
                        onClick={this.callCodeGenerate}
                      >
                        Code Generate
                      </a>
          </small>*/}
                  </div>
                  {/*<div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.passChange}
                  />
                  <small className="pull-right">
                    <a href="JavaScript:Void(0);" onClick={this.changePass}>
                      Generate Password
                    </a>
                  </small>
          </div>*/}
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Badge Name</label>
                    <select
                      className="form-control"
                      value={this.state.badgeNameValue}
                      onChange={this.badgeNameChange}
                    >
                      {badgeData &&
                        badgeData.map((data) => {
                          return (
                            <option
                              data-id={data.rule_id}
                              data-action={data.action}
                            >
                              {data.title.en}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.promo_code_date_from}
                    selectsStart
                    promo_code_date_from={this.state.promo_code_date_from}
                    promo_code_date_to={this.state.promo_code_date_to}
                    onChange={this.promoCodeStart}
                    minDate={moment().toDate()}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.promo_code_date_to}
                    selectsEnd
                    promo_code_date_from={this.state.promo_code_date_from}
                    promo_code_date_to={this.state.promo_code_date_to}
                    onChange={this.promoCodeEnd}
                    minDate={this.state.promo_code_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Cap/Limit</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="000"
                      value={this.state.capLimitValue}
                      onChange={this.capLimitChange}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.savePromoCode}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
