import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import LoadingAjax from "../../components/Loading";
import { Inputs } from "adminlte-2-react";

require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const topupPromReport_url = API.apiEndpoint + API.nfctagReport;
const topupPromotion_url = API.apiEndpoint + API.TopupRewardPromotions;

export default class NfcReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      topupPromReport: [],
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      callApi: false,
      enableBtn: false,
      loadingCart: false,
      stores: [],
      store_code: "",
      status: "",
      search_clear: true,
    };
  }
  async componentDidMount() {
    await this.getReportData();

    let options = { url: topupPromReport_url };
    let StoreOptions = { url: store_url };

    let api_response = await ApiRequest.get(options);
    let store_response = await ApiRequest.get(StoreOptions);

    let cashBackPromotion_options = {
      url: topupPromotion_url + "?allRecords=1",
    };
    let cashBackPromotion_options_response = await ApiRequest.get(
      cashBackPromotion_options
    );

    if (api_response.error || store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({ value: store.store_code, label: store.store_code });
      });

      this.setState({
        cashBackTierName: cashBackPromotion_options_response.data.results,
        // topupPromReportCard: api_response.data,
        // topupPromReport: api_response.data.results,
        // pagination: api_response.data.pagination,
        stores: stores,
        loading: false,
        error: false,
        message: api_response.message,
      });
      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          topupPromReportCard: api_response.data,
          topupPromReport: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });
    let url = topupPromReport_url + "?page=" + pageNumber;

    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }

    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    if (this.state.topup_promotion_id) {
      url =
        url +
        "&filter=true&key=promotion_id&value=" +
        this.state.topup_promotion_id;
    }

    // if (this.state.tierSelected) {
    //   url = url + "&tierSelected=" + this.state.tierSelected;
    // }

    let options = { url: url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        topupPromReportCard: api_response.data,
        topupPromReport: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }
    this.setState({
      loadingCart: false,
    });
  };
  downloadData = async (pageNumber = 1) => {
    let url = topupPromReport_url + "?csv=" + 1;

    if (this.state.topup_promotion_id) {
      url = url + "&topup_promotion_id=" + this.state.topup_promotion_id;
    }
    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = {
      url: url,
      file_name:
        "NFC Tag Report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };

    await ApiRequest.download(options);
  };
  handleTierChange = (event) => {
    this.setState({ topup_promotion_id: event.target.value, enableBtn: true });
  };
  noResultText() {
    return "N/A";
  }
  renderLoader() {
    return <LoadingSpinner />;
  }
  handleChangeStoreName = async (selectedOption) => {
    this.setState({
      store_code: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });

    // this.state.selectedOption = "";
    // this.state.store_code = "";
  };
  handleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
      enableBtn: true,
      usersReports: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      status: "",
      selectedOption: "",
    });

    this.state.status = "";
    this.state.selectedOption = "";
    this.state.store_code = "";

    await this.getReportData();
  };
  rescue_value(value) {
    return value === "" ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const { Select2 } = Inputs;
    const {
      pagination,
      topupPromReport,
      topupPromReportCard,
      loading,
      loadingCart,
      error,
      limit,
      message,
      callApi,
      enableBtn,
      stores,
      cashBackTierName,
    } = this.state;
    return (
      <Content title="NFC Tag Report" browserTitle="NFC Tag Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                {/*<div className="col-xs-4">
                    <div className="form-group rangeFilter">
                      <label>Sale Date</label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.sale_date_from}
                        selectsStart
                        sale_date_from={this.state.sale_date_from}
                        sale_date_to={this.state.sale_date_to}
                        onChange={this.saleStart}
                        placeholderText="From"
                      />
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.sale_date_to}
                        selectsEnd
                        sale_date_from={this.state.sale_date_from}
                        sale_date_to={this.state.sale_date_to}
                        onChange={this.saleEnd}
                        minDate={this.state.sale_date_from}
                        placeholderText="To"
                      />
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="form-group">
                      <label>Activation Source </label>
                      <select
                        className="form-control"
                        value={this.state.activation_source}
                        onChange={this.handleActivationSource}
                      >
                        <option value={null}>Select</option>
                        <option value="Admin">Admin</option>
                        <option value="Ahlain">Ahlain</option>
                        <option value="MobileApp">Mobile App</option>
                        <option value=" RegionalHoOffices">
                          Regional/Ho Offices
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-4 cusSelect">
                    <Select2
                      label="Region"
                      value={this.state.selectedRegion}
                      onChange={this.handleChangeRegion}
                      options={cities}
                      placeholder="Select"
                    />
                  </div>
                  <div className="col-xs-4 cusSelect">
                    <Select2
                      label="Stores"
                      className="form-control"
                      value={this.state.selectedOption}
                      onChange={this.handleChangeStoreName}
                      options={stores}
                      placeholder="Select"
                    />
                  </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Name</label>

                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.selectedTierNameOption}
                      onChange={this.handleTierChange}
                    >
                      <option value=""> Select Name</option>
                      {cashBackTierName &&
                        cashBackTierName.map((data) => {
                          return (
                            <option value={data.id}>
                              {data.name ? data.name : "No Name"}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>*/}
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={this.state.status}
                      onChange={this.handleChangeStatus}
                    >
                      <option value="">Select</option>
                      <option value={"1"}>Active</option>
                      <option value={"0"}>InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                    disabled={!enableBtn}
                  >
                    Clear
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/*<div className="box-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          TOTAL ELIGIBLE TOP-UPS
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : topupPromReportCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {topupPromReportCard.totalEligibleTopups}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          VALUE OF TOP-UPS (OMR)
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : topupPromReportCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {topupPromReportCard.totalTopups}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                        </div>*/}
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Card Number</th>
                            <th>Date/time</th>
                            <th>NFC Tags</th>
                            <th>Store Code</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {topupPromReport.length > 0 && topupPromReport ? (
                            topupPromReport.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.card_number)}</td>
                                  <td>
                                    {ApiRequest.date_time_format_utc(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                  <td>{this.rescue_value(data.nfc_tag)}</td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>{this.rescue_status(data.status)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="11" className="noRecord">
                              {callApi ? "No Record Found" : "No Record Found"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
