import React from "react";
import { Content } from "adminlte-2-react";
import API from "../../constants";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";

const updateCardsStoreCode = API.apiEndpoint + API.updateCardsStoreCode;
const store_url = API.apiEndpoint + API.store;

export default class UpdateCardsStoreCode extends React.Component {
	state = {
		error: false,
		message: "",
		loading: true,
		success: false,
		success_message: "",
		from: "",
		to: "",
		new_store_code: "",
	};

	async componentDidMount() {
		let optionsStore = { url: store_url };
		let store_response = await ApiRequest.get(optionsStore);

		if (store_response.error) {
			this.setState({
				loading: false,
				error: true,
				message: optionsStore.message,
			});
		} else {
			let stores = [];
			store_response.data.map((store) => {
				stores.push({
					value: store.store_code,
					label: store.store_code + " - " + store.store_name,
				});
			});

			this.setState({
				stores: stores,
				//stores: store_response.data,
				//store_code: store_response.data[0].store_code,
				loading: false,
				error: false,
				message: optionsStore.message,
			});
		}
	}

	handleChangeRangeFrom = (event) => {
		if (event.target.value.length > 16) {
			this.setState({
				error: true,
				message: "Card number limited to 16 digit",
			});
		} else {
			this.setState({
				error: false,
				enableBtn: true,
				from: event.target.value,
			});
		}
	};
	handleChangeRangeTo = (event) => {
		if (event.target.value.length > 16) {
			this.setState({
				error: true,
				message: "Card number limited to 16 digit",
			});
		} else {
			this.setState({
				error: false,
				enableBtn: true,
				to: event.target.value,
			});
		}
	};
	handleChangeStoreName = async (selectedOption) => {
		this.setState({
			new_store_code: selectedOption.target.value,
			enableBtn: true,
			limit: 10,
		});
	};

	saveBulkBlank = async () => {
		const formData = new FormData();

		let error = false;

		if (!this.state.to.length > 0) {
			error = true;
			this.setState({
				error: true,
				message: "Card To is Required",
			});
		}
		if (!this.state.from.length > 0) {
			error = true;
			this.setState({
				error: true,
				message: "Card From is Required",
			});
		}
		if (!this.state.new_store_code.length > 0) {
			error = true;
			this.setState({
				error: true,
				message: "New store code is Required",
			});
		}

		if (error) {
			return;
		}

		formData.append("from", this.state.from);
		formData.append("to", this.state.to);
		formData.append("new_store_code", this.state.new_store_code);

		let api_response = {};
		let options = {
			url: updateCardsStoreCode,
			form_data: formData,
		};
		api_response = await ApiRequest.post(options);
		if (api_response.error) {
			this.setState({
				loading: false,
				error: true,
				message: api_response.message,
			});
		} else {
			this.setState({
				success: true,
				loading: false,
				success_message: api_response.message,
			});
			setTimeout(
				function () {
					this.setState({ success: false });
					window.location = "/basma_white_list";
				}.bind(this),
				5000
			);
		}
	};

	backUser = () => {
		window.location = "/basma_white_list";
	};

	render() {
		const { Select2 } = Inputs;
		let { stores, error, message, success, success_message } = this.state;

		return (
			<Content
				title="Update Cards Store Code"
				browserTitle="Update Cards Store Code"
			>
				<LoadingAjax />
				<div className="row">
					{error && (
						<div
							className="alert alert-danger alert-dismissible"
							style={{
								marginRight: "15px",
								marginLeft: "15px",
							}}
						>
							<button
								type="button"
								className="close"
								data-dismiss="alert"
								aria-hidden="true"
							>
								×
							</button>
							{message}
						</div>
					)}
					{success && (
						<div
							className="alert alert-success alert-dismissible"
							style={{
								marginRight: "15px",
								marginLeft: "15px",
							}}
						>
							<button
								type="button"
								className="close"
								data-dismiss="alert"
								aria-hidden="true"
							>
								×
							</button>
							{success_message}
						</div>
					)}
					<div className="col-md-12">
						<div className="box box-primary">
							<div className="box-header with-border">
								<h3 className="box-title">
									Update Cards Store Code form
								</h3>
							</div>

							<div className="box-body">
								<div className="col-md-6">
									<div className="form-group rangeFilter">
										<label
											style={{
												display: "block",
											}}
										>
											Card Number
										</label>
										<input
											type="number"
											value={this.state.from}
											onChange={
												this.handleChangeRangeFrom
											}
											className="form-control"
											id="card_number"
											min={0}
											placeholder="From (0000000000000000)"
											style={{
												display: "inline-block",
												width: "49%",
												margin: "0 .7% 0 0",
											}}
										/>
										<input
											type="number"
											min={0}
											value={this.state.to}
											onChange={this.handleChangeRangeTo}
											className="form-control"
											placeholder="To (0000000000000000)"
											style={{
												display: "inline-block",
												width: "49%",
												margin: "0 .7% 0 0",
											}}
										/>
									</div>
								</div>
								<div className="col-md-6 cusSelect">
									<Select2
										label="Store Code"
										className="form-control"
										value={this.state.new_store_code}
										onChange={this.handleChangeStoreName}
										options={stores}
										placeholder="Select"
									/>
								</div>
							</div>

							<div className="box-footer clearfix no-border">
								<button
									onClick={this.saveBulkBlank}
									className="btn btn-primary pull-right "
								>
									Submit
								</button>
								<button
									onClick={this.backUser}
									className="btn btn-default pull-right mr-10"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</Content>
		);
	}
}
