import React from "react";
import ComplaintView from "./view";
import ComplaintListing from "./listing";
import axios from "axios";
import API from "../../constants";
const complaint_url = API.apiEndpoint + API.complaintReview;

export default class Complaint extends React.Component {
  state = {
    action: "listing",
    complaint: {}
  };

  async componentDidMount() {
    if (this.props.view) {
      const response = await axios.get(
        complaint_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewComplaint(response.data.data, "view");
      }
    }
  }

  viewComplaint = (complaint, action) => {
    this.setState({ action: action, complaint: complaint });
  };

  render() {
    const { action, complaint } = this.state;

    if (action === "view")
      return (
        <ComplaintView
          complaint={complaint}
          viewComplaint={this.viewComplaint}
        />
      );
    else return <ComplaintListing viewComplaint={this.viewComplaint} />;
  }
}
