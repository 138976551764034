import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import GoogleMap from "./components/GoogleMap";
//import axios from "axios";
import isEmpty from "lodash.isempty";
import LoadingAjax from "./components/Loading";
import CONFIG from "./constants";
import API from "./constants";
import ApiRequest from "./ApiRequest";
import LoadingSpinner from "../src/components/LoadingSpinner";

const stations_url = API.apiEndpoint + API.stationLocator;
const dashboard_url = API.apiEndpoint + API.dashboard;

const getInfoWindowString = (station) => `
    <div>
      <div style="font-size: 16px;">
        <a href="/stations/${station.unique_identifier}">${station.name.en}</a>
      </div>
    </div>`;

export default class Dashboard extends Component {
  state = {
    stations: [],
    no_of_stations: 0,
    no_of_registered_complaints: 0,
    no_of_contact_us: 0,
    no_of_notifications: 0,
    no_of_appusers: 0,
    no_of_whiteListedCards: 0,
    no_of_registeredCards: 0,
    no_of_rpsTransaction: 0,
    no_of_sfsTransaction: 0,
    no_of_online_topups: 0,
    no_of_badgesAwarded: 0,
    no_of_registeredVirtualCards: 0,
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let options = { url: stations_url };
    let options_status = { url: dashboard_url };

    let api_response = await ApiRequest.get(options);
    let api_response_status = await ApiRequest.get(options_status);

    if (api_response.error || api_response_status.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ error: false });
        }.bind(this),
        10000
      );
    } else {
      this.setState({
        stations: api_response.data,
        no_of_stations: api_response_status.data.no_of_stations,
        no_of_registered_complaints:
          api_response_status.data.no_of_registered_complaints,
        no_of_contact_us: api_response_status.data.no_of_contact_us,
        no_of_notifications: api_response_status.data.no_of_notifications,
        no_of_appusers: api_response_status.data.no_of_appusers,
        no_of_whiteListedCards: api_response_status.data.no_of_whiteListedCards,
        no_of_registeredCards: api_response_status.data.no_of_registeredCards,
        no_of_rpsTransaction: api_response_status.data.no_of_rpsTransaction,
        no_of_sfsTransaction: api_response_status.data.no_of_sfsTransaction,
        no_of_online_topups: api_response_status.data.no_of_online_topups,
        no_of_badgesAwarded: api_response_status.data.no_of_badgesAwarded,
        no_of_registeredVirtualCards:
          api_response_status.data.no_of_registeredVirtualCards,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  // async componentDidMount() {
  //   const response = await axios.get(stations_url);
  //   const response1 = await axios.get(dashboard_url);
  //   if (response.status === 200) {
  //     const data = response.data.data;
  //     const dashboard_data = response1.data.data;
  //     this.setState({
  //       stations: data,
  //       no_of_stations: dashboard_data.no_of_stations,
  //       no_of_registered_complaints: dashboard_data.no_of_registered_complaints,
  //       no_of_contact_us: dashboard_data.no_of_contact_us,
  //       no_of_notifications: dashboard_data.no_of_notifications,
  //       loading: false
  //     });
  //   }
  // }

  handleApiLoaded = (map, maps, stations) => {
    const markers = [];
    const infowindows = [];
    stations.forEach((station) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: station.location.coordinates[1],
            lng: station.location.coordinates[0],
          },
          map,
        })
      );

      infowindows.push(
        new maps.InfoWindow({
          content: getInfoWindowString(station),
        })
      );
    });

    markers.forEach((marker, i) => {
      marker.addListener("click", () => {
        infowindows[i].close(map, marker);
        infowindows[i].open(map, marker);
      });
    });
  };

  render() {
    const { stations, loading, error, message } = this.state;
    return (
      <Content title="Dashboard" browserTitle="Dashboard">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            {error && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-aqua">
                    <i className="fa fa-flag-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. stations</span>
                    <span className="info-box-number">
                      {this.state.no_of_stations}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-green">
                    <i className="fa fa-files-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. complaints </span>
                    <span className="info-box-number">
                      {this.state.no_of_registered_complaints}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-yellow">
                    <i className="fa fa-star-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. Contact us</span>
                    <span className="info-box-number">
                      {this.state.no_of_contact_us}
                    </span>
                  </div>

                  {this.props.content}
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-red">
                    <i className="fa fa-bell-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. Notifications</span>
                    <span className="info-box-number">
                      {this.state.no_of_notifications}
                    </span>
                  </div>

                  {this.props.content}
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-aqua">
                    <i className="fa fa-star-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. App Users</span>
                    <span className="info-box-number">
                      {this.state.no_of_appusers}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-green">
                    <i className="fa fa-flag-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. White List Cards</span>
                    <span className="info-box-number">
                      {this.state.no_of_whiteListedCards}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-yellow">
                    <i className="fa fa-flag-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. Registered Cards</span>
                    <span className="info-box-number">
                      {this.state.no_of_registeredCards}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-red">
                    <i className="fa fa-files-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. RPS Transaction</span>
                    <span className="info-box-number">
                      {this.state.no_of_rpsTransaction}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6 col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-yellow">
                    <i className="fa fa-files-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. SFS Transaction</span>
                    <span className="info-box-number">
                      {this.state.no_of_sfsTransaction}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {ApiRequest.can_access("dashboard", "can_view_stats") && (
              <Col className="col-md-3 col-sm-6  col-xs-12">
                <div className="info-box">
                  <span className="info-box-icon bg-aqua">
                    <i className="fa fa-flag-o" />
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">No. Top-ups</span>
                    <span className="info-box-number">
                      {this.state.no_of_online_topups}
                    </span>
                  </div>
                </div>
              </Col>
            )}
            <Col className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-flag-o" />
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">No. Badges Awarded</span>
                  <span className="info-box-number">
                    {this.state.no_of_badgesAwarded}
                  </span>
                </div>
              </div>
            </Col>
            <Col className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-flag-o" />
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">
                    No. Registered Virtual Cards
                  </span>
                  <span className="info-box-number">
                    {this.state.no_of_registeredVirtualCards}
                  </span>
                </div>
              </div>
            </Col>
            {ApiRequest.can_access("dashboard", "can_view_station_map") && (
              <Col xs={12}>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Stations</h3>
                  </div>
                  <div className="box-body">
                    <div className="map-spacing">
                      {!isEmpty(stations) && (
                        <GoogleMap
                          defaultZoom={5}
                          defaultCenter={CONFIG.MapCenter}
                          bootstrapURLKeys={{ key: CONFIG.GoogleMapKey }}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) =>
                            this.handleApiLoaded(map, maps, stations)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        )}
      </Content>
    );
  }
}
