const moment = require("moment-timezone");
function minutesSinceMidnightToDate(minutes) {
  var hours = Math.floor(minutes / 60);
  var mins = minutes % 60;

  // Create a new Date object
  var date = new Date();

  // Set hours and minutes
  date.setHours(hours);
  date.setMinutes(mins);
  date.setSeconds(0); // Set seconds to zero to avoid any discrepancies

  return date;
}

function dateToMinutesSinceMidnight(date) {
  return date.getHours() * 60 + date.getMinutes();
}

function dateRangeFormatter(dateStart, dateEnd) {
  return `${moment(dateStart).tz("Asia/Muscat").format("DD/MM/YYYY")} - ${moment(dateEnd).tz("Asia/Muscat").format("DD/MM/YYYY")}`;
}

function timeRangeFormatter(timeStart, timeEnd) {
  return `${moment(minutesSinceMidnightToDate(timeStart)).format("hh:mm a")} - ${moment(minutesSinceMidnightToDate(timeEnd)).format("hh:mm a")}`;
}

module.exports = {
  minutesSinceMidnightToDate,
  dateToMinutesSinceMidnight,
  dateRangeFormatter,
  timeRangeFormatter,
};
