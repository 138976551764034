import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import DatePicker from "react-datepicker";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import { Inputs } from "adminlte-2-react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import LoadingAjax from "../../components/Loading";

require("bootstrap/less/bootstrap.less");

const promoCodes_url = API.apiEndpoint + API.PromoCodeReport;
const promoCodeList_url = API.apiEndpoint + API.PromoCode;
// const store_url = API.apiEndpoint + API.store;
// const city_url = API.apiEndpoint + API.city;

export default class PromoCodesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submitUrl: "",
      status: "",
      activation_source: "",
      promoCodes: [],
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      callApi: false,
      enableBtn: false,
      loadingCart: false,
    };
  }
  async componentDidMount() {
    let options = { url: promoCodes_url };
    let api_response = await ApiRequest.get(options);

    let promoCodeOptions = { url: promoCodeList_url + "?all_records=true" };
    let promoCode_response = await ApiRequest.get(promoCodeOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        // promoCodes: api_response.data.results,
        // pagination: api_response.data.pagination,
        promoCodesList: promoCode_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });
      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          promoCodes: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = promoCodes_url + "?page=" + pageNumber;

    if (this.state.promo_code) {
      url = url + "&code=" + this.state.promo_code;
    }

    let options = { url: url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        promoCodes: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = promoCodes_url + "?csv=" + 1;

    if (this.state.promo_code) {
      url = url + "&code=" + this.state.promo_code;
    }
    let options = {
      url: url,
      file_name:
        "Promo Code Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };

    await ApiRequest.download(options);
  };
  handlePromoCodeChange = (event) => {
    this.setState({ promo_code: event.target.value, enableBtn: true });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  renderLoader() {
    return <LoadingSpinner />;
  }

  render() {
    //const { Select2 } = Inputs;
    const {
      pagination,
      promoCodes,
      loading,
      //loadingCart,
      error,
      limit,
      message,
      callApi,
      enableBtn,
      promoCodesList,
    } = this.state;

    return (
      <Content title="Promo Code Report" browserTitle="Promo Code Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {/*<Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
               <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Sale Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.sale_date_from}
                      selectsStart
                      sale_date_from={this.state.sale_date_from}
                      sale_date_to={this.state.sale_date_to}
                      onChange={this.saleStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.sale_date_to}
                      selectsEnd
                      sale_date_from={this.state.sale_date_from}
                      sale_date_to={this.state.sale_date_to}
                      onChange={this.saleEnd}
                      minDate={this.state.sale_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Activation Source </label>
                    <select
                      className="form-control"
                      value={this.state.activation_source}
                      onChange={this.handleActivationSource}
                    >
                      <option value={null}>Select</option>
                      <option value="Admin">Admin</option>
                      <option value="Ahlain">Ahlain</option>
                      <option value="MobileApp">Mobile App</option>
                      <option value=" RegionalHoOffices">
                        Regional/Ho Offices
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Region"
                    value={this.state.selectedRegion}
                    onChange={this.handleChangeRegion}
                    options={cities}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
          </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2 col-sm-6"></div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total eligible Top-ups
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : mobileAppSales ? (
                          <div>
                            <span className="info-box-number-custom">
                              {totalCardsSold + " Cards"}
                            </span>
                            <span className="info-box-omr-custom">
                              {totalCardsSoldAmount + " OMR"}
                            </span>
                          </div>
                        ) : (
                          //<div>{this.noResultText()}</div>
                          <span className="info-box-number-custom">100</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          {" "}
                          Value of Top-ups (OMR)
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : mobileAppSales ? (
                          <div>
                            <span className="info-box-number-custom">
                              {mobileAppSales.promoCodesCount + " Cards"}
                            </span>
                            <span className="info-box-omr-custom">
                              {mobileAppSales.promoCodesAmount + " OMR"}
                            </span>
                          </div>
                        ) : (
                          //<div>{this.noResultText()}</div>
                          <span className="info-box-number-custom">100</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6"></div>
                </div>
              </div>
            </div>
          </Col>*/}

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Promo Code Report</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
        </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Promo Code</label>
                    {/*<input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangePromoCode}
                      className="form-control"
                      placeholder="Enter Promo Code"
                    />*/}

                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.value}
                      onChange={this.handlePromoCodeChange}
                    >
                      <option value=""> Select Name</option>
                      {promoCodesList &&
                        promoCodesList.map((data) => {
                          return (
                            <option value={data.code}>
                              {data.code ? data.code : "No Name"}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Promo Code</th>
                            <th>OOMCO ID</th>
                            <th>Email</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {promoCodes.length > 0 && promoCodes ? (
                            promoCodes.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.promo_code)}</td>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user_id}>
                                          {this.rescue_value(data.oomco_id)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {this.rescue_value(data.email_address)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="11" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
