import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";

const rpsTransaction_url = API.apiEndpoint + API.rpsTransaction;
export default class RpsTransaction extends React.Component {
  state = {
    rps_transactions: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        rpsTransaction_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        rps_transactions: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    let {
      rps_transactions,
      loading,
      error,
      message,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;

    return (
      <Content title={"RPS Transaction"} browserTitle="RPS Transaction">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">RPS Transactions </h3>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    {
                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"group_id"}>OOMCO ID</option>
                          <option value={"card_number"}>
                            Card Serial Number
                          </option>
                          <option value={"transaction_id"}>
                            Transaction ID
                          </option>
                          <option value={"product_code"}>Product Code</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    }
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>OOMCO ID</th>
                            <th>Card Serial Number</th>
                            <th>Transaction ID</th>
                            <th>Store Name</th>
                            <th>Product Code</th>
                            <th>Transaction At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rps_transactions.length > 0 ? (
                            rps_transactions.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.group_id)}</td>
                                  <td>{this.rescue_value(data.card_number)}</td>
                                  <td>
                                    {this.rescue_value(data.transaction_id)}
                                  </td>
                                  <td>{this.rescue_value(data.store_name)}</td>
                                  <td>
                                    {this.rescue_value(data.product_code)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      parseInt(data.transaction_at) * 1000
                                    )}
                                  </td>
                                  <td>
                                    {ApiRequest.can_access(
                                      "card-transaction-logs",
                                      "can_view_single"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={
                                          "/rps_transaction_view/" + data.id
                                        }
                                      >
                                        {" "}
                                        <i class="fa fa-eye" />
                                        {"    "}
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="8" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
