import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
const moment = require("moment-timezone");

const loyaltyDistributionList_form_url = API.apiEndpoint + API.loyaltyCardsDistribution;
const stations_url = API.apiEndpoint + API.store;

export default class StationLoyaltyDistributionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      action: this.props.edit ? "edit" : "add",
      added_by: "",
      distributed_cards_count: "",
      start_card_number: "",
      end_card_number: "",
      store_code: API.storeCode,
      filler_name: "",
      filler_phone_number: "",
      stores_list: [],
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let options = { url: stations_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        ...this.state,
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        ...this.state,
        stores_list: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
    if (this.state.action === "edit") {
      let url = loyaltyDistributionList_form_url + "/" + this.props.match.params.id;
      let options = { url: url };
      let api_response = await ApiRequest.get(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        let data = api_response.data;
        this.setState({
          ...this.state,
          // added_by: "",
          distributed_cards_count: data.distributed_cards_count,
          start_card_number: data.start_card_number,
          end_card_number: data.end_card_number,
          store_code: data.store_code,
          filler_name: data.filler_name,
          filler_phone_number: data.filler_phone_number,
          loading: false,
          error: false,
          message: api_response.message,
        });
      }
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  storeChange = (event) => {
    this.setState({ store_code: event.target.value });
  };

  // DistributedCardsCountChange = (event) => {
  //   this.setState({ distributed_cards_count: event.target.value });
  // };

  FillerNameChange = (event) => {
    this.setState({ filler_name: event.target.value });
  };

  FillerPhoneNumberChange = (event) => {
    this.setState({ filler_phone_number: event.target.value });
  };

  startCardNumberChange = (event) => {
    this.setState({ start_card_number: event.target.value });
    if(!event.target.value) {
      this.setState({ distributed_cards_count: "" });
      return;
    }
    if(this.state.end_card_number !== "") {
      const distributed_cards_count = this.state.end_card_number - event.target.value + 1;
      if(distributed_cards_count > 0) {
        this.setState({ distributed_cards_count: distributed_cards_count });
      } else {
        this.setState({ distributed_cards_count: "" });
      }
    }
  };

  endCardNumberChange = (event) => {
    this.setState({ end_card_number: event.target.value });
    if(event.target.value === "") {
      this.setState({ distributed_cards_count: "" });
      return;
    }
    if(this.state.start_card_number !== "") {
      const distributed_cards_count = event.target.value - this.state.start_card_number + 1;
      if(distributed_cards_count > 0) {
        this.setState({ distributed_cards_count: distributed_cards_count });
      } else {
        this.setState({ distributed_cards_count: "" });
      }
    }
  };


  saveStationCardsDistribution = async (event) => {
    const formData = new FormData();
    formData.append("added_by", localStorage.getItem("user_id"));
    formData.append("distributed_cards_count", this.state.distributed_cards_count);
    formData.append("start_card_number", this.state.start_card_number);
    formData.append("end_card_number", this.state.end_card_number);
    formData.append("store_code", this.state.store_code);
    formData.append("filler_name", this.state.filler_name);
    formData.append("filler_phone_number", this.state.filler_phone_number);

    let api_response = {};
    let options = { url: loyaltyDistributionList_form_url, form_data: formData };
    if (this.state.action === "edit") {
      options.url = loyaltyDistributionList_form_url + "/" + this.props.match.params.id;
      api_response = await ApiRequest.put(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/stations_loyalty_distributions";
          }.bind(this),
          3000
        );
      }
    } else {
      api_response = await ApiRequest.post(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/stations_loyalty_distributions";
          }.bind(this),
          3000
        );
      }
    }
  };

  BackToStationsCardsDistributionList = () => {
    window.location = "/stations_loyalty_distributions";
  };

  render() {
    const { stores_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content
        title={`${this.state.action === "edit" ? "Edit" : "Add"} Station Cards Distribution Form`}
        browserTitle={`${this.state.action === "edit" ? "Edit" : "Add"} Station Cards Distribution Form`}>
        <LoadingAjax />
        <div className="row">
          {error && (
            <div class="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div class="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Station Cards Distribution Form</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="role">Station</label>
                  <select className="form-control" id="role" value={this.state.store_code} onChange={this.storeChange}>
                    <option>Select Station</option>
                    {stores_list &&
                      stores_list.map((e, i) => {
                        return <option value={e.store_code}>{`${e.store_name} - (${e.store_code})`}</option>;
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="start_card_number">Start Card Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="start_card_number"
                    placeholder="Start Card Number"
                    value={this.state.start_card_number}
                    onChange={this.startCardNumberChange}
                    pattern="[0-9]{16}"
                  />
                </div>
                {/* Show error if start_card_number is not 16 digits */}
                {this.state.start_card_number && this.state.start_card_number.length !== 16 && (
                  <div className="alert alert-danger">Start Card number should be 16 digits</div>
                )}
                <div className="form-group">
                  <label htmlFor="end_card_number">End Card Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="end_card_number"
                    placeholder="End Card Number"
                    value={this.state.end_card_number}
                    onChange={this.endCardNumberChange}
                    pattern="[0-9]{16}"
                  />
                </div>
                {/* Show error if end_card_number is not 16 digits */}
                {this.state.end_card_number && this.state.end_card_number.length !== 16 && (
                  <div className="alert alert-danger">End Card number should be 16 digits</div>
                )}
                <div className="form-group">
                  <label htmlFor="distributed_cards_count">Distributed Cards Count</label>
                  <input
                    type="number"
                    className="form-control"
                    id="distributed_cards_count"
                    placeholder=""
                    value={this.state.distributed_cards_count}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="filler_name">Filler Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="filler_name"
                    placeholder="Filler Name"
                    value={this.state.filler_name}
                    onChange={this.FillerNameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="filler_phone_number">Filler Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="filler_phone_number"
                    placeholder="Filler Phone Number"
                    value={this.state.filler_phone_number}
                    onChange={this.FillerPhoneNumberChange}
                    pattern="[0-9]{8}"
                  />
                </div>
                {/* Show error if filler_phone_number is not 8 digits */}
                {this.state.filler_phone_number && this.state.filler_phone_number.length !== 8 && (
                  <div className="alert alert-danger">Filler Phone number should be 8 digits</div>
                )}
              </div>

              <div className="box-footer clearfix no-border">
                <button 
                  onClick={this.saveStationCardsDistribution} 
                  className="btn btn-primary pull-right"
                  disabled={
                    !this.state.store_code ||
                    !this.state.start_card_number ||
                    !this.state.end_card_number ||
                    !this.state.filler_name ||
                    !this.state.filler_phone_number ||
                    !this.state.distributed_cards_count ||
                    this.state.start_card_number.length !== 16 ||
                    this.state.end_card_number.length !== 16 ||
                    this.state.filler_phone_number.length !== 8
                  }
                  >
                  {this.state.action === "edit" ? "Save" : "Submit"}
                </button>
                <button onClick={this.BackToStationsCardsDistributionList} className="btn btn-default pull-right mr-10">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
