import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

const topup_reward_form_url = API.apiEndpoint + API.TopupRewardPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;
const subTiers_for_parent_tier_url = API.apiEndpoint + API.getSubTiersForParentTier;

export default class TopupPromotionForm extends React.Component {
  state = {
    page: {},
    action: this.props.edit ? "edit" : "add",
    topup_promotion_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    start: "",
    end: "",
    topupPromotions: [],
    selectedOption: null,
    isPChecked: true,
    isVChecked: true,
    loyaltySubTier: null,
    availableLoyaltySubTiers: [],
  };

  async componentDidMount() {
    let options = { url: tierTargetList_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let topupPromotions = [];
      api_response.data.map((store) => {
        topupPromotions.push({
          value: store.CardTypeName,
          label: store.CardTypeName,
        });
      });
      if(!this.props.edit){
        this.setState({
          topupPromotions: topupPromotions,
          loading: false,
          error: false,
          message: api_response.message,
        });
      } else {
        let url2 = topup_reward_form_url + "/" + this.props.match.params.id;
        let options2 = { url: url2 };
        let api_response2 = await ApiRequest.get(options2);
        if (api_response2.error) {
          this.setState({
            loading: false,
            error: true,
            message: api_response2.message,
          });
        } else {
          let selectedParentTier = topupPromotions.find(
            (tier) => tier.value === api_response2.data.parentTierCardTypeName
          );
          let availableSubTiers = [];
          if(api_response2.data.loyaltySubTier){
            let subTiers_response = await ApiRequest.get({ url: subTiers_for_parent_tier_url + "/" + selectedParentTier.value });
            if (subTiers_response.error) {
              this.setState({
                loading: false,
                error: true,
                message: subTiers_response.message,
              });
            } else {
              subTiers_response.data.forEach((subTier) => {
                  availableSubTiers.push({
                    value: subTier.id,
                    label: subTier.name,
                  });
                });
            }
          }
          this.setState({
            topup_promotion_date_from: moment(api_response2.data.from).toDate(),
            topup_promotion_date_to: moment(api_response2.data.to).toDate(),
            topupValue: api_response2.data.transaction_value,
            topupPromotions: topupPromotions,
            selectedParentTier: selectedParentTier,
            availableLoyaltySubTiers: availableSubTiers,
            loyaltySubTier: api_response2.data.loyaltySubTier ? { value: api_response2.data.loyaltySubTier.id, label: api_response2.data.loyaltySubTier.name } : null,
            tierName: api_response2.data.name,
            isPChecked: api_response2.data.card_type.includes("physical"),
            isVChecked: api_response2.data.card_type.includes("virtual"),
            loading: false,
            error: false,
            message: api_response2.message,
          });
        }
      }
    }
  }
  promotionTypeChange = (event) => {
    this.setState({ promotionType: event.target.value });
  };
  tierTargetChange = (event) => {
    this.setState({ tier_target: event.target.value });
  };
  topupValueChange = (event) => {
    this.setState({ topupValue: event.target.value });
  };
  topupPromotionStart = async (date) => {
    this.setState({
      topup_promotion_date_from: date,
      enableBtn: true,
    });
  };
  topupPromotionEnd = async (date) => {
    this.setState({
      topup_promotion_date_to: date,
      enableBtn: true,
    });
  };
  handleParentTierChange = async (selectedParentTier) => {
    // Get Available Sub Tiers for Parent Tier
    let options = { url: subTiers_for_parent_tier_url + "/" + selectedParentTier.value };
    let subTiers_response = await ApiRequest.get(options);
    if (subTiers_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: subTiers_response.message,
      });
    } else {
      let availableSubTiers = [];
      subTiers_response.data.forEach((subTier) => {
          availableSubTiers.push({
            value: subTier.id,
            label: subTier.name,
          });
        });
      this.setState({
        selectedParentTier,
        availableLoyaltySubTiers: availableSubTiers,
      });
    }
  };
  handleChangeSubTier = (selectedSubTier) => {
    this.setState({ loyaltySubTier: selectedSubTier });
  }
  tierNameChange = (event) => {
    this.setState({ tierName: event.target.value });
  };
  onChangePhysicalCheck = (event) => {
    const { physical } = this.state;
    this.setState({
      physical: physical,
      enableBtn: true,
      isPChecked: !this.state.isPChecked,
    });
  };
  onChangeVirtualCheck = (event) => {
    const { virtual } = this.state;
    this.setState({
      virtual: virtual,
      enableBtn: true,
      isVChecked: !this.state.isVChecked,
    });
  };
  saveTopupPromotions = async (event) => {
    let error = false;
    if (!this.state.topup_promotion_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }
    if (!this.state.topup_promotion_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.tierName) {
      error = true;
      this.setState({
        error: true,
        message: "Name value is required",
      });
      return;
    }
    if (!this.state.selectedParentTier) {
      error = true;
      this.setState({
        error: true,
        message: "Parent Tier is required",
      });
      return;
    }
    if (!this.state.topupValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value is required",
      });
      return;
    }
    var moment = require("moment");
    const formData = new FormData();
    formData.append(
      "from",
      moment
        .utc(this.state.topup_promotion_date_from)
        .add(1, "days")
        .startOf("day")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.topup_promotion_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );
    formData.append("transaction_value", this.state.topupValue);
    formData.append("parentTierCardTypeName", this.state.selectedParentTier.value);
    if(this.state?.loyaltySubTier?.value){
      formData.append("loyaltySubTier", this.state.loyaltySubTier.value);
    }
    formData.append("name", this.state.tierName);
    formData.append("type", "Online Topup");

    var card_data = [];
    if (this.state.isPChecked === true) {
      card_data.push("physical");
    }
    if (this.state.isVChecked === true) {
      card_data.push("virtual");
    }
    formData.append("card_type", JSON.stringify(card_data));

    let api_response = {};
    if(this.props.edit){
      let options = { url: topup_reward_form_url  + "/" + this.props.match.params.id, form_data: formData };
      api_response = await ApiRequest.put(options);
    } else {
      let options = { url: topup_reward_form_url, form_data: formData };
      api_response = await ApiRequest.put(options);
      api_response = await ApiRequest.post(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/topup_promotion";
        }.bind(this),
        3000
      );
    }
  };

  backUser = () => {
    window.location = "/topup_promotion";
  };

  render() {
    let {
      error,
      message,
      success,
      success_message,
      topupPromotions,
      selectedParentTier,
      availableLoyaltySubTiers,
      loyaltySubTier,
      // physical,
      // virtual,
    } = this.state;
    return (
      <Content title="Topup Promotion" browserTitle="Topup Promotion">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Topup Promotion Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="promotion_type">Promotion Type</label>
                    {/*<select
                      className="form-control"
                      id="promotion_type"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Select Game ID </option>
                      {topup_promotion_list &&
                        topup_promotion_list.map((item, key) => {
                          return (
                            <option
                              value={item.GameId}
                              data-name={item.GameName}
                            >
                              {ApiRequest.string_humanize_format(item.GameName)}
                            </option>
                          );
                        })}
                      </select>*/}
                    <select
                      className="form-control"
                      id="promotion_type"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option>Online Topup</option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.topup_promotion_date_from}
                    selectsStart
                    topup_promotion_date_from={
                      this.state.topup_promotion_date_from
                    }
                    topup_promotion_date_to={this.state.topup_promotion_date_to}
                    onChange={this.topupPromotionStart}
                    placeholderText="From"
                    minDate={moment().toDate()}
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.topup_promotion_date_to}
                    selectsEnd
                    topup_promotion_date_from={
                      this.state.topup_promotion_date_from
                    }
                    topup_promotion_date_to={this.state.topup_promotion_date_to}
                    onChange={this.topupPromotionEnd}
                    minDate={this.state.topup_promotion_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.tierName}
                      onChange={this.tierNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Parent Tier Target</label>
                    <Select
                      value={selectedParentTier}
                      onChange={this.handleParentTierChange}
                      options={topupPromotions}
                      // isMulti
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="subTier">Loyalty Sub Tier Target (Optional)</label>
                    <Select
                        value={loyaltySubTier}
                        onChange={this.handleChangeSubTier}
                        options={availableLoyaltySubTiers}
                        isSearchable
                      />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Topup value</label>
                    <div className="form-control form-group-custom ">
                      <input
                        type="number"
                        min="0"
                        className="bdr0 cusInput"
                        placeholder="000"
                        value={this.state.topupValue}
                        onChange={this.topupValueChange}
                      />
                      <span style={{ color: "#ccc" }}>OMR</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="cardTypes">Card Types</label>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <label>
                        <input
                          onChange={this.onChangePhysicalCheck}
                          type="checkbox"
                          id="physicalId"
                          checked={this.state.isPChecked}
                        />
                        {" Physical"}
                      </label>
                      <label>
                        <input
                          onChange={this.onChangeVirtualCheck}
                          type="checkbox"
                          id="surveySponsored"
                          checked={this.state.isVChecked}
                        />
                        {" Virtual"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveTopupPromotions}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
