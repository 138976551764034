import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const view_spin_win_url = API.apiEndpoint + API.spinAndWin;

export default class SpinWinView extends React.Component {
  state = {
    spin_win_view: {},
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let url = view_spin_win_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        spin_win_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { spin_win_view, loading } = this.state;
    return (
      <Content title={"Spin & Win"} browserTitle="Spin & Win ">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Spin & Win Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Start</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(spin_win_view.start)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>End</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(spin_win_view.end)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Loyalty Game Name</b>
                      <span className="pull-right">
                        {ApiRequest.string_humanize_format(
                          spin_win_view.loyaltyGameName
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>
                      <span className="pull-right">
                        {spin_win_view.active == 1 ? "Active" : "Inactive"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
