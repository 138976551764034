import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";
const inAppPromotions_form_url = API.apiEndpoint + API.InappPromotions;
//const spinWinList_url = API.apiEndpoint + API.spinAndWinList;
//const schedule_spin_win_url = API.apiEndpoint + API.scheduleSpinAndWin;

export default class InAppPromotionsForm extends React.Component {
  state = {
    page: {},
    action: "add",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    from: "",
    to: "",
    popup: "yes",
    cat_title: { en: "", ar: "" },
  };

  async componentDidMount() {
    // let options = { url: spinWinList_url };
    // let api_response = await ApiRequest.get(options);
    // if (api_response.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response.message,
    //   });
    // } else {
    //   this.setState({
    //     win_list: api_response.data,
    //     loading: false,
    //     error: false,
    //     message: api_response.message,
    //   });
    // }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  titleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  ctaTitleChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let cat_title = Object.assign({}, prevState.cat_title); // creating copy of state variable tip
      cat_title[lang] = value; // update the name property, assign a new value
      return { cat_title }; // return new object tip object
    });
    return true;
  };
  handleChangeAge = (event) => {
    this.setState({
      order: event.target.value,
    });
  };
  onClickEngImageHandler = async (event) => {
    this.setState({ selectedEngFile: ("file", event.target.files[0]) });
  };
  onClickArImageHandler = async (event) => {
    this.setState({ selectedArFile: ("file", event.target.files[0]) });
  };
  popupChange = (event) => {
    this.setState({ popup: event.target.value });
  };

  inAppPromotionsFrom = async (date) => {
    this.setState({
      in_app_date_from: date,
      enableBtn: true,
    });
  };
  inAppPromotionsTo = async (date) => {
    this.setState({
      in_app_date_to: date,
      enableBtn: true,
    });
  };

  saveInApp = async (event) => {
    let error = false;

    if (!this.state.order) {
      error = true;
      this.setState({
        error: true,
        message: "Order is required",
      });
    }
    if (!this.state.cat_title.en) {
      error = true;
      this.setState({
        error: true,
        message: "CAT Title English is required",
      });
    }
    if (!this.state.cat_title.ar) {
      error = true;
      this.setState({
        error: true,
        message: "CAT Title Arabic is required",
      });
    }

    if (!this.state.title) {
      error = true;
      this.setState({
        error: true,
        message: "Title is required",
      });
    }

    if (error) {
      return;
    }
    var moment = require("moment");
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("cat_title", JSON.stringify(this.state.cat_title));
    formData.append("order", this.state.order);
    // formData.append("eng_image", this.state.selectedEngFile);
    // formData.append("ar_image", this.state.selectedArFile);
    formData.append("poupup", this.state.popup);
    formData.append(
      "from",
      moment
        .utc(this.state.in_app_date_from)
        .add(1, "days")
        .startOf("day")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.in_app_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );

    let api_response = {};
    let options = { url: inAppPromotions_form_url, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/In App Promotions/";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/In App Promotions/";
  };

  render() {
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="In App Promotions" browserTitle="In App Promotions">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">In App Promotions Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.titleChange}
                    />
                  </div>
                </div>

                <div className="form-group rangeFilter col-sm-6">
                  <label>Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.in_app_date_from}
                    selectsStart
                    minDate={moment().toDate()}
                    in_app_date_from={this.state.in_app_date_from}
                    in_app_date_to={this.state.in_app_date_to}
                    onChange={this.inAppPromotionsFrom}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.in_app_date_to}
                    selectsEnd
                    in_app_date_from={this.state.in_app_date_from}
                    in_app_date_to={this.state.in_app_date_to}
                    onChange={this.inAppPromotionsTo}
                    minDate={this.state.in_app_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>Image File </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_en">English Version </label>
                      <input
                        type="file"
                        id="imageEngVersion"
                        placeholder="Name english"
                        ref={(ref) => (this.imageEngVersion = ref)}
                        onChange={this.onClickEngImageHandler}
                        accept=".png, .jpeg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_ar">Arabic Version </label>
                      <input
                        type="file"
                        id="imageArVersion"
                        placeholder="Name english"
                        ref={(ref) => (this.imageArVersion = ref)}
                        onChange={this.onClickArImageHandler}
                        accept=".png, .jpeg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>CTA Title </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cat_title_en"
                        placeholder="Name english"
                        value={this.state.cat_title.en}
                        onChange={this.ctaTitleChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cat_title_ar"
                        placeholder="Name arabic"
                        value={this.state.cat_title.ar}
                        onChange={this.ctaTitleChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>CTA Link </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cat_title_en"
                        placeholder="Name english"
                        // value={this.state.cat_title.en}
                        // onChange={this.ctaTitleChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cat_title_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cat_title_ar"
                        placeholder="Name arabic"
                        // value={this.state.cat_title.ar}
                        // onChange={this.ctaTitleChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="form-group">
                    <label>Order </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeAge}
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="popup">Popup</label>
                    <select
                      className="form-control"
                      value={this.state.popup}
                      onChange={this.popupChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveInApp}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
