import React, { Component } from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "./../../components/LoadingSpinner";
// import "./User.css";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import LoadingAjax from "../../components/Loading";
const questionBankList_url = API.apiEndpoint + API.questionBank;

export default class QuestionBank extends Component {
  state = {
    question_bank_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    // search_clear: true,
    // search_select_value: "",
    // search_input_value: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        questionBankList_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        question_bank_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  deleteQuestion = async (val, event) => {
    confirmAlert({
      title: "Delete Question",
      message: "Are you sure you want to delete Question?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmDeleteQuestion(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  confirmDeleteQuestion = async (val, event) => {
    let api_response = {};
    let options = {
      url: questionBankList_url + "/" + val,
    };

    api_response = await ApiRequest.delete(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/oomco_fun/question_bank";
        }.bind(this),
        1000
      );
      sessionStorage.clear();
    }
  };

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit
      // this.state.filter,
      // this.state.search_select_value,
      // this.state.search_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  // handleSearchChange = async (event) => {
  //   this.setState({
  //     search_clear: false,
  //     search_select_value: event.target.value,
  //     filter: true,
  //   });
  //   await this.getData(
  //     1,
  //     this.state.limit,
  //     true,
  //     event.target.value,
  //     this.state.search_input_value
  //   );
  // };

  // handleSearchValueChange = async (event) => {
  //   this.setState({
  //     search_clear: false,
  //     search_input_value: event.target.value,
  //     filter: true,
  //   });
  //   await this.getData(
  //     1,
  //     this.state.limit,
  //     true,
  //     this.state.search_select_value,
  //     event.target.value
  //   );
  // };

  // handleSearchClear = async () => {
  //   this.setState({
  //     search_clear: true,
  //     search_input_value: "",
  //     search_select_value: "",
  //     filter: false,
  //   });
  //   await this.getData(1, this.state.limit, false);
  // };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const {
      loading,
      error,
      message,
      question_bank_list,
      pagination,
      limit,
      // search_select_value,
      // search_input_value,
    } = this.state;
    return (
      <Content title="Question Bank" browserTitle="Question Bank">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Question Bank</h3>
                <div className="box-tools">
                  <div>
                    {/*ApiRequest.can_access("admin-user", "can_add_single") && (
                      <a
                        className="btn btn-primary btn-xs mr-5"
                        href={"/question_bank_form/"}
                      >
                        {" "}
                        Add New
                        {"    "}
                      </a>
                    )*/}
                    <a
                      className="btn btn-sm btn-primary mr-5"
                      href={"/question_bank_form/"}
                    >
                      {" "}
                      Add New
                      {"    "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>

                    {/*<div className="cusSearch">
                      Search{" "}
                      <select
                        onChange={this.handleSearchChange}
                        value={search_select_value}
                        className="form-control input-sm mr-5 ml-5"
                      >
                        <option value="">Select Search by </option>
                        <option value={"id"}>ID</option>
                        <option value={"store_name"}>Store Name</option>
                        <option value={"store_code"}>Store Code</option>
                        <option value={"city"}>City</option>
                      </select>
                      <input
                        type="search"
                        className="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                      <button
                        className="btn btn-primary cusSearchBtn"
                        onClick={this.handleSearchClear}
                      >
                        Clear
                      </button>
                  </div>*/}
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Creation Date </th>
                            <th>Question Statement </th>
                            <th>Question Type </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {question_bank_list.length > 0 ? (
                            question_bank_list.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>
                                  <td>
                                    {data.questionText
                                      ? data.questionText.en
                                      : "-"}
                                  </td>

                                  <td>{this.rescue_value(data.type)}</td>
                                  <td>
                                    {/*<a
                                      className="btn btn-primary btn-xs mr-5"
                                      href={"/question_bank_view/" + data.id}
                                    >
                                      <i className="fa fa-eye" />
                                    </a>*/}
                                    {ApiRequest.can_access(
                                      "",
                                      "canBeEditted"
                                    ) && (
                                      <a
                                        className="btn btn-primary btn-xs mr-5"
                                        href={"/question_bank_edit/" + data.id}
                                      >
                                        <i className="fa fa-edit" />
                                      </a>
                                    )}

                                    {ApiRequest.can_access(
                                      "",
                                      "canBeDeleted"
                                    ) && (
                                      <button
                                        className="btn btn-primary btn-xs mr-5"
                                        onClick={this.deleteQuestion.bind(
                                          null,
                                          data.id
                                        )}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div className="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to
                          {pagination.currentPage * limit} of {pagination.size}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
