import React from "react";
import { Content, Inputs } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import "./survey.css";
import $ from "jquery";
// import ImageUploader from "react-images-upload";
import moment from "moment";
const create_quiz_form_url = API.apiEndpoint + API.quiz;

const quizQuestionList = API.apiEndpoint + API.questionBank;
const quiz_trigger_url = API.apiEndpoint + API.quizTrigger;
const loyalty_quiz_url = API.apiEndpoint + API.loyaltyQuizList;
const schedule_quiz_url = API.apiEndpoint + API.scheduleQuiz;

export default class QuizForm extends React.Component {
  state = {
    page: {},
    action: "add",
    name: {},
    status: "1",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    description: {},
    start: "",
    end: "",
    trigger: "",
    loyalty_quiz: "",
    quiz_list: [],
    sponsoredQuiz: "",
    // loyaltyGameId: [0],
    user_without_card: false,
    // pictures: [],
    selectedFile: null,
  };
  async componentDidMount() {
    let quizTrigger = { url: quiz_trigger_url };
    let api_response = await ApiRequest.get(quizTrigger);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        quizTrigger: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    let loyaltyQuiz = { url: loyalty_quiz_url };
    let loyaltyQuiz_response = await ApiRequest.get(loyaltyQuiz);
    if (loyaltyQuiz_response.data.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      {
        loyaltyQuiz_response &&
          loyaltyQuiz_response.data.map((loyalty_quiz_list, key) => {
            this.state.quiz_list.push(loyalty_quiz_list[0]);
          });
      }

      this.setState({
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    $(".questionShown").on("click", ".single_question", function () {
      var name = $(this).data("name");
      var title = $(this).data("title");
      var identifier = $(this).data("identifier");
      var id = $(this).data("id");
      if ($(".selected_question[data-indentifier='" + id + "']").length == 0) {
        $(".mainSelectedArea").append(
          '<div class="selected_question" data-indentifier="' +
            id +
            '" data-id="' +
            id +
            '">' +
            ' <a href="javascript:void(0)" class="closeQuest">X</a>' +
            '	<div class="quest">' +
            "		<div>" +
            "			<h4>" +
            name +
            "</h4>" +
            "			<p>" +
            title +
            "</p>" +
            "		</div>" +
            "	</div>" +
            '	<div class="WeightageWrap">' +
            '		<input class="form-control weightage_class" name="weightage[]" placeholder="weightage" type="text">' +
            '		<input class="form-control id_class" name="id[]"  value="' +
            id +
            '" type="hidden">' +
            "	</div>" +
            "</div>"
        );
      } else {
        alert("Question already selected");
      }
    });

    $(".mainSelectedArea").on("click", ".closeQuest", function () {
      $(this).parents(".selected_question").remove();
    });

    $("#quizTrigger").on("change", function () {
      var hasTriggerValue = $(this)
        .find("option:selected")
        .attr("data-hasTriggerValue");
      if (hasTriggerValue == "true") {
        $("#trigger_value").removeAttr("disabled");
        $("#trigger_value").attr("required", "required");
      } else {
        $("#trigger_value").attr("disabled", "disabled");
        $("#trigger_value").removeAttr("required", "required");
      }
    });

    $("#quizSponsored").on("change", function () {
      if (this.checked == true) {
        $(".sponsoredReq").show();
        $(".sponsoredReq").find("input").attr("required", "required");
      } else {
        $(".sponsoredReq").find("input").removeAttr("required");
        $(".sponsoredReq").hide();
      }
    });

    await this.getData();
  }
  getData = async (filter = true, key = "type", value = "") => {
    let options = {
      url:
        quizQuestionList +
        "?filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value +
        "&all_records=true",
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        quiz_question_list: api_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };
  nameChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let name = Object.assign({}, prevState.name); // creating copy of state variable tip
      name[lang] = value; // update the name property, assign a new value
      return { name }; // return new object tip object
    });
    return true;
  };
  descriptionChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let description = Object.assign({}, prevState.description); // creating copy of state variable tip
      description[lang] = value; // update the name property, assign a new value
      return { description }; // return new object tip object
    });
    return true;
  };
  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };
  sponsoredChange = (event) => {
    this.setState({ sponsoredQuiz: event.target.value });
  };
  passingWeightageChange = (event) => {
    this.setState({ passingWeightage: event.target.value });
  };
  triggerValueChange = (event) => {
    this.setState({ triggerValue: event.target.value });
  };
  handleChangeQuizTrigger = async (event) => {
    this.setState({
      trigger: event.target.value,
      enableBtn: true,
    });
  };
  handleChangeLoyaltyQuiz = async (event) => {
    this.setState({
      loyalty_quiz: event.target.value,
      enableBtn: true,
    });
  };
  backUser = () => {
    window.location = "/oomco_fun/quiz";
  };
  quizStart = async (date) => {
    var moment = require("moment");
    var obj = {};

    obj.type = "start";
    obj.date = moment.utc(date).startOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_quiz_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_start").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_start").val("1");
    }
    this.setState({
      quiz_create_date_from: date,
      enableBtn: true,
    });
  };
  quizEnd = async (date) => {
    var moment = require("moment");
    var obj = {};

    obj.type = "end";
    obj.date = moment.utc(date).endOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_quiz_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_end").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_end").val("1");
    }
    this.setState({
      quiz_create_date_to: date,
      enableBtn: true,
    });
  };
  objCreationTime = () => {
    return Math.floor(new Date().valueOf() * Math.random());
  };
  sponsoredNameChange = (event) => {
    this.setState({ sponsoredNameValue: event.target.value });
  };
  sponsoredNameArabicChange = (event) => {
    this.setState({ sponsoredNameArabicValue: event.target.value });
  };
  onChangeCardCheck = (event) => {
    const { quiz_sponsored } = this.state;
    this.setState({
      quiz_sponsored: quiz_sponsored,
      enableBtn: true,
    });
  };
  onClickHandler = async (event) => {
    this.setState({ selectedFile: ("file", event.target.files[0]) });
  };

  saveUser = async (event) => {
    let error = false;
    if (
      $("#trigger_value").prop("required") &&
      $("#trigger_value").val() == ""
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Trigger value is required",
      });
    }
    if (
      $("#is_validate_start").val() == 0 ||
      $("#is_validate_end").val() == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Select Valid Date",
      });
    }
    if (!this.state.name.en) {
      error = true;
      this.setState({
        error: true,
        message: "Name is required",
      });
    }

    if (!this.state.description.en) {
      error = true;
      this.setState({
        error: true,
        message: "Description is required",
      });
    }
    if (!this.state.loyalty_quiz) {
      error = true;
      this.setState({
        error: true,
        message: "Select loyalty Game ID",
      });
    }
    var Qlist = $(".selected_question").length;
    if (Qlist < 1) {
      error = true;
      this.setState({
        error: true,
        message: "Question is required",
      });
    }
    var sum = [];
    $(".weightage_class").each(function (i) {
      sum.push(parseInt($(this).val()));
    });
    var xyz = sum.reduce(function (a, b) {
      return a + b;
    }, 0);

    if (xyz > 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should not be greater then 100%",
      });
      return false;
    } else if (xyz < 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should be equals to 100%",
      });
      return false;
    }
    if (parseInt($("#weigt_age").val()) > 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should not be greater then 100%",
      });
    }

    if (error) {
      return;
    }

    // this.setState({ loading: true });
    //let rsp = await axios.post(create_quiz_form_url, data, {});
    // formData.append("sponsor_image", this.state.age_from);
    var moment = require("moment");
    const formData = new FormData();
    formData.append("name", JSON.stringify(this.state.name));
    formData.append("description", JSON.stringify(this.state.description));
    formData.append("active", this.state.status);
    formData.append("passingWeightage", this.state.passingWeightage);
    formData.append("loyaltyGameId", this.state.loyalty_quiz);
    formData.append("trigger", this.state.trigger);
    formData.append(
      "start",
      moment
        .utc(this.state.quiz_create_date_from)
        .add(1, "days")
        .startOf("day")
        .valueOf()
    );
    formData.append(
      "end",
      moment
        .utc(this.state.quiz_create_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );
    if (this.state.triggerValue != "") {
      formData.append("triggerValue", this.state.triggerValue);
    }

    var questions = [];
    $(".mainSelectedArea")
      .find(".selected_question")
      .each(function (i) {
        var weightage = $(this).find(".weightage_class").val();
        var id = $(this).find(".id_class").val();
        questions.push({ id: id, weightage: weightage });
      });

    formData.append("questions", JSON.stringify(questions));

    if ($("#quizSponsored").is(":checked")) {
      let error = false;
      if (!this.state.sponsoredNameArabicValue) {
        error = true;
        this.setState({
          error: true,
          message: "Sponsor name arabic is required",
        });
      }
      if (!this.state.sponsoredNameValue) {
        error = true;
        this.setState({
          error: true,
          message: "Sponsor name is required",
        });
      }
      if (error) {
        return;
      }
      formData.append("is_sponsored", "1");
      formData.append("sponsor_name", this.state.sponsoredNameValue);
      formData.append("sponsor_name_ar", this.state.sponsoredNameArabicValue);
      formData.append("sponsor_image", this.state.selectedFile);
    } else {
      formData.append("is_sponsored", "0");
    }

    let api_response = {};
    let options = { url: create_quiz_form_url, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/oomco_fun/quiz";
        }.bind(this),
        10000
      );
    }
  };

  render() {
    const { quiz_question_list } = this.state;
    let {
      error,
      message,
      success,
      success_message,
      quizTrigger,
      quiz_list,
      quiz_sponsored,
    } = this.state;
    return (
      <Content title="Quiz" browserTitle="Quiz">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Quiz Form</h3>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Name</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name_en"
                        placeholder="Name english"
                        value={this.state.name.en}
                        onChange={this.nameChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name_ar"
                        placeholder="Name arabic"
                        value={this.state.name.ar}
                        onChange={this.nameChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>Description</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_en">English</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description_en"
                        placeholder="description english"
                        value={this.state.description.en}
                        onChange={this.descriptionChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="description_ar">Arabic</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description_ar"
                        placeholder="description arabic"
                        value={this.state.description.ar}
                        onChange={this.descriptionChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group rangeFilter col-sm-4">
                    <label>Quiz Create Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.quiz_create_date_from}
                      selectsStart
                      minDate={moment().toDate()}
                      quiz_create_date_from={this.state.quiz_create_date_from}
                      quiz_create_date_to={this.state.quiz_create_date_to}
                      onChange={this.quizStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.quiz_create_date_to}
                      selectsEnd
                      quiz_create_date_from={this.state.quiz_create_date_from}
                      quiz_create_date_to={this.state.quiz_create_date_to}
                      onChange={this.quizEnd}
                      minDate={this.state.quiz_create_date_from}
                      placeholderText="To"
                    />
                    <input type="hidden" id="is_validate_start" />
                    <input type="hidden" id="is_validate_end" />
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        value={this.state.status}
                        onChange={this.statusChange}
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name_ar">Passing Weightage</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name_ar"
                        placeholder="Enter number"
                        value={this.state.passingWeightage}
                        onChange={this.passingWeightageChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 cusSelect">
                    <div className="form-group">
                      <label>Quiz Trigger</label>
                      <select
                        className="form-control"
                        id="quizTrigger"
                        name="quizTrigger"
                        value={this.state.trigger}
                        onChange={this.handleChangeQuizTrigger}
                      >
                        <option>-- Select Quiz Trigger --</option>
                        {quizTrigger &&
                          quizTrigger.map((item, key) => (
                            <option
                              value={item.identifier}
                              data-hasTriggerValue={item.hasTriggerValue}
                            >
                              {item.label}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 triggerValReq">
                    <div className="form-group">
                      <label htmlFor="trigger_value">Trigger Value</label>
                      <input
                        type="text"
                        className="form-control"
                        id="trigger_value"
                        placeholder="Trigger Value"
                        value={this.state.triggerValue}
                        onChange={this.triggerValueChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 cusSelect">
                    <div className="form-group">
                      <label>Loyalty Game ID</label>
                      <select
                        className="form-control"
                        id="loyalty_quiz"
                        name="loyalty_quiz"
                        value={this.state.loyalty_quiz}
                        onChange={this.handleChangeLoyaltyQuiz}
                      >
                        <option value=""> -- Select Game ID -- </option>
                        {quiz_list &&
                          quiz_list.map((item, key) => (
                            <option value={item.GameId}>{item.GameName}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      {"Sponsor on Quiz"}
                      <input
                        className="ml-5"
                        onChange={this.onChangeCardCheck}
                        type="checkbox"
                        id="quizSponsored"
                        checked={quiz_sponsored}
                      />
                    </label>
                  </div>
                  <div className="col-md-4 sponsoredReq">
                    <div className="form-group">
                      <label htmlFor="name">Sponsor Name</label>
                      <div className="form-group-custom">
                        <input
                          type="text"
                          className="form-control mr-5"
                          id="name"
                          placeholder="Name English"
                          value={this.state.sponsoredNameValue}
                          onChange={this.sponsoredNameChange}
                        />
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name Arabic"
                          value={this.state.sponsoredNameArabicValue}
                          onChange={this.sponsoredNameArabicChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sponsoredReq">
                    <label for="myfile">Sponsor Image</label>
                    <input
                      id="uploadList"
                      type="file"
                      name="file"
                      ref={(ref) => (this.uploadList = ref)}
                      onChange={this.onClickHandler}
                      accept=".png, .jpeg"
                    />
                  </div>
                </div>
                <div className="row questionShown">
                  <div className="col-md-3">
                    <div className="quesList">
                      <h3>Question List</h3>
                      <ul>
                        {quiz_question_list ? (
                          quiz_question_list.map((data) => {
                            return (
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  data-id={data.id}
                                  data-identifier={data.id}
                                  className="single_question"
                                  data-name={data.name ? data.name.en : "-"}
                                  data-title={
                                    data.questionText
                                      ? data.questionText.en
                                      : "-"
                                  }
                                >
                                  <h4>{data.name ? data.name.en : "-"} </h4>
                                  <p>
                                    {data.questionText
                                      ? data.questionText.en
                                      : "-"}
                                  </p>
                                </a>
                              </li>
                            );
                          })
                        ) : (
                          <li colSpan="5" className="noRecord">
                            No Record Found
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="mainSelectedArea"></div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveUser}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
