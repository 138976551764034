import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "./../../ApiRequest";
import API from "./../../constants";
import LoadingSpinner from "./../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
//import { CSVLink } from "react-csv";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;

const epaymentTransaction_url = API.apiEndpoint + API.epaymentTransaction;

export default class EPaymentTransaction extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    date_from: "",
    date_to: "",
    amount_from: "",
    amount_to: "",
    serial_number_from: "",
    serial_number_to: "",
    oomco_id: "",
    payment_id: "",
    transaction_id: "",
    epaymentTransaction: [],
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    search_clear: true,
    rrn: "",
    completed: "",
    pagination: {},
  };

  async componentDidMount() {
    let options = { url: store_url };
    let api_response = await ApiRequest.get(options);

    let optionsEpaymentTrans = { url: epaymentTransaction_url };
    let report_response = await ApiRequest.get(optionsEpaymentTrans);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        // epaymentTransaction: report_response.data.results,
        // pagination: report_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);

        this.setState({
          epaymentTransaction: report_response.data.results,
          pagination: report_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });
    let url = epaymentTransaction_url + "?page=" + pageNumber;

    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.payment_id) {
      url = url + "&payment_id=" + this.state.payment_id;
    }
    if (this.state.transaction_id) {
      url = url + "&transaction_id=" + this.state.transaction_id;
    }
    if (this.state.rrn) {
      url = url + "&rrn=" + this.state.rrn;
    }
    if (this.state.completed) {
      url = url + "&completed=" + this.state.completed;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        epaymentTransaction: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = epaymentTransaction_url + "?csv=" + 1;
    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.payment_id) {
      url = url + "&payment_id=" + this.state.payment_id;
    }
    if (this.state.transaction_id) {
      url = url + "&transaction_id=" + this.state.transaction_id;
    }
    if (this.state.rrn) {
      url = url + "&rrn=" + this.state.rrn;
    }
    if (this.state.completed) {
      url = url + "&completed=" + this.state.completed;
    }

    let options = {
      url: url,
      file_name:
        "Card Inventory - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeAmountFrom = (event) => {
    this.setState({
      amount_from: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeAmountTo = (event) => {
    this.setState({
      amount_to: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeSerialNumberFrom = (event) => {
    this.setState({
      serial_number_from: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeSerialNumberTo = (event) => {
    this.setState({
      serial_number_to: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeOomcoID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      oomco_id: event.target.value,
    });
  };
  handleChangePaymentID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      //limit: 10,
      payment_id: event.target.value,
    });
  };
  handleChangeTransactionID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      //limit: 10,
      transaction_id: event.target.value,
    });
  };
  handleChangeRrnNumber = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      rrn: event.target.value,
    });
  };
  handleChangeStatusCompleted = (event) => {
    this.setState({
      completed: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      date_from: "",
      date_to: "",
      amount_from: "",
      amount_to: "",
      serial_number_from: "",
      serial_number_to: "",
      oomco_id: "",
      payment_id: "",
      transaction_id: "",
      rrn: "",
      completed: "",
    });
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }

  render() {
    const { Select2 } = Inputs;
    const {
      epaymentTransaction,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
    } = this.state;
    return (
      <Content
        title="ePayment transactions"
        browserTitle="ePayment transactions"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                    disabled={!enableBtn}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_from}
                      selectsStart
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_to}
                      selectsEnd
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Amount{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_from}
                      onChange={this.handleChangeAmountFrom}
                      className="form-control"
                      placeholder="From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_to}
                      onChange={this.handleChangeAmountTo}
                      className="form-control"
                      placeholder="To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Serial Number{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.serial_number_from}
                      onChange={this.handleChangeSerialNumberFrom}
                      className="form-control"
                      placeholder="Serial Number From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.serial_number_to}
                      onChange={this.handleChangeSerialNumberTo}
                      className="form-control"
                      placeholder="Serial Number To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>OOMCO ID </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeOomcoID}
                      className="form-control"
                      placeholder="Enter Oomco ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>RRN</label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeRrnNumber}
                      className="form-control"
                      placeholder="RRN Number"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Payment ID </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangePaymentID}
                      className="form-control"
                      placeholder="Enter Payment ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Transaction ID </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeTransactionID}
                      className="form-control"
                      placeholder="Enter Transaction ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status </label>
                    <select
                      className="form-control"
                      value={this.state.completed}
                      onChange={this.handleChangeStatusCompleted}
                    >
                      <option value="">Select</option>
                      <option value="1">Complete</option>
                      <option value="0">Not Complete</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  {/*<button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                  >
                    Clear
                  </button>*/}
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>Track ID</th>
                            <th>RRN</th>
                            <th>Transaction ID</th>
                            <th>Payment ID</th>
                            <th>Serial Number</th>
                            <th>OOMCO ID</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Time</th>
                          </tr>
                        </thead>

                        <tbody>
                          {epaymentTransaction.length > 0 &&
                          epaymentTransaction ? (
                            epaymentTransaction.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {this.rescue_value(
                                      data.payment_response.track_id
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.payment_response.ref_id
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.payment_response.transaction_id
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.payment_response.payment_id
                                    )}
                                  </td>
                                  <td>{this.rescue_value(data.card_number)}</td>
                                  <td>
                                    {
                                      <span>
                                        {data.user.oomcoId ? (
                                          <a
                                            target="_blank"
                                            href={"/app_view/" + data.user.id}
                                          >
                                            {data.user.oomcoId
                                              ? data.user.oomcoId
                                              : "-"}
                                          </a>
                                        ) : (
                                          <p>
                                            {data.user.oomcoId
                                              ? data.user.oomcoId
                                              : "-"}
                                          </p>
                                        )}
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {this.rescue_value(data.topup_amount)}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.transactionComplate === true
                                        ? "Complete"
                                        : "In Complete"
                                    )}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.updatedAt)}
                                  </td>
                                  <td>
                                    {ApiRequest.time_format(data.updatedAt)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="10" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
