import React, { Component } from "react";
import axios from "axios";
import API from "./../../constants";
import "./Login.css";
import logo from "./omanoil-logo.png";
//import { faTumblr } from "@fortawesome/free-brands-svg-icons";
const login_url = API.apiEndpoint + API.login;


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      code: "",
      isotp: false,
      submitted: false,
      submitError: false,
      error: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitError: false });
  }
  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, code } = this.state;

    if (username && password && !code) {
      const formData = new FormData();
      formData.append("username", this.state.username);
      formData.append("password", this.state.password);

      try {
        const { data } = await axios.post(login_url, formData);
        if (data.message === "otp_sent_successfully") {
          this.setState({ isotp: true });
          return;
        }

        const response = data.data;
        localStorage.setItem("user_id", response.id);
        localStorage.setItem("user_token", response.accessToken.access_token);
        localStorage.setItem("user_email", response.email);

        let modulePermissions = response.modulePermissions;
        localStorage.setItem("role_name", modulePermissions.name);
        localStorage.setItem("user_terminalId", modulePermissions.terminal_id);
        localStorage.setItem("topup_limit_min", modulePermissions.topupLimitMin ? modulePermissions.topupLimitMin : "10");
        localStorage.setItem("topup_limit_max", modulePermissions.topupLimitMax ? modulePermissions.topupLimitMax : "2000");
        localStorage.setItem("permissions", JSON.stringify(modulePermissions.permissions));
        localStorage.setItem("allowed_payment_methods", modulePermissions.allowed_payment_methods);
        localStorage.setItem("store_code", modulePermissions.store_code ? modulePermissions.store_code : "");

        localStorage.setItem("store_token", modulePermissions.store_token ? modulePermissions.store_token : "");

        window.location = "/";
        return;
      } catch (error) {
        let { data } = error.response;
        this.setState({
          submitError: true,
          error: data.message,
        });
      }
    }
    if (username && password && code) {
      const formData = new FormData();
      formData.append("username", this.state.username);
      formData.append("password", this.state.password);
      formData.append("code", this.state.code);

      try {
        const { data } = await axios.post(login_url, formData);
        const response = data.data;
        localStorage.setItem("user_id", response.id);
        localStorage.setItem("user_token", response.accessToken.access_token);
        localStorage.setItem("user_email", response.email);

        let modulePermissions = response.modulePermissions;
        localStorage.setItem("role_name", modulePermissions.name);
        localStorage.setItem("user_terminalId", modulePermissions.terminal_id);
        localStorage.setItem("topup_limit_min", modulePermissions.topupLimitMin ? modulePermissions.topupLimitMin : "10");
        localStorage.setItem("topup_limit_max", modulePermissions.topupLimitMax ? modulePermissions.topupLimitMax : "2000");
        localStorage.setItem("permissions", JSON.stringify(modulePermissions.permissions));
        localStorage.setItem("allowed_payment_methods", modulePermissions.allowed_payment_methods);
        localStorage.setItem("store_code", modulePermissions.store_code ? modulePermissions.store_code : "");

        localStorage.setItem("store_token", modulePermissions.store_token ? modulePermissions.store_token : "");

        window.location = "/";
        return;
      } catch (error) {
        let { data } = error.response;
        this.setState({
          submitError: true,
          error: data.message,
        });
      }
    }
  };

  render() {
    const { username, password, code, submitted, submitError, error, isotp } = this.state;
    return (
      <div className=" login-box">
        <div className="login-logo">
          <a href="/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </div>
        <div className="login-box-body">
          <h2 className="login-box-msg">Login</h2>
          <form name="form" onSubmit={this.handleSubmit}>
            {submitError && <div className="has-error help-block">{error}</div>}
            <div className={"form-group has-feedback" + (submitted && !username ? " has-error" : "")}>
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                name="username"
                disabled={isotp}
                value={username}
                onChange={this.handleChange}
              />
              <span className="fa fa-fw fa-envelope form-control-feedback" />
              {submitted && !username && <div className="help-block">Username is required</div>}
            </div>

            <div className={"form-group has-feedback" + (submitted && !password ? " has-error" : "")}>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                disabled={isotp}
                value={password}
                onChange={this.handleChange}
              />
              <span className="fa fa-fw fa-lock form-control-feedback" />
              {submitted && !password && <div className="help-block">Password is required</div>}
            </div>

            <div className={"form-group has-feedback" + (submitted && !username ? " has-error" : "")}>
              <input
                type={!isotp ? "hidden" : "text"}
                className="form-control"
                placeholder="otp"
                name="code"
                minLength={4}
                maxLength={4}
                value={code}
                onChange={this.handleChange}
                hidden={!isotp}
              />

              {submitted && !code && isotp && <div className="help-block">otp is required</div>}
            </div>
            <div className="row">
              <div className="col-xs-4 col-xs-offset-8">
                <button type="submit" className="btn btn-primary btn-block btn-flat">
                  {!isotp ? "Login" : "Submit OTP"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
