import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import ApiRequest from "./../../ApiRequest";

const cardstranview_url = API.apiEndpoint + API.cardTransaction;

export default class CardTransactionView extends React.Component {
  state = {
    card_trans_view: [],
    loading: true
  };

  async componentDidMount() {
    const response = await axios.get(
      cardstranview_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      const data = response.data.data;
      this.setState({
        card_trans_view: data,
        loading: false
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    let { card_trans_view, loading } = this.state;

    return (
      <Content
        title={"Card Transaction View"}
        browserTitle="Card Transaction View"
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>ID</b>{" "}
                      <span className="pull-right">{card_trans_view.id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Operation</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(
                          ApiRequest.string_humanize_format(
                            card_trans_view.operation
                          )
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Request From</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.request_from)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Request User ID</b>{" "}
                      <a
                        href={"/app_view/" + card_trans_view.request_user_id}
                        className="pull-right"
                      >
                        {this.rescue_value(card_trans_view.request_user_id)}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>SFS Transaction</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.sfs_transaction_id)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>RPS Transaction ID</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.rps_transaction_id)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Store Name</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.store_name)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Store Code</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.store_code)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Transaction ID</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.transaction_id)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Card Serial Number</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.card_number)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Topup Amount</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.topup_amount)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Topup Method</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card_trans_view.topup_method)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Created At</b>{" "}
                      <span className="pull-right">
                        {/*ApiRequest.date_format_utc(card_trans_view.createdAt)*/}
                        {ApiRequest.date_time_format(card_trans_view.createdAt)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Updated At</b>{" "}
                      <span className="pull-right">
                        {/*ApiRequest.date_format_utc(card_trans_view.updatedAt)*/}
                        {ApiRequest.date_time_format(card_trans_view.updatedAt)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
