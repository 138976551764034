import React from "react";

export default class LoadingAjax extends React.Component {
  render() {
    return (
      <div id="waiting_screen" style={{ display: "none" }}>
        <div
          style={{
            backgroundColor: "#000000bd",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            left: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontSize: 20
          }}
        >
          Loading ...
        </div>
      </div>
    );
  }
}
