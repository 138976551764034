import React from "react";
import AppUserListing from "./listing";
import AppUserForm from "./form";

export default class AppUser extends React.Component {
  state = {
    action: "listing",
    page: {}
  };

  handleClick = (page, action) => {
    this.setState({ action: action, page: page });
  };

  render() {
    const { action, page } = this.state;
    if (action === "edit" || action === "add")
      return <AppUserForm action={action} page={page} />;
    else return <AppUserListing handleClick={this.handleClick} />;
  }
}
