import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const myGarageView = API.apiEndpoint + API.myGarageView;

export default class MyGarageView extends React.Component {
  state = {
    partner_view: {},
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let url = myGarageView + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        partner_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 0 ? "Active" : "Inactive";
  }

  render() {
    let { partner_view, loading } = this.state;
    return (
      <Content
        title={"All Vehicles Garage View"}
        browserTitle="All Vehicles Garage View"
      >
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Vehicles Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Ommco ID</b>{" "}
                      <span className="pull-right">{partner_view.oomco_id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Car Plate #</b>{" "}
                      <span className="pull-right">{partner_view.vno} </span>
                    </li>
                      <li className="list-group-item">
                        <b>Car Plate Type</b>{" "}
                        <span className="pull-right">{partner_view.vchar} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Mobile Number</b>{" "}
                      <span className="pull-right">{partner_view.cno} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Brand</b>{" "}
                      <span className="pull-right">{partner_view.brand} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Model</b>{" "}
                      <span className="pull-right">{partner_view.model} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Engine Capacity</b>{" "}
                      <span className="pull-right">
                      {partner_view.engine_capacity}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>{" "}
                      <span className="pull-right">
                          {this.status_value(partner_view.status)}
                        </span>
                    </li>
                    <li className="list-group-item">
                      <b>Created At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(partner_view.createdAt)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
