import React from "react";
import { Content } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import DatePicker from "react-datepicker";
import "./survey.css";
import $ from "jquery";
import moment from "moment";
const edit_survey_form_url = API.apiEndpoint + API.survey;

const surveyQuestionList = API.apiEndpoint + API.questionBank;
const loyalty_survey_url = API.apiEndpoint + API.loyaltyQuizList;
const schedule_survey_url = API.apiEndpoint + API.scheduleSurvey;

export default class SurveyFormEdit extends React.Component {
  state = {
    page: {},
    action: "",
    name: {},
    status: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    description: {},
    start: "",
    end: "",
    survey_list: [],
    triggerValue: "",
    loyalty_survey: "",
    base_url: API,
    selectedFile: null,
  };

  async componentDidMount() {
    let url = edit_survey_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    api_response.data.questions.map((item_value, key) => {
      var questions = this.getSingleQuestion(
        item_value.id,
        item_value.weightage
      );
    });
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let data = api_response.data;
      var startDate = new Date(data.start);
      var endDate = new Date(moment.utc(data.end).format("MM/DD/YYYY"));
      sessionStorage.triggerValue = data.triggerValue;
      this.setState({
        id: data.id,
        name: data.name,
        description: data.description,
        status: data.active,
        survey_create_date_from: startDate,
        survey_create_date_to: endDate,
        loyalty_survey: data.loyaltyGameId,
        sponsoredNameValue: data.sponsor_name,
        sponsoredNameArabicValue: data.sponsor_name_ar,
        sponsorImage:
          typeof data.sponsor_image != "undefined"
            ? data.sponsor_image
            : "null",
        loading: false,
        error: false,
        message: api_response.message,
      });
      setTimeout(function () {
        if (data.is_sponsored == "1") {
          $("#surveySponsored").prop("checked", true);
          $(".sponsoredReq").show();
        } else {
          $("#surveySponsored").prop("checked", false);
          $(".sponsoredReq").hide();
        }
      }, 500);
    }

    let loyaltySurvey = { url: loyalty_survey_url };
    let loyaltySurvey_response = await ApiRequest.get(loyaltySurvey);
    if (loyaltySurvey_response.data.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      {
        loyaltySurvey_response &&
          loyaltySurvey_response.data.map((loyaltyGameId_list, key) => {
            this.state.survey_list.push(loyaltyGameId_list[0]);
          });
      }

      this.setState({
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    $(".questionShown").on("click", ".single_question", function () {
      var name = $(this).data("name");
      var title = $(this).data("title");
      var identifier = $(this).data("identifier");
      var id = $(this).data("id");
      if ($(".selected_question[data-indentifier='" + id + "']").length == 0) {
        $(".mainSelectedArea").append(
          '<div class="selected_question" data-indentifier="' +
            id +
            '" data-id="' +
            id +
            '">' +
            ' <a href="javascript:void(0)" class="closeQuest">X</a>' +
            '	<div class="quest">' +
            "		<div>" +
            "			<h4>" +
            name +
            "</h4>" +
            "			<p>" +
            title +
            "</p>" +
            "		</div>" +
            "	</div>" +
            '	<div class="WeightageWrap">' +
            '		<input class="form-control weightage_class" name="weightage[]" placeholder="weightage" type="text">' +
            '		<input class="form-control id_class" name="id[]"  value="' +
            id +
            '" type="hidden">' +
            "	</div>" +
            "</div>"
        );
      } else {
        alert("Question already selected");
      }
    });

    $(".mainSelectedArea").on("click", ".closeQuest", function () {
      $(this).parents(".selected_question").remove();
    });

    $("#surveyTrigger").on("change", function () {
      var hasTriggerValue = $(this)
        .find("option:selected")
        .attr("data-hasTriggerValue");
      if (hasTriggerValue == "true") {
        sessionStorage.is_true = hasTriggerValue;
        $("#trigger_value").removeAttr("disabled");
        $("#trigger_value").attr("required", "required");
        $("#trigger_value").val(sessionStorage.triggerValue);
      } else {
        $("#trigger_value").attr("disabled", "disabled");
        $("#trigger_value").removeAttr("required", "required");
        setTimeout(function () {
          $("#trigger_value").val("");
        }, 400);
      }
    });

    var survey_Trigger = $("#surveyTrigger")
      .find("option[value='" + api_response.data.trigger + "']")
      .attr("data-hastriggervalue");

    if (survey_Trigger === true) {
      $("#trigger_value").prop("disabled", true);
      $("#trigger_value").val(api_response.data.triggerValue);
    } else {
      $("#trigger_value").attr("disabled", "disabled");
      $("#trigger_value").val("");
    }

    $("#surveySponsored").on("change", function () {
      if (this.checked == true) {
        $(".sponsoredReq").show();
        $(".sponsoredReq").find("input").attr("required", "required");
      } else {
        $(".sponsoredReq").find("input").removeAttr("required");
        $(".sponsoredReq").hide();
      }
    });

    await this.getData();
  }

  getData = async (filter = true, key = "type", value = "") => {
    let options = {
      url:
        surveyQuestionList +
        "?filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value +
        "&all_records=true",
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        survey_question_list: api_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };
  getSingleQuestion = async (id, weightage) => {
    let options = {
      url: surveyQuestionList + "/" + id,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    $(".mainSelectedArea").append(
      '<div class="selected_question" data-indentifier="' +
        api_response.data.id +
        '" data-id="' +
        api_response.data.id +
        '">' +
        ' <a href="javascript:void(0)" class="closeQuest">X</a>' +
        '	<div class="quest">' +
        "		<div>" +
        "			<h4>" +
        api_response.data.name.en +
        "</h4>" +
        "			<p>" +
        api_response.data.questionText.en +
        "</p>" +
        "		</div>" +
        "	</div>" +
        '	<div class="WeightageWrap">' +
        '		<input class="form-control weightage_class" name="weightage[]" value="' +
        weightage +
        '" placeholder="weightage" type="text">' +
        '		<input class="form-control id_class" name="id[]"  value="' +
        api_response.data.id +
        '" type="hidden">' +
        "	</div>" +
        "</div>"
    );

    return api_response.data;
  };

  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };
  triggerValueChange = (event) => {
    this.setState({ triggerValue: event.target.value });
  };
  handleChangeSurveyTrigger = async (event) => {
    this.setState({
      trigger: event.target.value,
      enableBtn: true,
    });
  };
  handleChangeLoyaltySurvey = async (event) => {
    this.setState({
      loyalty_survey: event.target.value,
      enableBtn: true,
    });
  };
  backUser = () => {
    window.location = "/oomco_fun/survey";
  };
  nameChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let name = Object.assign({}, prevState.name); // creating copy of state variable tip
      name[lang] = value; // update the name property, assign a new value
      return { name }; // return new object tip object
    });
    return true;
  };
  descriptionChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let description = Object.assign({}, prevState.description); // creating copy of state variable tip
      description[lang] = value; // update the name property, assign a new value
      return { description }; // return new object tip object
    });
    return true;
  };
  surveyStart = async (date) => {
    var moment = require("moment");
    var obj = {};
    var id = this.state.id;

    obj.type = "start";
    obj.id = id;
    //obj.date = new Date(date).getTime();
    obj.date = moment.utc(date).startOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_survey_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_start").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_start").val("1");
    }
    this.setState({
      survey_create_date_from: date,
      enableBtn: true,
    });
  };
  surveyEnd = async (date) => {
    var moment = require("moment");
    var obj = {};
    var id = this.state.id;

    obj.type = "end";
    obj.id = id;
    obj.date = moment.utc(date).endOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_survey_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_end").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_end").val("1");
    }
    this.setState({
      survey_create_date_to: date,
      enableBtn: true,
    });
  };
  objCreationTime = () => {
    return Math.floor(new Date().valueOf() * Math.random());
  };
  onChangeCardCheck = (event) => {
    const { survey_sponsored } = this.state;
    this.setState({
      survey_sponsored: survey_sponsored,
      enableBtn: true,
    });
  };
  sponsoredNameChange = (event) => {
    this.setState({ sponsoredNameValue: event.target.value });
  };
  sponsoredNameArabicChange = (event) => {
    this.setState({ sponsoredNameArabicValue: event.target.value });
  };
  saveUser = async (event) => {
    let error = false;
    if (
      $("#trigger_value").prop("required") &&
      $("#trigger_value").val() == ""
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Trigger value is required",
      });
    }
    if (
      $("#is_validate_start").val() == 0 ||
      $("#is_validate_end").val() == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Select Valid Date",
      });
    }
    if (!this.state.name.en) {
      error = true;
      this.setState({
        error: true,
        message: "Name is required",
      });
    }
    if (!this.state.description.en) {
      error = true;
      this.setState({
        error: true,
        message: "Description is required",
      });
    }
    if (!this.state.loyalty_survey) {
      error = true;
      this.setState({
        error: true,
        message: "Select loyalty Game ID",
      });
    }
    var Qlist = $(".selected_question").length;
    if (Qlist < 1) {
      error = true;
      this.setState({
        error: true,
        message: "Question is required",
      });
    }
    var sum = [];
    $(".weightage_class").each(function (i) {
      sum.push(parseInt($(this).val()));
    });
    var xyz = sum.reduce(function (a, b) {
      return a + b;
    }, 0);

    if (xyz > 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should not be greater then 100%",
      });
      return false;
    } else if (xyz < 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should be equals to 100%",
      });
      return false;
    }

    if (parseInt($("#weigt_age").val()) > 100) {
      error = true;
      this.setState({
        error: true,
        message: "Passing value should not be greater then 100%",
      });
    }
    if (error) {
      return;
    }

    var moment = require("moment");
    const formData = new FormData();
    formData.append("name", JSON.stringify(this.state.name));
    formData.append("description", JSON.stringify(this.state.description));
    formData.append("active", this.state.status);
    formData.append("loyaltyGameId", this.state.loyalty_survey);
    formData.append(
      "start",
      moment.utc(this.state.survey_create_date_from).startOf("day").valueOf()
    );
    formData.append(
      "end",
      moment
        .utc(this.state.survey_create_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );
    if (sessionStorage.is_true == "true" && $("#trigger_value").val() !== "") {
      formData.append("triggerValue", this.state.triggerValue);
    }

    var questions = [];
    $(".mainSelectedArea")
      .find(".selected_question")
      .each(function (i) {
        var weightage = $(this).find(".weightage_class").val();
        var id = $(this).find(".id_class").val();
        questions.push({ id: id, weightage: weightage });
      });

    formData.append("questions", JSON.stringify(questions));

    if ($("#surveySponsored").is(":checked")) {
      let error = false;
      if (!this.state.sponsoredNameValue) {
        error = true;
        this.setState({
          error: true,
          message: "Sponsor name is required",
        });
      }
      if (!this.state.sponsoredNameArabicValue) {
        error = true;
        this.setState({
          error: true,
          message: "Sponsor name arabic is required",
        });
      }

      if (error) {
        return;
      }
      formData.append("is_sponsored", "1");
      formData.append("sponsor_name", this.state.sponsoredNameValue);
      formData.append("sponsor_name_ar", this.state.sponsoredNameArabicValue);
      if (this.state.selectedFile)
        formData.append("sponsor_image", this.state.selectedFile);
    } else {
      formData.append("is_sponsored", "0");
    }

    let api_response = {};
    let options = { url: edit_survey_form_url, form_data: formData };
    options.url = edit_survey_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/oomco_fun/survey";
        }.bind(this),
        10000
      );
    }
  };

  onClickHandler = async (event) => {
    this.setState({ selectedFile: ("file", event.target.files[0]) });
  };

  render() {
    const { survey_question_list, survey_list } = this.state;
    let {
      error,
      message,
      success,
      success_message,
      survey_sponsored,
    } = this.state;
    return (
      <Content title="Survey" browserTitle="Survey">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Survey Form</h3>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Name</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name_en"
                        placeholder="Name english"
                        value={this.state.name.en}
                        onChange={this.nameChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name_ar"
                        placeholder="Name arabic"
                        value={this.state.name.ar}
                        onChange={this.nameChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>Description</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name_en">English</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description_en"
                        placeholder="description english"
                        value={this.state.description.en}
                        onChange={this.descriptionChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="description_ar">Arabic</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description_ar"
                        placeholder="description arabic"
                        value={this.state.description.ar}
                        onChange={this.descriptionChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group rangeFilter col-sm-4">
                    <label>Survey Create Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.survey_create_date_from}
                      selectsStart
                      survey_create_date_from={
                        this.state.survey_create_date_from
                      }
                      survey_create_date_to={this.state.survey_create_date_to}
                      onChange={this.surveyStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.survey_create_date_to}
                      selectsEnd
                      survey_create_date_from={
                        this.state.survey_create_date_from
                      }
                      survey_create_date_to={this.state.survey_create_date_to}
                      onChange={this.surveyEnd}
                      minDate={this.state.survey_create_date_from}
                      placeholderText="To"
                    />
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        value={this.state.status}
                        onChange={this.statusChange}
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 cusSelect">
                    <div className="form-group">
                      <label>Loyalty Game ID</label>
                      <select
                        className="form-control"
                        id="loyalty_survey"
                        name="loyalty_survey"
                        value={this.state.loyalty_survey}
                        onChange={this.handleChangeLoyaltySurvey}
                      >
                        <option> -- Select Game ID --</option>
                        {survey_list &&
                          survey_list.map((item, key) => (
                            <option value={item.GameId}>{item.GameName}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      {"Sponsor on Survey"}
                      <input
                        className="ml-5"
                        onChange={this.onChangeCardCheck}
                        type="checkbox"
                        id="surveySponsored"
                        checked={survey_sponsored}
                      />
                    </label>
                  </div>
                  <div className="col-md-4 sponsoredReq">
                    <div className="form-group">
                      <label htmlFor="name">Sponsor Name</label>
                      <div className="form-group-custom">
                        <input
                          type="text"
                          className="form-control mr-5"
                          id="name"
                          placeholder="Name"
                          value={this.state.sponsoredNameValue}
                          onChange={this.sponsoredNameChange}
                        />
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name Arabic"
                          value={this.state.sponsoredNameArabicValue}
                          onChange={this.sponsoredNameArabicChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sponsoredReq">
                    <div className="form-group">
                      <label for="myfile">Sponsor Image</label>
                      <div>
                        <input
                          id="uploadList"
                          type="file"
                          name="file"
                          className="imgField"
                          ref={(ref) => (this.uploadList = ref)}
                          onChange={this.onClickHandler}
                          accept=".png, .jpeg"
                        />
                        <img
                          data-image={this.state.sponsorImage}
                          className="thumnailImg"
                          src={
                            this.state.base_url.apiEndpoint +
                            "/" +
                            this.state.sponsorImage
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row questionShown">
                  <div className="col-md-3">
                    <div className="quesList">
                      <h3>Question List</h3>
                      <ul>
                        {survey_question_list ? (
                          survey_question_list.map((data) => {
                            return (
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  data-id={data.id}
                                  data-identifier={data.id}
                                  className="single_question"
                                  data-name={data.name ? data.name.en : "-"}
                                  data-title={
                                    data.questionText
                                      ? data.questionText.en
                                      : "-"
                                  }
                                >
                                  <h4>{data.name ? data.name.en : "-"} </h4>
                                  <p>
                                    {data.questionText
                                      ? data.questionText.en
                                      : "-"}
                                  </p>
                                </a>
                              </li>
                            );
                          })
                        ) : (
                          <li colSpan="5" className="noRecord">
                            No Record Found
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="mainSelectedArea"></div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveUser}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
