import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
// import Moment from "react-moment";
import moment from "moment";
const card_detail_url = API.apiEndpoint + API.getCard;
const store_url = API.apiEndpoint + API.store;
const cardOperation_url = API.apiEndpoint + API.cardOperation;
const cardplateTypes_url = API.apiEndpoint + API.carplateTypes;
const referenceNumber_url = API.apiEndpoint + API.referenceNumber;

export default class ActiveCard extends React.Component {
  state = {
    card: {},
    stores: [],
    store_code: {},
    store_token: {},
    topup_amount: "",
    topup_method: "",
    customer_code: "",
    car_plate: "",
    loading: true,
    enable: false,
    mobile_number: "",
    country_code: "968",
    error: false,
    error_message: "",
    success: false,
    success_message: "",
    simpleNumber: {},
    cardplateTypes: [],
    allowed_payment_methods: JSON.parse(
      localStorage.getItem("allowed_payment_methods")
    ),
    remarks: "",
    message: "",
    reference_id: "",
    userEmail: localStorage.getItem("user_email"),
    terminal_id: localStorage.getItem("user_terminalId"),
    topup_limit_min: localStorage.getItem("topup_limit_min")
      ? localStorage.getItem("topup_limit_min")
      : null,
    topup_limit_max: localStorage.getItem("topup_limit_max")
      ? localStorage.getItem("topup_limit_max")
      : null
  };

  async componentDidMount() {
    const response = await axios.get(
      card_detail_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      this.setState({ card: response.data.data });
    }

    let options = { url: cardplateTypes_url };
    const carPlateType = await ApiRequest.get(options);
    this.setState({
      cardplateTypes: carPlateType.data,
      car_plate: carPlateType.data[0].name
    });

    let referenceNumber = { url: referenceNumber_url };
    const referenceNum = await ApiRequest.get(referenceNumber);
    if (referenceNum.error === false) {
      this.setState({
        reference_id: referenceNum.data.reference_number
      });
    } else {
      this.setState({
        reference_id: ""
      });
    }
  }

  handleChangeCarPlateType = async event => {
    this.setState({
      car_plate: event.target.value
    });
  };

  // carPlateChange = event => {
  //   this.setState({ error: false, car_plate: event.target.value });
  // };

  countryCodeChange = event => {
    this.setState({ error: false, country_code: event.target.value });
  };

  topupChange = event => {
    this.setState({ error: false, topup_amount: event.target.value });
  };

  // customerCodeChange = event => {
  //   this.setState({ error: false, customer_code: event.target.value });
  // };
  topupMethodChange = event => {
    this.setState({ error: false, topup_method: event.target.value });
  };
  backPage = () => {
    window.location = "/basma_white_list";
  };

  numberChange = event => {
    if (event.target.value.length == 6) {
      this.setState({
        error: true,
        error_message: "Number limited to 5 digit"
      });
    } else {
      this.setState({ error: false, simpleNumber: event.target.value });
    }
  };

  mobileNumberChange = event => {
    if (event.target.value.length == 9) {
      this.setState({
        error: true,
        error_message: "Mobile Number limited to 8 digit"
      });
    } else {
      this.setState({ error: false, mobile_number: event.target.value });
    }
  };

  changeContent = event => {
    this.setState({
      remarks: event.target.value
    });
  };

  activeCard = async event => {
    const formData = new FormData();
    let error = false;

    this.setState({ enable: true });
    let topup_limit_max = this.state.topup_limit_max
      ? parseInt(this.state.topup_limit_max)
      : 2000;

    let topup_limit_min = this.state.topup_limit_min
      ? parseInt(this.state.topup_limit_min)
      : 10;

    if (!this.state.simpleNumber.length > 0) {
      error = true;
      this.setState({
        error: true,
        error_message: "Car Number is required"
      });
    }

    if (
      !(parseInt(this.state.topup_amount) >= topup_limit_min) ||
      !(parseInt(this.state.topup_amount) <= topup_limit_max)
    ) {
      error = true;
      this.setState({
        error: true,
        error_message:
          "Topup Amount Min " + topup_limit_min + "  to Max " + topup_limit_max
      });
    }

    if (!this.state.terminal_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Terminal ID Not Available"
      });
    }

    if (!this.state.reference_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Need Reference ID"
      });
    }

    if (this.state.mobile_number.length === 9) {
      error = true;
      this.setState({
        error: true,
        error_message: "Mobile Number limited to 8 digit"
      });
    }

    if (error) {
      return;
    }
    formData.append("method_name", "card_activate");
    formData.append("transaction_id", new Date().getTime());
    formData.append("card_number", this.state.card.card_number);
    formData.append(
      "car_plate",
      this.state.simpleNumber + this.state.car_plate
    );
    formData.append("topup_amount", this.state.topup_amount);
    formData.append("topup_method", this.state.topup_method);
    formData.append("mobile_number", this.state.mobile_number);
    formData.append("country_code", this.state.country_code);
    formData.append("reference_id", this.state.reference_id);
    formData.append("userEmail", this.state.userEmail);
    formData.append("terminal_id", this.state.terminal_id);
    formData.append("remarks", this.state.remarks);
    formData.append("request_from", API.request_from);

    let headers = {
      store_code: localStorage.getItem("store_code"),
      store_token: localStorage.getItem("store_token"),
      "X-Access-Token": localStorage.getItem("user_token")
    };

    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            enable: false,
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message
          });
          setTimeout(
            function() {
              this.setState({ success: false });
              window.location = "/basma_white_list";
            }.bind(this),
            10000
          );
        }
      })
      .catch(error => {
        let message = error.response.data.message;
        this.setState({
          error: true,
          enable: false,
          error_message: message.param_missing
            ? ApiRequest.string_humanize_format(message.param_missing) +
              " is required"
            : message
        });
      });
  };

  render() {
    const {
      card,
      error,
      error_message,
      cardplateTypes,
      success,
      success_message,
      allowed_payment_methods
    } = this.state;

    return (
      <Content title="Card Active" browserTitle="Card Active">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {error_message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Card Active </h3>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label htmlFor="card_number">Card Serial Number </label>
                    <input
                      type="number"
                      className="form-control"
                      id="card_number"
                      value={card.card_number}
                      disabled={true}
                    />
                  </div>

                  {/*<div className="col-md-6 form-group">
                    <label htmlFor="car_plate">Car Plate </label>
                    <input
                      type="text"
                      className="form-control"
                      id="car_plate"
                      onChange={this.carPlateChange}
                    />
          </div>*/}
                  <div className="col-md-6 form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="number">Car Number </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control "
                          id="number"
                          placeholder="00000"
                          value={this.state.simpleNumber}
                          onChange={this.numberChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control "
                          id="carplatetype"
                          name="carplatetype"
                          onChange={this.handleChangeCarPlateType}
                          value={this.state.car_plate}
                        >
                          {cardplateTypes.length > 0 &&
                            cardplateTypes.map((item, key) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="mobile_number">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobile_number"
                      value={this.state.mobile_number}
                      onChange={this.mobileNumberChange}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="country_code">Country Code</label>
                    <input
                      type="number"
                      className="form-control"
                      id="country_code"
                      value="968"
                      //onChange={this.countryCodeChange}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="topup_amount">Topup Amount</label>
                    <input
                      type="number"
                      min={
                        this.state.topup_limit_min
                          ? this.state.topup_limit_min
                          : 1
                      }
                      max={
                        this.state.topup_limit_max
                          ? this.state.topup_limit_max
                          : 100000
                      }
                      className="form-control"
                      id="topup_amount"
                      placeholder="10 to 2000"
                      value={this.state.topup_amount}
                      onChange={this.topupChange}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="topup_method">Topup Method</label>
                    <select
                      className="form-control"
                      onChange={this.topupMethodChange}
                      value={this.state.topup_method}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {allowed_payment_methods.map((item, i) =>
                        item.checked === true ? (
                          <option value={item.value}>{item.label === 'ePayment' ? 'Thawani' : item.label}</option>
                        ) : (
                          ""
                        )
                      )}
                      <option value="3rd_Party_Loyalty">3rd Party Loyalty</option>
                    </select>
                    {/*<input
                      type="text"
                      className="form-control"
                      id="topup_method"
                      onChange={this.topupMethodChange}
                    />*/}
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="store_code">Your Store Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="store_code"
                      value={localStorage.getItem("store_code")}
                      disabled={true}
                    />
                  </div>
                  {/*<div className="col-md-6 form-group">
                    <label htmlFor="customer_code">Customer Code</label>
                    <input
                      type="number"
                      className="form-control"
                      id="customer_code"
                      onChange={this.customerCodeChange}
                    />
                  </div>*/}
                  <div className="col-md-6 form-group">
                    <label htmlFor="store_code">Remarks</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.value}
                      onChange={this.changeContent}
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <label htmlFor="Terminal_Id">Terminal ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Terminal_Id"
                      value={this.state.terminal_id}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="Reference_ID">Reference ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_ID"
                      value={this.state.reference_id}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="Terminal_Id">User</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Terminal_Id"
                      value={this.state.userEmail}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.activeCard}
		  disabled={this.state.enable}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backPage}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
