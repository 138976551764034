import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";

const edit_spin_win_form_url = API.apiEndpoint + API.spinAndWin;
const spinWinList_url = API.apiEndpoint + API.spinAndWinList;
const schedule_spin_win_url = API.apiEndpoint + API.scheduleSpinAndWin;

export default class SpinWinEditForm extends React.Component {
  state = {
    spin_win_list: [],
    loyaltyGameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    active: "",
    start: "",
    end: "",
  };

  async componentDidMount() {
    let url = edit_spin_win_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    let data = api_response.data;

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      var startDate = new Date(data.start);
      var endDate = new Date(moment.utc(data.end).format("MM/DD/YYYY"));
      this.setState({
        id: data.id,
        active: data.active,
        spin_win_date_from: startDate,
        spin_win_date_to: endDate,
        loyaltyGameId: data.loyaltyGameId,
        loyaltyGameName: data.loyaltyGameName,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    let SpinWinListoptions = { url: spinWinList_url };
    let api_spinWinList_response = await ApiRequest.get(SpinWinListoptions);
    if (api_spinWinList_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      {
        api_spinWinList_response &&
          api_spinWinList_response.data.map((spin_list, key) => {
            this.state.spin_win_list.push(spin_list[0]);
          });
      }
      this.setState({
        win_list: api_spinWinList_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  gameIdChange = (event) => {
    var option = $("option:selected", event.target).attr("data-name");
    this.setState({ loyaltyGameId: event.target.value, gameName: option });
  };
  statusChange = (event) => {
    this.setState({ active: event.target.value });
  };
  spinWinStart = async (date) => {
    var moment = require("moment");
    var obj = {};
    var id = this.state.id;

    obj.type = "start";
    obj.id = id;
    //obj.date = new Date(date).getTime();
    obj.date = moment.utc(date).startOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_spin_win_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_start").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_start").val("1");
    }
    this.setState({
      spin_win_date_from: date,
      enableBtn: true,
    });
  };
  spinWinEnd = async (date) => {
    var moment = require("moment");
    var obj = {};

    var id = this.state.id;
    obj.type = "end";
    obj.id = id;
    //obj.date = new Date(date).getTime();
    obj.date = moment.utc(date).endOf("day").valueOf();

    let api_response = {};
    let options = {
      url: schedule_spin_win_url,
      form_data: JSON.stringify(obj),
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
      $("#is_validate_end").val("0");
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      $("#is_validate_end").val("1");
    }
    this.setState({
      spin_win_date_to: date,
      enableBtn: true,
    });
  };
  saveSpinWin = async (event) => {
    let error = false;

    if (!this.state.spin_win_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
    }
    if (!this.state.spin_win_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
    }
    if (!this.state.loyaltyGameId) {
      error = true;
      this.setState({
        error: true,
        message: "Loyalty Game ID is required",
      });
    }
    if (error) {
      return;
    }

    var moment = require("moment");
    const formData = new FormData();
    formData.append("active", this.state.active);
    formData.append("loyaltyGameId", this.state.loyaltyGameId);
    formData.append("loyaltyGameName", this.state.loyaltyGameName);
    formData.append(
      "start",
      moment.utc(this.state.spin_win_date_from).startOf("day").valueOf()
    );
    formData.append(
      "end",
      moment
        .utc(this.state.spin_win_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );

    let api_response = {};

    let options = { url: edit_spin_win_form_url, form_data: formData };

    options.url = edit_spin_win_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/oomco_fun/spin_win";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/oomco_fun/spin_win";
  };

  render() {
    const { spin_win_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="Spin & Win" browserTitle="Spin & Win">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Spin & Win Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="loyaltyGameId">Loyalty Game ID</label>
                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.loyaltyGameId}
                      onChange={this.gameIdChange}
                    >
                      <option> Select Game ID </option>
                      {spin_win_list &&
                        spin_win_list.map((item, key) => {
                          return (
                            <option
                              value={item.GameId}
                              data-name={item.loyaltyGameName}
                            >
                              {ApiRequest.string_humanize_format(item.GameName)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="active">Status</label>
                    <select
                      className="form-control"
                      value={this.state.active}
                      onChange={this.statusChange}
                    >
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Spin/Win Create Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.spin_win_date_from}
                    selectsStart
                    spin_win_date_from={this.state.spin_win_date_from}
                    spin_win_date_to={this.state.spin_win_date_to}
                    onChange={this.spinWinStart}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.spin_win_date_to}
                    selectsEnd
                    spin_win_date_from={this.state.spin_win_date_from}
                    spin_win_date_to={this.state.spin_win_date_to}
                    onChange={this.spinWinEnd}
                    minDate={this.state.spin_win_date_from}
                    placeholderText="To"
                  />
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveSpinWin}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
