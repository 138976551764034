import { Modal, Button } from "react-bootstrap";

import { useMainStore } from "../store";

export function DownloadModal() {
  const show = useMainStore((state) => state.showDownlaodModal);
  const setShowDownlaodModal = useMainStore(
    (state) => state.setShowDownlaodModal,
  );
  const handleClose = () => setShowDownlaodModal(false);
  return (
    <div onClick={() => setShowDownlaodModal(!show)}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Generating Download Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The download link is being generated. Once it's ready, it will be sent
          to your email address.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
