import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
const card_detail_url = API.apiEndpoint + API.getCard;
const store_url = API.apiEndpoint + API.store;
const assignStore_url = API.apiEndpoint + API.assignStore;

export default class AssignStore extends React.Component {
  state = {
    card: {},
    stores: [],
    error: false,
    error_message: "",
    store_code: "",
    success: false,
    success_message: ""
  };

  async componentDidMount() {
    const response = await axios.get(
      card_detail_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      this.setState({ card: response.data.data });
    }
    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token")
    };
    const response1 = await axios.get(store_url, { headers: headers });
    if (response1.status === 200) {
      this.setState({ stores: response1.data.data });
    }
  }

  storeChange = event => {
    this.setState({ store_code: event.target.value });
  };

  assignStore = async event => {
    const formData = new FormData();
    formData.append("card_number", this.state.card.card_number);
    formData.append("store_code", this.state.store_code);
    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token")
    };
    await axios
      .post(assignStore_url, formData, { headers: headers })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message
          });
          setTimeout(
            function() {
              this.setState({ success: false });
              window.location = "/basma_white_list";
            }.bind(this),
            10000
          );
        }
      })
      .catch(error => {
        this.setState({
          error: true,
          error_message: error.response.data.message
        });
      });
  };

  render() {
    const {
      card,
      stores,
      error,
      error_message,
      success,
      success_message
    } = this.state;

    return (
      <Content
        title="Assign Store -  Basma Card"
        browserTitle="Assign Store - Basma Card"
      >
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {error_message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Assign Store - Basma Card </h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="card_number">Card Number </label>
                  <input
                    type="text"
                    className="form-control"
                    id="card_number"
                    value={card.card_number}
                    disabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="store">Stores</label>
                  <select className="form-control" onChange={this.storeChange}>
                    <option value="" disabled selected>
                      Select Store
                    </option>
                    {stores &&
                      stores.map(store => {
                        return (
                          <option key={store.id} value={store.store_code}>
                            {store.store_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.assignStore}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
