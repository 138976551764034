import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import TagsInput from "react-tagsinput";
import LoadingAjax from "../../components/Loading";

import "react-tagsinput/react-tagsinput.css";
const safety_url = API.apiEndpoint + API.safety;

export default class SafetyForm extends React.Component {
  state = {
    page: {},
    action: "add",
    tags: [],
    tip: {},
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: ""
  };

  async componentDidMount() {
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        tip: this.props.page.tip,
        tags: JSON.parse(this.props.page.tags),
        page: this.props.page
      });
    }
  }

  tipChange = (lang, event) => {
    let value = event.target.value;
    this.setState(prevState => {
      let tip = Object.assign({}, prevState.tip); // creating copy of state variable tip
      tip[lang] = value; // update the name property, assign a new value
      return { tip }; // return new object tip object
    });
    return true;
  };

  tagsChange = tags => {
    this.setState({ tags });
  };

  saveSafety = async event => {
    const formData = new FormData();
    let error = false;
    if (!this.state.tip.en) {
      error = true;
      this.setState({
        error: true,
        message: "English tip not filled"
      });
    }
    if (!this.state.tip.ar) {
      error = true;
      this.setState({
        error: true,
        message: "Arabic tip not filled"
      });
    }
    if (this.state.tags.length == 0) {
      error = true;
      this.setState({
        error: true,
        message: "Tags not filled"
      });
    }

    if (error) {
      return;
    }
    formData.append("tip", JSON.stringify(this.state.tip));
    formData.append("tags", JSON.stringify(this.state.tags));

    let api_response = {};
    let options = { url: safety_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      options = {
        url: safety_url + "/" + this.state.page.id,
        form_data: formData
      };
      api_response = await ApiRequest.put(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message
      });
      setTimeout(
        function() {
          this.setState({ success: false });
          window.location = "/safety_tips";
        }.bind(this),
        10000
      );
    }
  };

  backSafety = () => {
    window.location = "/safety_tips";
  };

  render() {
    const { error, message, success, success_message } = this.state;
    return (
      <Content title="Safety Tips" browserTitle="Safety Tips">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Safety Tips Form </h3>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Tip</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="tip_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tip_en"
                        placeholder="Tip english"
                        value={this.state.tip.en}
                        onChange={this.tipChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="tip_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tip_ar"
                        placeholder="Tip arabic"
                        value={this.state.tip.ar}
                        onChange={this.tipChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="tags">Tags</label>
                  <TagsInput
                    value={this.state.tags}
                    onChange={this.tagsChange.bind(null)}
                  />
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveSafety}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.props.handleClick.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
