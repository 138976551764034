import React from "react";
import FuelListing from "./listing";
import FuelForm from "./form";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const fuel_url = API.apiEndpoint + API.fuel;

export default class FuelPrices extends React.Component {
  state = {
    action: "listing",
    page: {}
  };

  async componentDidMount() {
    if (this.props.edit) {
      let options = {
        url: fuel_url + "/" + this.props.match.params.id
      };
      let response = await ApiRequest.get(options);
      if (response.error === false) {
        this.page(response.data, "edit");
      }
    }
  }

  page = (page, action) => {
    this.setState({ action: action, page: page });
  };

  handleClick = (page, action) => {
    this.setState({ action: action, page: page });
  };

  render() {
    const { action, page } = this.state;
    if (action === "edit" || action === "add")
      return (
        <FuelForm action={action} page={page} handleClick={this.handleClick} />
      );
    else return <FuelListing handleClick={this.handleClick} />;
  }
}
