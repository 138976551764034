import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const card_detail_url = API.apiEndpoint + API.getCard;
//const store_url = API.apiEndpoint + API.store;
const cardReplace_url = API.apiEndpoint + API.cardReplace;
const referenceNumber_url = API.apiEndpoint + API.referenceNumber;

export default class CardReplace extends React.Component {
  state = {
    card: {},
    //stores: [],
    error: false,
    error_message: "",
    card_number: "",
    country_code: "",
    mobile_number: "",
    replacement_reason: "",
    success: false,
    success_message: "",
    reference_id: "",
    userEmail: localStorage.getItem("user_email"),
    terminal_id: localStorage.getItem("user_terminalId"),
  };

  async componentDidMount() {
    const response = await axios.get(
      card_detail_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      this.setState({ card: response.data.data });
    }

    let referenceNumber = { url: referenceNumber_url };
    const referenceNum = await ApiRequest.get(referenceNumber);
    if (referenceNum.error === false) {
      this.setState({
        reference_id: referenceNum.data.reference_number,
      });
    } else {
      this.setState({
        reference_id: "",
      });
    }
  }

  add_new_card = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        error_message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({ error: false, card_number: event.target.value });
    }
  };
  countryCode = (event) => {
    this.setState({ error: false, country_code: event.target.value });
  };
  mobileNumber = (event) => {
    this.setState({ error: false, mobile_number: event.target.value });
  };
  replaceReason = (event) => {
    this.setState({ error: false, replacement_reason: event.target.value });
  };
  topupChange = (event) => {
    this.setState({ card_number: event.target.value });
  };
  cardReplace = async (event) => {
    const formData = new FormData();

    let error = false;

    if (!this.state.terminal_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Terminal ID Not Available",
      });
    }

    if (!this.state.reference_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Need Reference ID",
      });
    }

    if (error) {
      return;
    }

    formData.append("request_from", API.request_from);
    formData.append("method_name", "card_replace");
    formData.append("transaction_id", new Date().getTime());
    formData.append("car_plate", this.state.card.car_plate);
    formData.append("card_number", this.state.card_number);
    formData.append("country_code", this.state.country_code);
    formData.append("mobile_number", this.state.mobile_number);
    formData.append("replacement_reason", this.state.replacement_reason);
    formData.append("reference_id", this.state.reference_id);
    formData.append("userEmail", this.state.userEmail);
    formData.append("terminal_id", this.state.terminal_id);

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
      store_code: API.storeCode,
      store_token: API.storeToken,
    };

    await axios
      .post(cardReplace_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              window.location = "/basma_cards_management";
            }.bind(this),
            10000
          );
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        this.setState({
          error: true,
          error_message: message.param_missing
            ? ApiRequest.string_humanize_format(message.param_missing) +
              " is required"
            : message,
        });
      });
  };

  render() {
    const { card, error, error_message, success, success_message } = this.state;
    return (
      <Content title="Card Replace" browserTitle="Card Replace">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {error_message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Card Replace</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="card_number">Old Card Serial Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="old_card"
                      value={card.card_number}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="car_plate">Car Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="car_plate"
                      value={card.car_plate}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="card_number">New Card Serial Number</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      id="new_card"
                      onChange={this.add_new_card}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="country_code">Country Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="country_code"
                      onChange={this.countryCode}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="mobile_number">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile_number"
                      onChange={this.mobileNumber}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="replaceReason">Reason</label>
                    <select
                      className="form-control"
                      value={this.state.replacement_reason}
                      onChange={this.replaceReason}
                    >
                      <option value="" disabled>
                        Select{" "}
                      </option>
                      <option value="defective_card">Defective Card</option>
                      <option value="lost_card">Lost Card</option>
                    </select>
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="Terminal_Id">Terminal ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Terminal_Id"
                      value={this.state.terminal_id}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="Reference_ID">Reference ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_ID"
                      value={this.state.reference_id}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="userEmail">User</label>
                    <input
                      type="text"
                      className="form-control"
                      id="userEmail"
                      value={this.state.userEmail}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.cardReplace}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
