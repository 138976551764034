import React, { Component } from "react";
import { Content, Row } from "adminlte-2-react";

import LoadingAjax from "../../components/Loading";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";

const carPlateLetters = API.apiEndpoint + API.carPlateLetters;

export default class UpdateCarPlateLetter extends Component {
	state = {
		data: {},
		status: "",
		error: false,
		success: false,
		message: "",
		success_message: "",
		loading: false,
		callApi: false,
		name: "",
		nameAr: "",
	};

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData() {
		this.setState({ loading: true });
		let url = carPlateLetters + "/" + this.props.match.params.id;

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { results } = response.data;
			this.setState({
				loading: false,
				error: false,
				callApi: true,
				data: results,
        name: results.name,
        nameAr: results.nameAr
			});
		}
	}

	handelNameChange = (event) => {
		this.setState({ name: event.target.value });
	};

	handelNameArChange = (event) => {
		this.setState({ nameAr: event.target.value });
	};

	handelBack = () => {
		window.location = "/car_plate_letters";
	};

	handleSubmit = async () => {
		const formData = new FormData();
		formData.append("name", this.state.name);
		formData.append("nameAr", this.state.nameAr);

		let url = carPlateLetters + "/" + this.props.match.params.id;
		let api_response = {};
		let options = { url, form_data: formData };

		api_response = await ApiRequest.put(options);

		if (api_response.error) {
			this.setState({
				loading: false,
				error: true,
				message: api_response.message,
			});
		} else {
			this.setState({
				success: true,
				loading: false,
				success_message: api_response.message,
			});
			setTimeout(
				function () {
					this.setState({ success: false });
					window.location = "/car_plate_letters";
				}.bind(this),
				5000
			);
		}
	};

	render() {
		const {
			error,
			message,
			success,
			success_message,
		} = this.state;

		return (
			<Content
				title="Update Car Plate Letter" browserTitle="Update Car Plate Letter"
			>
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<div className="col-md-12">
						<div className="box box-primary">
							<div className="box-header with-border">
								<h3 className="box-title">
                  Update Car Plate Letter
								</h3>
							</div>

							<div className="box-body">
								<div className="form-group">
									<label htmlFor="name">Name</label>
									<input
										type="text"
										className="form-control"
										id="name"
										placeholder="Car Plate letter"
										value={this.state.name}
                    onChange={this.handelNameChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="name">Name Ar</label>
									<input
										type="text"
										className="form-control"
										id="name"
										placeholder="Car Plate letter"
										value={this.state.nameAr}
                    onChange={this.handelNameArChange}
									/>
								</div>
							</div>
							<div className="box-footer clearfix no-border">
								<button
									onClick={this.handleSubmit}
									className="btn btn-primary pull-right "
								>
									Submit
								</button>
								<button
									onClick={this.handelBack}
									className="btn btn-default pull-right mr-10"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}
