import React from "react";
import axios from "axios";
import API from "../../constants";
import ReconciliationListing from "./listing";
import ReconciliationView from "./view";
const stations_url = API.apiEndpoint + API.stationLocator;

export default class Reconciliation extends React.Component {
  state = {
    action: "listing",
    station_id: null,
  };

  async componentDidMount() {
    if (this.props.view_station) {
      const response = await axios.get(
        stations_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewStationLoyatyDistribution(response.data.data, "view");
      }
    }
  }
  viewStationLoyatyDistribution = ( action) => {
    this.setState({ action: action });
  };

  render() {
    const { action } = this.state;

    if (action === "view")
      return (
        <ReconciliationView
          viewStationLoyatyDistribution={this.viewStationLoyatyDistribution}
        />
      );
    else
      return (
        <ReconciliationListing
          viewStationLoyatyDistribution={this.viewStationLoyatyDistribution}
        />
      );
  }
}
