import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";

import "./business_user_requests.css";
import { confirmAlert } from "react-confirm-alert";

const getBusinessUserRequest_url = API.apiEndpoint + API.businessUserRequestsView;
const businessUserRequests_change_status_url = API.apiEndpoint + API.businessUserRequests_change_status_url;

export default class BusinessUserRequestsView extends Component {
  state = {
    business_user_request_view: {},
    loading: true,
    error: false,
    message: ""
  };

  getData = async () => {
    let url = getBusinessUserRequest_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        business_user_request_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  renderStatus(status) {
    if (status === "pending") {
      return (
        <span
          style={{
            background: "rgba(255,191,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    } else if (status === "accepted") {
      return (
        <span
          style={{
            background: "rgba(0,255,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    } else if (status === "declined") {
      return (
        <span
          style={{
            background: "rgba(255,0,0,0.3)",
            padding: "5px 15px",
            borderRadius: "9999px",
            fontWeight: "600",
          }}
        >
          {status}
        </span>
      );
    }
  }

  changeStatus = (businessUserRequestId, newStatus) => {
    confirmAlert({
      title: "Business User Request",
      message: `Are you sure you want to ${
        newStatus === "accepted" ? "accept" : "decline"
      } this user's request to become a business user?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const formData = new FormData();
            formData.append("id", businessUserRequestId);
            formData.append("status", newStatus);
            formData.append("acceptedBy", localStorage.getItem("user_id"));

            let api_response = {};
            let options = {
              url: businessUserRequests_change_status_url,
              form_data: formData,
            };
            this.setState({ loading: true });
            api_response = await ApiRequest.post(options);
            if (api_response.error) {
              this.setState({
                loading: false,
                error: true,
                message: api_response.message,
              });
            } else {
              this.setState({
                loading: false,
                success: true,
                success_message: api_response.message,
              });
              await this.getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  renderRequestDocuments = (data) => {
    if(!data.user_type || data.user_type === "none") {
      return (
        <div>-</div>
      )
    }
    if (data.user_type === "individual") {
      return (
        <div>
          <a
            href={data.mulkia_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Mulkia
          </a>
          <a
            href={data.car_picture_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Car Picture
          </a>
        </div>
      )
    } else if (data.user_type === "business") {
      return (
        <div>
          <a  
            href={data.business_cr_url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs mr-5"
          >
            Business CR
          </a>
          <a
           href={data.letter_head_url}
           target="_blank"
           rel="noreferrer"
           className="btn btn-primary btn-xs mr-5"
           >
            Letter Head
           </a>
        </div>
      )
    }
  };

  render() {
    const business_user_request = this.state.business_user_request_view;

    return (
      <Content title={"Business User Request - " + business_user_request.id} browserTitle="Business User Request">
        <LoadingAjax />
        <Row>
          <Col xs={12}>
            <Col>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">View</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Business User Request ID</b>{" "}
                      <span className="pull-right">{business_user_request.id}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Oomco ID</b>{" "}
                      <span className="pull-right">
                        {business_user_request?.oomcoId ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={"/app_view/" + business_user_request.user_id}
                          >
                            {business_user_request.oomcoId}
                          </a>
                        ) : (
                          <p>-</p>
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>User Type</b>{" "}
                      <span className="pull-right" style={{ textTransform: "capitalize" }}>{business_user_request?.user_type ?? "-"}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>{" "}
                      <span className="pull-right" style={{ textTransform: "capitalize" }}>{this.renderStatus(business_user_request.status)}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Requested At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_time_format(
                          business_user_request.createdAt
                        )}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Status Updated At</b>{" "}
                      <span className="pull-right">
                      {business_user_request.acceptedAt
                        ? ApiRequest.date_time_format(
                            business_user_request.acceptedAt
                          )
                        : "-"}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Status Changed By</b>{" "}
                      <span className="pull-right">
                        {
                          !business_user_request.acceptedByData?.id ? (
                            <div style={{textAlign: "center"}}>-</div>
                          ) : (
                            <a
                            class="btn btn-primary btn-xs mr-5"
                            target="_blank"
                            rel="noreferrer"
                            href={
                              "/admin_user_view/" +
                              business_user_request.acceptedByData.id
                            }
                          >
                            {business_user_request.acceptedByData.name}
                          </a>
                          )
                        }
                      </span>
                    </li>
                    {business_user_request?.user_type === "individual" && (
                      <>
                        <li className="list-group-item">
                          <b>Mulkia</b>{" "}
                          <span className="pull-right">
                            {
                              !business_user_request?.mulkia_url ? (
                                <div style={{textAlign: "center"}}>-</div>
                              ) : (
                                <a
                                class="btn btn-primary btn-xs mr-5"
                                target="_blank"
                                rel="noreferrer"
                                href={business_user_request.mulkia_url}
                              >
                                Mulkia
                                <span style={{marginLeft: "0.7em"}}>
                                  <i className="fa fa-external-link" />
                                </span>
                              </a>
                              )
                            }
                          </span>
                        </li>
                        <li className="list-group-item">
                          <b>Car Picture</b>{" "}
                          <span className="pull-right">
                            {
                              !business_user_request?.car_picture_url ? (
                                <div style={{textAlign: "center"}}>-</div>
                              ) : (
                                <a
                                class="btn btn-primary btn-xs mr-5"
                                target="_blank"
                                rel="noreferrer"
                                href={business_user_request.car_picture_url}
                              >
                                Car Picture
                                <span style={{marginLeft: "0.7em"}}>
                                  <i className="fa fa-external-link" />
                                </span>
                              </a>
                              )
                            }
                          </span>
                        </li>
                      </>
                    )}
                    {business_user_request?.user_type === "business" && (
                      <>
                        <li className="list-group-item">
                          <b>Business CR</b>{" "}
                          <span className="pull-right">
                            {
                              !business_user_request?.business_cr_url ? (
                                <div style={{textAlign: "center"}}>-</div>
                              ) : (
                                <a
                                class="btn btn-primary btn-xs mr-5"
                                target="_blank"
                                rel="noreferrer"
                                href={business_user_request.business_cr_url}
                              >
                                Business CR
                                <span style={{marginLeft: "0.7em"}}>
                                  <i className="fa fa-external-link" />
                                </span> 
                              </a>
                              )
                            }
                          </span>
                        </li>
                        <li className="list-group-item">
                          <b>Letter Head</b>{" "}
                          <span className="pull-right">
                            {
                              !business_user_request?.letter_head_url ? (
                                <div style={{textAlign: "center"}}>-</div>
                              ) : (
                                <a
                                class="btn btn-primary btn-xs mr-5"
                                target="_blank"
                                rel="noreferrer"
                                href={business_user_request.letter_head_url}
                              >
                                Letter Head
                                <span style={{marginLeft: "0.7em"}}>
                                  <i className="fa fa-external-link" />
                                </span>
                              </a>
                              )
                            }
                          </span>
                        </li>
                      </>
                    )}
                    <li className="list-group-item">
                      <b>Change Status</b>{" "}
                      <span className="pull-right">
                        <select
                          className="form-control"
                          value={
                            business_user_request.status !== "pending"
                              ? business_user_request.status
                              : ""
                          }
                          onChange={(e) =>
                            this.changeStatus(
                              business_user_request.id,
                              e.target.value
                            )
                          }
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option
                            value="accepted"
                            style={{
                              background: "rgba(0,255,0,0.15)",
                              fontSize: "16px"
                            }}
                          >
                          Accepted
                          </option>
                          <option
                            value="declined"
                            style={{
                              background: "rgba(255,0,0,0.15)",
                              fontSize: "16px"
                            }}
                          >
                            Declined
                          </option>
                        </select>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Content>
    );
  }
}
