import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const fuel_url = API.apiEndpoint + API.fuel;

export default class FuelForm extends React.Component {
  state = {
    page: {},
    action: "add",
    fuel_type: { en: "", ar: "" },
    price: "",
    unit: { en: "", ar: "" },
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
  };

  async componentDidMount() {
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        fuel_type: this.props.page.fuel_type,
        price: this.props.page.price,
        unit: this.props.page.unit,
        page: this.props.page,
      });
    }
  }

  // componentDidMount() {
  //   if (this.props.action === "edit")
  //     this.setState({
  //       action: this.props.action,
  //       fuel_type: this.props.page.fuel_type,
  //       price: this.props.page.price,
  //       unit: this.props.page.unit,
  //       page: this.props.page
  //     });
  // }

  priceChange = (event) => {
    this.setState({ price: event.target.value });
  };

  // typeChange = event => {
  //   this.setState({ fuel_type: event.target.value });
  // };

  typeChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let fuel_type = Object.assign({}, prevState.fuel_type); // creating copy of state variable tip
      fuel_type[lang] = value; // update the name property, assign a new value
      return { fuel_type }; // return new object tip object
    });
    return true;
  };

  unitChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let unit = Object.assign({}, prevState.unit); // creating copy of state variable tip
      unit[lang] = value; // update the name property, assign a new value
      return { unit }; // return new object tip object
    });
    return true;
  };

  // unitChange = event => {
  //   this.setState({ unit: event.target.value });
  // };

  saveFuel = async (event) => {
    const formData = new FormData();
    let error = false;
    if (!this.state.fuel_type.en) {
      error = true;
      this.setState({
        error: true,
        message: "Fuel Type English is required",
      });
    }
    if (!this.state.fuel_type.ar) {
      error = true;
      this.setState({
        error: true,
        message: "Fuel Type Arabic is required",
      });
    }
    if (!this.state.price.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Price is required",
      });
    }
    if (!this.state.unit.en) {
      error = true;
      this.setState({
        error: true,
        message: "Unit English is required",
      });
    }
    if (!this.state.unit.ar) {
      error = true;
      this.setState({
        error: true,
        message: "Unit Arabic is required",
      });
    }
    if (error) {
      return;
    }
    formData.append("fuel_type", JSON.stringify(this.state.fuel_type));
    formData.append("price", this.state.price);
    formData.append("unit", JSON.stringify(this.state.unit));

    let api_response = {};
    let options = { url: fuel_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      options = {
        url: fuel_url + "/" + this.state.page.id,
        form_data: formData,
      };
      api_response = await ApiRequest.put(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/fuel_prices";
        }.bind(this),
        10000
      );
    }
  };

  // saveFuel = async event => {
  //   const formData = new FormData();
  //   formData.append("fuel_type", this.state.fuel_type);
  //   formData.append("price", this.state.price);
  //   formData.append("unit", this.state.unit);
  //   let response = {};
  //   if (this.state.action === "add") {
  //     response = await axios.post(fuel_url, formData);
  //   } else {
  //     response = await axios.put(fuel_url + "/" + this.state.page.id, formData);
  //   }

  //   if (response.status === 200) {
  //     window.location = "/fuel_prices";
  //   }
  // };

  backFuel = () => {
    window.location = "/fuel_prices";
  };

  render() {
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="Fuel Prices" browserTitle="Fuel Prices">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Fuel Prices Form </h3>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="fuel_type">Fuel Type English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fuel_type"
                        placeholder="Fuel Type English "
                        value={this.state.fuel_type.en}
                        onChange={this.typeChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="fuel_type">Fuel Type Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fuel_type"
                        placeholder="Fuel Type Arabic"
                        value={this.state.fuel_type.ar}
                        onChange={this.typeChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="price">Fuel Price</label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        placeholder="Fuel Price"
                        value={this.state.price}
                        onChange={this.priceChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="unit">Unit English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        placeholder="Unit English"
                        value={this.state.unit.en}
                        onChange={this.unitChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="unit">Unit</label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        placeholder="Unit Arabic"
                        value={this.state.unit.ar}
                        onChange={this.unitChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveFuel}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.props.handleClick.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
