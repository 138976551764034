import React, { Component } from "react";

import { Content, Row, Col } from "adminlte-2-react";
import Pagination from "react-js-pagination";

import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import { confirmAlert } from "react-confirm-alert";
require("bootstrap/less/bootstrap.less");

const carPlateLetters = API.apiEndpoint + API.carPlateLetters;

export default class CarPlateLetters extends Component {
	state = {
		data: [],
		search: "",
		activePage: 1,
		error: false,
		success: false,
		message: "",
		success_message: "",
		loading: false,
		limit: 20,
		callApi: false,
		enableBtn: false,
		pagination: {},
	};

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData(pageNumber = 1, limit = 20, search = null) {
		this.setState({ loading: true });
		let url = carPlateLetters + "?page=" + pageNumber + "&limit=" + limit;

		if (this.state.search) {
			url =
				url +
				"&search=" +
				(search !== null ? search : this.state.search);
		}

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { results, pagination } = response.data;
			this.setState({
				loading: false,
				error: false,
				callApi: true,
				data: results,
				pagination,
			});
		}
	}

	handleSubmit = async () => {
		await this.getReportData();
	};

	handlePageChange = async (pageNumber) => {
		await this.getReportData(pageNumber);
	};

	handleSearchClear = async () => {
		this.setState({
			search: "",
			enableBtn: false,
			limit: 20,
		});
		await this.getReportData(1, 20, new Date(), new Date(), "", "");
	};

	handleSearch = async (event) => {
		this.setState({
			search: event.target.value,
		});
	};

	noResultText() {
		return "N/A";
	}

	deleteCarPlateLetter = async (val) => {
		confirmAlert({
			title: "Delete Car Plate Letter",
			message: "Are you sure you want to delete Car Plate Letter?",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.confirmDeleteCarPlateLetter(val),
				},
				{
					label: "No",
					onClick: () => {},
				},
			],
		});
	};

	confirmDeleteCarPlateLetter = async (val) => {
		let api_response = {};
		let options = {
			url: carPlateLetters + "/" + val,
		};

		api_response = await ApiRequest.delete(options);

		if (api_response.error) {
			this.setState({
				loading: false,
				error: true,
				message: api_response.message,
			});
		} else {
			this.setState({
				success: true,
				loading: false,
				success_message: api_response.message,
			});
			await this.getReportData();
		}
	};

	render() {
		const {
			data,
			loading,
			error,
			message,
			success,
			success_message,
			callApi,
			pagination,
			limit,
		} = this.state;
		return (
			<Content title="Car Plate Letters" browserTitle="Car Plate Letters">
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<Col xs={12}>
						<div className="box box-primary">
							<FilterForm
								handleSearchClear={this.handleSearchClear}
								search={this.state.search}
								handleSearch={this.handleSearch}
								handleSubmit={this.handleSubmit}
							/>
							<Table
								handlePageChange={this.handlePageChange}
								pagination={pagination}
								loading={loading}
								limit={limit}
								callApi={callApi}
								data={data}
								deleteCarPlateLetter={this.deleteCarPlateLetter}
							/>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}

class FilterForm extends Component {
	render() {
		return (
			<div className="row clearfix" style={{ padding: "10px" }}>
				<div className="box-header with-border box-table-cus">
					<h3 className="box-title">Car Plate Letters</h3>
					<div className="box-tools">
						{ApiRequest.can_access("admin-user", "can_add") && (
							<a
								class="btn btn-primary mr-5"
								href="/car_plate_letters_create"
							>
								Create
							</a>
						)}
					</div>
				</div>
				<div className="col-xs-4">
					<div className="form-group">
						<label>Search by Car Plate Letter</label>
						<input
							type="search"
							class="form-control input-md mr-5 ml-5"
							value={this.props.search}
							onChange={this.props.handleSearch}
							placeholder=""
						/>
					</div>
				</div>
				<div
					className="text-right col-xs-12"
					style={{
						display: "flex",
						gap: "1em",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<button
						onClick={this.props.handleSubmit}
						className="btn btn-primary cusSearchBtn"
					>
						Submit
					</button>
					<button
						className="btn btn-primary cusSearchBtn"
						onClick={this.props.handleSearchClear}
					>
						Clear
					</button>
				</div>
			</div>
		);
	}
}

class Table extends Component {
	rescue_value(val) {
		return val === undefined || val === null || val.length <= 0 ? "-" : val;
	}
	render() {
		return (
			<div className="box-body">
				<div className="table-responsive">
					{this.props.loading ? (
						<LoadingSpinner />
					) : (
						<div>
							<table
								className="table no-margin"
								id="export-table-inventory"
							>
								<thead>
									<tr>
										<th>Id</th>
										<th>Name</th>
										<th>NameAr</th>
										<th>Date</th>
										<th>Time</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{this.props.data &&
									this.props.data.length > 0 ? (
										this.props.data.map((data) => {
											return (
												<tr key={data._id}>
													<td>
														{this.rescue_value(
															data._id
														)}
													</td>
													<td>
														{this.rescue_value(
															data.name
														)}
													</td>
													<td>
														{this.rescue_value(
															data.nameAr
														)}
													</td>
													<td>
														{ApiRequest.date_format(
															data.createdAt
														)}
													</td>
													<td>
														{ApiRequest.time_format(
															data.createdAt
														)}
													</td>
													<td>
														{ApiRequest.can_access(
															"admin-user",
															"can_edit"
														) && (
															<a
																class="btn btn-primary btn-xs mr-5"
																href={
																	"/car_plate_letters/" +
																	data._id
																}
															>
																<i class="fa fa-edit" />
															</a>
														)}
														{ApiRequest.can_access(
															"admin-user",
															"can_delete"
														) && (
															<button
																className="btn btn-primary btn-xs mr-5"
																onClick={this.props.deleteCarPlateLetter.bind(
																	null,
																	data._id
																)}
															>
																<i className="fa fa-trash" />
															</button>
														)}
													</td>
												</tr>
											);
										})
									) : (
										<td colSpan="10" className="noRecord">
											{this.props.callApi
												? "No Record Found"
												: "Please Select Filter"}
										</td>
									)}
								</tbody>
							</table>
							{this.props.pagination.size > 0 && (
								<div className="tableFooter">
									<div>
										<div className="dataTables_info">
											Showing{" "}
											{(this.props.pagination
												.currentPage -
												1) *
												this.props.limit +
												1}{" "}
											to{" "}
											{this.props.pagination.currentPage *
												this.props.limit}{" "}
											of {this.props.pagination.size}{" "}
											entries
										</div>
									</div>
									<div style={{ textAlign: "right" }}>
										<Pagination
											prevPageText={"<"}
											nextPageText={">"}
											activePage={
												this.props.pagination
													.currentPage
											}
											itemsCountPerPage={
												this.props.pagination.limit
											}
											totalItemsCount={
												this.props.pagination.size
											}
											pageRangeDisplayed={5}
											onChange={
												this.props.handlePageChange
											}
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
