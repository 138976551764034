import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const stations_url = API.apiEndpoint + API.stationLocator;

export default class StationListing extends React.Component {
  state = {
    stations: [],
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let options = { url: stations_url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        stations: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  // async componentDidMount() {
  //   const response = await axios.get(stations_url);
  //   if (response.status === 200) {
  //     const data = response.data.data;
  //     this.setState({ stations: data, loading: false });
  //   }
  // }

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  render() {
    const columns = [
      {
        title: "ID",
        data: "id",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Unique Identifier",
        data: "unique_identifier",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Name",
        data: "name",
        render: (name) => {
          return typeof name === "object" ? name.en : this.rescue_value(name);
        },
      },
      {
        title: "Region",
        data: "region",
        render: (region) => {
          return typeof region === "object"
            ? region.name.en
            : this.rescue_value(region);
        },
      },
      {
        title: "Working Hours",
        data: "workingHours",
        render: (workingHours) => {
          return typeof workingHours === "object"
            ? workingHours.opening + " -  " + workingHours.closing
            : workingHours;
        },
      },
      // {
      //   // title: "Reviewed At",
      //   // data: "updatedAt",
      //   // render: updatedAt =>
      //   //   new Date(updatedAt).getDate() +
      //   //   " - " +
      //   //   new Date(updatedAt).getMonth() +
      //   //   " - " +
      //   //   new Date(updatedAt).getFullYear()
      // },
      {
        title: "Avg. Rating",
        data: "average_rating",
        render: (average_rating) => {
          switch (average_rating) {
            case 0:
              return (
                <span className="icon-spacing">
                  <i className="fa fa-lg fa-meh-o"> </i>
                  <i className="fa fa-lg fa-smile-o" />
                  <i className="fa fa-lg fa-frown-o"> </i>
                </span>
              );
            case 2:
              return (
                <span className="icon-spacing">
                  <i className="fa fa-lg fa-meh-o icon-filled"> </i>
                  <i className="fa fa-lg fa-smile-o" />
                  <i className="fa fa-lg fa-frown-o"> </i>
                </span>
              );
            case 3:
              return (
                <span className="icon-spacing">
                  <i className="fa fa-lg fa-meh-o"> </i>
                  <i className="fa fa-lg fa-smile-o icon-filled" />
                  <i className="fa fa-lg fa-frown-o"> </i>
                </span>
              );
            case 1:
              return (
                <span className="icon-spacing">
                  <i className="fa fa-lg fa-meh-o"> </i>
                  <i className="fa fa-lg fa-smile-o" />
                  <i className="fa fa-lg fa-frown-o icon-filled"> </i>
                </span>
              );
            default:
              return <span>No rating </span>;
          }
        },
      },
    ];

    if (ApiRequest.can_access("station", "can_view_single")) {
      columns.push({
        title: "Actions",
        data: "unique_identifier",
        sortable: false,
        render: (id) => (
          <a class="btn btn-primary btn-xs" href={"/stations/" + id}>
            {" "}
            <i class="fa fa-eye" />{" "}
          </a>
        ),
      });
    }

    const { loading, error, message } = this.state;
    return (
      <Content title="Stations" browserTitle="Stations">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Stations</h3>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="Station Locator"
                    selectableRows={false}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.stations}
                    paginationPerPage={3}
                    onClickEvents={{
                      clickable: (data, rowIdx, rowData) => {
                        this.props.viewStation(data, "view");
                      },
                    }}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
