import React from "react";
import WhiteListView from "./view";
import WhiteListListing from "./listing";
import BasmaForm from "./form";
import axios from "axios";
import API from "./../../constants";
const card_detail_url = API.apiEndpoint + API.getCard;

export default class WhiteList extends React.Component {
  state = {
    action: "listing",
    card: {}
  };

  async componentDidMount() {
    if (this.props.view) {
      const response = await axios.get(
        card_detail_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewWhiteList(response.data.data, 'view');
      }
    }
  }

  viewWhiteList = (whiteList, action) => {
    this.setState({ action: action, card: whiteList });
  };

  render() {
    const { action, card } = this.state;
    if (action === "view") return <WhiteListView card={card} />;
    if (action === "edit" || action === "add")
      return (
        <BasmaForm
          action={action}
          viewWhiteList={this.viewWhiteList}
          card={card}
        />
      );
    else return <WhiteListListing viewWhiteList={this.viewWhiteList} />;
  }
}
