import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "./../../ApiRequest";
import API from "./../../constants";
import LoadingSpinner from "./../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";

//import { CSVLink } from "react-csv";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const completeTransaction_url = API.apiEndpoint + API.completeThawaniTransaction;
const verifyTransaction_url = API.apiEndpoint + API.verifyThawaniTransaction;
const getTransaction_url = API.apiEndpoint + "/loyaltypartnertopuprequest";
const retryTransaction_url = API.apiEndpoint + API.retry_3p_transaction_url;


const StatusList = {
  received: "Received",
  processing: "Processing",
  completed: "Completed",
  validation_error: "Validation error",
  internal_error: "Internal Error",
  rps_error: "RPS error"
}

export default class EPaymentTransaction extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    date_from: "",
    date_to: "",
    amount_from: "",
    amount_to: "",
    serial_number_from: "",
    serial_number_to: "",
    oomco_id: "",
    partner_name: "",
    epaymentTransaction: [],
    stats: [],
    activePage: 1,
    error: false,
    success: false,
    message: "",
    success_message: "",
    loading: false,
    limit: 20,
    callApi: false,
    enableBtn: false,
    search_clear: true,
    status: "",
    pagination: {},
  };

  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });
    let url = getTransaction_url + "?page=" + pageNumber;

    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.partner_name) {
      url = url + "&partner_name=" + this.state.partner_name;
    }

    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message,
      });
    } else {
      const { results, pagination, ...stats } = response.data

      this.setState({
        stats,
        epaymentTransaction: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = getTransaction_url + "?csv=" + 1;
    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.partner_name) {
      url = url + "&partner_name=" + this.state.partner_name;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = {
      url: url,
      file_name:
        "Partner topup requests report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      enableBtn: true,
      // pagination: {},
      limit: 10,
    });
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      date_from: new Date(),
      date_to: new Date(),
      amount_from: "",
      amount_to: "",
      serial_number_from: "",
      serial_number_to: "",
      oomco_id: "",
      partner_name: "",
      status: "",
    });
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }
  completeTransaction = async (transactionId) => {
    confirmAlert({
      title: "Complete Transaction ",
      message: "Are you sure you want to complete this transaction?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmCompleteTransaction(transactionId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmCompleteTransaction = async (transactionId) => {
    const formData = new FormData();
    formData.append("paymentId", transactionId);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(completeTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              let currentPage = window.location.href;
              window.location = currentPage;
            }.bind(this),
            100
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
          message: error.response.data
            ? error.response.data.message
            : error.response.message,
        });
      });
  };

  verifyTransaction = async (transactionId) => {
    const formData = new FormData();
    formData.append("paymentId", transactionId);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(verifyTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
            }.bind(this),
            5000
          );
        }
      })
      .catch((error) => {
        console.log("error here ======", error)
        this.setState({
          error: true,
          loading: false,
          message: error.response && error.response.data
            ? error.response.data.message 
            : error.message
        });
      });
  }
  noResultText() {
    return "N/A";
  }

  retryTransaction = async (id, onClose) => {
    const formData = new FormData();
    formData.append("transaction_id", id);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(retryTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          onClose();
          setTimeout(
            function () {
              this.setState({ success: false });
              this.getReportData();
            }.bind(this),
            3000
          );
        }
      })
      .catch((error) => {
        console.log("error here ======", error);
        onClose();
        this.setState({
          error: true,
          loading: false,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : error.message,
        });
        setTimeout(() => {
          this.getReportData();
        }, 5000);
      });
  };

  confirmRetry = async (val, event) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>Confirm Retry</h1>Are you sure you want to retry this transaction?
            <div className="react-confirm-alert-button-group">
              <button 
                label="Yes" 
                onClick={() => this.retryTransaction(val, onClose)}
                >Yes</button>
              <button 
                label="No" 
                onClick={() => onClose()}>No</button>
            </div>
          </div>
        );
      },
    });
  };

  async componentDidMount(){
    await this.getReportData()
  }
  render() {
    const { Select2 } = Inputs;
    const {
      epaymentTransaction,
      stats,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
      success,
      success_message
    } = this.state;
    return (
      <Content
        title="Partner topup requests"
        browserTitle="Partner topup requests"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_from}
                      selectsStart
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_to}
                      selectsEnd
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Amount{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="amount_from"
                      value={this.state.amount_from}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      name="amount_to"
                      value={this.state.amount_to}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Card Number{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="serial_number_from"
                      value={this.state.serial_number_from}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Serial Number From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      name="serial_number_to"
                      value={this.state.serial_number_to}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Serial Number To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>OOMCO ID</label>
                    <input
                      type="text"
                      name="oomco_id"
                      value={this.state.oomco_id}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Enter oomco id"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Partner name</label>
                    <input
                      type="text"
                      name="partner_name"
                      value={this.state.partner_name}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Enter partner name"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={this.state.status}
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      { Object.keys(StatusList)
                        .map(key => <option key={key} value={key}>{StatusList[key]}</option>)}
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  {/*<button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                  >
                    Clear
                  </button>*/}
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
             
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Partner</th>
                            <th>User</th>
                            <th>Card Number</th>
                            <th>Points redeemed</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Time</th>
                            <th>Transaction ID</th>
                            {
                              ApiRequest.can_access("reports", "can_view_epayment_transaction_report") && (
                                <th>Actions</th>
                              )
                            }
                          </tr>
                        </thead>

                        <tbody>
                          {epaymentTransaction.length > 0 &&
                          epaymentTransaction ? (
                            epaymentTransaction.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value( data.id)}</td>
                                  <td>{this.rescue_value(data.requestData.params.partner_name)}</td>
                                  <td> 
                                      <span>
                                        {data.userId ? <a target="_blank" href={"/app_view/" + data.userId} >
                                            {data.requestData.params.oomco_id ? data.requestData.params.oomco_id : "-"}
                                          </a>
                                         : <p> {data.requestData.params.oomco_id ? data.requestData.params.oomco_id : "-"} </p>}
                                      </span>
                                  </td>
                                  <td>{this.rescue_value(data.cardNumber || data.requestData.params.card_number)}</td>
                                  <td>{this.rescue_value(data.requestData.params.points_redeemed)}</td>
                                  <td>{this.rescue_value(data.requestData.params.amount)}</td>
                                  <td>
				                            {this.rescue_value(StatusList[data.status] || data.status)} 
                                  </td>
                                  <td>
                                    {ApiRequest.date_format(data.createdAt)} {ApiRequest.time_format(data.createdAt)}
                                  </td>
                                  <td>
				                            {data?.requestData?.params?.Trx_ID ? 
                                    this.rescue_value(data?.requestData?.params?.Trx_ID) 
                                    : data?.rps_response?.data?.rps_transaction_id ?
                                    this.rescue_value(data?.rps_response?.data?.rps_transaction_id)
                                    : "-"} 
                                  </td>
                                  {
                                    ApiRequest.can_access("reports", "can_view_epayment_transaction_report") && (
                                      <td>
                                        {
                                          data.status === "rps_error" && (
                                            <button
                                            className="btn btn-primary btn-xs"
                                            onClick={this.confirmRetry.bind(
                                              null,
                                              data.id
                                            )}
                                          >
                                            {"Retry"}
                                          </button>
                                          )
                                        }
                                      </td>
                                    )
                                  }
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="10" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}


