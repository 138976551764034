import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import moment from "moment";
import Select from "react-select";
import { QuestionCompositeModel } from "survey-react";

const fuel_voucher_form_url = API.apiEndpoint + API.FuelVoucherPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;

export default class FuelVoucherForm extends React.Component {
  state = {
    page: {},
    action: "add",
    fuel_voucher_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    start: "",
    end: "",
    topupPromotions: [],
    selectedOption: null,
  };

  async componentDidMount() {
    let options = { url: tierTargetList_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let topupPromotions = [];
      api_response.data.map((store) => {
        topupPromotions.push({
          value: store.CardTypeName,
          label: store.CardTypeName,
        });
      });
      this.setState({
        topupPromotions: topupPromotions,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  promotionTypeChange = (event) => {
    var option = $("option:selected", event.target).attr("data-name");
    this.setState({ promotionType: event.target.value, gameName: option });
  };
  handleTierChange = (selectedTierOption) => {
    this.setState({ tierSelected: selectedTierOption });
  };
  voucherSlot1ValueChange = (event) => {
    this.setState({ slots1: event.target.value });
  };
  voucherSlot2ValueChange = (event) => {
    this.setState({ slots2: event.target.value });
  };
  voucherSlot3ValueChange = (event) => {
    this.setState({ slotsValue: event.target.value });
  };
  voucherSlot1PointChange = (event) => {
    this.setState({ slots1Point: event.target.value });
  };
  voucherSlot2PointChange = (event) => {
    this.setState({ slots2Point: event.target.value });
  };
  voucherSlot3PointChange = (event) => {
    this.setState({ slots: event.target.value });
  };

  fuelVoucherStart = async (date) => {
    this.setState({
      fuel_voucher_date_from: date,
      enableBtn: true,
    });
  };
  fuelVoucherEnd = async (date) => {
    this.setState({
      fuel_voucher_date_to: date,
      enableBtn: true,
    });
  };
  saveFuelVoucher = async (event) => {
    let error = false;

    var voucherLen = $(".voucher-group").find("input").length;
    var Voucher = [];
    if (voucherLen > 0) {
      for (var i = 0; i < voucherLen; i++) {
        var amount = $(".voucher-group").find("input.amount").eq(i).val();
        var points = $(".voucher-group").find("input.point").eq(i).val();
        if (typeof amount == "undefined") continue;

        Voucher.push({ amount: amount, points: points });
      }
    }

    if (!this.state.fuel_voucher_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }
    if (!this.state.fuel_voucher_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.tierName) {
      error = true;
      this.setState({
        error: true,
        message: "Name value is required",
      });
      return;
    }
    if (!this.state.tierSelected) {
      error = true;
      this.setState({
        error: true,
        message: "Tier is required",
      });
      return;
    }
    if (Voucher.length < 3) {
      error = true;
      this.setState({
        error: true,
        message: "Slot is required",
      });
      return;
    }

    var tierPost = [];
    this.state.tierSelected.map((store) => {
      tierPost.push(store.value);
    });
    var moment = require("moment");
    const formData = new FormData();

    formData.append(
      "from",
      moment
        .utc(this.state.fuel_voucher_date_from)
        .add(1, "days")
        .startOf("day")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.fuel_voucher_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );

    formData.append("tiers", JSON.stringify(tierPost));
    formData.append("slots", JSON.stringify(Voucher));
    formData.append("name", this.state.tierName);
    //formData.append("type", this.state.promotionType);
    formData.append("type", "Fuel Voucher");

    let api_response = {};
    let options = { url: fuel_voucher_form_url, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/fuel_voucher";
        }.bind(this),
        10000
      );
    }
  };

  tierNameChange = (event) => {
    this.setState({ tierName: event.target.value });
  };

  backUser = () => {
    window.location = "/fuel_voucher";
  };

  render() {
    let {
      error,
      message,
      success,
      success_message,
      topupPromotions,
      selectedTierOption,
    } = this.state;
    return (
      <Content title="Fuel Voucher" browserTitle="Fuel Voucher">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Fuel Voucher Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="loyaltyGameId">Promotion Type</label>
                    {/*<select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Select Game ID </option>
                      {fuel_voucher_list &&
                        fuel_voucher_list.map((item, key) => {
                          return (
                            <option
                              value={item.GameId}
                              data-name={item.GameName}
                            >
                              {ApiRequest.string_humanize_format(item.GameName)}
                            </option>
                          );
                        })}
                      </select>*/}
                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Fuel Voucher </option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.fuel_voucher_date_from}
                    selectsStart
                    minDate={moment().toDate()}
                    fuel_voucher_date_from={this.state.fuel_voucher_date_from}
                    fuel_voucher_date_to={this.state.fuel_voucher_date_to}
                    onChange={this.fuelVoucherStart}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.fuel_voucher_date_to}
                    selectsEnd
                    fuel_voucher_date_from={this.state.fuel_voucher_date_from}
                    fuel_voucher_date_to={this.state.fuel_voucher_date_to}
                    onChange={this.fuelVoucherEnd}
                    minDate={this.state.fuel_voucher_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.tierName}
                      onChange={this.tierNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Tier Target</label>
                    <Select
                      value={selectedTierOption}
                      onChange={this.handleTierChange}
                      options={topupPromotions}
                      isMulti
                      isSearchable
                    />
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="col-md-6 voucher-group ">
                    <label htmlFor="tierTarget">Voucher</label>

                    <div className="form-group form-group-custom">
                      <label htmlFor="tierTarget">Slot 1</label>

                      <div className="form-control form-group-custom mr-5 w-45">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput amount"
                          placeholder="Amount"
                          value={this.state.slots1}
                          onChange={this.voucherSlot1ValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>

                      <input
                        type="number"
                        min="0"
                        className="form-control w-45 point"
                        placeholder="Points"
                        value={this.state.slots1Point}
                        onChange={this.voucherSlot1PointChange}
                      />
                    </div>
                    <div className="form-group form-group-custom">
                      <label htmlFor="tierTarget">Slot 2</label>

                      <div className="form-control form-group-custom mr-5 w-45">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput amount"
                          placeholder="Amount"
                          value={this.state.slots2}
                          onChange={this.voucherSlot2ValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>

                      <input
                        type="number"
                        min="0"
                        className="form-control w-45 point"
                        placeholder="Points"
                        value={this.state.slots2Point}
                        onChange={this.voucherSlot2PointChange}
                      />
                    </div>
                    <div className="form-group form-group-custom">
                      <label htmlFor="tierTarget">Slot 3</label>

                      <div className="form-control form-group-custom mr-5 w-45">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput amount"
                          placeholder="Amount"
                          value={this.state.slotsValue}
                          onChange={this.voucherSlot3ValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>

                      <input
                        type="number"
                        min="0"
                        className="form-control w-45 point"
                        placeholder="Points"
                        value={this.state.slots}
                        onChange={this.voucherSlot3PointChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveFuelVoucher}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
