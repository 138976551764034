import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";

const stampCard_url = API.apiEndpoint + API.stampCard;
const stampcardDelete_url = API.apiEndpoint + API.stampcardDelete;

export default class StampCard extends React.Component {
  state = {
    badge_management: [],
    loading: true,
    error: false,
    message: "",

    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        stampCard_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });

    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        badge_management: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  deleteStampcard = async (val, event) => {
    confirmAlert({
      title: "Stamp Card",
      message: "Are you sure you want to delete Stamp Card?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmdeleteStampcard(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmdeleteStampcard = async (val, event) => {
    const formData = new FormData();
    formData.append("status", "1");

    let api_response = {};
    let options = {
      url: stampcardDelete_url + "/" + val,
      form_data: formData,
    };

    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/stamp_card";
        }.bind(this),
        100
      );
      // sessionStorage.clear();
    }
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value == 1 ? "Inactive" : "Active";
  }
  rescue_limit(value) {
    return value === 1 ? "One Time " : "Repeated ";
  }

  render() {
    let {
      badge_management,
      loading,
      error,
      message,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;
    return (
      <Content title={"Stamp Card"} browserTitle="Stamp Card">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Stamp Card </h3>
                <div className="box-tools">
                    {ApiRequest.can_access(
                        "super-admin", "can_view"
                        ) && (
                        <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/stamp_card_add/"}
                      >
                      Add Stamp Card
                        </a>
                    )}
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    <div className="cusSearch">
                      Search{" "}
                      <select
                        onChange={this.handleSearchChange}
                        value={search_select_value}
                        className="form-control input-sm mr-5 ml-5"
                      >
                        <option value="">Select Search by </option>
                        <option value={"title"}>Title</option>
                      </select>
                      <input
                        type="search"
                        class="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                      <button
                        className="btn btn-primary cusSearchBtn"
                        onClick={this.handleSearchClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            <th>Start</th>
                            <th>Expiry</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {badge_management.length > 0 ? (
                            badge_management.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.title)}</td>
                                  <td>{this.rescue_value(data.type_source)}</td>
                                  <td>{this.rescue_status(data.status)}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.from_date)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.to_date)}
                                  </td>

                                  <td>
                                    {/*<a
                                      class="btn btn-primary btn-xs mr-5"
                                      href={
                                        "/badges_statistics_view/" + data.id
                                      }
                                    >
                                      <i class="fa fa-eye" />
                                    </a>*/}

                                    {ApiRequest.can_access("super-admin", "can_view") && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/stamp_card_edit/" + data.id}
                                      >
                                        <i class="fa fa-edit" />
                                      </a>
                                    )}
                                    <button
                                      className="btn btn-primary btn-xs mr-5"
                                      onClick={this.deleteStampcard.bind(
                                        null,
                                        data.id
                                      )}
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

