import React from "react";
import BasmaManagementView from "./view";
import BasmaManagementListing from "./listing";

export default class BasmaManagement extends React.Component {
  state = {
    action: "listing",
    basma: {}
  };

  viewBasma = basma => {
    this.setState({ action: "view", basma: basma });
  };

  render() {
    const { action, basma } = this.state;

    if (action === "view") return <BasmaManagementView basma={basma} />;
    else return <BasmaManagementListing viewBasma={this.viewBasma} />;
  }
}
