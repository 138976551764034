import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
const appUser_url = API.apiEndpoint + API.appUser;

export default class AppUserForm extends React.Component {
  state = {
    page: {},
    action: "add",
    name: "",
    email_address: "",
    status: ""
  };

  componentDidMount() {
    if (this.props.action === "edit")
      this.setState({
        action: this.props.action,
        name: this.props.page.name,
        email_address: this.props.page.email_address,
        status: this.props.page.status,
        page: this.props.page
      });
  }

  nameChange = event => {
    this.setState({ name: event.target.value });
  };

  emailChange = event => {
    this.setState({ email_address: event.target.value });
  };

  statusChange = event => {
    this.setState({ status: event.target.value });
  };

  saveUser = async event => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email_address", this.state.email_address);
    formData.append("status", this.state.status);
    let response = {};
    if (this.state.action === "add") {
      response = await axios.post(appUser_url, formData);
    } else {
      response = await axios.put(
        appUser_url + "/" + this.state.page.id,
        formData
      );
    }

    if (response.status === 200) {
      setTimeout(function() {
        window.location = "/app_user";
      }, 10000);
    }
  };

  backUser = () => {
    window.location = "/app_user";
  };

  render() {
    return (
      <Content title="User Page" browserTitle="User Page">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">User Form </h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.nameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Email address"
                    value={this.state.email_address}
                    onChange={this.emailChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <input
                    type="text"
                    className="form-control"
                    id="status"
                    placeholder="Status"
                    value={this.state.status}
                    onChange={this.statusChange}
                  />
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveUser}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
