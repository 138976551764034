import React, { Component } from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "./../../components/LoadingSpinner";
import "./Listing.css";
import LoadingAjax from "../../components/Loading";

const cms_url = API.apiEndpoint + API.get_all_cms;
export default class CmsListing extends Component {
  state = {
    cms_list: [],
    loading: true,
    error: false,
    message: ""
  };

  async componentDidMount() {
    let options = { url: cms_url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        cms_list: api_response.data,
        loading: false,
        error: false,
        message: api_response.message
      });
    }
  }

  // async componentDidMount() {
  //   const response = await axios.get(cms_url);
  //   if (response.status === 200) {
  //     const data = response.data;
  //     this.setState({ cms_list: data.data, loading: false });
  //   }
  // }

  render() {
    const columns = [
      {
        title: "ID",
        data: "id"
      },
      {
        title: "Name",
        data: "name"
      },
      {
        title: "Alias",
        data: "alias"
      }
    ];

    if (ApiRequest.can_access("cms-pages", "can_edit_single")) {
      columns.push({
        title: "Actions",
        data: null,
        sortable: false,
        render: () => (
          <button class="btn btn-primary btn-xs clickable">
            <i class="fa fa-edit" />
          </button>
        )
      });
    }

    const records = this.state.cms_list;
    const { loading, error, message } = this.state;

    return (
      <Content title="CMS Pages" browserTitle="CMS Pages">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">CMS Pages</h3>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="News Integration"
                    selectableRows={false}
                    records={records}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.cms_list}
                    paginationPerPage={3}
                    onClickEvents={{
                      clickable: (data, rowIdx, rowData) => {
                        this.props.handleClick(data, "edit");
                      }
                    }}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
