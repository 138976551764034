import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import DatePicker from "react-datepicker";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import LoadingAjax from "../../components/Loading";
require("bootstrap/less/bootstrap.less");

const storeTrans_url = API.apiEndpoint + API.reportStoreTrans;
const store_url = API.apiEndpoint + API.store;
const storeTransType_url = API.apiEndpoint + API.storeTransType;
const cardTransactionsTopupMethods_url =
  API.apiEndpoint + API.cardTransactionsTopupMethods;
const storeTypeWithAmount_url = API.apiEndpoint + API.storeTypeWithAmount;

export default class RefuelingTransaction extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    store_code: "",
    transaction_type: "",
    payment_method: "",
    transaction_date_from: "",
    transaction_date_to: "",
    amount_from: "",
    amount_to: "",
    storesTrans: [],
    stores: [],
    store_trans_type: [],
    card_trans_method: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    show_payment_method: false,
    show_amount_range: false,
    show_payment_options: [],
    callApi: false,
    enableBtn: false
  };

  handleChangeStoreName = event => {
    this.setState({
      store_code: event.target.value,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  handleByTransactionType = event => {
    let show_payment = false;
    if (this.state.show_payment_options.includes(event.target.value)) {
      show_payment = true;
    }
    this.setState({
      transaction_type: event.target.value,
      show_amount_range: show_payment,
      show_payment_method: show_payment,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  handleTopupMethod = event => {
    this.setState({
      payment_method: event.target.value,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  byDateStart = date => {
    this.setState({
      transaction_date_from: date,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  byDateEnd = date => {
    this.setState({
      transaction_date_to: date,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  handleChangeAmountFrom = event => {
    this.setState({
      amount_from: event.target.value,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };
  handleChangeAmountTo = event => {
    this.setState({
      amount_to: event.target.value,
      enableBtn: true,
      storesTrans: [],
      pagination: {},
      limit: 10
    });
  };

  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });

    let url = storeTrans_url + "?page=" + pageNumber;

    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }
    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;
    }
    if (this.state.payment_method) {
      url = url + "&payment_method=" + this.state.payment_method;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message
      });
    } else {
      this.setState({
        storesTrans: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true
      });
    }
  };
  handleSubmit = async event => {
    await this.getReportData();
  };
  handlePageChange = async pageNumber => {
    await this.getReportData(pageNumber);
  };

  async componentDidMount() {
    let options = { url: storeTrans_url };
    let optionsStore = { url: store_url };
    let optionsTopupMethods = { url: cardTransactionsTopupMethods_url };
    let optionsStoreTrans = { url: storeTransType_url };
    let optionsStoreTypeAmount = { url: storeTypeWithAmount_url };

    let store_response = await ApiRequest.get(optionsStore);
    let topupMethod_response = await ApiRequest.get(optionsTopupMethods);
    let storeTransType_response = await ApiRequest.get(optionsStoreTrans);
    let storeTypeAmount_response = await ApiRequest.get(optionsStoreTypeAmount);

    // let options = { url: store_url };
    // let optionsCity = { url: city_url };

    // let api_response = await ApiRequest.get(options);
    // let city_response = await ApiRequest.get(optionsCity);

    if (store_response.error || storeTransType_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: store_response.message
      });
    } else {
      let stores = [];
      store_response.data.map(store => {
        stores.push({ value: store.store_code, label: store.store_name });
      });

      this.setState({
        store_trans_type: storeTransType_response.data,
        show_payment_options: storeTypeAmount_response.data
      });

      let card_trans_method = [];
      topupMethod_response.data.map(tansMethod => {
        card_trans_method.push({ label: tansMethod, value: tansMethod });
      });

      this.setState({
        stores: stores,
        store_trans_type: storeTransType_response.data,
        card_trans_method: card_trans_method,
        loading: false,
        error: false,
        message: store_response.message
      });

      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          storesTrans: api_response.data.results,
          pagination: api_response.data.pagination
        });
      }
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : ApiRequest.string_humanize_format(value);
  }

  downloadData = async event => {
    let url = storeTrans_url + "?csv=" + 1;

    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
    }
    if (this.state.transaction_type) {
      url = url + "&transaction_type=" + this.state.transaction_type;
    }
    if (this.state.payment_method) {
      url = url + "&payment_method=" + this.state.payment_method;
    }
    if (this.state.transaction_date_from) {
      url = url + "&transaction_date_from=" + this.state.transaction_date_from;
    }
    if (this.state.transaction_date_to) {
      url = url + "&transaction_date_to=" + this.state.transaction_date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }

    let options = {
      url: url,
      file_name:
        "Store Transaction Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv"
    };

    await ApiRequest.download(options);
  };

  render() {
    const { Select2 } = Inputs;
    const {
      storesTrans,
      stores,
      pagination,
      store_trans_type,
      card_trans_method,
      loading,
      error,
      limit,
      message,
      enableBtn,
      show_payment_method,
      show_amount_range,
      transaction_type,
      callApi
    } = this.state;
    return (
      <Content title="Store Transaction" browserTitle="Store Transaction">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                    placeholder="Select"
                  />
                </div>

                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Transaction Type"
                    className="form-control"
                    value={this.state.transaction_type}
                    onChange={this.handleByTransactionType}
                    options={store_trans_type}
                    placeholder="Select"
                  />
                </div>

                {show_payment_method && (
                  <div className="col-xs-4 cusSelect">
                    <Select2
                      label="Payment Method"
                      className="form-control"
                      value={this.state.payment_method}
                      onChange={this.handleTopupMethod}
                      options={card_trans_method}
                      placeholder="Select"
                    />
                  </div>
                )}
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      selected={this.state.transaction_date_from}
                      selectsStart
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      selected={this.state.transaction_date_to}
                      selectsEnd
                      transaction_date_from={this.state.transaction_date_from}
                      transaction_date_to={this.state.transaction_date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.transaction_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                {show_amount_range && (
                  <div className="col-xs-4">
                    <div className="form-group rangeFilter">
                      <label
                        style={{
                          display: "block"
                        }}
                      >
                        Amount{" "}
                      </label>
                      <input
                        type="number"
                        min="0"
                        value={this.state.amount_from}
                        onChange={this.handleChangeAmountFrom}
                        className="form-control"
                        placeholder="From"
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0"
                        }}
                      />
                      <input
                        type="number"
                        min="0"
                        value={this.state.amount_to}
                        onChange={this.handleChangeAmountTo}
                        className="form-control"
                        placeholder="To"
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0"
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        class="table no-margin"
                        id="export-table-store-trans"
                      >
                        <thead>
                          <tr>
                            <th>Store Code</th>
                            <th>Transaction Type</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {storesTrans.length > 0 && storesTrans ? (
                            storesTrans.map(data => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>
                                    {transaction_type ===
                                    "collect_loyalty_points"
                                      ? this.rescue_value(data.method_name)
                                      : this.rescue_value(data.operation)}
                                  </td>
                                  <td>
                                    {transaction_type ===
                                    "collect_loyalty_points"
                                      ? this.rescue_value(data.total_amount)
                                      : this.rescue_value(data.topup_amount)}
                                  </td>
                                  <td>
                                    {transaction_type ===
                                    "collect_loyalty_points"
                                      ? this.rescue_value(data.payment_method)
                                      : this.rescue_value(data.topup_method)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="6" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
