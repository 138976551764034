import React, { Component } from "react";

import { Content, Row, Col } from "adminlte-2-react";

import LoadingAjax from "../../components/Loading";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const single_reconciliation_trx = API.apiEndpoint + API.reconciliation_trx_url;

export default class ReconciliationViews extends Component {
	state = {
		data: [],
		status: "",
		error: false,
		success: false,
		message: "",
		success_message: "",
		loading: false,
		callApi: false,
	};

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData() {
		this.setState({ loading: true });
		let url =
			single_reconciliation_trx +
			"/single" +
			"/" +
			this.props.match.params.id;

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { transaction } = response.data;
			this.setState({
				loading: false,
				error: false,
				callApi: true,
				data: transaction,
			});
		}
	}

	noResultText() {
		return "N/A";
	}

	rescue_value(val) {
		return val === undefined || val === null || val.length <= 0 ? "-" : val;
	}

	renderStatus(status) {
		if (status === "uncompleted" || status === "") {
			return (
				<span
					style={{
						background: "rgba(255,191,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					{status || "N/A"}
				</span>
			);
		} else if (status === "completed" || status === "Success") {
			return (
				<span
					style={{
						background: "rgba(0,255,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					{status}
				</span>
			);
		} else if (status === "failed" || status === "Failed") {
			return (
				<span
					style={{
						background: "rgba(255,0,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					{status}
				</span>
			);
		}
	}

	renderDiscrepancy(discrepancy) {
		const discrepancyMap = {
			conflict: "conflict",
			"status-amount-mismatch": "Amount & Status Mismatch",
			"amount-mismatch": "Amount Mismatch",
			"status-mismatch": "Status Mismatch",
			"no-discrepancy": "No discrepancy",
		};
		return discrepancyMap[discrepancy] || "N/A";
	}
	render() {
		const { data, error, message, success, success_message } = this.state;
		return (
			<Content
				title="Reconciliation Transaction Details"
				browserTitle="Reconciliation Transaction Details"
			>
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<Col xs={12}>
						{/* info */}
						<div className="box box-primary">
							<div className="box-header with-border">
								<h3 className="box-title">
									Reconciliation Transaction Details
								</h3>
							</div>
							<div className="box-body">
								<li className="list-group-item">
									<b>Transaction ID</b>{" "}
									<span className="pull-right">
										{this.rescue_value(data.id)}
									</span>
								</li>
								<li className="list-group-item">
									<b>Discrepancy</b>{" "}
									<span className="pull-right">
										{this.renderDiscrepancy(
											data.discrepancy
										)}
									</span>
								</li>
								<li className="list-group-item">
									<b>Status</b>{" "}
									<span className="pull-right">
										{this.renderStatus(data.status)}
									</span>
								</li>
							</div>
						</div>

						{/* transaction status */}
						<div className="box box-primary">
							<div className="box-header with-border">
								<h3 className="box-title">
									Transaction Status
								</h3>
							</div>
							<div className="box-body">
								<li className="list-group-item">
									<b>Thawani Transaction Status</b>{" "}
									<span className="pull-right">
										{this.renderStatus(data.thawaniStatus)}
									</span>
								</li>
								{/* <li className="list-group-item">
									<b>RPS Transaction Status</b>{" "}
									<span className="pull-right">
										{this.renderStatus(data.RPSStatus)}
									</span>
								</li> */}
								<li className="list-group-item">
									<b>OOMCO Transaction Status</b>{" "}
									<span className="pull-right">
										{this.renderStatus(data.OOMCOStatus)}
									</span>
								</li>
							</div>
						</div>

						{/* thawani details internal */}
						{data.thawaniTransaction ? (
							<div className="box box-primary">
								<div className="box-header with-border">
									<h3 className="box-title">
										Thawani Transaction Internal
									</h3>
								</div>
								<div className="box-body">
									<li className="list-group-item">
										<b>Transaction ID</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.id
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Status</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.status
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Amount</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.amount
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Created At</b>
										<span className="pull-right">
											{ApiRequest.date_format(
												data.thawaniTransaction
													?.createdAt
											)}{" "}
											{" - "}
											{ApiRequest.time_format(
												data.thawaniTransaction
													?.createdAt
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Reference ID</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.refId
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Operation ID</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction
													?.operationId
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Type</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.type
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Card Number</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction
													?.cardNumber
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>User</b>
										<span className="pull-right">
											{this.rescue_value(
												data.thawaniTransaction?.user
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Updated At</b>
										<span className="pull-right">
											{ApiRequest.date_format(
												data.thawaniTransaction
													?.updatedAt
											)}{" "}
											{" - "}
											{ApiRequest.time_format(
												data.thawaniTransaction
													?.updatedAt
											)}
										</span>
									</li>
								</div>
							</div>
						) : null}

						{/* thawani details External */}
						{data.external ? (
							<div className="box box-primary">
								<div className="box-header with-border">
									<h3 className="box-title">
										Thawani Transaction External
									</h3>
								</div>
								<div className="box-body">
									<li className="list-group-item">
										<b>Transaction ID</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Id
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Status</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Status
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Amount</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Amount
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Date</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Date
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Invoice</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Invoice
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Transaction Type</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.TransactionType
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Reference Id</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.ClientReferenceId
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>RRN</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.RRN
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Last 4</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Last4
											)}
										</span>
									</li>
									<li className="list-group-item">
										<b>Refunded</b>
										<span className="pull-right">
											{this.rescue_value(
												data.external?.Refunded
											)}
										</span>
									</li>
								</div>
							</div>
						) : null}
					</Col>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}
