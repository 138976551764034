import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import DatePicker from "react-datepicker";
import { dateToMinutesSinceMidnight, minutesSinceMidnightToDate } from "../../helpers";
//import LoadingSpinner from "../../components/LoadingSpinner";
const moment = require("moment-timezone");

const self_service_nozzles_form_url = API.apiEndpoint + API.selfServiceNozzles;
const stations_url = API.apiEndpoint + API.store;

export default class SelfServiceNozzlesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      action: this.props.edit ? "edit" : "add",
      PumpNr: "",
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      timeEnd: null,
      stores_list: [],
      store: "",
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let options = { url: stations_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        stores_list: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
    if (this.state.action === "edit") {
      let url = self_service_nozzles_form_url + "/" + this.props.match.params.id;
      let options = { url: url };
      let api_response = await ApiRequest.get(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        let data = api_response.data;
        this.setState({
          ...this.state,
          PumpNr: data.PumpNr,
          store: data.store_code,
          dateStart: moment(data.dateStart).tz("Asia/Muscat").toDate(),
          dateEnd: moment(data.dateEnd).tz("Asia/Muscat").toDate(),
          timeStart: minutesSinceMidnightToDate(data.timeStart),
          timeEnd: minutesSinceMidnightToDate(data.timeEnd),
          loading: false,
          error: false,
          message: api_response.message,
        });
      }
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  PumpNrChange = (event) => {
    this.setState({ PumpNr: event.target.value });
  };

  storeChange = (event) => {
    this.setState({ store: event.target.value });
  };

  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  partnerCodeChange = (event) => {
    this.setState({ partner_code: event.target.value });
  };

  dateStartChange = (date) => {
    const utcDate = moment(date)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate();
    this.setState({ dateStart: utcDate });
  };

  dateEndChange = (date) => {
    const utcDate = moment(date)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate();
    this.setState({ dateEnd: utcDate });
  };

  timeStartChange = (date) => {
    this.setState({ timeStart: date });
  };

  timeEndChange = (date) => {
    this.setState({ timeEnd: date });
  };

  saveSelfServiceNozzle = async (event) => {
    const formData = new FormData();
    formData.append("PumpNr", this.state.PumpNr);

    const adjustedDateStart = moment(this.state.dateStart)
      .tz("Asia/Muscat")
      .startOf("day") // This ensures the time is set to midnight
      .toISOString(); // ISO string format for backend compatibility
    formData.append("dateStart", adjustedDateStart);

    const adjustedDateEnd = moment(this.state.dateEnd)
      .tz("Asia/Muscat")
      .startOf("day") // Similarly, setting time to midnight
      .toISOString(); // ISO string format for backend compatibility
    formData.append("dateEnd", adjustedDateEnd);

    formData.append("timeStart", dateToMinutesSinceMidnight(this.state.timeStart));
    formData.append("timeEnd", dateToMinutesSinceMidnight(this.state.timeEnd));
    formData.append("store_code", this.state.store);
    formData.append("addedBy", localStorage.getItem("user_id"));

    let api_response = {};
    let options = { url: self_service_nozzles_form_url, form_data: formData };
    if (this.state.action === "edit") {
      options.url = self_service_nozzles_form_url + "/" + this.props.match.params.id;
      api_response = await ApiRequest.put(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/self_service_nozzles";
          }.bind(this),
          3000
        );
      }
    } else {
      api_response = await ApiRequest.post(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/self_service_nozzles";
          }.bind(this),
          3000
        );
      }
    }
  };

  BackToSelfServiceNozzlesList = () => {
    window.location = "/self_service_nozzles";
  };

  render() {
    const { stores_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content
        title={`${this.state.action === "edit" ? "Edit" : "Add"} Self Service Nozzle`}
        browserTitle={`${this.state.action === "edit" ? "Edit" : "Add"} Self Service Nozzle`}>
        <LoadingAjax />
        <div className="row">
          {error && (
            <div class="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div class="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Self Service Nozzle Form</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="PumpNr">Pump Number (CPUNr)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="PumpNr"
                    placeholder="Pump Number"
                    value={this.state.PumpNr}
                    onChange={this.PumpNrChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="role">Station</label>
                  <select className="form-control" id="role" value={this.state.store} onChange={this.storeChange}>
                    <option>Select Station</option>
                    {stores_list &&
                      stores_list.map((e, i) => {
                        return <option value={e.store_code}>{`${e.store_name} - (${e.store_code})`}</option>;
                      })}
                  </select>
                </div>
                <div className="form-group rangeFilter">
                  <label>Date Range</label>
                  <div style={{ display: "flex", gap: "3em", justifyContent: "center", alignItems: "center" }}>
                    <DatePicker
                      selected={this.state.dateStart}
                      onChange={this.dateStartChange}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={this.state.dateEnd}
                      onChange={this.dateEndChange}
                      minDate={this.state.dateStart}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="form-group rangeFilter">
                  <label>Time Range</label>
                  <div style={{ display: "flex", gap: "3em", justifyContent: "center", alignItems: "center" }}>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                      selected={this.state.timeStart}
                      onChange={this.timeStartChange}
                      placeholderText="From"
                    />
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                      selected={this.state.timeEnd}
                      onChange={this.timeEndChange}
                      placeholderText="To"
                      minTime={this.state.timeStart ? new Date(this.state.timeStart.getTime() + 15 * 60000) : new Date().setHours(0, 0, 0, 0)}
                      maxTime={new Date().setHours(23, 45, 0, 0)}
                    />
                  </div>
                </div>
              </div>

              <div className="box-footer clearfix no-border">
                <button onClick={this.saveSelfServiceNozzle} className="btn btn-primary pull-right ">
                  {this.state.action === "edit" ? "Save" : "Submit"}
                </button>
                <button onClick={this.BackToSelfServiceNozzlesList} className="btn btn-default pull-right mr-10">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
