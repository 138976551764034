import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import isEmpty from "lodash.isempty";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import { Inputs } from "adminlte-2-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./card.css";
const cards_url = API.apiEndpoint + API.basmaCards;
const cardOperation_url = API.apiEndpoint + API.cardOperation;
const fuelReward_url = API.apiEndpoint + API.fuelReward;
const refundTransaction_url = API.apiEndpoint + API.refundThawaniTransaction;

export default class Card extends React.Component {
  state = {
    card: {},
    user: {},
    transactions: [],
    card_status: 0,
    error: false,
    success: false,
    message: "",
    success_message: "",
    loading: true,
    fuelReward: [],
    fuel_reward_id: "",
  };

  async fetchData() {
    let options = { url: cards_url + "/" + this.props.match.params.id };
    let optionsFuelReward = { url: fuelReward_url };

    let api_response = await ApiRequest.get(options);
    let fuelReward_response = await ApiRequest.get(optionsFuelReward);
    if (api_response.error || fuelReward_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let fuelReward = [];
      fuelReward_response.data.map((fuel) => {
        fuelReward.push({ value: fuel.id, label: fuel.title.en });
      });

      this.setState({
        fuelReward: fuelReward,
        card: api_response.data,
        transactions: api_response.data.transactions,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  rescue_value(value) {
    return value === 0 ? "Inactive" : "Active";
  }
  handleCardStatus = async (val, event) => {
    confirmAlert({
      title: val ? "Activate Card" : "Deactivate Card",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmCardStatus(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmCardStatus = async (val) => {
    let method_name = val ? "activate_deactivated_card" : "card_deactivate";
    document.getElementById("waiting_screen").style.display = "block";
    const formData = new FormData();
    //formData.append("card_number", val);
    formData.append("car_plate", this.state.card.car_plate);
    formData.append("request_from", API.request_from);
    formData.append("mobile_number", this.state.card.user.mobile_no);
    formData.append("country_code", this.state.card.user.country_code);
    formData.append("transaction_id", new Date().getTime());
    formData.append("method_name", method_name);
    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        document.getElementById("waiting_screen").style.display = "none";
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
            }.bind(this),
            10000
          );
          let card = this.state.card;
          card.activate = val;
          this.setState({ card: card });
        }
      })
      .catch((error) => {
        document.getElementById("waiting_screen").style.display = "none";
        this.setState({
          error: true,
          message: error.response.data.message
            ? error.response.data.message
            : error.response.message,
        });
        setTimeout(
          function () {
            this.setState({ error: false });
          }.bind(this),
          10000
        );
      });
  };

  vehicleCancel = async (val) => {
    confirmAlert({
      title: "Cancel Vehicle ",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmVehicleCancel(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmVehicleCancel = async () => {
    const formData = new FormData();
    //formData.append("card_number", val);
    formData.append("car_plate", this.state.card.car_plate);
    formData.append("request_from", API.request_from);
    formData.append("transaction_id", new Date().getTime());
    formData.append("method_name", "cancel_vehicle");

    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              let currentPage = window.location.href;
              window.location = currentPage;
            }.bind(this),
            10000
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          message: error.response.data.message
            ? error.response.data.message
            : error.response.message,
        });
      });
  };

  handleChangeFuelReward = async (selectedOption) => {
    this.setState({
      fuel_reward_id: selectedOption.target.value,
    });
  };
  fuelReward = async (val) => {
    confirmAlert({
      title: "Fuel Reward ",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmFuelReward(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmFuelReward = async () => {
    const formData = new FormData();
    formData.append("card_number", this.state.card.card_number);
    formData.append("fuel_reward_id", this.state.fuel_reward_id);
    formData.append("request_from", API.request_from);
    formData.append("transaction_id", new Date().getTime());
    formData.append("method_name", "redeem_fuel_by_card_number");

    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              // let currentPage = window.location.href;
              // window.location = currentPage;
            }.bind(this),
            10000
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          message: error.response.data.message
            ? error.response.data.message
            : error.response.message,
        });
      });
  };

  refundTransaction = async (card_transaction_log_id, refundTicketNumber, refundReason, onClose) => {
    const formData = new FormData();
    formData.append("card_transaction_log_id", card_transaction_log_id);
    formData.append("reason", `Refund Ticket: ${refundTicketNumber} | Refund Reason: ${refundReason}`);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(refundTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          onClose();
          setTimeout(
            function () {
              this.setState({ success: false });
              this.fetchData();
            }.bind(this),
            3000
          );
        }
      })
      .catch((error) => {
        console.log("error here ======", error);
        onClose();
        this.setState({
          error: true,
          loading: false,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : error.message,
        });
        setTimeout(() => {
          this.fetchData();
        }, 5000);
      });
  };

  confirmRefundTransaction = async (val, event) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>Refund Transaction</h1>Are you sure you want to refund this
            transaction?
            <div style={{marginTop: "1em"}}>Ticket Number:</div>
            <input
              className="form-control"
              style={{textAlign: "start"}}
              onChange={(e) => {
                this.setState({ refundTicketNumber: e.target.value })
              }}
              required
            />
            <div style={{ marginTop: "1em"}}>Reason:</div>
            <textarea
              className="form-control"
              style={{textAlign: "start"}}
              onChange={(e) => {
                this.setState({ refundReason: e.target.value })
              }}
              required
            ></textarea>
            <div className="react-confirm-alert-button-group">
              <button 
                label="Yes" 
                onClick={() => this.refundTransaction(val, this.state.refundTicketNumber, this.state.refundReason, onClose)}
                >Yes</button>
              <button 
                label="No" 
                onClick={() => onClose()}>No</button>
            </div>
          </div>
        );
      },
    });
  };

  renderReasonPopup = (remarks) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let reasons = remarks.split(" | ");
        return (
          <div className="react-confirm-alert-body">
            <h2>Transaction Remarks</h2>
            {reasons.map((reason) => {
              const title = reason.split(': ')[0];
              const description = reason.split(': ')[1];
              return (
                <div style={{ marginTop: "2em", display: "flex", justifyContent: "start", alignItems: "center", gap: "1em"}}>
                  <span style={{fontWeight: "bold"}}>{title} :</span>
                  <span>{description}</span>
                </div>
              )
            })}
            <div className="react-confirm-alert-button-group">
              <button label="Close" onClick={() => onClose()}>
                Close
              </button>
            </div>
          </div>
        );
      },
    });
  };

  renderReason = (remarks) => {
    return (
      <button
        type="button"
        className="btn btn-primary btn-xs"
        onClick={() => this.renderReasonPopup(remarks)}
      >
        Show Reason
      </button>
    );
  };

  render() {
    const { Select2 } = Inputs;
    let {
      card,
      transactions,
      loading,
      error,
      success,
      success_message,
      message,
      fuelReward,
    } = this.state;

    return (
      <Content title={"Cards Detail"} browserTitle="Cards Detail">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <Col xs={6}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                  <span className="pull-right">
                    <a
                      class="btn btn-primary btn-xs mr-5"
                      href={"/update_vehicle/" + card.id}
                    >
                      {"Update Vehicle"}
                    </a>
                    <button
                      className="btn btn-primary btn-xs  mr-5"
                      onClick={this.vehicleCancel.bind(null, card.card_number)}
                    >
                      {"Cancel Vehicle"}
                    </button>
                    <a
                      class="btn btn-primary btn-xs mr-5"
                      href={"/card_top_up/" + card.id}
                    >
                      {"Top Up"}
                    </a>
                    <a
                      class="btn btn-primary btn-xs mr-5"
                      href={"/card_top_up_reverse/" + card.id}
                    >
                      {"Deduct amount "}
                    </a>
                  </span>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    {/*<li className="list-group-item">
                    <b>Id</b> <span className="pull-right">{card.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Active Request Store</b>{" "}
                    <span className="pull-right">
                      {card.active_request_store}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <b>Store Id</b>{" "}
                    <span className="pull-right">{card.store_code}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Assigned</b>{" "}
                    <span className="pull-right">
                      {this.rescue_value(card.assigned)}
                    </span>
                  </li>*/}
                    <li className="list-group-item">
                      <b>Card Serial Number</b>{" "}
                      <span className="pull-right">{card.card_number} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Card Number </b>{" "}
                      <span className="pull-right">{card.serial_number}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Card Balance</b>{" "}
                      <span className="pull-right">{card.card_balance}</span>
                    </li>
                    <li className="list-group-item">
                      <b>OOMCO ID</b>{" "}
                      <span className="pull-right">{card.oomco_id}</span>
                    </li>
                    {/*<li className="list-group-item">
                    <b>Purchased</b>{" "}
                    <span className="pull-right">
                      {this.rescue_value(card.purchased)}
                    </span>
                </li>*/}
                    <li className="list-group-item">
                      <b>Replaced</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card.replaced)}
                      </span>
                    </li>
                    {/*<li className="list-group-item">
                        <b>White list</b>{" "}
                        <span className="pull-right">
                          {this.rescue_value(card.white_list)}
                        </span>
                    </li>*/}
                    <li className="list-group-item">
                      <b>Card Status</b>{" "}
                      {/*<span className="pull-right">
                        {this.rescue_value(card.activate)}
                      </span>*/}
                      <span className="pull-right ">
                        {card.activate ? (
                          <button
                            className="mr-10 btn btn-primary btn-xs"
                            onClick={this.handleCardStatus.bind(null, 0)}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            className="mr-10 btn btn-primary btn-xs"
                            value="1"
                            onClick={this.handleCardStatus.bind(null, 1)}
                          >
                            Activate
                          </button>
                        )}{" "}
                        {this.rescue_value(card.activate)}
                      </span>
                    </li>

                    {/*<li className="list-group-item">
                      <b>Deactivate</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(card.deactivate)}
                      </span>
                    </li>*/}
                    <li className="list-group-item">
                      <b>Car Number</b>{" "}
                      <span className="pull-right">{card.car_plate}</span>
                    </li>
                    {/*<li className="list-group-item">
                    <b>Created At</b>{" "}
                    <span className="pull-right">
                      {new Date(card.createdAt).getDate() +
                        " - " +
                        (new Date(card.createdAt).getMonth() + 1) +
                        " - " +
                        new Date(card.createdAt).getFullYear()}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <b>Updated At</b>{" "}
                    <span className="pull-right">
                      {new Date(card.updatedAt).getDate() +
                        " - " +
                        (new Date(card.updatedAt).getMonth() + 1) +
                        " - " +
                        new Date(card.updatedAt).getFullYear()}
                    </span>
                      </li>*/}
                  </ul>
                </div>
              </div>
            </Col>
            {!isEmpty(card.user) && (
              <Col xs={6}>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">User Information</h3>
                  </div>
                  <div className="box-body">
                    <ul className="list-group list-group-unbordered">
                      <li className="list-group-item">
                        <b>Name</b>{" "}
                        <span className="pull-right"> {card.user.name}</span>
                      </li>
                      <li className="list-group-item">
                        <b>Phone Number</b>{" "}
                        <span className="pull-right">
                          {" "}
                          {card.user.country_code + " " + card.user.mobile_no}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <b>Address</b>{" "}
                        <span className="pull-right"> {card.user.address}</span>
                      </li>
                      <li className="list-group-item">
                        <b>Email Address</b>{" "}
                        <span className="pull-right">
                          {" "}
                          {card.user.email_address}
                        </span>
                      </li>
                      {/*<li className="list-group-item">
                        <b>DOB</b>{" "}
                        <span className="pull-right"> {card.user.dob}</span>
            </li>*/}
                      <li className="list-group-item">
                        <b>Civil Number</b>{" "}
                        <span className="pull-right">
                          {" "}
                          {card.user.civil_number}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <b>Status</b>{" "}
                        <span className="pull-right">
                          {this.rescue_value(card.user.status)}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            )}
            <Col xs={6}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Fuel Vouchers</h3>
                </div>
                <div className="box-body">
                  <div className=" cusSelect">
                    <Select2
                      label="Fuel Vouchers"
                      className="form-control"
                      value={this.state.selectedOption}
                      onChange={this.handleChangeFuelReward}
                      options={fuelReward}
                      placeholder="Select"
                    />
                  </div>
                  <button
                    class="btn btn-primary btn-xs"
                    style={{ marginTop: "10px", float: "right" }}
                    onClick={this.fuelReward.bind(null, card.card_number)}
                  >
                    Fuel Vouchers
                  </button>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Transactions</h3>
                </div>
                <div className="box-body">
                  <table className="table no-margin">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Operation</th>
                        <th>Request From</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Topup Reason</th>
                        <th>By Admin</th>
                        <th>Created At</th>
                        <th>Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((trans) => (
                        <tr>
                          {" "}
                          <td>{trans.id}</td>
                          <td>{trans.operation}</td>
                          <td>{trans.request_from}</td>
                          <td>{trans.topup_amount}</td>
                          <td>{trans.topup_method}</td>
                          <td>
                            {trans.remarks
                              ? this.renderReason(trans.remarks)
                              : "-"}
                          </td>
                          <td>{trans.admin_email ? trans.admin_email : "-"}</td>
                          <td>{ApiRequest.date_format(trans.createdAt)}</td>
                          <td>{ApiRequest.time_format(trans.createdAt)}</td>
                          <td>
                            {trans.operation === "Top-Up" ? (
                              !trans?.refund_status ||
                              trans?.refund_status === "none" ? (
                                <button
                                  className="btn btn-primary btn-xs"
                                  onClick={this.confirmRefundTransaction.bind(
                                    null,
                                    trans.id
                                  )}
                                >
                                  {"Refund"}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-xs"
                                  onClick={() => {}}
                                  disabled
                                >
                                  {trans.refund_status === "thawani_refunded"
                                    ? "Thawani Refunded & RPS Pending"
                                    : trans.refund_status ===
                                      "thawani_refunded_rps_refund_error"
                                    ? "Thawani Refunded & RPS Error"
                                    : trans.refund_status ===
                                      "thawani_refunded_rps_refunded"
                                    ? "Thawani Refunded & RPS Refunded"
                                    : "-"}
                                </button>
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
