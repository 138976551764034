import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import DataTable2 from "./components/DataTable2";
import DataTable3 from "./components/DataTable3";
import CKEditor2 from "./components/CkEditor";
import TagsInput2 from "./components/TagsInput";
import GoogleMap from "./components/Map";

export default class Listing extends React.Component {
  render() {
    return (
      <Content title="Components" browserTitle="Components">
        <Row>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-envelope-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Default Subject</span>
                <span className="info-box-number">200</span>
              </div>

              {this.props.content}
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-green">
                <i className="fa fa-flag-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Bookmarks</span>
                <span className="info-box-number">200</span>
              </div>

              {this.props.content}
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-yellow">
                <i className="fa fa-files-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Uploads</span>
                <span className="info-box-number">200</span>
              </div>

              {this.props.content}
            </div>
          </Col>
          <Col className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-red">
                <i className="fa fa-star-o" />
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Likes</span>
                <span className="info-box-number">93,139</span>
              </div>

              {this.props.content}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-md-8">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Latest Orders</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-box-tool" data-widget="collapse">
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-box-tool" data-widget="remove">
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table no-margin">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Item</th>
                        <th>Status</th>
                        <th>Popularity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR9842</a>
                        </td>
                        <td>Call of Duty IV</td>
                        <td>
                          <span className="label label-success">Shipped</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#00a65a"
                            data-height="20"
                          >
                            90,80,90,-70,61,-83,63
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR1848</a>
                        </td>
                        <td>Samsung Smart TV</td>
                        <td>
                          <span className="label label-warning">Pending</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#f39c12"
                            data-height="20"
                          >
                            90,80,-90,70,61,-83,68
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR7429</a>
                        </td>
                        <td>iPhone 6 Plus</td>
                        <td>
                          <span className="label label-danger">Delivered</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#f56954"
                            data-height="20"
                          >
                            90,-80,90,70,-61,83,63
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR7429</a>
                        </td>
                        <td>Samsung Smart TV</td>
                        <td>
                          <span className="label label-info">Processing</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#00c0ef"
                            data-height="20"
                          >
                            90,80,-90,70,-61,83,63
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR1848</a>
                        </td>
                        <td>Samsung Smart TV</td>
                        <td>
                          <span className="label label-warning">Pending</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#f39c12"
                            data-height="20"
                          >
                            90,80,-90,70,61,-83,68
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR7429</a>
                        </td>
                        <td>iPhone 6 Plus</td>
                        <td>
                          <span className="label label-danger">Delivered</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#f56954"
                            data-height="20"
                          >
                            90,-80,90,70,-61,83,63
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="pages/examples/invoice.html">OR9842</a>
                        </td>
                        <td>Call of Duty IV</td>
                        <td>
                          <span className="label label-success">Shipped</span>
                        </td>
                        <td>
                          <div
                            className="sparkbar"
                            data-color="#00a65a"
                            data-height="20"
                          >
                            90,80,90,-70,61,-83,63
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="box-footer clearfix">
                <a href="/" className="btn btn-sm btn-info btn-flat pull-left">
                  Place New Order
                </a>
                <a
                  href="/"
                  className="btn btn-sm btn-default btn-flat pull-right"
                >
                  View All Orders
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box box-default">
              <div className="box-header with-border">
                <h3 className="box-title">Browser Usage</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-box-tool" data-widget="collapse">
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-box-tool" data-widget="remove">
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-8">
                    <div className="chart-responsive">
                      <canvas id="pieChart" height="150" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ul className="chart-legend clearfix">
                      <li>
                        <i className="fa fa-circle-o text-red" /> Chrome
                      </li>
                      <li>
                        <i className="fa fa-circle-o text-green" /> IE
                      </li>
                      <li>
                        <i className="fa fa-circle-o text-yellow" /> FireFox
                      </li>
                      <li>
                        <i className="fa fa-circle-o text-aqua" /> Safari
                      </li>
                      <li>
                        <i className="fa fa-circle-o text-light-blue" /> Opera
                      </li>
                      <li>
                        <i className="fa fa-circle-o text-gray" /> Navigator
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="box-footer no-padding">
                <ul className="nav nav-pills nav-stacked">
                  <li>
                    <a href="/">
                      United States of America{" "}
                      <span className="pull-right text-red">
                        <i className="fa fa-angle-down" /> 12%
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      India{" "}
                      <span className="pull-right text-green">
                        <i className="fa fa-angle-up" /> 4%
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      China{" "}
                      <span className="pull-right text-yellow">
                        <i className="fa fa-angle-left" /> 0%
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Visitors Report</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-box-tool" data-widget="collapse">
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-box-tool" data-widget="remove">
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="box-body no-padding">
                <DataTable2 />
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div className="col-md-12">
            <div className="box-body no-padding">
              <DataTable3 />
            </div>
          </div>
        </Row>

        <Row>
          <div className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Quick Example</h3>
              </div>
              <form>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">File input</label>
                    <input type="file" id="exampleInputFile" />

                    <p className="help-block">
                      Example block-level help text here.
                    </p>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" /> Check me out
                    </label>
                  </div>
                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <button
              type="button"
              className="btn btn-default"
              data-toggle="modal"
              data-target="#modal-default"
            >
              Launch Default Modal
            </button>

            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title">Default Modal</h4>
                  </div>
                  <div className="modal-body">
                    <p>One fine body&hellip;</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default pull-left"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <TagsInput2 />
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="box box-primary">
              <CKEditor2 />
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Google Map</h3>
              </div>
              <div className="box-body">
                <GoogleMap />
              </div>
            </div>
          </div>
        </Row>
      </Content>
    );
  }
}
