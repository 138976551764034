import React, { Component } from "react";
import PropTypes from "prop-types";
import { Content, Row, Col, Box } from "adminlte-2-react";
import GoogleMap from "../../components/GoogleMap";
import isEmpty from "lodash.isempty";
import CONFIG from "../../constants";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";

import "./station_loyalty_distribution.css";
//import { text } from "@fortawesome/fontawesome-svg-core";
const icon_url = API.apiEndpoint + API.icon;

const getInfoWindowString = station => `
    <div>
      <div style="font-size: 16px;">
        ${station.name.en}
      </div>
    </div>`;

export default class StationLoyaltyDistributionView extends Component {
  static propTypes = {
    station: PropTypes.object.isRequired
  };

  handleApiLoaded = (map, maps, station) => {
    const markers = [];
    const infowindows = [];
    markers.push(
      new maps.Marker({
        position: {
          lat: station.location.coordinates[1],
          lng: station.location.coordinates[0]
        },
        map
      })
    );

    infowindows.push(
      new maps.InfoWindow({
        content: getInfoWindowString(station)
      })
    );

    markers.forEach((marker, i) => {
      marker.addListener("click", () => {
        infowindows[i].open(map, marker);
      });
    });
  };

  average_ranting_view = average_rating => {
    switch (average_rating) {
      case 0:
        return (
          <span className="icon-spacing">
            <i className="fa fa-lg fa-meh-o"> </i>
            <i className="fa fa-lg fa-smile-o" />
            <i className="fa fa-lg fa-frown-o"> </i>
          </span>
        );
      case 2:
        return (
          <span className="icon-spacing">
            <i className="fa fa-lg fa-meh-o icon-filled"> </i>
            <i className="fa fa-lg fa-smile-o" />
            <i className="fa fa-lg fa-frown-o"> </i>
          </span>
        );
      case 3:
        return (
          <span className="icon-spacing">
            <i className="fa fa-lg fa-meh-o"> </i>
            <i className="fa fa-lg fa-smile-o icon-filled" />
            <i className="fa fa-lg fa-frown-o"> </i>
          </span>
        );
      case 1:
        return (
          <span className="icon-spacing">
            <i className="fa fa-lg fa-meh-o"> </i>
            <i className="fa fa-lg fa-smile-o" />
            <i className="fa fa-lg fa-frown-o icon-filled"> </i>
          </span>
        );
      default:
        return <span>No rating </span>;
    }
  };

  render() {
    const station = this.props.station;

    return (
      <Content title={"Station - " + station.name.en} browserTitle="Station">
        <LoadingAjax />
        <Row>
          <Col xs={6}>
            <Col>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">View </h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Station ID</b>{" "}
                      <span className="pull-right">{station.id}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Unique Identifier </b>{" "}
                      <span className="pull-right">
                        {station.unique_identifier}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Station Name</b>{" "}
                      <span className="pull-right">{station.name.en}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Short Business Name</b>{" "}
                      <span className="pull-right">{station.short_name}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Business Name</b>{" "}
                      <span className="pull-right">
                        {station.business_name}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Main Business Phone</b>{" "}
                      <span className="pull-right">{station.phone_number}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Business Email Address</b>{" "}
                      <span className="pull-right">{station.email}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Station Region</b>{" "}
                      {typeof station.region === "object" && (
                        <span className="pull-right">
                          {station.region.name.en}{" "}
                        </span>
                      )}
                      {typeof station.region === "string" && (
                        <span className="pull-right">{station.region} </span>
                      )}
                    </li>
                    <li className="list-group-item">
                      <b>Working Hours </b>{" "}
                      {typeof station.workingHours === "object" && (
                        <span className="pull-right">
                          <b>Opening</b>: {station.workingHours.opening}
                          <b> Closing</b> : {station.workingHours.closing}
                        </span>
                      )}
                      {typeof station.workingHours === "string" && (
                        <span className="pull-right">
                          <b> {station.workingHours} </b>
                        </span>
                      )}
                    </li>
                    <li className="list-group-item">
                      <b>Reviewed At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(station.updatedAt)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Facebook URL</b>{" "}
                      <span className="pull-right">{station.facebook_url}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Twitter URL</b>{" "}
                      <span className="pull-right">{station.twitter_url}</span>
                    </li>

                    <li className="list-group-item">
                      <b>LinkedIn URL</b>{" "}
                      <span className="pull-right">{station.linkedin_url}</span>
                    </li>

                    <li className="list-group-item">
                      <b>Instagram URL</b>{" "}
                      <span className="pull-right">
                        {station.instagram_url}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Station Map </h3>
                </div>
                <div className="box-body ">
                  <div className="map-spacing">
                    {!isEmpty(station.location.coordinates) && (
                      <GoogleMap
                        defaultZoom={5}
                        defaultCenter={CONFIG.MapCenter}
                        bootstrapURLKeys={{ key: CONFIG.GoogleMapKey }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          this.handleApiLoaded(map, maps, station)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            {station.car_care_services && (
              <Col>
                <div className="box box-primary">
                  <div className="box-header with-border ">
                    <h3 className="box-title">Car Care Services</h3>
                  </div>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin station-service">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Icon</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station.car_care_services.map((row, i) => (
                            <tr key={i}>
                              <td>{row.unique_identifier}</td>
                              <td>{row.name.en}</td>
                              <td>
                                <div>
                                  <img
                                    src={
                                      icon_url +
                                      row.unique_identifier +
                                      "_black.png"
                                    }
                                    alt="images"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {station.products && (
              <Col>
                <Box title="Products">
                  <div className="table-responsive">
                    <table className="table no-margin station-service">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Icon</th>
                        </tr>
                      </thead>
                      <tbody>
                        {station.products.map((row, i) => (
                          <tr key={i}>
                            <td>{row.unique_identifier}</td>
                            <td>{row.name.en}</td>
                            <td>
                              <div>
                                <img
                                  src={
                                    icon_url +
                                    row.unique_identifier +
                                    "_black.png"
                                  }
                                  alt="images"
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Box>
              </Col>
            )}
          </Col>
          <Col xs={6}>
            {station.qsr && (
              <Col>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Brands / QSR </h3>
                  </div>
                  <div className="box-body ">
                    <div className="table-responsive">
                      <table className="table no-margin station-service">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Icon</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station.qsr.map((row, i) => (
                            <tr key={i}>
                              <td>{row.unique_identifier}</td>
                              <td>{row.name.en}</td>
                              <td>
                                <div>
                                  <img
                                    src={
                                      icon_url +
                                      row.unique_identifier +
                                      "_black.png"
                                    }
                                    alt="images"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {station.amenities && (
              <Col>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Amenities </h3>
                  </div>
                  <div className="box-body ">
                    <div className="table-responsive">
                      <table className="table no-margin station-service">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Icon</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station.amenities.map((row, i) => (
                            <tr key={i}>
                              <td>{row.unique_identifier}</td>
                              <td>{row.name.en}</td>
                              <td>
                                <div>
                                  <img
                                    src={
                                      icon_url +
                                      row.unique_identifier +
                                      "_black.png"
                                    }
                                    alt="images"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {station.payment_methods_accepted && (
              <Col>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Payment Methods Accepted </h3>
                  </div>
                  <div className="box-body ">
                    <div className="table-responsive">
                      <table className="table no-margin station-service">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Icon</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station.payment_methods_accepted.map((row, i) => (
                            <tr key={i}>
                              <td>{row.unique_identifier}</td>
                              <td>{row.name.en}</td>
                              <td>
                                <div>
                                  <img
                                    src={
                                      icon_url +
                                      row.unique_identifier +
                                      "_black.png"
                                    }
                                    alt="images"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {station.services && (
              <Col>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Services Methods </h3>
                  </div>
                  <div className="box-body ">
                    <div className="table-responsive">
                      <table className="table no-margin station-service">
                        <thead>
                          <tr>
                            <th>Unique Identifer</th>
                            <th>Name English</th>
                            <th>Name Arabic</th>
                            <th>Icon</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station.services.map((row, i) => (
                            <tr key={i}>
                              <td>{row.unique_identifier}</td>
                              <td>{row.name.en}</td>
                              <td>{row.name.ar}</td>
                              <td>
                                <div>
                                  <img
                                    src={
                                      icon_url +
                                      row.unique_identifier +
                                      "_black.png"
                                    }
                                    alt="images"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            )}

            <Col>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Rating</h3>
                </div>
                <div className="box-body ">
                  <div className="table-responsive">
                    <table className="table no-margin station-service">
                      <thead>
                        <tr>
                          <th>
                            <i
                              className="fa fa-lg fa-meh-o icon-filled"
                              title="Netural"
                            >
                              {" "}
                            </i>
                          </th>
                          <th>
                            <i
                              className="fa fa-lg fa-smile-o icon-filled"
                              title="Happy"
                            />
                          </th>
                          <th>
                            <i
                              className="fa fa-lg fa-frown-o icon-filled"
                              title="Sad"
                            >
                              {" "}
                            </i>
                          </th>
                          <th style={{ textAlign: "right" }}>Avg. Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {station.surprised_reviews_count
                              ? station.surprised_reviews_count
                              : 0}
                          </td>
                          <td>
                            {station.happy_reviews_count
                              ? station.happy_reviews_count
                              : 0}
                          </td>
                          <td>
                            {station.sad_reviews_count
                              ? station.sad_reviews_count
                              : 0}
                          </td>
                          <td align="right">
                            {this.average_ranting_view(station.average_rating)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Content>
    );
  }
}
