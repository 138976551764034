import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import { isTSEnumMember } from "@babel/types";
import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Inputs } from "adminlte-2-react";
const card_detail_url = API.apiEndpoint + API.getCard;
const store_url = API.apiEndpoint + API.store;
const cardOperation_url = API.apiEndpoint + API.cardOperation;
const referenceNumber_url = API.apiEndpoint + API.referenceNumber;

export default class CardTopUp extends React.Component {
  state = {
    card: {},
    store_code: "",
    store_token: "",
    stores: [],
    error: false,
    error_message: "",
    topup_amount: "",
    topup_method: "",
    success: false,
    disabled: false,
    disabledCash: false,
    success_message: "",
    allowed_payment_methods: JSON.parse(
      localStorage.getItem("allowed_payment_methods")
    ),
    reference_id: "",
    userEmail: localStorage.getItem("user_email"),
    terminal_id: localStorage.getItem("user_terminalId"),
    topup_limit_min: localStorage.getItem("topup_limit_min")
      ? localStorage.getItem("topup_limit_min")
      : null,
    topup_limit_max: localStorage.getItem("topup_limit_max")
      ? localStorage.getItem("topup_limit_max")
      : null,
    loading: true,
    remarks: "",
    request_from: "",
    status: "",
  };
  async componentDidMount() {
    const response = await axios.get(
      card_detail_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      this.setState({ card: response.data.data, loading: false });
    }

    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    let referenceNumber = { url: referenceNumber_url };
    const referenceNum = await ApiRequest.get(referenceNumber);

    if (referenceNum.error === false) {
      this.setState({
        reference_id: referenceNum.data.reference_number,
      });
    } else {
      this.setState({
        reference_id: "",
      });
    }

    if (optionsStore.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code + "__" + store.store_token,
          label: store.store_name + " - " + store.store_code,
          store_token: store.store_token,
        });
      });

      this.setState({
        stores: stores,
        // store_code: store_response.data[0].store_code,
        // store_token: store_response.data[0].store_token,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  handleChangeStoreName = async (event) => {
    // var index = event.target.selectedIndex;
    // var optionElement = event.target.childNodes[index];
    // var store_token = optionElement.getAttribute("store_token");
    let event_store_value = event.target.value;
    event_store_value = event_store_value.split("__");

    this.setState({
      store_code: event_store_value[0],
      store_token: event_store_value[1],
    });
  };
  // requestFromChange = (event) => {
  //   this.setState({ request_from: event.target.value });
  // };
  requestFromChange = async (event) => {
    this.setState({
      status: event.target.value,
      request_from: event.target.value,
    });

    let params = "";

    switch (event.target.value) {
      case "Station":
        params = "?station=1";
        break;
      case "Ahlain":
        params = "?store=1";
        break;
      case "MobileApp":
        params =
          this.state.topup_method === "Visa" ||
          this.state.topup_method === "TransferBal" ||
          this.state.topup_method === "Loyalty" ||
          this.state.topup_method === "BankTransfer"
            ? "?epayment=1"
            : this.state.topup_method === "ePayment"
            ? "?epayment=1&cubix=1"
            : "";
        break;
      default:
        params = "";
    }

    let optionsStore = { url: store_url + params };
    let store_response = await ApiRequest.get(optionsStore);
    if (optionsStore.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code + "__" + store.store_token,
          label: store.store_name + " - " + store.store_code,
          store_token: store.store_token,
        });
      });
      this.setState({
        stores: stores,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  };

  topupChange = (event) => {
    this.setState({ topup_amount: event.target.value });
  };
  // topupMethodChange = (event) => {
  //   this.setState({ topup_method: event.target.value });
  // };
  topupMethodChange = async (event) => {
    this.setState({ topup_method: event.target.value });

    let params;
    if (
      event.target.value !== "TransferBal" &&
      event.target.value !== "Loyalty" &&
      event.target.value !== "BankTransfer" &&
      event.target.value !== "Visa" &&
      event.target.value !== "ePayment"
    ) {
      params = "";
      this.setState({ disabled: false, status: "", request_from: "" });
    }
    if (
      event.target.value == "TransferBal" ||
      event.target.value == "Loyalty" ||
      event.target.value == "BankTransfer" ||
      event.target.value == "Visa" ||
      event.target.value == "ePayment"
    ) {
      params =
        event.target.value === "Visa" ||
        event.target.value === "TransferBal" ||
        event.target.value === "Loyalty" ||
        event.target.value === "BankTransfer"
          ? "?epayment=1"
          : event.target.value === "ePayment"
          ? "?epayment=1&cubix=1"
          : "";
      this.setState({ disabled: true, status: "", request_from: "" });
    }

    if (event.target.value !== "Cash") {
      this.setState({ disabledCash: false, request_from: "" });
    }
    if (event.target.value == "Cash") {
      this.setState({ disabledCash: true, status: "", request_from: "" });
    }

    let optionsStore = { url: store_url + params };
    let store_response = await ApiRequest.get(optionsStore);

    if (optionsStore.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code + "__" + store.store_token,
          label: store.store_name + " - " + store.store_code,
          store_token: store.store_token,
        });
      });
      this.setState({
        stores: stores,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  };

  remarksChange = (event) => {
    this.setState({ remarks: event.target.value });
  };
  topupAmount = async (event) => {
    this.setState({ loading: true});
    const formData = new FormData();
    let error = false;
    let topup_limit_max = this.state.topup_limit_max
      ? parseInt(this.state.topup_limit_max)
      : 2000;

    let topup_limit_min = this.state.topup_limit_min
      ? parseInt(this.state.topup_limit_min)
      : 10;
    if (
      !(parseInt(this.state.topup_amount) >= topup_limit_min) ||
      !(parseInt(this.state.topup_amount) <= topup_limit_max)
    ) {
      error = true;
      this.setState({
        error: true,
        error_message:
          "Topup Amount Min " + topup_limit_min + "  to Max " + topup_limit_max,
      });
    }

    if (!this.state.terminal_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Terminal ID Not Available",
      });
    }

    if (!this.state.reference_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Need Reference ID",
      });
    }

    if (!this.state.topup_method) {
      error = true;
      this.setState({
        error: true,
        error_message: " Topup Method is required",
      });
    }

    if (!this.state.request_from) {
      error = true;
      this.setState({
        error: true,
        error_message: " Request From is required",
      });
    }

    if (error) {
      return;
    }
    formData.append("car_plate", this.state.card.car_plate);
    formData.append("topup_amount", this.state.topup_amount);
    formData.append("topup_method", this.state.topup_method);
    formData.append("remarks", this.state.remarks);
    formData.append("method_name", "card_topup");
    formData.append("transaction_id", new Date().getTime());
    //formData.append("request_from", API.request_from);
    formData.append("reference_id", this.state.reference_id);
    formData.append("userEmail", this.state.userEmail);
    formData.append("terminal_id", this.state.terminal_id);
    formData.append("store_code", this.state.store_code);
    formData.append("store_token", this.state.store_token);
    formData.append("request_from", this.state.request_from);

    let headers = {
      store_code: this.state.store_code,
      store_token: this.state.store_token,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    this.setState({ loading: true });
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: true, // keep loading true so that user cannot click submit again after successfull topup
            topup_amount: "",
            topup_method: "",
            success_message: response.data.data.message
              ? response.data.data.message
              : response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              window.location = "/basma_cards_management";
            }.bind(this),
            3000
          );
        }else {
          this.setState({
            error: true,
            error_message: response.data.message,
            loading: false,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        this.setState({
          error: true,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      });
  };
  backCard = () => {
    window.location = "/card/" + this.state.card.id;
  };
  render() {
    const { Select2 } = Inputs;
    const {
      card,
      error,
      error_message,
      success,
      success_message,
      stores,
      allowed_payment_methods,
      loading,
      disabled,
      disabledCash,
    } = this.state;
    
    return (
      <Content title="Card Topup" browserTitle="Card Topup">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {error_message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Card Topup </h3>
              </div>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label htmlFor="card_number">Card Serial Number </label>
                        <input
                          type="text"
                          className="form-control"
                          id="card_number"
                          value={card.card_number}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="car_plate">Car Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="card_ncar_plateumber"
                          value={card.car_plate}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="store">Topup Amount</label>
                        <input
                          min={
                            this.state.topup_limit_min
                              ? this.state.topup_limit_min
                              : 1
                          }
                          max={
                            this.state.topup_limit_max
                              ? this.state.topup_limit_max
                              : 100000
                          }
                          type="number"
                          className="form-control"
                          id="topup_amount"
                          placeholder="10 to 2000"
                          value={this.state.topup_amount}
                          onChange={this.topupChange}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="store">Topup Method</label>
                        <select
                          className="form-control"
                          onChange={this.topupMethodChange}
                          value={this.state.topup_method}
                        >
                          <option value="" disabled>
                            Select
                          </option>

                          {allowed_payment_methods.map((item, i) =>
                            item.checked === true  && item.label !== 'TransferBal' ? (
                              <option value={item.value}>{item.label === 'ePayment' ? 'Thawani' : item.label}</option>
                            ) : (
                              ""
                            )
                            )}
                            <option value="3rd_Party_Loyalty">3rd Party Loyalty</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="topup_reason">Topup Reason </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.topup_reason}
                          id="topup_reason"
                          onChange={this.remarksChange}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="Terminal_Id">Terminal ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Terminal_Id"
                          value={this.state.terminal_id}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="Reference_ID">Reference ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Reference_ID"
                          value={this.state.reference_id}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="Terminal_Id">User</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Terminal_Id"
                          value={this.state.userEmail}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 cusSelect">
                        <Select2
                          label="Stores"
                          className="form-control"
                          value={this.state.selectedOption}
                          onChange={this.handleChangeStoreName}
                          options={stores}
                          placeholder="Select"
                        />
                        {/*
                          <div className="form-group">
                            <label>Store</label>
                            <select
                              className="form-control"
                              id="store"
                              name="store"
                              onChange={this.handleChangeStoreName}
                              value={this.state.store_code}
                            >
                              {stores.length > 0 &&
                                stores.map((item, key) => (
                                  <option
                                    value={item.store_code}
                                    store_token={item.store_token}
                                  >
                                    {item.store_name + " - " + item.store_code}
                                  </option>
                                ))}
                            </select>
                          </div>
                                */}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="status">Request From</label>
                          <select
                            className="form-control"
                            value={this.state.status || ""}
                            onChange={(event) => this.requestFromChange(event)}
                          >
                            <option value="">select</option>
                            <option value="MobileApp" disabled={disabledCash}>
                              MobileApp
                            </option>
                            <option value="Ahlain" disabled={disabled}>
                              Ahlain
                            </option>
                            <option value="Station" disabled={disabled}>
                              Station
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer clearfix no-border">
                    <button
                      onClick={this.topupAmount}
                      className="btn btn-primary pull-right mr-5"
                      disabled={this.state.loading}
                    >
                      Submit
                    </button>
                    <button
                      onClick={this.backCard}
                      className="btn btn-default pull-right mr-10"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
