import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const loyaltyPartnerView = API.apiEndpoint + API.getLoyaltyPartner;

export default class PartnerView extends React.Component {
  state = {
    partner_view: {},
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let url = loyaltyPartnerView + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        partner_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { partner_view, loading } = this.state;
    return (
      <Content title={"Partner View"} browserTitle="Partner View">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Partner Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>ID</b>{" "}
                      <span className="pull-right">{partner_view.id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Partner Code</b>{" "}
                      <span className="pull-right">
                        {partner_view.partner_code}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Partner Name</b>{" "}
                      <span className="pull-right">
                        {partner_view.partner_name}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Secret Key</b>{" "}
                      <span className="pull-right">
                        {partner_view.secret_key}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>IP Address</b>{" "}
                      <span className="pull-right">
                        {partner_view.ip_address}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>{" "}
                      <span className="pull-right">
                        {this.status_value(partner_view.status)}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Created At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(partner_view.createdAt)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
