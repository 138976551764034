import React, { Component } from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./SelfService.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import { dateRangeFormatter, timeRangeFormatter } from "../../helpers";
const self_service_nozzles_url = API.apiEndpoint + API.selfServiceNozzles;
const userview_url = API.apiEndpoint + API.adminUserView;

export default class SelfServiceNozzlesListing extends Component {
  state = {
    self_service_nozzles_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (pageNumber = 1, limit = 10, filter = false, key = "", value = "") => {
    let options = {
      url:
        self_service_nozzles_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      const results_with_fetched_users = [];

      for (const result of api_response.data.results) {
        let user_url = userview_url + "/" + result.addedBy;
        let options = { url: user_url };
        let user_api_response = await ApiRequest.get(options);
        if (user_api_response.error) {
          results_with_fetched_users.push(result);
        } else {
          results_with_fetched_users.push({
            ...result,
            user_name: user_api_response.data.name,
          });
        }
      }

      this.setState({
        // self_service_nozzles_list: api_response.data.results,
        self_service_nozzles_list: results_with_fetched_users,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(pageNumber, this.state.limit, this.state.filter, this.state.search_select_value, this.state.search_input_value);
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, event.target.value, this.state.search_input_value);
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, this.state.search_select_value, event.target.value);
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  deleteSelfServiceNozzle = async (selfServiceNozzleId) => {
    confirmAlert({
      title: "Self Service Nozzle",
      message: "Are you sure you want to delete this self service nozzle?",
      buttons: [
        {
          label: "Yes",
          // onClick: () => this.confirmDeleteCardRepository(val),
          onClick: async () => {
            let options = {
              url: self_service_nozzles_url + "/" + selfServiceNozzleId,
            };
            this.setState({ loading: true });
            let api_response = await ApiRequest.delete(options);
            if (api_response.error) {
              this.setState({
                loading: false,
                error: true,
                message: api_response.message,
              });
            } else {
              this.setState({
                loading: false,
                success: true,
                success_message: api_response.message,
              });
              await this.getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  render() {
    const {
      loading,
      error,
      message,
      success,
      success_message,
      self_service_nozzles_list,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;
    return (
      <Content title="Self Service Nozzles" browserTitle="Self Service Nozzles">
        <LoadingAjax />
        <Row>
          {error && (
            <div class="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div class="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {success_message}
            </div>
          )}

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Self Service Nozzles</h3>
                <div className="box-tools">
                  <div>
                    <a class="btn btn-sm btn-primary mr-5" href={"/self_service_nozzles_form/"}>
                      {" "}
                      Add New
                      {"    "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select value={limit} className="form-control input-sm mr-5 ml-5" onChange={this.handleLimitChange}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>

                    <div className="cusSearch">
                      Search{" "}
                      <select onChange={this.handleSearchChange} value={search_select_value} className="form-control input-sm mr-5 ml-5">
                        <option value="">Select Search by </option>
                        <option value={"store_code"}>Store Code</option>
                      </select>
                      <input
                        type="search"
                        class="form-control input-sm mr-5 ml-5"
                        value={search_input_value}
                        onChange={this.handleSearchValueChange}
                        placeholder=""
                      />
                      <button className="btn btn-primary cusSearchBtn" onClick={this.handleSearchClear}>
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Store Code</th>
                            <th>Pump Number</th>
                            <th>Date Range</th>
                            <th>Time Range</th>
                            <th>Added By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {self_service_nozzles_list.length > 0 ? (
                            self_service_nozzles_list.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>{this.rescue_value(data.PumpNr)}</td>
                                  <td>{dateRangeFormatter(data.dateStart, data.dateEnd)}</td>
                                  <td>{timeRangeFormatter(data.timeStart, data.timeEnd)}</td>
                                  <td>
                                    {ApiRequest.can_access("admin-user", "can_view_single") && (
                                      <a class="btn btn-primary btn-xs mr-5" href={"/admin_user_view/" + data.addedBy}>
                                        {data.user_name}
                                      </a>
                                    )}
                                  </td>
                                  <td>{ApiRequest.date_format_utc(data.createdAt)}</td>
                                  <td>
                                    {ApiRequest.can_access("station", "can_view") && (
                                      <a class="btn btn-primary btn-xs mr-5" href={"/self_service_nozzles_form/edit/" + data.id}>
                                        {" "}
                                        <i class="fa fa-edit" />
                                        {"    "}
                                      </a>
                                    )}
                                    {ApiRequest.can_access("station", "can_view") && (
                                      <button class="btn btn-primary btn-xs mr-5" onClick={() => this.deleteSelfServiceNozzle(data.id)}>
                                        <i class="fa fa-trash" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to {pagination.currentPage * limit} of {pagination.size} entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
