import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "react-modal";
import $ from "jquery";

const badgeManagement = API.apiEndpoint + API.badgeManagement;
const store_url = API.apiEndpoint + API.store;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class AddBadge extends React.Component {
  state = {
    page: {},
    action: "",
    cash_back_promotion_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    card_from: "",
    card_to: "",
    stores: [],
    store_code: "9765",
    tableData: [],
    totalData: "",
    badge_en: null,
    badge_ar: null,
    // bdg_title_en: "",
    // bdg_title_ar: "",
    // desp_title_en: "",
    // desp_title_ar: "",

    bdg_title: { en: "", ar: "" },
    desp_title: { en: "", ar: "" },
    //tip: {},
    status: "0",
  };

  async componentDidMount() {
    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code,
          label: store.store_code + " - " + store.store_name,
        });
      });

      this.setState({
        stores: stores,
        //stores: store_response.data,
        //store_code: store_response.data[0].store_code,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  openModal = async (cards_not) => {
    this.setState({ totalData: cards_not.fulldata });
    this.setState({ tableData: cards_not.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    window.location = "/badges_statistics";
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  badgeTitleChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let bdg_title = Object.assign({}, prevState.bdg_title); // creating copy of state variable tip
      bdg_title[lang] = value; // update the name property, assign a new value
      return { bdg_title }; // return new object tip object
    });
    return true;
  };

  desp_Change = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let desp_title = Object.assign({}, prevState.desp_title); // creating copy of state variable tip
      desp_title[lang] = value; // update the name property, assign a new value
      return { desp_title }; // return new object tip object
    });
    return true;
  };

  EngImageSquareHandler = async (event) => {
    this.setState({
      badge_en: ("file", event.target.files[0]),
    });
  };
  ArImageSquareHandler = async (event) => {
    this.setState({
      badge_ar: ("file", event.target.files[0]),
    });
  };

  action_Change = (event) => {
    this.setState({ action: event.target.value });
  };
  rule_id_Change = (event) => {
    this.setState({ rule_id: event.target.value });
  };
  action_point_Change = (event) => {
    this.setState({ action_point: event.target.value });
  };
  limit_Change = (event) => {
    this.setState({ limit: event.target.value });
  };
  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  saveBulkBlank = async (event) => {
    const formData = new FormData();

    let error = false;

    if (!this.state.badge_ar) {
      error = true;
      this.setState({
        error: true,
        message: "Arabic Badge Image is required",
      });
    }

    if (!this.state.badge_en) {
      error = true;
      this.setState({
        error: true,
        message: "English Badge Image is required",
      });
    }

    if (error) {
      return;
    }

    // let data = {
    //   title: { en: this.state.bdg_title_en, ar: this.state.bdg_title_ar },
    //   description: {
    //     en: this.state.desp_title_en,
    //     ar: this.state.bdg_title_ar,
    //   },
    //   action: this.state.action,
    //   rule_id: this.state.rule_id,
    //   action_points: this.state.action_point,
    //   limit: parseInt(this.state.limit),
    //   status: this.state.status,
    //   image: {
    //     en: "Promo_User_English.png",
    //     ar: "Promo_User_Arabic.png",
    //   },
    // };

    // let image = {};
    // image.badge_en = this.state.badge_en;
    // image.badge_ar = this.state.badge_ar;

    formData.append("title", JSON.stringify(this.state.bdg_title));
    formData.append("description", JSON.stringify(this.state.desp_title));
    formData.append("action", this.state.action);
    formData.append("rule_id", this.state.rule_id);
    formData.append("action_points", this.state.action_point);
    formData.append("limit", this.state.limit);
    formData.append("status", this.state.status);
    formData.append("badge_image_en", this.state.badge_en);
    formData.append("badge_image_ar", this.state.badge_ar);

    //formData.append("badge_image", JSON.stringify(image));

    // formData.append("badge_image", this.state.image_en);
    // formData.append("badge_image_ar", this.state.image_ar);

    let api_response = {};
    let options = {
      url: badgeManagement,
      //form_data: data,
      form_data: formData,
    };
    // api_response = await ApiRequest.post(options);
    api_response = await ApiRequest.post(options, false, {
      headers: {
        "X-Access-Token": localStorage.getItem("user_token"),
        "Content-Type": "multipart/form-data",
      },
    });

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let cards_not = api_response;
      this.openModal(cards_not);
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/badges_statistics";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/badges_statistics";
  };

  render() {
    let engImgSquare = "";
    if (typeof this.state.engImgSquare !== "undefined") engImgSquare = this.state.engImgSquare.file;

    let arImgSquare = "";
    if (typeof this.state.arImgSquare !== "undefined") arImgSquare = this.state.engImgSquare.file;

    let { error, totalData, message, success, success_message } = this.state;

    return (
      <div>
        <Content title="Add Badge " browserTitle="Add Badge ">
          <LoadingAjax />
          <div className="row">
            {error && (
              <div className="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div className="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Badge Form </h3>
                </div>

                <div className="box-body">
                  <div className="col-sm-12 p0">
                    <div className="col-sm-12">
                      <label>Title </label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="bdg_title_en">English</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bdg_title_en"
                          placeholder="Title english"
                          value={this.state.bdg_title.en}
                          onChange={this.badgeTitleChange.bind(null, "en")}

                          // value={this.state.bdg_title_en}
                          // onChange={(e) =>
                          //   this.setState({ bdg_title_en: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="bdg_title_ar">Arabic</label>
                        <input
                          type="text"
                          className="form-control"
                          id="bdg_title_ar"
                          placeholder="Title arabic"
                          value={this.state.bdg_title.ar}
                          onChange={this.badgeTitleChange.bind(null, "ar")}

                          // value={this.state.bdg_title_ar}
                          // onChange={(e) =>
                          //   this.setState({ bdg_title_ar: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 p0">
                    <div className="col-sm-12">
                      <label>Description </label>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_en">English</label>
                        <input
                          type="text"
                          className="form-control"
                          id="description_en"
                          placeholder="Description english"
                          value={this.state.desp_title.en}
                          onChange={this.desp_Change.bind(null, "en")}

                          // value={this.state.desp_title_en}
                          // onChange={(e) =>
                          //   this.setState({ desp_title_en: e.target.value })
                          // }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title_ar">Arabic</label>
                        <input
                          type="text"
                          className="form-control"
                          id="description_ar"
                          placeholder="Description arabic"
                          value={this.state.desp_title.ar}
                          onChange={this.desp_Change.bind(null, "ar")}

                          // value={this.state.desp_title_ar}
                          // onChange={(e) =>
                          //   this.setState({ desp_title_ar: e.target.value })
                          // }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="action">Action</label>
                        <input
                          type="text"
                          className="form-control"
                          id="action"
                          placeholder="Action"
                          value={this.state.action}
                          onChange={this.action_Change}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="RoldID">Rule ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Rule ID"
                          placeholder="Rule ID"
                          value={this.state.rule_id}
                          onChange={this.rule_id_Change}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="action point">Action Point</label>
                        <input
                          type="Number"
                          className="form-control"
                          id="action point"
                          placeholder="Action point"
                          value={this.state.action_point}
                          onChange={this.action_point_Change}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="limit">Limit</label>
                        <input
                          type="Number"
                          className="form-control"
                          id="limit"
                          placeholder="Limit"
                          value={this.state.limit}
                          onChange={this.limit_Change}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="status">Visibility</label>
                        <select className="form-control" value={this.state.status} onChange={this.statusChange}>
                          <option value="1">Show</option>
                          <option value="0">Hidden</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label>Badge Image </label>
                    </div>
                    <div className="col-md-6">
                      <div style={{ display: "flex", "align-items": "center" }}>
                        <div className="form-group">
                          <label htmlFor="badge_img_en">English </label>
                          <input
                            type="file"
                            id="badge_img_en"
                            placeholder="Name english"
                            ref={(ref) => (this.imageEngVersion = ref)}
                            onChange={this.EngImageSquareHandler}
                            accept=".png, .jpeg"
                          />
                        </div>
                        {engImgSquare === "" ? "" : <img src={engImgSquare} alt="Logo" style={{ width: "120px" }} />}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={{ display: "flex", "align-items": "center" }}>
                        <div className="form-group">
                          <label htmlFor="badge_img_ar">Arabic </label>
                          <input
                            type="file"
                            id="badge_img_ar"
                            placeholder="Name english"
                            ref={(ref) => (this.imageArVersion = ref)}
                            onChange={this.ArImageSquareHandler}
                            accept=".png, .jpeg"
                          />
                        </div>
                        {arImgSquare === "" ? "" : <img src={arImgSquare} alt="Logo" style={{ width: "120px" }} />}
                      </div>
                    </div>
                  </div>
                  <div className="box-footer clearfix no-border">
                    <button onClick={this.saveBulkBlank} className="btn btn-primary pull-right ">
                      Submit
                    </button>
                    <button onClick={this.backUser} className="btn btn-default pull-right mr-10">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}
