import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import $ from "jquery";

const addProductBarcode = API.apiEndpoint + API.addProductBarcode;
//const badgeManagement_url = API.apiEndpoint + API.badgeManagement;

export default class Product_bar_code_form extends React.Component {
  state = {
    page: {},
    action: "add",
    promo_code_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    badge_management: [],
  };

  async componentDidMount() {
    // let options = { url: badgeManagement_url + "?allRecords=1" };
    // let api_response = await ApiRequest.get(options);
    // if (api_response.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response.message,
    //   });
    // } else {
    //   this.setState({
    //     productBarCodeData: api_response.data.results,
    //     loading: false,
    //     error: false,
    //     message: api_response.message,
    //   });
    // }
  }
  product_name_Change = (event) => {
    this.setState({ product_name: event.target.value });
  };
  product_code_Change = (event) => {
    this.setState({ product_code: event.target.value });
  };
  limit_Change = (event) => {
    this.setState({ limit: event.target.value });
  };

  saveTopupPromotions = async (event) => {
    let error = false;

    if (!this.state.product_name) {
      error = true;
      this.setState({
        error: true,
        message: "Product Name is required",
      });
      return;
    }

    if (!this.state.product_code) {
      error = true;
      this.setState({
        error: true,
        message: "Product Code is required",
      });
      return;
    }

    if (!this.state.limit) {
      error = true;
      this.setState({
        error: true,
        message: "Limit is required",
      });
      return;
    }

    const formData = new FormData();

    // let data = {
    //   campaign_title: this.state.title,
    //   badge_title: this.state.badgeTitle,
    //   badge_id: this.state.badgeId,
    //   badge_action: this.state.badgeAction,
    //   mechanic_id: this.state.mechanic_id,
    // };

    formData.append("product_name", this.state.product_name);
    formData.append("product_code", this.state.product_code);
    formData.append("limit", this.state.limit);

    let api_response = {};
    let options = { url: addProductBarcode, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: "Product Barcode successfully Added",
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/product_bar_code";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/product_bar_code";
  };

  render() {
    let {
      error,
      message,
      success,
      success_message,
      productBarCodeData,
    } = this.state;
    return (
      <Content title="Product Barcode" browserTitle="Product Barcode Form">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Product Barcode Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="product_name">Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_name"
                      placeholder="Product name"
                      value={this.state.product_name}
                      onChange={this.product_name_Change}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="product_code">Product Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_code"
                      placeholder="Product code"
                      value={this.state.product_code}
                      onChange={this.product_code_Change}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="limit">Limit</label>
                    <input
                      type="text"
                      className="form-control"
                      id="limit"
                      placeholder="Limit"
                      value={this.state.limit}
                      onChange={this.limit_Change}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveTopupPromotions}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
