import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

const cash_back_form_url = API.apiEndpoint + API.CashBackPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;
const subTiers_for_parent_tier_url = API.apiEndpoint + API.getSubTiersForParentTier;


export default class CashBackPromotionForm extends React.Component {
  state = {
    page: {},
    action: this.props.edit ? "edit" : "add",
    cash_back_promotion_list: [],
    selectedParentTier: null,
    availableParentTiers: [],
    loyaltySubTier: null,
    availableLoyaltySubTiers: [],
    selectedProducts: [],
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    start: "",
    end: "",
    reward_type: "percentage",
    isRefuelingChecked: false,
    isTopupChecked: false,
    disabled: false,
  };

  handleParentTierChange = async (selectedParentTier) => {
    // Get Available Sub Tiers for Parent Tier
    let options = { url: subTiers_for_parent_tier_url + "/" + selectedParentTier.value };
    let subTiers_response = await ApiRequest.get(options);
    if (subTiers_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: subTiers_response.message,
      });
    } else {
      let availableSubTiers = [];
      subTiers_response.data.forEach((subTier) => {
          availableSubTiers.push({
            value: subTier.id,
            label: subTier.name,
          });
        });
      this.setState({
        selectedParentTier,
        availableLoyaltySubTiers: availableSubTiers,
        loyaltySubTier: null,
      });
    }
  };

  async componentDidMount() {
    let options = { url: tierTargetList_url };
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let parentTiers = [];
      api_response.data.forEach((store) => {
        parentTiers.push({ value: store.CardTypeName, label: store.CardTypeName });
      });

      if(!this.props.edit) {
        this.setState({
          selectedProducts: [],
          topup_method_Selected: [],
          availableParentTiers: parentTiers,
          loading: false,
          error: false,
          message: api_response.message,
        });
      } else {
        let url_edit = cash_back_form_url + "/" + this.props.match.params.id;
        let options_edit = { url: url_edit };
        let api_response_edit = await ApiRequest.get(options_edit);
        if(api_response_edit.error) {
          this.setState({
            loading: false,
            error: true,
            message: api_response_edit.message,
          });
          return;
        }
        let data_edit = api_response_edit?.data;

        // Get Available Sub Tiers for Parent Tier
        let options_subTier = { url: subTiers_for_parent_tier_url + "/" + data_edit.parentTierCardTypeName };
        let subTiers_response = await ApiRequest.get(options_subTier);
        if (subTiers_response.error) {
          this.setState({
            loading: false,
            error: true,
            message: subTiers_response.message,
          });
          return;
        } else {
          let availableSubTiers = [];
          subTiers_response.data.forEach((subTier) => {
              availableSubTiers.push({
                value: subTier.id,
                label: subTier.name,
              });
            });
          this.setState({
            selectedProducts: data_edit.products ? data_edit.products.map(product => ({ value: product, label: product })) : [],
            topup_method_Selected: [],
            availableParentTiers: parentTiers,
            selectedParentTier: { value: data_edit.parentTierCardTypeName, label: data_edit.parentTierCardTypeName },
            loyaltySubTier: data_edit.loyaltySubTier ? { value: data_edit.loyaltySubTier.id, label: data_edit.loyaltySubTier.name } : null,
            availableLoyaltySubTiers: availableSubTiers,
            cash_back_promotion_date_from: new Date(data_edit.from),
            cash_back_promotion_date_to: new Date(data_edit.to),
            promotionName: data_edit.name,
            reward_type: data_edit.reward_type,
            cashBackRewardValue: data_edit.reward_value,
            rewardBudgetValue: data_edit.budget,
            cashBackFromValue: data_edit.transaction_value_range_from,
            cashBackToValue: data_edit.transaction_value_range_to,
            isTopupChecked: data_edit.transaction_type.includes("topup"),
            isRefuelingChecked: data_edit.transaction_type.includes("refueling"),
            loading: false,
            error: false,
            message: api_response.message,
          });
        }
      }

    }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  promotionTypeChange = (event) => {
    this.setState({ promotionType: event.target.value });
  };
  cashBackPromotionStart = async (date) => {
    this.setState({
      cash_back_promotion_date_from: date,
      enableBtn: true,
    });
  };
  cashBackPromotionEnd = async (date) => {
    this.setState({
      cash_back_promotion_date_to: date,
      enableBtn: true,
    });
  };
  productsChange = (event) => {
    this.setState({ products: event.target.value });
  };
  cashBackFromValueChange = (event) => {
    this.setState({ cashBackFromValue: event.target.value });
  };
  cashBackToValueChange = (event) => {
    this.setState({ cashBackToValue: event.target.value });
  };
  rewardBudgetValueChange = (event) => {
    this.setState({ rewardBudgetValue: event.target.value });
  };
  cashBackRewardValueChange = (event) => {
    this.setState({ cashBackRewardValue: event.target.value });
  };
  promotionNameChange = (event) => {
    this.setState({ promotionName: event.target.value });
  };
  handleProductsChange = (selectedProducts) => {
    this.setState({ selectedProducts: selectedProducts });
  };
  onChangeTopupMethodCheck = (event) => {
    this.setState({
      isTopupChecked: !this.state.isTopupChecked,
    });
  };
  onChangeRefuelingCheck = (event) => {
    this.setState({
      isRefuelingChecked: !this.state.isRefuelingChecked,
    });
  };

  saveCashBack = async (event) => {

    if (!this.state.cash_back_promotion_date_from) {
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }

    if (!this.state.cash_back_promotion_date_to) {
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.promotionName) {
      this.setState({
        error: true,
        message: "Promotion name is required",
      });
      return;
    }

    if (
      this.state.isRefuelingChecked &&
      Array.isArray(this.state.selectedProducts) &&
      this.state.selectedProducts.length === 0
    ) {
      this.setState({
        error: true,
        message: "Products is required",
      });
      return;
    }

    if (!this.state.cashBackFromValue) {
      this.setState({
        error: true,
        message: "Topup value from is required",
      });
      return;
    }
    if (!this.state.cashBackToValue) {
      this.setState({
        error: true,
        message: "Topup value to is required",
      });
      return;
    }
    if (!this.state.cashBackRewardValue) {
      this.setState({
        error: true,
        message: "Reward Value is required",
      });
      return;
    }
    if (!this.state.rewardBudgetValue) {
      this.setState({
        error: true,
        message: "Reward Budget is required",
      });
      return;
    }

    if (this.state.rewardBudgetValue <= 0) {
      this.setState({
        error: true,
        message: "Reward Budget must be greater than 0",
      });
      return;
    }

    var productsPost = [];
    this.state.selectedProducts &&
      this.state.selectedProducts.forEach((productSelect) => {
        productsPost.push(productSelect.value);
      });

    var moment = require("moment");

    const formData = new FormData();
    formData.append(
      "from",
      moment
        .utc(this.state.cash_back_promotion_date_from)
        // .add(1, "days")
        // .endOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.cash_back_promotion_date_to)
        // .add(1, "days")
        // .endOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );

    formData.append("name", this.state.promotionName);
    formData.append("reward_type", this.state.reward_type);
    formData.append("reward_value", this.state.cashBackRewardValue);
    formData.append("products", JSON.stringify(productsPost));
    formData.append("parentTierCardTypeName", this.state.selectedParentTier.value);
    if(this.state.loyaltySubTier) {
      formData.append("loyaltySubTier", this.state.loyaltySubTier.value);
    }
    formData.append("budget", this.state.rewardBudgetValue);

    formData.append(
      "transaction_value_range_from",
      this.state.cashBackFromValue
    );
    formData.append("transaction_value_range_to", this.state.cashBackToValue);

    var transaction_type_data = [];

    if (this.state.isTopupChecked === true) {
      transaction_type_data.push("topup");
    }
    if (this.state.isRefuelingChecked === true) {
      transaction_type_data.push("refueling");
    }

    formData.append("transaction_type", JSON.stringify(transaction_type_data));

    let api_response = {};
    if(this.props.edit) {
      let options = { url: cash_back_form_url + '/' + this.props.match.params.id, form_data: formData };
      api_response = await ApiRequest.put(options);
    } else {
      let options = { url: cash_back_form_url, form_data: formData };
      api_response = await ApiRequest.post(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/cash_back_promotion";
        }.bind(this),
        3000
      );
    }
  };
  backUser = () => {
    window.location = "/cash_back_promotion";
  };

  handleChangeSubTier = (loyaltySubTier) => {
    this.setState({ loyaltySubTier });
  }

  handleRewardTypeChange = (event) => {
    this.setState({ reward_type: event.target.value });
  }



  render() {
    let {
      error,
      message,
      success,
      success_message,
      selectedParentTier,
      availableParentTiers,
      loyaltySubTier,
      availableLoyaltySubTiers,
      selectedProducts,
    } = this.state;

    var products = [
      { value: "MOGAS 91", label: "MOGAS 91" },
      { value: "MOGAS 95", label: "MOGAS 95" },
      { value: "Diesel", label: "Diesel" },
      { value: "MOGAS 98", label: "MOGAS 98" },
    ];

    return (
      <Content title="Cash Back Promotion" browserTitle="Cash Back Promotion">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Cash Back Promotion Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="loyaltyGameId">Promotion Type</label>
                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Cash Back Promotion </option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    // dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_from}
                    selectsStart
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionStart}
                    minDate={moment().toDate()}
                    placeholderText="From"
                  />
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    //dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_to}
                    selectsEnd
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionEnd}
                    minDate={this.state.cash_back_promotion_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="promotionName">Promotion Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.promotionName}
                      onChange={this.promotionNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Topup value</label>
                    <div className="form-group-custom">
                      <div className="form-control form-group-custom mr-5">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput "
                          placeholder="From"
                          value={this.state.cashBackFromValue}
                          onChange={this.cashBackFromValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                      <div className="form-control form-group-custom ">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput"
                          placeholder="To"
                          value={this.state.cashBackToValue}
                          onChange={this.cashBackToValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="reward">Reward Value</label>
                    <div className="form-group-custom">
                      <select
                        style={{ width: "50%" }}
                        className="form-control mr-5"
                        value={this.state.reward_type}
                        onChange={this.handleRewardTypeChange}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        placeholder="00"
                        value={this.state.cashBackRewardValue}
                        onChange={this.cashBackRewardValueChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="rewardBudget">Reward Budget</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Reward budget"
                      value={this.state.rewardBudgetValue}
                      onChange={this.rewardBudgetValueChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Parent Tier Target</label>
                    <Select
                      value={selectedParentTier}
                      onChange={this.handleParentTierChange}
                      options={availableParentTiers}
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="subTier">Loyalty Sub Tier Target (Optional)</label>
                    <Select
                        value={loyaltySubTier}
                        onChange={this.handleChangeSubTier}
                        options={availableLoyaltySubTiers}
                        isSearchable
                      />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="products">Products</label>
                    <Select
                      value={selectedProducts}
                      onChange={this.handleProductsChange}
                      options={products}
                      isMulti
                      isSearchable
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="subTier">Transaction Type(s)</label>
                  <div className="">
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeTopupMethodCheck}
                        type="checkbox"
                        checked={this.state.isTopupChecked}
                      />
                      {" Topup"}
                    </label>
                  </div>
                  <div className="">
                    <div>
                      <label>
                        <input
                          className="ml-5"
                          onChange={this.onChangeRefuelingCheck}
                          type="checkbox"
                          id="refueling"
                          checked={this.state.isRefuelingChecked}
                        />
                        {" Refueling"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveCashBack}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
