import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import { confirmAlert } from "react-confirm-alert";
import LoadingAjax from "../../components/Loading";
// import Moment from "react-moment";
import { Inputs } from "adminlte-2-react";
import moment from "moment";
import Modal from "react-modal";

const bulk_gift_card_form_url = API.apiEndpoint + API.bulkGiftCardActivation;
const store_url = API.apiEndpoint + API.store;
const lastGiftCar_url = API.apiEndpoint + API.lastGiftCarPlate;
const cardTransactionsTopupMethods_url =
  API.apiEndpoint + API.cardTransactionsTopupMethods;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class BulkGiftCardActivationForm extends React.Component {
  state = {
    card: {},
    stores: [],
    store_code: {},
    store_token: {},
    topup_amount: "",
    topup_method: "",
    customer_code: "",
    card_from: "",
    card_to: "",
    car_plate: "",
    mobile_number: "80076626",
    country_code: "+968",
    customerCode: "",
    description: "",
    error: false,
    message: "",
    success: false,
    success_message: "",
    simpleNumber: {},
    cardplateTypes: [],
    tableData: [],
    totalData: "",
    card_trans_method: [],
    allowed_payment_methods: JSON.parse(
      localStorage.getItem("allowed_payment_methods")
    ),
    topup_limit_min: localStorage.getItem("topup_limit_min")
      ? localStorage.getItem("topup_limit_min")
      : null,
    topup_limit_max: localStorage.getItem("topup_limit_max")
      ? localStorage.getItem("topup_limit_max")
      : null,
  };

  async componentDidMount() {
    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    let lastGiftCarPlate = { url: lastGiftCar_url };
    let lastGiftCar_response = await ApiRequest.get(lastGiftCarPlate);

    let optionsTopupMethods = { url: cardTransactionsTopupMethods_url };
    let topupMethod_response = await ApiRequest.get(optionsTopupMethods);

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code,
          label: store.store_code + " - " + store.store_name,
        });
      });

      let card_trans_method = [];
      topupMethod_response.data
        .filter((tansMethod) => tansMethod !== "TransferBal" && tansMethod !== null)
        .forEach((tansMethod) => {
        card_trans_method.push({ label: tansMethod, value: tansMethod });
      });

      this.setState({
        lastGiftCar: lastGiftCar_response.data.initial_car_plate,
        stores: stores,
        car_plate: lastGiftCar_response.data.initial_car_plate,
        card_trans_method: card_trans_method,
        //stores: store_response.data,
        //store_code: store_response.data[0].store_code,,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  openModal = async (data1) => {
    this.setState({ totalData: data1.fulldata });
    this.setState({ tableData: data1.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    window.location = "/basma_white_list";
  };
  handleChangeRangeFrom = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        card_from: event.target.value,
      });
    }
  };
  handleChangeRangeTo = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        card_to: event.target.value,
      });
    }
  };
  countryCodeChange = (event) => {
    this.setState({ error: false, country_code: event.target.value });
  };
  topupChange = (event) => {
    this.setState({ error: false, topup_amount: event.target.value });
  };
  handleTopupMethod = (event) => {
    this.setState({
      topup_method: event.target.value,
      enableBtn: true,
      card_trans_report: [],
      pagination: {},
      limit: 10,
    });
  };
  // topupMethodChange = (event) => {
  //   this.setState({ error: false, topup_method: event.target.value });
  // };
  mobileNumberChange = (event) => {
    if (event.target.value.length == 9) {
      this.setState({
        error: true,
        message: "Mobile Number limited to 8 digit",
      });
    } else {
      this.setState({ error: false, mobile_number: event.target.value });
    }
  };
  customerCodeChange = (event) => {
    this.setState({ customerCode: event.target.value });
  };
  carPlateChange = (event) => {
    this.setState({ car_plate: event.target.value });
  };
  descriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };
  changeContent = (event) => {
    this.setState({
      remarks: event.target.value,
    });
  };
  handleChangeStoreName = async (selectedOption) => {
    this.setState({
      store_code: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };
  backPage = () => {
    window.location = "/basma_white_list";
  };
  confirmActiveBulkGiftCard = async (val, event) => {

      if (this.state.store_code.length > 0) {
          confirmAlert({
              title: "Bulk Gift Card Activation",
              message: "Store code "+val+" selected for this activation. Is this correct?",
              buttons: [
                  {
                      label: "Yes",
                      onClick: () => this.activeBulkGiftCard(),
                  },
                  {
                      label: "No",
                      onClick: () => {},
                  },
              ],
          });

      }else{
          this.activeBulkGiftCard();
      }
  };
  activeBulkGiftCard = async (event) => {
    const formData = new FormData();
    let error = false;
    if (!this.state.description.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Description is Required",
      });
    }
    // if (!this.state.car_plate.length > 0) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Car Plate is required",
    //   });
    // }
    if (!this.state.customerCode.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Customer Code is Required",
      });
    }
    if (!this.state.store_code.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "store Code is Required",
      });
    }
    if (!this.state.topup_method.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Topup Method is required",
      });
    }
    let topup_limit_max = this.state.topup_limit_max
      ? parseInt(this.state.topup_limit_max)
      : 2000;

    let topup_limit_min = this.state.topup_limit_min
      ? parseInt(this.state.topup_limit_min)
      : 10;

    if (
      !(parseInt(this.state.topup_amount) >= topup_limit_min) ||
      !(parseInt(this.state.topup_amount) <= topup_limit_max)
    ) {
      error = true;
      this.setState({
        error: true,
        message:
          "Topup Amount Min " + topup_limit_min + "  to Max " + topup_limit_max,
      });
    }

    if (!this.state.country_code.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Country Code is Required",
      });
    }
    if (!this.state.mobile_number.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Mobile Number limited to 8 digit",
      });
    }
    if (!this.state.card_to.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Card To is Required and limited to 16 digit",
      });
    }
    if (!this.state.card_from.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Card From is Required and limited to 16 digit",
      });
    }

    if (error) {
      return;
    }

    formData.append("card_from", this.state.card_from);
    formData.append("card_to", this.state.card_to);
    formData.append("topup_amount", this.state.topup_amount);
    formData.append("topup_method", this.state.topup_method);
    formData.append("mobile_number", this.state.mobile_number);
    formData.append("country_code", this.state.country_code);
    formData.append("store_code", this.state.store_code);
    formData.append("customer_code", this.state.customerCode);
    formData.append("car_plate", this.state.car_plate);
    formData.append("description", this.state.description);

    let api_response = {};
    let options = { url: bulk_gift_card_form_url, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let data1 = api_response;
      this.openModal(data1);
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          //window.location = "/basma_white_list";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/basma_white_list";
  };

  render() {
    const { Select2 } = Inputs;
    const {
      error,
      message,
      success,
      success_message,
      stores,
      totalData,
      //allowed_payment_methods,
      lastGiftCar,
      card_trans_method,
    } = this.state;

    return (
      <div>
        <Content
          title="Bulk Gift Card Activation"
          browserTitle="Bulk Gift Card Activation"
        >
          <LoadingAjax />
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Bulk Gift Card Activation </h3>
                </div>

                <div className="box-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group rangeFilter">
                        <label
                          style={{
                            display: "block",
                          }}
                        >
                          Card Number
                        </label>
                        <input
                          type="number"
                          value={this.state.card_from}
                          onChange={this.handleChangeRangeFrom}
                          className="form-control"
                          id="card_number"
                          min={0}
                          placeholder="From (0000000000000000)"
                          style={{
                            display: "inline-block",
                            width: "49%",
                            margin: "0 .7% 0 0",
                          }}
                        />
                        <input
                          type="number"
                          min={0}
                          value={this.state.card_to}
                          onChange={this.handleChangeRangeTo}
                          className="form-control"
                          placeholder="To (0000000000000000)"
                          style={{
                            display: "inline-block",
                            width: "49%",
                            margin: "0 .7% 0 0",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="mobile_number">Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobile_number"
                        value={this.state.mobile_number}
                        onChange={this.mobileNumberChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="countryCode">Country Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="countryCode"
                          placeholder="country code"
                          value={this.state.country_code}
                          onChange={this.countryCodeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="topup_amount">Topup Amount</label>
                      <input
                        type="number"
                        min={
                          this.state.topup_limit_min
                            ? this.state.topup_limit_min
                            : 1
                        }
                        max={
                          this.state.topup_limit_max
                            ? this.state.topup_limit_max
                            : 100000
                        }
                        className="form-control"
                        id="topup_amount"
                        placeholder="10 to 2000"
                        value={this.state.topup_amount}
                        onChange={this.topupChange}
                      />
                    </div>
                    <div className="col-md-6 cusSelect">
                      <Select2
                        label="Payment Method"
                        className="form-control"
                        value={this.state.topup_method}
                        onChange={this.handleTopupMethod}
                        options={card_trans_method}
                        placeholder="Select"
                      />
                    </div>
                    {/*<div className="col-md-6 form-group">
                      <label htmlFor="topup_method">Topup Method</label>
                      <select
                        className="form-control"
                        onChange={this.topupMethodChange}
                        value={this.state.topup_method}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {allowed_payment_methods.map((item, i) =>
                          item.checked === true ? (
                            <option value={item.value}>{item.label}</option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                          </div>*/}
                    <div className="col-md-6 cusSelect">
                      <Select2
                        label="Store Code"
                        className="form-control"
                        value={this.state.selectedOption}
                        onChange={this.handleChangeStoreName}
                        options={stores}
                        placeholder="Select"
                      />
                    </div>
                    {/*<div className="col-md-6">
                      <div className="form-group">
                        <label>Store Code</label>
                        <select
                          className="form-control"
                          id="store_code"
                          name="store_code"
                          onChange={this.onChange}
                          value={this.state.store_code}
                        >
                          {stores.length > 0 &&
                            stores.map((item, key) => (
                              <option value={item.store_code}>
                                {item.store_name}
                              </option>
                            ))}
                        </select>
                      </div>
                            </div>*/}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="customerCode">Customer Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="customerCode"
                          placeholder="Customer Code"
                          value={this.state.customerCode}
                          onChange={this.customerCodeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="form-group"
                        style={
                          this.state.lastGiftCar === lastGiftCar
                            ? { pointerEvents: "none" }
                            : ""
                        }
                      >
                        <label htmlFor="carPlate">Car Plate</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carPlate"
                          placeholder="Car Plate"
                          data-value={lastGiftCar}
                          value={
                            this.state.lastGiftCar === lastGiftCar
                              ? this.state.lastGiftCar
                              : this.state.car_plate
                          }
                          onChange={this.carPlateChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.descriptionChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.confirmActiveBulkGiftCard.bind(null, this.state.store_code)}
                    className="btn btn-primary pull-right "
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.backPage}
                    className="btn btn-default pull-right mr-10"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Proceed</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.card_number}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
