import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
require("bootstrap/less/bootstrap.less");

const get_spin_win_report_url = API.apiEndpoint + API.spinandwinReport;
const game_name_url = API.apiEndpoint + API.spinAndWin;
const winner_spin_url = API.apiEndpoint + API.WinnerSpinAndWin;

export default class SpinWinReport extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    spinWins: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    loadingCart: false,
    spin_win_date_from: "",
    spin_win_date_to: "",
    name: "",
    oomcoId: "",
    game_name: "",
  };

  async componentDidMount() {
    let options = { url: get_spin_win_report_url };
    let api_response = await ApiRequest.get(options);

    let gameNameOptions = { url: game_name_url + "?allRecords=1" };
    let gameName_response = await ApiRequest.get(gameNameOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        // spinWins: api_response.data,
        // pagination: api_response.data.pagination,
        gameNameList: gameName_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          spinWins: api_response.data,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });
    let url = get_spin_win_report_url + "?page=" + pageNumber;
    if (this.state.spin_win_date_from) {
      url = url + "&spin_win_date_from=" + this.state.spin_win_date_from;
    }
    if (this.state.spin_win_date_to) {
      url = url + "&spin_win_date_to=" + this.state.spin_win_date_to;
    }
    if (this.state.name) {
      url = url + "&name=" + this.state.name;
    }
    if (this.state.oomcoId) {
      url = url + "&oomcoId=" + this.state.oomcoId;
    }
    if (this.state.game_name) {
      url = url + "&GameId=" + this.state.game_name;
    }
    if (this.state.winner_name) {
      url = url + "&PartitionValue=" + this.state.winner_name;
    }
    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        loadingCart: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        spinWins: response.data,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
        loadingCart: false,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = get_spin_win_report_url + "?csv=" + 1;

    if (this.state.spin_win_date_from) {
      url = url + "&spin_win_date_from=" + this.state.spin_win_date_from;
    }
    if (this.state.spin_win_date_to) {
      url = url + "&spin_win_date_to=" + this.state.spin_win_date_to;
    }
    if (this.state.name) {
      url = url + "&name=" + this.state.name;
    }
    if (this.state.oomcoId) {
      url = url + "&oomcoId=" + this.state.oomcoId;
    }
    if (this.state.game_name) {
      url = url + "&GameId=" + this.state.game_name;
    }
    if (this.state.winner_name) {
      url = url + "&PartitionValue=" + this.state.winner_name;
    }
    let options = {
      url: url,
      file_name:
        "Spin and Win Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };
    await ApiRequest.download(options);
  };
  spinWinStart = async (date) => {
    this.setState({
      spin_win_date_from: date,
      enableBtn: true,
      spinWins: [],
      pagination: {},
      limit: 10,
    });
  };
  spinWinEnd = async (date) => {
    this.setState({
      spin_win_date_to: date,
      enableBtn: true,
      spinWins: [],
      pagination: {},
      limit: 10,
    });
  };
  handleChangeName = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      name: event.target.value,
    });
  };
  handleChangeOomcoID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      oomcoId: event.target.value,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  gameListChange = async (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("data-id");

    this.setState({
      game_name: event.target.value,
      enableBtn: true,
    });

    let winner_response = {};
    let winnerOptions = {
      url: winner_spin_url + "?id=" + option,
    };
    winner_response = await ApiRequest.get(winnerOptions);
    if (winner_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: winner_response.message,
      });
    } else {
      this.setState({
        winnerList: winner_response.data.GameDetails,
        success: true,
        loading: false,
        success_message: winner_response.message,
      });
    }
  };
  winnerListChange = async (event) => {
    this.setState({
      winner_name: event.target.value,
      enableBtn: true,
    });
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }
  noResultText() {
    return "N/A";
  }

  render() {
    const {
      spinWins,
      pagination,
      loading,
      error,
      enableBtn,
      message,
      limit,
      callApi,
      loadingCart,
      gameNameList,
      winnerList,
    } = this.state;
    var quizWin = "";
    if (typeof spinWins.wins !== "undefined") {
      quizWin = spinWins.wins;
    }

    return (
      <Content title="Spin and Win Report" browserTitle="Spin and Win Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Spin and Win Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.spin_win_date_from}
                      selectsStart
                      spin_win_date_from={this.state.spin_win_date_from}
                      spin_win_date_to={this.state.spin_win_date_to}
                      onChange={this.spinWinStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.spin_win_date_to}
                      selectsEnd
                      spin_win_date_from={this.state.spin_win_date_from}
                      spin_win_date_to={this.state.spin_win_date_to}
                      onChange={this.spinWinEnd}
                      minDate={this.state.spin_win_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeName}
                      className="form-control"
                      placeholder="Enter name"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Oomco ID</label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeOomcoID}
                      className="form-control"
                      placeholder="Enter oomco ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <label>Loyalty Game Name</label>
                  <select
                    className="form-control"
                    id="loyaltyGameId"
                    value={this.state.game_name}
                    onChange={this.gameListChange}
                  >
                    <option value=""> Select Game ID </option>
                    {gameNameList &&
                      gameNameList.map((item, key) => {
                        return (
                          <option data-id={item.id} value={item.loyaltyGameId}>
                            {item.loyaltyGameName
                              ? item.loyaltyGameName
                              : "No Game Name"}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-xs-4">
                  <label>Winner</label>
                  <select
                    className="form-control"
                    id="Winner"
                    value={this.state.winner_name}
                    onChange={this.winnerListChange}
                    disabled={this.state.game_name == ""}
                  >
                    <option value="" hidden>
                      Select winner
                    </option>
                    {winnerList &&
                      winnerList.map((item, key) => {
                        return (
                          <option value={item.PartitionValue}>
                            {item
                              ? item.PartitionValue +
                                " - " +
                                item.Probability +
                                "%"
                              : "No Partition Value"}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total User Participated In Spin and Win
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : spinWins.totalUserParticipatedInQuiz ? (
                          <div>
                            <span className="info-box-number-custom">
                              {spinWins.totalUserParticipatedInQuiz}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4"></div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>Oomco ID</th>
                            <th>User Name</th>
                            <th>Loyalty Game Name</th>
                            <th>Winner</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {quizWin.length > 0 && quizWin ? (
                            quizWin.map((data) => {
                              return (
                                <tr>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user.id}>
                                          {this.rescue_value(data.user.oomcoId)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user.id}>
                                          {this.rescue_value(data.user.name)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a
                                          href={
                                            "/spin_win_view/" + data.gameData.id
                                          }
                                        >
                                          {data.gameData
                                            ? data.gameData.loyaltyGameName
                                            : "-"}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {data.attemptData.winnigPartition
                                      ? data.attemptData.winnigPartition
                                          .PartitionValue
                                      : "-"}
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(
                                        data.attemptData.createdAt
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="4" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={(num) =>
                                this.handlePageChange(num, "getReport")
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
