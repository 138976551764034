import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const userview_url = API.apiEndpoint + API.adminUserView;

export default class AdminUserView extends React.Component {
  state = {
    admin_user_view: {},
    loading: true,
    error: false,
    message: ""
  };

  async componentDidMount() {
    let url = userview_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        admin_user_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { admin_user_view, loading } = this.state;
    return (
      <Content title={"Admin User View"} browserTitle="Admin User View">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>ID</b>{" "}
                      <span className="pull-right">{admin_user_view.id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Name</b>{" "}
                      <span className="pull-right">
                        {admin_user_view.name}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Email</b>{" "}
                      <span className="pull-right">
                        {admin_user_view.email}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Role</b>{" "}
                      <span className="pull-right">
                        {admin_user_view.role}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>{" "}
                      <span className="pull-right">
                        {this.status_value(admin_user_view.status)}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Created At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(admin_user_view.createdAt)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
