import { Component } from "react";

import { Content, Row, Col } from "adminlte-2-react";

import LoadingAjax from "../../components/Loading";

import API from "../../constants";
import ApiRequest from "../../ApiRequest";
require("bootstrap/less/bootstrap.less");

const LimitHit = API.apiEndpoint + API.limitHittingRecording;

export default class LimitHitView extends Component {
	state = {
		data: {},
		status: "",
		discrepancy: "",
		error: false,
		success: false,
		message: "",
		loading: false,
		success_message: "",
		callApi: false,
	};

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData() {
		this.setState({ loading: true });
		let url = LimitHit + "/" + this.props.match.params.id;

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { results } = response.data;
			this.setState({
				loading: false,
				error: false,
				data: results,
			});
		}
	}

	render() {
		const { data, error, message, success, success_message } = this.state;
		return (
			<Content
				title="Limit Hitting Recording View"
				browserTitle="Limit Hitting Recording View"
			>
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<Col xs={12}>
						<div
							className="box box-primary"
							style={{ padding: "8px" }}
						>
							<h4 className="">OOMCO Id: {data?.oomcoId}</h4>
							<h4 className="">Type: {data?.type} </h4>
							<h4 className="">
								Created At:{" "}
								{ApiRequest.date_format_utc(data?.createdAt)}
								{" - "}
								{ApiRequest.time_format(data?.createdAt)}
							</h4>
							<div>
								<h3>RPS transactions List</h3>
								{data?.rpsTransactions &&
								data?.rpsTransactions.length > 0 ? (
									data.rpsTransactions.map((trx) => (
										<div key={trx.id}>
											<p>Transaction Id: {trx.id}</p>
										</div>
									))
								) : (
									<h4>Empty List</h4>
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}
