import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
//import { CSVLink } from "react-csv";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
require("bootstrap/less/bootstrap.less");

const recordInsurances_url = API.apiEndpoint + API.recordInsurances;
const cardplateTypes_url = API.apiEndpoint + API.carplateTypes;

export default class InsurancesReport extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    date_from: "",
    date_to: "",
    oomco_id: "",
    recordInsurances: [],
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    search_clear: true,
    pagination: {},
    status: "",
    completed: "",
    cardplateTypes: [],
  };

  async componentDidMount() {
    let options = { url: recordInsurances_url };
    let api_response = await ApiRequest.get(options);

    let CarPlateOptions = { url: cardplateTypes_url };
    let carPlateType = await ApiRequest.get(CarPlateOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        recordInsurances: api_response.data.results,
        pagination: api_response.data.pagination,
        cardplateTypes: carPlateType.data,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          recordInsurances: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });
    let url = recordInsurances_url + "?page=" + pageNumber;

    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.vno) {
      url = url + "&vno=" + this.state.vno;
    }
    if (this.state.vchar) {
      url = url + "&vchar=" + this.state.vchar;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        recordInsurances: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = recordInsurances_url + "?csv=" + 1;
    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.vno) {
      url = url + "&vno=" + this.state.vno;
    }
    if (this.state.vchar) {
      url = url + "&vchar=" + this.state.vchar;
    }
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = {
      url: url,
      file_name:
        "Insurance Report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  handleChangeOomcoID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      oomco_id: event.target.value,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      date_from: "",
      date_to: "",
      oomco_id: "",
    });
  };
  handleChangeCarPlate = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      vno: event.target.value,
    });
  };
  // handleChangeCarType = (event) => {
  //   this.setState({
  //     enableBtn: true,
  //     pagination: {},
  //     limit: 10,
  //     vchar: event.target.value,
  //   });
  // };
  handleChangeCarPlateType = async (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 10,
      vchar: event.target.value,
    });
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }

  handleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  rescue_status(val) {
    return val === "failed" ? "Incompleted" : val;
  }

  render() {
    const { Select2 } = Inputs;
    const {
      recordInsurances,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
      cardplateTypes,
    } = this.state;
    return (
      <Content title="Insurance" browserTitle="Insurance">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                    //disabled={!enableBtn}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>OOMCO ID </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.value}
                      onChange={this.handleChangeOomcoID}
                      className="form-control"
                      placeholder="Enter Oomco ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Car Plate </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeCarPlate}
                      className="form-control"
                      placeholder="Enter Car Plate"
                    />
                  </div>
                </div>
                {/*<div className="col-xs-4">
                  <div className="form-group">
                    <label>Car Type </label>
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChangeCarType}
                      className="form-control"
                      placeholder="Enter Car Type"
                    />
                  </div>
          </div>*/}

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Car Type </label>
                    <select
                      className="form-control "
                      id="carplatetype"
                      name="carplatetype"
                      onChange={this.handleChangeCarPlateType}
                      value={this.state.vchar}
                    >
                      <option value="">Select</option>
                      {cardplateTypes.length > 0 &&
                        cardplateTypes.map((item, key) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_from}
                      selectsStart
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_to}
                      selectsEnd
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={this.state.status}
                      onChange={this.handleChangeStatus}
                    >
                      <option value="">Select</option>
                      <option value={"completed"}>Completed</option>
                      <option value={"failed"}>Incompleted</option>
                    </select>
                  </div>
                </div>

                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  {/*<button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                  >
                    Clear
                  </button>*/}
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>OOMCO ID</th>
                            <th>Brand</th>
                            <th>Model</th>
                            <th>Car Plate</th>
                            <th>Car Type</th>
                            <th>CtId</th>
                            <th>Total Paid</th>
                            <th>Commission Amount</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {recordInsurances.length > 0 && recordInsurances ? (
                            recordInsurances.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.oomco_id)}</td>

                                  <td>{this.rescue_value(data.brand)}</td>
                                  <td>{this.rescue_value(data.model)}</td>
                                  <td>{this.rescue_value(data.vno)}</td>
                                  <td>{this.rescue_value(data.vchar)}</td>
                                  <td>{this.rescue_value(data.ctId)}</td>
                                  <td>
                                    {this.rescue_value(
                                      data.TotalPremiumPaid_ToProvider
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.CommissionAmount)}
                                  </td>
                                  <td>{this.rescue_status(data.status)}</td>

                                  <td>
                                    {ApiRequest.date_format_utc(data.updatedAt)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="10" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
