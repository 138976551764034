import React from "react";
import { Content } from "adminlte-2-react";
import CKEditor from "react-ckeditor-component";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";

const cms_url = API.apiEndpoint + API.cms;

export default class CmsForm extends React.Component {
  state = {
    page: {},
    action: "add",
    name: "",
    html: {
      en: "",
      ar: ""
    },
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: ""
  };

  async componentDidMount() {
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        name: this.props.page.name,
        html: this.props.page.html,
        page: this.props.page
      });
    }
  }

  // componentDidMount() {
  //   if (this.props.action === "edit")
  //     this.setState({
  //       action: this.props.action,
  //       name: this.props.page.name,
  //       html: this.props.page.html,
  //       page: this.props.page
  //     });

  //   // CKEDITOR.config.language = "ar";
  // }

  contentChange = (lang, evt) => {
    let value = evt.editor.getData();
    this.setState(prevState => {
      let html = Object.assign({}, prevState.html); // creating copy of state variable tip
      html[lang] = value; // update the name property, assign a new value
      return { html }; // return new object tip object
    });
    return true;
    // this.setState({ html: evt.editor.getData() });
  };

  nameChange = event => {
    this.setState({ name: event.target.value });
  };

  saveCMS = async event => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("html", JSON.stringify(this.state.html));

    let api_response = {};
    let options = { url: cms_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      options = {
        url: cms_url + "/" + this.state.page.id,
        form_data: formData
      };
      api_response = await ApiRequest.put(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message
      });
      setTimeout(
        function() {
          this.setState({ success: false });
          window.location = "/cms";
        }.bind(this),
        10000
      );
    }
  };

  // saveCMS = async event => {
  //   const formData = new FormData();
  //   formData.append("name", this.state.name);
  //   formData.append("html", JSON.stringify(this.state.html));
  //   let response = {};
  //   if (this.state.action === "add") {
  //     response = await axios.post(cms_url, formData);
  //   } else {
  //     response = await axios.put(cms_url + "/" + this.state.page.id, formData);
  //   }

  //   if (response.status === 200) {
  //     window.location = "/cms";
  //   }
  // };

  backCMS = () => {
    window.location = "/cms";
  };

  render() {
    let CONFIG = { language: "ar" };
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="CMS Page" browserTitle="CMS Page">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">CMS Form </h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.nameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="html">Content English</label>
                  <CKEditor
                    activeClass="p10"
                    content={this.state.html.en}
                    events={{
                      change: this.contentChange.bind(null, "en")
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="html">Content Arabic</label>
                  <CKEditor
                    activeClass="p10"
                    content={this.state.html.ar}
                    lang={this.arabic}
                    events={{
                      change: this.contentChange.bind(null, "ar")
                    }}
                    config={CONFIG}
                  />
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveCMS}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.props.handleClick.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
