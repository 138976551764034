import React from "react";
import { Content } from "adminlte-2-react";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import { Inputs } from "adminlte-2-react";
import LoadingAjax from "../../components/Loading";
//import CKEditor from "react-ckeditor-component";

const pushNotication_url = API.apiEndpoint + API.pushNotification;
const city_url = API.apiEndpoint + API.city;

export default class Notification extends React.Component {
  state = {
    notification: {},
    notification_title: "",
    notification_body: "",
    for_all_users: "1",
    //for_card_owners: "",
    for_nss_holder: "",
    for_language: "",
    oomcoId: "",
    show_oomco_id: false,
    loading: true,
    error: false,
    message: "",
    success: false,
    success_message: "",
    cityId: "",
    cities: [],
    gender: "",
    age_from: "",
    age_to: "",
    no_of_vehicles_from: "",
    no_of_vehicles_to: "",
  };

  async componentDidMount() {
    let optionsCity = { url: city_url };
    let city_response = await ApiRequest.get(optionsCity);
    if (city_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: city_response.message,
      });
    } else {
      let cities = [];
      city_response.data.map((city) => {
        cities.push({ value: city.cityId, label: city.name });
      });
      this.setState({
        cities: cities,
        loading: false,
        error: false,
        message: city_response.message,
      });
    }
  }

  handleChangeRegion = async (selectedRegion) => {
    this.setState({
      cityId: selectedRegion.target.value,
    });
  };
  changeNotTitle = (event) => {
    this.setState({
      notification_title: event.target.value,
    });
  };
  changeContent = (event) => {
    this.setState({
      notification_body: event.target.value,
    });
  };
  changeNotOmcoId = (event) => {
    this.setState({
      oomcoId: event.target.value,
    });
  };
  notUsers = (event) => {
    this.setState({
      for_all_users: event.target.value,
    });
  };
  // cardOwners = event => {
  //   this.setState({
  //     for_card_owners: event.target.value
  //   });
  // };
  nssHolder = (event) => {
    this.setState({
      for_nss_holder: event.target.value,
    });
  };
  notLanguage = (event) => {
    this.setState({
      for_language: event.target.value,
    });
  };
  notGender = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };
  not_age_from = (event) => {
    this.setState({
      age_from: event.target.value,
    });
  };
  not_age_to = (event) => {
    this.setState({
      age_to: event.target.value,
    });
  };
  handleChangeVehiclesCardFrom = (event) => {
    this.setState({
      no_of_vehicles_from: event.target.value,
    });
  };
  handleChangeVehiclesCardTo = (event) => {
    this.setState({
      no_of_vehicles_to: event.target.value,
    });
  };

  contentChange = (evt) => {
    let value = evt.editor.getData();
    this.setState({
      notification_body: value,
    });
    return true;
  };

  isStateEmpty(value) {
    return value === "" || !value;
  }

  submitNotification = async (event) => {
    const {
      for_card_owners,
      for_nss_holder,
      for_language,
      gender,
    } = this.state;
    const formData = new FormData();
    formData.append("for_all_users", this.state.for_all_users);

    // {
    //   !this.isStateEmpty(for_card_owners) &&
    //     formData.append("for_card_owners", for_card_owners);
    // }

    {
      !this.isStateEmpty(for_nss_holder) &&
        formData.append("for_nss_holder", this.state.for_nss_holder);
    }

    {
      !this.isStateEmpty(for_language) &&
        formData.append("for_language", this.state.for_language);
    }

    {
      !this.isStateEmpty(gender) &&
        formData.append("gender", this.state.gender);
    }

    formData.append("age_from", this.state.age_from);
    formData.append(
      "age_to",
      this.state.age_to === "" ? this.state.age_from : this.state.age_to
    );
    formData.append("no_of_vehicles_from", this.state.no_of_vehicles_from);
    formData.append("no_of_vehicles_to", this.state.no_of_vehicles_to);
    formData.append("cityId", this.state.cityId);
    formData.append("oomcoId", this.state.oomcoId);
    formData.append("notification_title", this.state.notification_title);
    formData.append("notification_body", this.state.notification_body);
    formData.append("target_identifier", "admin_notification");

    let api_response = {};
    let options = { url: pushNotication_url, form_data: formData };

    options = {
      url: pushNotication_url,
      form_data: formData,
    };

    api_response = await ApiRequest.sendNotification(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: ApiRequest.string_humanize_format(api_response.message),
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: ApiRequest.string_humanize_format(
          api_response.message
        ),
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/notification";
        }.bind(this),
        10000
      );
    }
  };

  render() {
    const { Select2 } = Inputs;
    const { error, message, cities, success, success_message } = this.state;
    return (
      <Content title="Notification" browserTitle="Notification">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Notification </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="name">Notify To</label>
                    <select
                      className="form-control"
                      value={this.state.for_all_users}
                      onChange={this.notUsers}
                    >
                      <option value="1">All Users</option>
                      <option value="0">Single User</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="name">OOMCO ID</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.value}
                      onChange={this.changeNotOmcoId}
                      disabled={this.state.for_all_users == 1}
                    />
                  </div>
                  {/*<div className="form-group col-md-4">
                    <label htmlFor="name">Card Owners</label>
                    <select
                      className="form-control"
                      value={this.state.for_card_owners}
                      onChange={this.cardOwners}
                      disabled={this.state.for_all_users == 0}
                    >
                      <option value="">All</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
          </div>*/}
                  <div className="form-group col-md-4">
                    <div className="form-group rangeFilter">
                      <label
                        style={{
                          display: "block",
                        }}
                      >
                        Number of Vehicles/Card{" "}
                      </label>
                      <input
                        type="number"
                        min={0}
                        value={this.state.no_of_vehicles_from}
                        onChange={this.handleChangeVehiclesCardFrom}
                        className="form-control"
                        placeholder="From"
                        disabled={this.state.for_all_users == 0}
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                      <input
                        type="number"
                        min={0}
                        value={this.state.no_of_vehicles_to}
                        onChange={this.handleChangeVehiclesCardTo}
                        className="form-control"
                        placeholder="To"
                        disabled={this.state.for_all_users == 0}
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="name">NSS Holder</label>
                    <select
                      className="form-control"
                      value={this.state.for_nss_holder}
                      onChange={this.nssHolder}
                      disabled={this.state.for_all_users == 0}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="name">Language</label>
                    <select
                      className="form-control"
                      value={this.state.for_language}
                      onChange={this.notLanguage}
                      disabled={this.state.for_all_users == 0}
                    >
                      <option value="">Select</option>
                      <option value="en">English</option>
                      <option value="ar">Arabic</option>
                    </select>
                  </div>
                  <div className="col-xs-4 cusSelect">
                    <Select2
                      label="City"
                      value={this.state.selectedRegion}
                      onChange={this.handleChangeRegion}
                      options={cities}
                      placeholder="Select"
                      disabled={this.state.for_all_users == 0}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Gender</label>
                    <select
                      className="form-control"
                      value={this.state.gender}
                      onChange={this.notGender}
                      disabled={this.state.for_all_users == 0}
                    >
                      <option value="">Select</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="0">Un specified</option>
                    </select>
                  </div>
                  <div className="col-xs-6">
                    <div className="form-group">
                      <label
                        style={{
                          display: "block",
                        }}
                      >
                        Age{" "}
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        value={this.state.age_from}
                        onChange={this.not_age_from}
                        className="form-control"
                        placeholder="From Age"
                        disabled={this.state.for_all_users == 0}
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                      <input
                        type="number"
                        min={0}
                        max={100}
                        value={this.state.age_to}
                        onChange={this.not_age_to}
                        className="form-control"
                        placeholder="To Age"
                        disabled={this.state.for_all_users == 0}
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.value}
                    onChange={this.changeNotTitle}
                    style={{ textTransform: "capitalize" }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="html">Message</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.value}
                    onChange={this.changeContent}
                  />

                  {/*<CKEditor
                    activeClass="p10"
                    content={this.state.notification_body}
                    events={{
                      change: this.contentChange.bind(null)
                    }}
                  />*/}
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.submitNotification}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                {/*<button
                  onClick={this.props.handleClick.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>*/}
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
