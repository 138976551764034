import React from "react";
import LoyaltySubTiersListing from "./listing";
import LoyaltySubTiersForm from "../loyalty_sub_tiers_form";

export default class LoyaltySubTiers extends React.Component {
  state = {
    action: "listing",
    page: {},
  };

  handleClick = (page, action) => {
    this.setState({ action: action, page: page });
  };

  render() {
    const { action, page } = this.state;
    if (action === "edit" || action === "add") {
      return <LoyaltySubTiersForm action={action} page={page} />;
    } else {
      return <LoyaltySubTiersListing />;
    }
  }
}
