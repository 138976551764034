import React from "react";
import ContactView from "./view";
import ContactListing from "./listing";
import axios from "axios";
import API from "../../constants";

const contact_url = API.apiEndpoint + API.contactReview;

export default class ContactForm extends React.Component {
  state = {
    action: "listing",
    contact: {}
  };

  async componentDidMount() {
    if (this.props.view) {
      const response = await axios.get(
        contact_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewContact(response.data.data, "view");
      }
    }
  }

  viewContact = (contact, action) => {
    this.setState({ action: action, contact: contact });
  };

  render() {
    const { action, contact } = this.state;

    if (action === "view")
      return <ContactView contact={contact} viewContact={this.viewContact} />;
    else return <ContactListing viewContact={this.viewContact} />;
  }
}
