import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import $ from "jquery";

const campaingBarCode = API.apiEndpoint + API.campaingBarCode;
const badgeManagement_url = API.apiEndpoint + API.badgeManagement;

export default class Campaign_form extends React.Component {
  state = {
    page: {},
    action: "add",
    promo_code_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    badge_management: [],
    mechanic_id: "1",
  };

  async componentDidMount() {
    let options = { url: badgeManagement_url + "?allRecords=1" };

    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        badgeData: api_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }
  titleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  mechanic_id_Change = (event) => {
    this.setState({ mechanic_id: event.target.value });
  };
  badgeNameChange = (event) => {
    var rule_id = $("option:selected", event.target).attr("data-id");
    var action = $("option:selected", event.target).attr("data-action");
    var title = $("option:selected", event.target).attr("data-title");
    this.setState({
      badgeNameValue: event.target.value,
      badgeId: rule_id,
      badgeAction: action,
      badgeTitle: title,
    });
  };

  saveTopupPromotions = async (event) => {
    // let error = false;
    // if (!this.state.title) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Title is required",
    //   });
    // }

    // if (!this.state.badgeNameValue) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Badge Name is required",
    //   });
    //   return;
    // }

    const formData = new FormData();

    let data = {
      campaign_title: this.state.title,
      badge_title: this.state.badgeTitle,
      badge_id: this.state.badgeId,
      badge_action: this.state.badgeAction,
      mechanic_id: this.state.mechanic_id,
    };

    // formData.append("title", this.state.title);

    // formData.append("badge_name", this.state.badgeNameValue);
    // formData.append("badge_id", this.state.badgeId);
    // formData.append("badge_action", this.state.badgeAction);
    // formData.append("mechanic_id", this.state.popup);

    let api_response = {};
    let options = { url: campaingBarCode, form_data: data };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/campaign";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/campaign";
  };

  render() {
    let { error, message, success, success_message, badgeData } = this.state;
    return (
      <Content title="Campaign" browserTitle="Campaign">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Campaign Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="title">Campaign Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.titleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Badge Name</label>
                    <select
                      className="form-control"
                      value={this.state.badgeNameValue}
                      onChange={this.badgeNameChange}
                    >
                      <option value="">Select Badge Name</option>
                      {badgeData &&
                        badgeData.map((data) => {
		            return (
		              <option
		                data-id={data.id}
		                data-action={data.action}
		                data-title={data.title.en}
		                data-status={data.status}
		              >
		                {data.title.en}
		              </option>
		            );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="MechanicID">Mechanic</label>
                    <select
                      className="form-control"
                      value={this.state.mechanic_id}
                      onChange={this.mechanic_id_Change}
                    >
                      <option value="1">Enable </option>
                      <option value="0">Disable</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveTopupPromotions}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
