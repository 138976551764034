import React from "react";
import DataTable from "react-data-table-component";

const data = [
  { id: 1, title: "Conan the Barbarian", year: "1982" },
  { id: 2, title: "Conan the Barbarian", year: "19821" },
  { id: 3, title: "Conan the Barbarian", year: "1983212" },
  { id: 4, title: "Conan the Barbarian", year: "1982" },
  { id: 5, title: "Conan the Barbarian", year: "2311982" },
  { id: 6, title: "Conan the Barbarian", year: "1982" },
  { id: 7, title: "Conan the Barbarian", year: "1982" },
  { id: 8, title: "Conan the Barbarian", year: "1982" },
  { id: 9, title: "Conan the Barbarian", year: "1982" },
  { id: 10, title: "Conan the Barbarian", year: "1982" },
  { id: 11, title: "Conan the Barbarian", year: "1982" },
  { id: 14, title: "Conan the Barbarian", year: "1982" },
  { id: 111, title: "Conan the Barbarian", year: "1982" },
  { id: 12, title: "Conan the Barbarian", year: "1982" },
  { id: 13, title: "Conan the Barbarian", year: "1982" },
  { id: 124, title: "Conan the Barbarian", year: "1982" },
  { id: 121, title: "Conan the Barbarian", year: "1982" },
  { id: 212, title: "Conan the Barbarian", year: "1982" },
  { id: 1233, title: "Conan the Barbarian", year: "1982" },
  { id: 123224, title: "Conan the Barbarian", year: "1982" },
  { id: 12123, title: "Conan the Barbarian", year: "1982" },
  { id: 123122, title: "Conan the Barbarian", year: "1982" },
  { id: 12313, title: "Conan the Barbarian", year: "1982" },
  { id: 4123, title: "Conan the Barbarian", year: "1982" },
  { id: 1231, title: "Conan the Barbarian", year: "1982" },
  { id: 2123, title: "Conan the Barbarian", year: "1982" },
  { id: 3453, title: "Conan the Barbarian", year: "1982" },
  { id: 3454, title: "Conan the Barbarian", year: "1982" }
];
const columns = [
  {
    name: "Title",
    selector: "title",
    sortable: true
  },
  {
    name: "Year",
    selector: "year",
    sortable: true,
    right: true
  }
];

class DataTable2 extends React.Component {
  render() {
    return (
      <DataTable
        title="Arnold Movies"
        selectableRows={true}
        columns={columns}
        highlightOnHover={true}
        pointerOnHover={true}
        pagination={true}
        striped={true}
        data={data}
        paginationPerPage={3}
      />
    );
  }
}

export default DataTable2;
