import React, { Component } from "react";
import PropTypes from "prop-types";
import { Content, Row, Col } from "adminlte-2-react";
import LoadingAjax from "../../components/Loading";

export default class StoreView extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {
    const { store } = this.props;
    return (
      <Content title={"Store Detail"} browserTitle="Store Detail">
        <LoadingAjax />
        <Row>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Store Info</h3>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>ID</b> <span className="pull-right">{store.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Store Name</b>{" "}
                    <span className="pull-right">{store.store_name}</span>
                  </li>

                  <li className="list-group-item">
                    <b>Store Code</b>{" "}
                    <span className="pull-right">{store.store_code}</span>
                  </li>
                  <li className="list-group-item">
                    <b>City</b> <span className="pull-right">{store.city}</span>
                  </li>

                  <li className="list-group-item">
                    <b>Store Token</b>{" "}
                    <span className="pull-right">{store.store_token}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
