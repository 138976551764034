import React from "react";
import CKEditor from "react-ckeditor-component";

class CKEditor2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    this.setState({ content: evt.editor.getData() });
  }

  render() {
    return (
      <CKEditor
        activeClass="p10"
        content={this.state.content}
        events={{
          blur: this.onBlur,
          afterPaste: this.afterPaste,
          change: this.onChange
        }}
      />
    );
  }
}

export default CKEditor2;
