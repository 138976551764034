import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";

// const inAppPromotions_form_url = API.apiEndpoint + API.spinAndWin;
// const spinWinList_url = API.apiEndpoint + API.spinAndWinList;
// const schedule_spin_win_url = API.apiEndpoint + API.scheduleSpinAndWin;

const inAppPromotions_form_url = API.apiEndpoint + API.InappPromotions;
const UploadFile_url = API.apiEndpoint + API.UploadFile;

export default class InAppPromotionsEditForm extends React.Component {
  state = {
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    // cta_title: { en: "", ar: "" },
    // cta_link: { en: "", ar: "" },
  };

  async componentDidMount() {
    let url = inAppPromotions_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    let data = api_response.data;

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      var startDate = new Date(data.from);
      var endDate = new Date(moment.utc(data.to).format("MM/DD/YYYY"));
      this.setState({
        title: data.title,
        in_app_date_from: startDate,
        in_app_date_to: endDate,
        order: data.order,
        popup: data.poupup,
        engImgSquare: data.image_english_square,
        arImgSquare: data.image_arabic_square,
        engImgRectangle: data.image_english_rectangle,
        arImgRectangle: data.image_arabic_rectangle,
        cta_title: data.cta_title,
        cta_link: data.cta_link,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    // let SpinWinListoptions = { url: spinWinList_url };
    // let api_spinWinList_response = await ApiRequest.get(SpinWinListoptions);
    // if (api_spinWinList_response.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response.message,
    //   });
    // } else {
    //   {
    //     api_spinWinList_response &&
    //       api_spinWinList_response.data.map((spin_list, key) => {
    //         this.state.spin_win_list.push(spin_list[0]);
    //       });
    //   }
    //   this.setState({
    //     win_list: api_spinWinList_response.data,
    //     loading: false,
    //     error: false,
    //     message: api_response.message,
    //   });
    // }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  titleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  ctaTitleChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let cta_title = Object.assign({}, prevState.cta_title); // creating copy of state variable tip
      cta_title[lang] = value; // update the name property, assign a new value
      return { cta_title }; // return new object tip object
    });
    return true;
  };
  ctaLinkChange = (lang, event) => {
    let value = event.target.value;
    this.setState((prevState) => {
      let cta_link = Object.assign({}, prevState.cta_link); // creating copy of state variable tip
      cta_link[lang] = value; // update the name property, assign a new value
      return { cta_link }; // return new object tip object
    });
    return true;
  };
  handleChangeOrder = (event) => {
    this.setState({
      order: event.target.order,
    });
  };
  onClickEngImageHandler = async (event) => {
    this.setState({ selectedEngFile: ("file", event.target.files[0]) });
  };
  onClickArImageHandler = async (event) => {
    this.setState({ selectedArFile: ("file", event.target.files[0]) });
  };
  popupChange = (event) => {
    this.setState({ popup: event.target.value });
  };
  inAppPromotionsFrom = async (date) => {
    this.setState({
      in_app_date_from: date,
      enableBtn: true,
    });
  };
  inAppPromotionsTo = async (date) => {
    this.setState({
      in_app_date_to: date,
      enableBtn: true,
    });
  };
  EngImageSquareHandler = async (data) => {
    const formData = new FormData();
    formData.append("file", $(data.target)[0].files[0]);
    let api_response = {};
    api_response = await axios.post(UploadFile_url, formData, {
      headers: { "X-Access-Token": API.storageAccessToken },
    });
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        success_message: api_response.data.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.data.message,
        engImgSquare: api_response.data.data,
      });
    }
  };
  
  ArImageSquareHandler = async (data) => {
    const formData = new FormData();
    formData.append("file", $(data.target)[0].files[0]);

    let api_response = {};

    api_response = await axios.post(UploadFile_url, formData, {
      headers: { "X-Access-Token": API.storageAccessToken },
    });
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        success_message: api_response.data.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.data.message,
        arImgSquare: api_response.data.data,
      });
    }
  };

  EngImageRectangleHandler = async (data) => {
    const formData = new FormData();
    formData.append("file", $(data.target)[0].files[0]);

    let api_response = {};

    api_response = await axios.post(UploadFile_url, formData, {
      headers: { "X-Access-Token": API.storageAccessToken },
    });
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        success_message: api_response.data.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.data.message,
        engImgRectangle: api_response.data.data,
      });
    }
  };
  ArImageRectangleHandler = async (data) => {
    const formData = new FormData();
    formData.append("file", $(data.target)[0].files[0]);

    let api_response = {};

    api_response = await axios.post(UploadFile_url, formData, {
      headers: { "X-Access-Token": API.storageAccessToken },
    });
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        success_message: api_response.data.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.data.message,
        arImgRectangle: api_response.data.data,
      });
    }
  };

  saveInApp = async (event) => {
    let error = false;

    if (!this.state.order) {
      error = true;
      this.setState({
        error: true,
        message: "Order is required",
      });
    }
    if (!this.state.cta_link.en) {
      error = true;
      this.setState({
        error: true,
        message: "CTA Link English is required",
      });
    }
    if (!this.state.cta_link.ar) {
      error = true;
      this.setState({
        error: true,
        message: "CTA Link Arabic is required",
      });
    }
    if (!this.state.cta_title.ar) {
      error = true;
      this.setState({
        error: true,
        message: "CTA Title Arabic is required",
      });
    }
    if (!this.state.cta_title.en) {
      error = true;
      this.setState({
        error: true,
        message: "CTA Title English is required",
      });
    }

    if (!this.state.in_app_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "Date To is required",
      });
    }
    if (!this.state.in_app_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Date From is required",
      });
    }
    if (!this.state.title) {
      error = true;
      this.setState({
        error: true,
        message: "Title is required",
      });
    }

    if (error) {
      return;
    }
    var moment = require("moment");
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("cta_title", JSON.stringify(this.state.cta_title));
    formData.append("cta_link", JSON.stringify(this.state.cta_link));
    formData.append("order", this.state.order);
    formData.append(
      "image_english_square",
      JSON.stringify(this.state.engImgSquare)
    );
    formData.append(
      "image_arabic_square",
      JSON.stringify(this.state.arImgSquare)
    );
    formData.append(
      "image_english_rectangle",
      JSON.stringify(this.state.engImgRectangle)
    );
    formData.append(
      "image_arabic_rectangle",
      JSON.stringify(this.state.arImgRectangle)
    );
    // formData.append("ar_image", this.state.selectedArFile);
    formData.append("poupup", this.state.popup);
    formData.append(
      "from",
      moment
        .utc(this.state.in_app_date_from)

        .startOf("day")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.in_app_date_to)
        .add(1, "days")
        .endOf("day")
        .valueOf()
    );

    let api_response = {};
    let options = { url: inAppPromotions_form_url, form_data: formData };

    options.url = inAppPromotions_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location.href = "/in_app_promotions/";
        }.bind(this),
        1000
      );
    }
  };

  backUser = () => {
    window.loctaion = "/in_app_promotions/";
  };

  render() {
    let obj = {};
    let { error, message, success, success_message } = this.state;
    let title = "";
    if (typeof this.state.cta_title !== "undefined") {
      title = this.state.cta_title;
    }

    let link = "";
    if (typeof this.state.cta_link !== "undefined") {
      link = this.state.cta_link;
    }

    // let engImgSquare = "";
    if (typeof this.state.engImgSquare !== "undefined")
      obj.engImgSquare = this.state.engImgSquare.file
        ? this.state.engImgSquare.file
        : this.state.engImgSquare
        ? this.state.engImgSquare
        : this.state.image_english_square;

    // let arImgSquare = "";
    if (typeof this.state.arImgSquare !== "undefined")
      obj.arImgSquare = this.state.arImgSquare.file
        ? this.state.arImgSquare.file
        : this.state.arImgSquare
        ? this.state.arImgSquare
        : this.state.image_arabic_square;

    // let engImgRectangle = "";
    if (typeof this.state.engImgRectangle !== "undefined")
      obj.engImgRectangle = this.state.engImgRectangle.file
        ? this.state.engImgRectangle.file
        : this.state.engImgRectangle;

    // let arImgRectangle = "";
    if (typeof this.state.arImgRectangle !== "undefined")
      obj.arImgRectangle = this.state.arImgRectangle.file
        ? this.state.arImgRectangle.file
        : this.state.arImgRectangle;

    obj.state = this.state;

    return (
      <Content title="In-App Promotions" browserTitle="In-App Promotions">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">In-App Promotions Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.titleChange}
                    />
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-6">
                  <label>Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.in_app_date_from}
                    selectsStart
                    minDate={moment().toDate()}
                    in_app_date_from={this.state.in_app_date_from}
                    in_app_date_to={this.state.in_app_date_to}
                    onChange={this.inAppPromotionsFrom}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.in_app_date_to}
                    selectsEnd
                    in_app_date_from={this.state.in_app_date_from}
                    in_app_date_to={this.state.in_app_date_to}
                    onChange={this.inAppPromotionsTo}
                    minDate={this.state.in_app_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>Image File </label>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="cta_title_en">
                          English Square Version{" "}
                        </label>
                        <input
                          type="file"
                          id="imageEngVersion"
                          placeholder="Name english"
                          ref={(ref) => (this.imageEngVersion = ref)}
                          onChange={this.EngImageSquareHandler}
                          accept=".png, .jpeg"
                        />
                      </div>
                      {obj.engImgSquare === "" ? (
                        ""
                      ) : (
                        <img
                          src={obj.engImgSquare}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="cta_title_ar">
                          Arabic Square Version{" "}
                        </label>
                        <input
                          type="file"
                          id="imageArVersion"
                          placeholder="Name english"
                          ref={(ref) => (this.imageArVersion = ref)}
                          onChange={this.ArImageSquareHandler}
                          accept=".png, .jpeg"
                        />
                      </div>
                      {obj.arImgSquare === "" ? (
                        ""
                      ) : (
                        <img
                          src={obj.arImgSquare}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="cta_title_en">
                          English Rectangle Version{" "}
                        </label>
                        <input
                          type="file"
                          id="imageEngVersion"
                          placeholder="Name english"
                          ref={(ref) => (this.imageEngRecVersion = ref)}
                          onChange={this.EngImageRectangleHandler}
                          accept=".png, .jpeg"
                        />
                      </div>

                      {obj.engImgRectangle === "" ? (
                        ""
                      ) : (
                        <img
                          src={obj.engImgRectangle}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex", "align-items": "center" }}>
                      <div className="form-group">
                        <label htmlFor="cta_title_ar">
                          Arabic Rectangle Version{" "}
                        </label>
                        <input
                          type="file"
                          id="imageArVersion"
                          placeholder="Name english"
                          ref={(ref) => (this.imageArRecVersion = ref)}
                          onChange={this.ArImageRectangleHandler}
                          accept=".png, .jpeg"
                        />
                      </div>

                      {obj.arImgRectangle === "" ? (
                        ""
                      ) : (
                        <img
                          src={obj.arImgRectangle}
                          alt="Logo"
                          style={{ width: "120px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>CTA Title </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cta_title_en">English</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cta_title_en"
                        placeholder="Name english"
                        value={title.en}
                        onChange={this.ctaTitleChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cta_title_ar">Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cta_title_ar"
                        placeholder="Name arabic"
                        value={title.ar}
                        onChange={this.ctaTitleChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0">
                  <div className="col-sm-12">
                    <label>CTA Link </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cta_link_en">English</label>
                      <input
                        type="url"
                        className="form-control"
                        id="cta_link_en"
                        placeholder="Name english"
                        value={link.en}
                        onChange={this.ctaLinkChange.bind(null, "en")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cta_link_ar">Arabic</label>
                      <input
                        type="url"
                        className="form-control"
                        id="cta_link_ar"
                        placeholder="Name arabic"
                        value={link.ar}
                        onChange={this.ctaLinkChange.bind(null, "ar")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="form-group">
                    <label>Order </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.order}
                      onChange={this.handleChangeOrder}
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="popup">Popup</label>
                    <select
                      className="form-control"
                      value={this.state.popup}
                      onChange={this.popupChange}
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveInApp}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
