import React from "react";
import { Content } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./updateVehicle.css";
const card_detail_url = API.apiEndpoint + API.getCard;
const cardOperation_url = API.apiEndpoint + API.cardOperation;
const cardplateTypes_url = API.apiEndpoint + API.carplateTypes;

export default class UpdateVehicle extends React.Component {
  state = {
    card: {},
    error: false,
    success: false,
    card_number: "",
    error_message: "",
    new_car_plate: "",
    success_message: "",
    cardplateTypes: [],
    simpleNumber: {},
  };

  async componentDidMount() {
    let options = { url: card_detail_url + "/" + this.props.match.params.id };
    let api_response = await ApiRequest.get(options);

    let CarPlateOptions = { url: cardplateTypes_url };
    let carPlateType = await ApiRequest.get(CarPlateOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        card: api_response.data,
        //transactions: api_response.data.transactions,
        cardplateTypes: carPlateType.data,
        car_plate: carPlateType.data[0].name,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }
  handleChangeCarPlateType = async (event) => {
    this.setState({
      car_plate: event.target.value,
    });
  };

  numberChange = (event) => {
    if (event.target.value.length > 5) {
      this.setState({
        error: true,
        error_message: "Number is required limited to 5 digit",
      });
    } else {
      this.setState({ error: false, simpleNumber: event.target.value });
    }
  };

  updateVehicle = async () => {
    confirmAlert({
      title: "Update Vehicle",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmUpdateVehicle(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  confirmUpdateVehicle = async (event) => {
    const formData = new FormData();
    let error = false;
    if (!this.state.simpleNumber.length > 0) {
      error = true;
      this.setState({
        error: true,
        error_message: "Number is required",
      });
    }
    if (error) {
      return;
    }
    formData.append("card_number", this.state.card.card_number);
    //formData.append("new_car_plate", this.state.new_car_plate);
    formData.append("method_name", "update_vehicle");
    formData.append("transaction_id", new Date().getTime());
    formData.append("request_from", API.request_from);
    formData.append(
      "new_car_plate",
      this.state.simpleNumber + this.state.car_plate
    );
    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
      store_code: API.storeCode,
      store_token: API.storeToken,
    };
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: ApiRequest.string_humanize_format(
              response.data.message
            ),
          });
          window.location = "/basma_cards_management";
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        this.setState({
          error: true,
          error_message: message.param_missing
            ? ApiRequest.string_humanize_format(message.param_missing) +
              " is required"
            : message,
        });
      });
  };

  backCard = () => {
    window.location = "/card/" + this.state.card.id;
  };

  render() {
    const {
      card,
      error,
      error_message,
      success,
      success_message,
      cardplateTypes,
    } = this.state;
    return (
      <Content title="Update Vehicle" browserTitle="Update Vehicle">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {error_message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Update Vehicle </h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="card_number">Card Serial Number </label>
                    <input
                      type="text"
                      className="form-control"
                      id="card_number"
                      value={card.card_number}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="number">New Car Plate </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control "
                          id="number"
                          placeholder="00000"
                          value={this.state.simpleNumber}
                          onChange={this.numberChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control "
                          id="carplatetype"
                          name="carplatetype"
                          onChange={this.handleChangeCarPlateType}
                          value={this.state.car_plate}
                        >
                          {cardplateTypes.length > 0 &&
                            cardplateTypes.map((item, key) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="form-group">
                  <label htmlFor="car_plate">New Car Plate </label>
                  <input
                    type="text"
                    className="form-control"
                    id="card_plate"
                    onChange={this.carPlate}
                  />
          </div>*/}
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.updateVehicle}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backCard}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
