import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
require("bootstrap/less/bootstrap.less");

const get_quiz_report_url = API.apiEndpoint + API.quizReport;

export default class QuizReport extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    quizzes: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    loadingCart: false,
    quiz_date_from: "",
    quiz_date_to: "",
  };

  async componentDidMount() {
    let options = { url: get_quiz_report_url };
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        //quizzes: api_response.data,
        //pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          quizzes: api_response.data,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = get_quiz_report_url + "?page=" + pageNumber;

    if (this.state.quiz_date_from) {
      url = url + "&quiz_date_from=" + this.state.quiz_date_from;
    }
    if (this.state.quiz_date_to) {
      url = url + "&quiz_date_to=" + this.state.quiz_date_to;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);

    if (response.error) {
      this.setState({
        loading: false,
        loadingCart: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        quizzes: response.data,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
        loadingCart: false,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = get_quiz_report_url + "?csv=" + 1;

    if (this.state.quiz_date_from) {
      url = url + "&quiz_date_from=" + this.state.quiz_date_from;
    }
    if (this.state.quiz_date_to) {
      url = url + "&quiz_date_to=" + this.state.quiz_date_to;
    }
    let options = {
      url: url,
      file_name:
        "Quiz Report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  quizStart = async (date) => {
    this.setState({
      quiz_date_from: date,
      enableBtn: true,
      quizzes: [],
      pagination: {},
      limit: 10,
    });
  };
  quizEnd = async (date) => {
    this.setState({
      quiz_date_to: date,
      enableBtn: true,
      quizzes: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };

  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }

  rescue_passed(value) {
    return value === true ? "Winner" : "-";
  }

  noResultText() {
    return "N/A";
  }

  render() {
    const {
      quizzes,
      pagination,
      loading,
      error,
      enableBtn,
      message,
      limit,
      callApi,
      loadingCart,
    } = this.state;
    var quizWin = "";
    if (typeof quizzes.wins !== "undefined") {
      quizWin = quizzes.wins;
    }
    return (
      <Content title="Quiz Report" browserTitle="Quiz Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Quiz Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.quiz_date_from}
                      selectsStart
                      quiz_date_from={this.state.quiz_date_from}
                      quiz_date_to={this.state.quiz_date_to}
                      onChange={this.quizStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.quiz_date_to}
                      selectsEnd
                      quiz_date_from={this.state.quiz_date_from}
                      quiz_date_to={this.state.quiz_date_to}
                      onChange={this.quizEnd}
                      minDate={this.state.quiz_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total User Participated In Quiz
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : quizzes.totalUserParticipatedInQuiz ? (
                          <div>
                            <span className="info-box-number-custom">
                              {quizzes.totalUserParticipatedInQuiz}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4"></div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Quiz Name</th>
                            <th>Trigger Type</th>
                            <th>Winner</th>
                            <th>Prize</th>
                            <th>Point</th>
                            <th>Score</th>

                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {quizWin.length > 0 && quizWin ? (
                            quizWin.map((data) => {
                              return (
                                <tr>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user.id}>
                                          {this.rescue_value(data.user.id)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user.id}>
                                          {this.rescue_value(data.user.name)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a
                                          href={
                                            "/quiz_form_view/" +
                                            data.attemptData.quiz_id
                                          }
                                        >
                                          {data.gameData.name
                                            ? data.gameData.name.en
                                            : "-"}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {data.gameData.trigger
                                      ? ApiRequest.string_humanize_format(
                                          data.gameData.trigger
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {this.rescue_passed(
                                      data.attemptData.attemptPassed
                                    )}
                                  </td>
                                  <td>
                                    {data.attemptData.winningPrize
                                      ? data.attemptData.winningPrize.prizeType
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.attemptData.winningPrize
                                      ? data.attemptData.winningPrize.prizeValue
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.attemptData
                                      ? data.attemptData.scoredWeightage
                                      : "-"}
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(
                                        data.attemptData.createdAt
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="9" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please select filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={(num) =>
                                this.handlePageChange(num, "getReport")
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
