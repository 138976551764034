import React, { Component } from "react";
import PropTypes from "prop-types";
import { Content, Row, Col } from "adminlte-2-react";
import "./basma.css";

export default class BasmaManagementView extends Component {
  static propTypes = {
    basma: PropTypes.object.isRequired
  };

  render() {
    const { basma } = this.props;
    return (
      <Content title={"Cards Detail"} browserTitle="Cards Detail">
        <Row>
          <Col xs={6}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Basma Card Info</h3>
                <div className="box-tools pull-right">
                  <button
                    type="button"
                    className="btn btn-box-tool"
                    data-widget="collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                </div>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>Id</b> <span className="pull-right">{basma.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Card Number</b>{" "}
                    <span className="pull-right">{basma.card_number}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Card Type</b>{" "}
                    <span className="pull-right">{basma.card_type}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Card Balance</b>{" "}
                    <span className="pull-right">{basma.card_balance}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Plate Number</b>{" "}
                    <span className="pull-right">{basma.plate_number}</span>
                  </li>
                  <li className="list-group-item">
                    <b>status</b>{" "}
                    <span className="pull-right">{basma.status}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Owner Info</h3>
                <div className="box-tools pull-right">
                  <button
                    type="button"
                    className="btn btn-box-tool"
                    data-widget="collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                </div>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>Owner Id</b>{" "}
                    <span className="pull-right">{basma.owner.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Owner Name</b>{" "}
                    <span className="pull-right">{basma.owner.name}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Email</b>{" "}
                    <span className="pull-right">{basma.owner.email}</span>
                  </li>
                  <li className="list-group-item">
                    <b>status</b>{" "}
                    <span className="pull-right">{basma.owner.status}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
