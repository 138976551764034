import React from "react";
import SafetyListing from "./listing";
import SafetyForm from "./form";

export default class SafetyTips extends React.Component {
  state = {
    action: "listing",
    page: {}
  };

  handleClick = (page, action) => {
    this.setState({ action: action, page: page });
  };

  render() {
    const { action, page } = this.state;
    if (action === "edit" || action === "add")
      return (
        <SafetyForm
          action={action}
          page={page}
          handleClick={this.handleClick}
        />
      );
    else return <SafetyListing handleClick={this.handleClick} />;
  }
}
