import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";

const topupPromotion_url = API.apiEndpoint + API.TopupRewardPromotions;

export default class TopupPromotionView extends React.Component {
  state = {
    topup_promotion: {},
    loading: true,
    error: false,
    message: "",
  };

  async componentDidMount() {
    let url = topupPromotion_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        topup_promotion: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { topup_promotion, loading } = this.state;
    return (
      <Content title={"Topup Promotion "} browserTitle="Topup Promotion  ">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Topup Promotion Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Start</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(topup_promotion.from)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>End</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(topup_promotion.to)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Name</b>
                      <span className="pull-right">
                        {topup_promotion.name
                          ? ApiRequest.string_humanize_format(
                              topup_promotion.name
                            )
                          : " No Name "}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Transaction Value</b>
                      <span className="pull-right">
                        {ApiRequest.string_humanize_format(
                          topup_promotion.transaction_value
                        )}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Type</b>
                      <span className="pull-right">
                        {ApiRequest.string_humanize_format(
                          topup_promotion.type
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Parent Tier Target</b>
                      <span className="pull-right">
                        {ApiRequest.string_humanize_format(
                          topup_promotion.parentTierCardTypeName
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Sub Tier Target</b>
                      <span className="pull-right">
                        {ApiRequest.string_humanize_format(
                          topup_promotion?.loyaltySubTier?.name
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
