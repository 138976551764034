import axios from "axios";
import API from "./constants";
import { useMainStore } from "./store";
import { number } from "prop-types";
import moment from "moment";
const FileDownload = require("js-file-download");
const humanize = require("string-humanize");

const ApiRequest = {
  download: async (params, send_access_token = true, loader = true) => {
    if (loader)
      document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data, headers } = send_access_token
        ? await axios.get(params.url, {
            headers: {
              "X-Access-Token": localStorage.getItem("user_token"),
              store_code: localStorage.getItem("store_code"),
              store_token: localStorage.getItem("store_token"),
            },
          })
        : await axios.get(params.url);
      if (loader)
        document.getElementById("waiting_screen").style.display = "none";
      console.log('data => ', data, headers.get('content-disposition') );
      console.log(headers);
      if(headers.get('content-disposition') || headers.getContentType().includes('csv')) {
        
      FileDownload(data, params.file_name);
      }else if(typeof data === "object" && data?.message === "File will be sent to your email"){
        useMainStore.getState().setShowDownlaodModal(true);

      }
    } catch (error) {
      if (loader)
        document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  get: async (params, send_access_token = true, loader = true) => {
    if (loader)
      document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data } = send_access_token
        ? await axios.get(params.url, {
            headers: { "X-Access-Token": localStorage.getItem("user_token") },
          })
        : await axios.get(params.url);
      if (loader)
        document.getElementById("waiting_screen").style.display = "none";
      return {
        error: false,
        message: data.message,
        data: data.data,
      };
    } catch (error) {
      if (loader)
        document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  post: async (params, send_access_token = true, headers = {}) => {
    document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data } = send_access_token
        ? await axios.post(params.url, params.form_data, {
            headers: {
              "X-Access-Token": localStorage.getItem("user_token"),
              "Content-Type": "application/json",
            },
          })
        : await axios.post(params.url, params.form_data, headers);
      document.getElementById("waiting_screen").style.display = "none";
      return {	
        error: false,	
        message: data.message,	
        data: data.data,	
        fulldata: data,	
      };
    } catch (error) {
      document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  put: async (params, send_access_token = true) => {
    document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data } = send_access_token
        ? await axios.put(params.url, params.form_data, {
            headers: {
              "X-Access-Token": localStorage.getItem("user_token"),
              "Content-Type": "application/json",
            },
          })
        : await axios.post(params.url, params.form_data);
      document.getElementById("waiting_screen").style.display = "none";
      return { error: false, message: data.message, data: data.data };
    } catch (error) {
      document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  delete: async (params, send_access_token = true) => {
    document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data } = send_access_token
        ? await axios.delete(params.url, {
            headers: { "X-Access-Token": localStorage.getItem("user_token") },
          })
        : await axios.get(params.url);
      document.getElementById("waiting_screen").style.display = "none";
      return { error: false, message: data.message, data: data.data };
    } catch (error) {
      document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  sendNotification: async (params, send_access_token = true) => {
    document.getElementById("waiting_screen").style.display = "block";
    try {
      const { data } = send_access_token
        ? await axios.post(params.url, params.form_data, {
            headers: {
              notification_access_token: API.pushNotificationAccessToken,
            },
          })
        : await axios.post(params.url, params.form_data);
      document.getElementById("waiting_screen").style.display = "none";
      return { error: false, message: data.message, data: data.data };
    } catch (error) {
      document.getElementById("waiting_screen").style.display = "none";
      const data = error.response ? error.response.data : error;
      if (data.token_expired) {
        ApiRequest.redirectLoginScreen();
      }
      return { error: true, message: data.message, data: data };
    }
  },

  can_access: (alias, operation, headers) => {

    // if(process.env.NODE_ENV === "development") {
    //   return true;
    // }
    if (localStorage.getItem("permissions", "user_token")) {
      let permissions =
        typeof localStorage.getItem("permissions") === "string"
          ? JSON.parse(localStorage.getItem("permissions"))
          : localStorage.getItem("permissions");
      let module = permissions.filter((obj) => obj.alias.trim() === alias);
      if (module.length > 0) {
        if (module[0].permissions[operation] !== undefined) {
          return module[0].permissions[operation];
        } else {
          return false;
        }
      }
      return true;
    }
  },

  redirectLoginScreen: () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("role_name");
    localStorage.removeItem("permissions");
    window.location = "/login";
  },

  date_format: (date) => {
    if (isNaN(date)) {
      return date;
    }
    return (
      ("0" + (new Date(date).getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + new Date(date).getDate()).slice(-2) +
      "/" +
      new Date(date).getFullYear()
    );
  },

  date_time_format: (date) => {
    if (isNaN(date)) {
      return date;
    }
    return (
      ("0" + (new Date(date).getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + new Date(date).getUTCDate()).slice(-2) +
      "/" +
      new Date(date).getFullYear() +
      " " +
      ("0" + new Date(date).getHours()).slice(-2) +
      ":" +
      ("0" + new Date(date).getMinutes()).slice(-2) +
      ":" +
      ("0" + new Date(date).getSeconds()).slice(-2)
    );
  },

  time_format: (time) => {
    if (isNaN(time)) {
      return time;
    }
    return (
      ("0" + new Date(time).getHours()).slice(-2) +
      ":" +
      ("0" + new Date(time).getMinutes()).slice(-2) +
      ":" +
      ("0" + new Date(time).getSeconds()).slice(-2)
    );
  },

  date_format_utc: (date) => {
    return (
      ("0" + (new Date(date).getUTCMonth() + 1)).slice(-2) +
      "/" +
      ("0" + new Date(date).getUTCDate()).slice(-2) +
      "/" +
      new Date(date).getUTCFullYear()
    );
  },	
  date_time_format_utc: (date) => {	
    return (	
      ("0" + (new Date(date).getUTCMonth() + 1)).slice(-2) +	
      "/" +	
      ("0" + new Date(date).getUTCDate()).slice(-2) +	
      "/" +	
      new Date(date).getUTCFullYear() +	
      " " +	
      ("0" + new Date(date).getUTCHours()).slice(-2) +	
      ":" +	
      ("0" + new Date(date).getUTCMinutes()).slice(-2) +	
      ":" +	
      ("0" + new Date(date).getUTCSeconds()).slice(-2)	
    );	
  },	
  string_humanize_format: (data) => {	
    return humanize(data);	
  },

  // display time in utc without change it to client locale time zone
  time_format_utc : (time) => {
    if (isNaN(time)) {
      return time;
    }
    const formattedTime = moment.unix(time / 1000).utc().format('HH:mm:ss');
    return formattedTime
  }
};

export default ApiRequest;
