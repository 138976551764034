import React from "react";
import axios from "axios";
import API from "../../constants";
import BusinessUserRequestsListing from "./listing";
import BusinessUserRequestsView from "./view";
const stations_url = API.apiEndpoint + API.stationLocator;

export default class BusinessUserRequests extends React.Component {
  state = {
    action: "listing",
    station_id: null
  };

  async componentDidMount() {
    if (this.props.view_station) {
      const response = await axios.get(
        stations_url + "/" + this.props.match.params.id
      );
      if (response.status === 200) {
        this.viewStationLoyatyDistribution(response.data.data, "view");
      }
    }
  }
  viewStationLoyatyDistribution = (station, action) => {
    this.setState({ action: action, station: station });
  };

  render() {
    const { action, station } = this.state;

    if (action === "view")
      return <BusinessUserRequestsView station={station} viewStationLoyatyDistribution={this.viewStationLoyatyDistribution} />;
    else return <BusinessUserRequestsListing viewStationLoyatyDistribution={this.viewStationLoyatyDistribution} />;
  }
}
