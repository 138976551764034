import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
// import "./User.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";

const listingproductbarcode = API.apiEndpoint + API.listingproductbarcode;
const uploadCardsRepository_url = API.apiEndpoint + API.uploadCardsRepository;

export default class Product_bar_code extends Component {
  state = {
    Product_bar_code: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    tableData: [],
    totalData: "",
    search_date: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url: listingproductbarcode,
      // +
      //   "?QR_codeListingPage=1&page=" +
      //   pageNumber +
      //   "&limit=" +
      //   limit +
      //   "&filter=" +
      //   filter +
      //   "&key=" +
      //   key +
      //   "&value=" +
      //   value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        Product_bar_code: api_response.data,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  deleteQR_code = async (val, event) => {
    confirmAlert({
      title: "Qr Code",
      message: "Are you sure you want to delete Qr Code?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmDeleteQR_code(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmDeleteQR_code = async (val, event) => {
    let api_response = {};
    let options = {
      url: listingproductbarcode + "/" + val,
    };

    api_response = await ApiRequest.delete(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/cash_back_promotion";
        }.bind(this),
        100
      );
      // sessionStorage.clear();
    }
  };

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };

  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(uploadCardsRepository_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Card successfully uploaded",
        });
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          5000
        );
        this.openModal(rsp.data);
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };
  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  rescue_used_value(value) {
      return value === true ? "File used" : "-";
  }

  render() {
    const {
      loading,
      error,
      message,
      Product_bar_code,
      pagination,
      limit,
    } = this.state;
    return (
      <div>
        <Content title="Product Barcode" browserTitle="Product Barcode">
          <LoadingAjax />
          <Row>
            {error && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}

            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Product Barcode </h3>
                  <div className="box-tools">
                    <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/product_bar_code_form/"}
                    >
                      Add Product Barcode
                    </a>
                  </div>
                </div>
                <div className="box-body">
                  <div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Project Name</th>
                              <th>Product Code</th>
                              <th>Created At</th>
                              <th>File Used</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {Product_bar_code.length > 0 ? (
                              Product_bar_code.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>{this.rescue_value(data.id)}</td>
                                    <td>
                                      {this.rescue_value(data.product_name)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.product_code)}
                                    </td>
                                    <td>
                                      {ApiRequest.date_format_utc(
                                          data.createdAt
                                      )}
                                    </td>
                                    <td>
                                      {this.rescue_used_value(data.file_used)}
                                    </td>
                                    <td>
                                      <a
                                        className="btn btn-sm btn-primary mr-5"
                                        href={data.filename}
                                        download={data.filename}
                                      >
                                        Download CSV
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {/*{pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}*/}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}
