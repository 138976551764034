import React from "react";
import { CSVLink } from "react-csv";
import { Content } from "adminlte-2-react";
import axios from "axios";
import "./OnboardingUrlGenerator.css";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
const moment = require("moment-timezone");

const onboarding_url_generator_url = API.apiEndpoint + API.onBoardingUrlGenerator;

export default class OnboardingUrlGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(onboarding_url_generator_url, data);
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Onboarding URL Generator CSV uploaded",
          data: rsp.data.data,
        });
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing ? message.param_missing + " is required" : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };

  render() {
    const { stores_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="Onboarding URL Generator" browserTitle="Onboarding URL Generator">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div class="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div class="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-body">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2em",
                    marginBottom: "2em",
                    gap: "1em",
                  }}>
                  <input
                    id="uploadList"
                    type="file"
                    name="file"
                    ref={(ref) => (this.uploadList = ref)}
                    onChange={this.onClickHandler}
                    style={{ display: "none" }}
                    accept=".xlsx, .csv, .xls"
                  />
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1em",
                    }}
                    onClick={(e) => this.uploadList.click()}>
                    <i className="fa fa-upload" aria-hidden="true" style={{ fontSize: "1.2em" }}></i>
                    <span style={{ fontSize: "1.5em" }}>Upload CSV to generate onboarding links</span>
                  </button>
                  <CSVLink
                    data={[["card_number"], [`7000050002266999`]]}
                    filename={"onboarding_url_generator_sample.csv"}
                    className="btn btn-sm btn-primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1em",
                    }}>
                    <i className="fa fa-download" aria-hidden="true" style={{ fontSize: "1.2em" }}></i>
                    <span style={{ fontSize: "1.5em" }}>Download Sample CSV</span>
                  </CSVLink>
                </div>
                {this.state.data && (
                  <div>
                    <div className="form-group">
                      <CSVLink
                        data={
                          this.state.data?.map((item) => {
                            return {
                              card_number: `=""${item.card_number}""`,
                              url: item.url,
                            };
                          }) || []
                        }
                        filename={"onboarding_url.csv"}
                        className="btn btn-primary">
                        Download CSV
                      </CSVLink>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Card Number</th>
                            <th>Onboarding URL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((item) => (
                            <tr key={item.card_number}>
                              <td>{item.card_number}</td>
                              <td>
                                <a href={item.url} target="_blank" rel="noreferrer">
                                  {item.url}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
