import React from "react";
import StoreView from "./view";
import StoreListing from "./listing";
import StoreForm from "./form";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const store_detail_url = API.apiEndpoint + API.store;

export default class WhiteList extends React.Component {
  state = {
    action: "listing",
    store: {}
  };

  async componentDidMount() {
    if (this.props.view) {
      let options = {
        url: store_detail_url + "/" + this.props.match.params.id
      };
      let response = await ApiRequest.get(options);

      if (response.error === false) {
        this.viewStore(response.data, "view");
      }
    }
    if (this.props.edit) {
      let options = {
        url: store_detail_url + "/" + this.props.match.params.id
      };
      let response = await ApiRequest.get(options);
      if (response.error === false) {
        this.viewStore(response.data, "edit");
      }
    }
  }

  viewStore = (store, action) => {
    this.setState({ action: action, store: store });
  };

  render() {
    const { action, store } = this.state;
    if (action === "view") return <StoreView store={store} />;
    if (action === "edit" || action === "add")
      return (
        <StoreForm action={action} viewStore={this.viewStore} store={store} />
      );
    else return <StoreListing viewStore={this.viewStore} />;
  }
}
