import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import { async } from "async";
import LoadingAjax from "../../components/Loading";
import DatePicker from "react-datepicker";
const contact_url = API.apiEndpoint + API.contactReview;

export default class ContactListing extends React.Component {
  state = {
    contacts: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    contact_date_from: "",
    contact_date_to: "",
    showDate: false,
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let url = contact_url + "?page=" + pageNumber;

    if (this.state.contact_date_from) {
      url = url + "&date_from=" + this.state.contact_date_from;
    }
    if (this.state.contact_date_to) {
      url = url + "&date_to=" + this.state.contact_date_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }

    let options = {
      url:
        url +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        contacts: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value || this.state.status_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      status_input_value: "",
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleChangeOmcoId = async (event) => {
    this.setState({
      oomco_id: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  handleSearchClear = async () => {
    this.setState(
      {
        search_clear: true,
        search_input_value: "",
        search_select_value: "",
        status_input_value: "",
        contact_date_from: "",
        contact_date_to: "",
        oomco_id: "",
        filter: false,
      },
      async () => {
        await this.getData(1, this.state.limit, false);
      }
    );
  };

  contactDateStart = async (date) => {
    this.setState({
      contact_date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  contactDateEnd = async (date) => {
    this.setState({
      contact_date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  toggleDateView = () => {
    const { showDate } = this.state;
    this.setState({
      showDate: !showDate,
    });
  };

  downloadData = async (e = {}, limit = 10, filter = true) => {
    let url = contact_url + "?csv=" + 1;

    if (filter) {
      url = url + "&filter=" + filter;
    }

    if (this.state.search_select_value) {
      url = url + "&key=" + this.state.search_select_value;
    }

    if (this.state.search_input_value) {
      url = url + "&value=" + this.state.search_input_value;
    }

    if (this.state.contact_date_from) {
      url = url + "&date_from=" + this.state.contact_date_from;
    }

    if (this.state.contact_date_to) {
      url = url + "&date_to=" + this.state.contact_date_to;
    }

    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }

    let options = {
      url: url + "&limit=" + limit,
      file_name: "Contact - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  render() {
    const columns = [
      {
        title: "Id",
        data: "id",
      },
      {
        title: "Name",
        data: "name",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Phone",
        data: "mobileNumber",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Message",
        data: "message",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Contact At",
        data: "updatedAt",
        render: (updatedAt) => ApiRequest.date_format_utc(updatedAt),
      },
    ];

    if (ApiRequest.can_access("contact-form", "can_view_single")) {
      columns.push({
        title: "Actions",
        data: "id",
        sortable: false,
        render: (id) => (
          <a class="btn btn-primary btn-xs" href={"/contact/" + id}>
            {" "}
            <i class="fa fa-eye" />{" "}
          </a>
        ),
      });
    }

    const {
      loading,
      error,
      message,
      pagination,
      limit,
      contacts,
      search_select_value,
      search_input_value,
      status_input_value,
    } = this.state;
    return (
      <Content title="Contact Form" browserTitle="Contact Form">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Contact Reviews</h3>
                <div class="box-tools text-right">
                  <button
                    class="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    <div>
                      {
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"name"}>Name</option>
                            <option value={"mobileNumber"}>
                              Mobile Number
                            </option>
                            <option value={"message"}>Message</option>
                            <option value={"status"}>Status</option>
                          </select>
                          {search_select_value === "status" ? (
                            <select
                              onChange={this.handleStatusChange}
                              value={status_input_value}
                              className="form-control input-sm mr-5 ml-5"
                            >
                              <option value={"open"}>Open</option>
                              <option value={"closed"}>Closed</option>
                            </select>
                          ) : (
                            <input
                              type="search"
                              class="form-control input-sm mr-5 ml-5"
                              value={search_input_value}
                              onChange={this.handleSearchValueChange}
                              placeholder=""
                            />
                          )}
                          <button
                            className="btn btn-primary cusSearchBtn mr-5"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                          <button
                            class="btn btn-sm btn-primary "
                            onClick={this.toggleDateView}
                          >
                            Advance Search
                          </button>
                        </div>
                      }
                      <div
                        className={`rangeFilter rangeFilterCus  mt-5 ${
                          !this.state.showDate && "hidden"
                        }`}
                      >
                        Date
                        <DatePicker
                          className="filterDate mr-5"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.contact_date_from}
                          selectsStart
                          contact_date_from={this.state.contact_date_from}
                          contact_date_to={this.state.contact_date_to}
                          onChange={this.contactDateStart}
                          placeholderText="From Date"
                        />
                        <DatePicker
                          className="filterDate"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.contact_date_to}
                          selectsEnd
                          contact_date_from={this.state.contact_date_from}
                          contact_date_to={this.state.contact_date_to}
                          onChange={this.contactDateEnd}
                          minDate={this.state.contact_date_from}
                          placeholderText="To Date"
                        />
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => this.getData()}
                        >
                          Submit
                        </button>
                      </div>
                      <div
                        className={`omcoId mt-5 ${
                          !this.state.showDate && "hidden"
                        }`}
                      >
                        <span>Oomco ID</span>
                        <input
                          type="text"
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleChangeOmcoId}
                          value={this.state.value}
                          placeholderText="To Date"
                        />
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => this.getData()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            {/*<th>ID</th>*/}
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Message</th>
                            <th>Status</th>
                            <th>Contact At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contacts.length > 0 ? (
                            contacts.map((data) => {
                              return (
                                <tr key={data.id}>
                                  {/*<td>{this.rescue_value(data.id)}</td>*/}

                                  {/*<td>{this.rescue_value(data.name)}</td>*/}
                                  <td>
                                    {
                                      <span>
                                        {data.userData ? (
                                          <a
                                            target="_blank"
                                            href={
                                              "app_view/" + data.userData.id
                                            }
                                          >
                                            {data.name ? data.name : "-"}
                                          </a>
                                        ) : (
                                          <p> {data.name ? data.name : "-"}</p>
                                        )}
                                      </span>
                                    }
                                  </td>

                                  {/*<td>
                                    {this.rescue_value(data.mobileNumber)}
                                  </td>*/}
                                  <td>
                                    {
                                      <span>
                                        {data.userData ? (
                                          <a
                                            target="_blank"
                                            href={
                                              "app_view/" + data.userData.id
                                            }
                                          >
                                            {data.mobileNumber
                                              ? data.mobileNumber
                                              : "-"}
                                          </a>
                                        ) : (
                                          <p>
                                            {" "}
                                            {data.mobileNumber
                                              ? data.mobileNumber
                                              : "-"}
                                          </p>
                                        )}
                                      </span>
                                    }
                                  </td>

                                  <td>{this.rescue_value(data.message)}</td>
                                  <td>
                                    {data.status
                                      ? ApiRequest.string_humanize_format(
                                          data.status
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.updatedAt)}
                                  </td>

                                  <td>
                                    {ApiRequest.can_access(
                                      "contact-form",
                                      "can_view_single"
                                    ) ? (
                                      <a
                                        class="btn btn-primary btn-xs"
                                        href={"/contact/" + data.id}
                                      >
                                        {" "}
                                        <i class="fa fa-eye" />{" "}
                                      </a>
                                    ) : (
                                      " "
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_infos">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
