import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import "./station_review.css";
import { async } from "async";
import LoadingAjax from "../../components/Loading";

const station_review_url = API.apiEndpoint + API.stationReview;

export default class StationReviewListing extends React.Component {
  state = {
    station_reviews: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: ""
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        station_review_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        station_reviews: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async pageNumber => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async event => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  handleSearchChange = async event => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleSearchValueChange = async event => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false
    });
    await this.getData(1, this.state.limit, false);
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
      station_reviews,
      search_select_value,
      search_input_value
    } = this.state;
    return (
      <Content title="Station Reviews " browserTitle="Station Reviews">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div class="box-header with-border box-table-cus">
                <h3 class="box-title">Stations Reviews</h3>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    {
                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"station.name.en"}>Station</option>
                          <option value={"name"}>Name</option>
                          <option value={"mobileNumber"}>Mobile Number</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    }
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>Station</th>
                            <th>Name</th>
                            <th>Mobile Number</th>
                            <th>Reaction</th>
                            <th>Reviewed At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {station_reviews.length > 0 ? (
                            station_reviews.map(data => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {
                                      <span>
                                        <a
                                          href={
                                            "stations/" +
                                            data.station.unique_identifier
                                          }
                                        >
                                          {data.station.name.en}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>{this.rescue_value(data.name)}</td>
                                  <td>
                                    {this.rescue_value(data.mobileNumber)}
                                  </td>
                                  <td>
                                    {
                                      <span className="icon-spacing">
                                        <i
                                          className={
                                            data.smiley === "surprised"
                                              ? "fa fa-lg fa-meh-o icon-filled"
                                              : "fa fa-lg fa-meh-o "
                                          }
                                        >
                                          {" "}
                                        </i>
                                        <i
                                          className={
                                            data.smiley === "happy"
                                              ? "fa fa-lg fa-smile-o icon-filled"
                                              : "fa fa-lg fa-smile-o"
                                          }
                                        />
                                        <i
                                          className={
                                            data.smiley === "sad"
                                              ? "fa fa-lg fa-frown-o icon-filled"
                                              : "fa fa-lg fa-frown-o"
                                          }
                                        />
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.updatedAt)}
                                  </td>
                                  <td>
                                    {
                                      <a
                                        class="btn btn-primary btn-xs"
                                        href={"/station_reviews/" + data.id}
                                      >
                                        {" "}
                                        <i class="fa fa-eye" />{" "}
                                      </a>
                                    }
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="6" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
