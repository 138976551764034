import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "react-modal";

const card_repository_wishlist_form_url =
  API.apiEndpoint + API.cardsRepositoryToWishlist;
const store_url = API.apiEndpoint + API.store;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class CardRepositoryForm extends React.Component {
  state = {
    page: {},
    action: "add",
    cash_back_promotion_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    card_from: "",
    card_to: "",
    stores: [],
    store_code: "9765",
    isLoyaltyCard: '',
    tableData: [],
    totalData: "",
  };

  async componentDidMount() {
    let optionsStore = { url: store_url };
    let store_response = await ApiRequest.get(optionsStore);

    if (store_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsStore.message,
      });
    } else {
      let stores = [];
      store_response.data.map((store) => {
        stores.push({
          value: store.store_code,
          label: store.store_code + " - " + store.store_name,
        });
      });

      this.setState({
        stores: stores,
        //stores: store_response.data,
        //store_code: store_response.data[0].store_code,
        loading: false,
        error: false,
        message: optionsStore.message,
      });
    }
  }
  openModal = async (cards_not) => {
    this.setState({ totalData: cards_not.fulldata });
    this.setState({ tableData: cards_not.data });
    this.setState({ modalIsOpen: true });
  };
  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    window.location = "/card_repository";
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  handleChangeRangeFrom = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        card_from: event.target.value,
      });
    }
  };
  handleChangeRangeTo = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        card_to: event.target.value,
      });
    }
  };
  handleChangeStoreName = async (selectedOption) => {
    this.setState({
      store_code: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };

  handleChangeIsLoyalty = async (selectedOption) => {
    this.setState({
      isLoyaltyCard: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };

  saveBulkBlank = async (event) => {
    const formData = new FormData();

    let error = false;

    if (!this.state.card_to.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Card To is Required",
      });
    }
    if (!this.state.card_from.length > 0) {
      error = true;
      this.setState({
        error: true,
        message: "Card From is Required",
      });
    }

    if (error) {
      return;
    }

    formData.append("card_from", this.state.card_from);
    formData.append("card_to", this.state.card_to);
    formData.append("store_code", this.state.store_code);
    formData.append("isLoyaltyCard", this.state.isLoyaltyCard);

    let api_response = {};
    let options = {
      url: card_repository_wishlist_form_url,
      form_data: formData,
    };
    api_response = await ApiRequest.post(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let cards_not = api_response;
      this.openModal(cards_not);
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          // window.location = "/bulk_blank_card_activation";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/card_repository";
  };

  render() {
    const { Select2 } = Inputs;
    let {
      stores,
      error,
      totalData,
      message,
      success,
      success_message,
    } = this.state;

    return (
      <div>
        <Content
          title="Card Repository Form "
          browserTitle="Card Repository Form "
        >
          <LoadingAjax />
          <div className="row">
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Card Repository Form Form </h3>
                </div>

                <div className="box-body">
                  <div className="col-md-6">
                    <div className="form-group rangeFilter">
                      <label
                        style={{
                          display: "block",
                        }}
                      >
                        Card Number
                      </label>
                      <input
                        type="number"
                        value={this.state.card_from}
                        onChange={this.handleChangeRangeFrom}
                        className="form-control"
                        id="card_number"
                        min={0}
                        placeholder="From (0000000000000000)"
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                      <input
                        type="number"
                        min={0}
                        value={this.state.card_to}
                        onChange={this.handleChangeRangeTo}
                        className="form-control"
                        placeholder="To (0000000000000000)"
                        style={{
                          display: "inline-block",
                          width: "49%",
                          margin: "0 .7% 0 0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 cusSelect">
                    <Select2
                      label="Store Code"
                      className="form-control"
                      value={this.state.selectedOption}
                      onChange={this.handleChangeStoreName}
                      options={stores}
                      placeholder="Select"
                    />
                  </div>

                  <div className="col-md-6 cusSelect">
                    <Select2
                      label="Is Loyalty"
                      className="form-control"
                      value={this.state.isLoyaltyCard}
                      onChange={this.handleChangeIsLoyalty}
                      options={[true, false]}
                      placeholder="Select"
                    />
                  </div>
                  {/*<div className="col-md-6">
                    <div className="form-group">
                      <label>Store Code</label>
                      <select
                        className="form-control"
                        id="store_code"
                        name="store_code"
                        onChange={this.onChange}
                        value={this.state.store_code}
                      >
                        {stores.length > 0 &&
                          stores.map((item, key) => (
                            <option value={item.store_code}>
                              {item.store_code} - {item.store_name}
                            </option>
                          ))}
                      </select>
                    </div>
                          </div>*/}
                </div>
                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.saveBulkBlank}
                    className="btn btn-primary pull-right "
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.backUser}
                    className="btn btn-default pull-right mr-10"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Proceed</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
