import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const loyalty_partner_edit = API.apiEndpoint + API.loyaltyPartner;
//const role_url = API.apiEndpoint + API.role;

export default class PartnerEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      action: "add",
      name: "",
      email: "",
      password: "",
      status: "",
      user_role_list: [],
      role: "",
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
      ip_address: [],
    };

    // this.handleChange = this.handleChange.bind(this);
    //this.changePass = this.changePass.bind(this);
  }

  async componentDidMount() {
    let url = loyalty_partner_edit + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let data = api_response.data;

      this.setState({
        partner_code: data.partner_code,
        partner_name: data.partner_name,
        secret_key: data.secret_key,
        ip_address: data.ip_address,
        email: data.email,
        ip_address: JSON.parse(data.ip_address),
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    // let roleUrl = role_url;
    // let optionsRole = { url: roleUrl };
    // let api_response_role = await ApiRequest.get(optionsRole);
    // if (api_response_role.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response_role.message,
    //   });
    // } else {
    //   this.setState({
    //     user_role_list: api_response_role.data,
    //     loading: false,
    //     error: false,
    //     message: api_response_role.message,
    //   });
    // }

    // Request Roles Data  drop-down

    // if (api_response.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response.message
    //   });
    // } else {
    //   this.setState({
    //     user_role_list: api_response.data,
    //     loading: false,
    //     error: false,
    //     message: api_response.message
    //   });
    // }
  }

  partnerCodeChange = (event) => {
    this.setState({ partner_code: event.target.value });
  };
  partnerNameChange = (event) => {
    this.setState({ partner_name: event.target.value });
  };
  // secretKeyChange = (event) => {
  //   this.setState({ secret_key: event.target.value });
  // };
  ipAddressChange = (event) => {
    this.setState({ ip_address: event.target.value });
  };
  tagsChange = (ip_address) => {
    this.setState({ ip_address });
  };
  saveTopupPromotions = async (event) => {
    let error = false;
    if (!this.state.partner_code) {
      error = true;
      this.setState({
        error: true,
        message: "Partner Code required",
      });
      return;
    }

    if (!this.state.partner_name) {
      error = true;
      this.setState({
        error: true,
        message: "Partner Name is required",
      });
      return;
    }
    if (!this.state.secret_key) {
      error = true;
      this.setState({
        error: true,
        message: "Secret Key is required",
      });
      return;
    }
    if (this.state.ip_address.length == 0) {
      error = true;
      this.setState({
        error: true,
        message: "IP Address is required",
      });
      return;
    }

    if (error) {
      return;
    }

    const formData = new FormData();

    // let data = {
    //   campaign_title: this.state.title,
    //   badge_title: this.state.badgeTitle,
    //   badge_id: this.state.badgeId,
    //   badge_action: this.state.badgeAction,
    //   mechanic_id: this.state.mechanic_id,
    // };

    formData.append("partner_code", this.state.partner_code);
    formData.append("partner_name", this.state.partner_name);
    formData.append("secret_key", this.state.secret_key);
    formData.append("ip_address", JSON.stringify(this.state.ip_address));

    let api_response = {};
    let options = {
      url: loyalty_partner_edit + "/" + this.props.match.params.id,
      form_data: formData,
    };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/partner_listing";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/partner_listing";
  };

  // changePass = () => {
  //   const rand = Math.floor(10000000 + Math.random() * 9000000000000);
  //   this.setState({ password: rand });
  // };

  render() {
    let { error, message, success, success_message, badgeData } = this.state;
    return (
      <Content title="Partner" browserTitle="Partner">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Partner Add </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="partner_code">Partner Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="partner_code"
                      placeholder="Partner Code"
                      value={this.state.partner_code}
                      onChange={this.partnerCodeChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="partner_name">Partner Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="partner_name"
                      placeholder="Partner Name"
                      value={this.state.partner_name}
                      onChange={this.partnerNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="secret_key">Secret Key</label>
                    <input
                    readOnly
                      type="text"
                      className="form-control"
                      id="secret_key"
                      placeholder="Secret Key"
                      value={this.state.secret_key}
                      //onChange={this.secretKeyChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="ip_address">IP Address</label>
                    <TagsInput
                      value={this.state.ip_address}
                      onChange={this.tagsChange.bind(null)}
                    />
                    {/*<input
                      type="text"
                      className="form-control"
                      id="ip_address"
                      placeholder="IP Address"
                      value={this.state.ip_address}
                      onChange={this.ipAddressChange}
                    />*/}
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveTopupPromotions}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
