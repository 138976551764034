import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import DatePicker from "react-datepicker";

const sfsTransaction_url = API.apiEndpoint + API.sfsTransactions;

export default class SfsTransaction extends React.Component {
  state = {
    sfs_transactions: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    sfs_date_from: "",
    sfs_date_to: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let url = sfsTransaction_url + "?page=" + pageNumber;

    if (this.state.sfs_date_from) {
      url = url + "&date_from=" + this.state.sfs_date_from;
    }
    if (this.state.sfs_date_to) {
      url = url + "&date_to=" + this.state.sfs_date_to;
    }

    let options = {
      url:
        url +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        sfs_transactions: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  // async componentDidMount() {
  //   let options = { url: sfsTransaction_url };
  //   let api_response = await ApiRequest.get(options);
  //   if (api_response.error) {
  //     this.setState({
  //       loading: false,
  //       error: true,
  //       message: api_response.message
  //     });
  //   } else {
  //     this.setState({
  //       sfs_transactions: api_response.data,
  //       loading: false,
  //       error: false,
  //       message: api_response.message
  //     });
  //   }
  // }

  // async componentDidMount() {
  //   const response = await axios.get(sfsTransaction_url);
  //   if (response.status === 200) {
  //     const data = response.data.data;
  //     this.setState({ sfs_transactions: data, loading: false });
  //   }
  // }

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchClear = async () => {
    this.setState(
      {
        search_clear: true,
        search_input_value: "",
        search_select_value: "",
        sfs_date_from: "",
        sfs_date_to: "",
        filter: false,
      },
      async () => {
        await this.getData(1, this.state.limit, false);
      }
    );
  };

  sfsDateStart = async (date) => {
    this.setState({
      sfs_date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  sfsDateEnd = async (date) => {
    this.setState({
      sfs_date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  toggleDateView = () => {
    const { showDate } = this.state;
    this.setState({
      showDate: !showDate,
    });
  };

  downloadData = async (e = {}, limit = 10, filter = true) => {
    let url = sfsTransaction_url + "?csv=" + 1;

    if (filter) {
      url = url + "&filter=" + filter;
    }

    if (this.state.search_select_value) {
      url = url + "&key=" + this.state.search_select_value;
    }

    if (this.state.search_input_value) {
      url = url + "&value=" + this.state.search_input_value;
    }

    if (this.state.sfs_date_from) {
      url = url + "&date_from=" + this.state.sfs_date_from;
    }

    if (this.state.sfs_date_to) {
      url = url + "&date_to=" + this.state.sfs_date_to;
    }

    let options = {
      url: url + "&limit=" + limit,
      file_name:
        "SFS Transaction - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    // if (ApiRequest.can_access("sfs-transaction", "can_view_single")) {
    //   columns.push({
    //     title: "Actions",
    //     data: "id",
    //     sortable: false,
    //     render: id => (
    //       <a
    //         class="btn btn-primary btn-xs"
    //         href={"/sfs_transaction_view/" + id}
    //       >
    //         {" "}
    //         <i class="fa fa-eye" />{" "}
    //       </a>
    //     )
    //   });
    // }

    let {
      sfs_transactions,
      loading,
      error,
      message,
      pagination,
      limit,
      search_select_value,
      search_input_value,
    } = this.state;

    return (
      <Content title={"SFS Transaction"} browserTitle="SFS Transaction">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">SFS Transactions </h3>
                <div class="box-tools text-right">
                  <button
                    class="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    <div>
                      {" "}
                      {
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"oomco_id"}>OOMCO ID</option>
                            <option value={"method_name"}>Method Name</option>
                            <option value={"transaction_id"}>
                              Transaction ID
                            </option>
                            <option value={"store_code"}>Store Code</option>
                          </select>
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                          <button
                            className="btn btn-primary cusSearchBtn mr-5"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                          <button
                            class="btn btn-sm btn-primary "
                            onClick={this.toggleDateView}
                          >
                            Advance Search
                          </button>
                        </div>
                      }
                      <div
                        className={`rangeFilter rangeFilterCus  mt-5 ${
                          !this.state.showDate && "hidden"
                        }`}
                      >
                        Date
                        <DatePicker
                          className="filterDate mr-5"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.sfs_date_from}
                          selectsStart
                          sfs_date_from={this.state.sfs_date_from}
                          sfs_date_to={this.state.sfs_date_to}
                          onChange={this.sfsDateStart}
                          placeholderText="From Date"
                        />
                        <DatePicker
                          className="filterDate"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.sfs_date_to}
                          selectsEnd
                          sfs_date_from={this.state.sfs_date_from}
                          sfs_date_to={this.state.sfs_date_to}
                          onChange={this.sfsDateEnd}
                          minDate={this.state.sfs_date_from}
                          placeholderText="To Date"
                        />
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => this.getData()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>OOMCO ID</th>
                            <th>Method Name</th>
                            <th>Transaction At</th>
                            <th>Store Code</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sfs_transactions.length > 0 ? (
                            sfs_transactions.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {this.rescue_value(data.transaction_id)}
                                  </td>
                                  <td>{this.rescue_value(data.oomco_id)}</td>
                                  <td>
                                    {ApiRequest.string_humanize_format(
                                      this.rescue_value(data.method_name)
                                    )}
                                  </td>
                                  <td>
                                    {/*ApiRequest.date_time_format(
                                      parseInt(data.transaction_date) * 1000
                                    )*/}

                                    {ApiRequest.transaction_date_utc
                                      ? ApiRequest.date_time_format(
                                          data.transaction_date_utc
                                        )
                                      : data.transaction_date
                                      ? ApiRequest.date_time_format(
                                          parseInt(data.transaction_date_utc) *
                                            1000
                                        )
                                      : "-"}
                                  </td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>
                                    {ApiRequest.can_access(
                                      "sfs-transaction",
                                      "can_view_single"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={
                                          "/sfs_transaction_view/" + data.id
                                        }
                                      >
                                        {" "}
                                        <i class="fa fa-eye" />
                                        {"    "}
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
