import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";

const basmaCards_url = API.apiEndpoint + API.basmaCards;

export default class basmaManagementListing extends React.Component {
  state = {
    value: "",
    basma: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: ""
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        basmaCards_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        basma: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async pageNumber => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async event => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async event => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleSearchValueChange = async event => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false
    });
    await this.getData(1, this.state.limit, false);
  };

  topupChange = async event => {
    this.setState({ value: event.target.value });
  };

  topupSubmit = async event => {
    event.preventDefault();
  };

  replaceChange = async event => {
    this.setState({ value: event.target.value });
  };

  replaceSubmit = async event => {
    event.preventDefault();
  };

  // rescue_value(value) {
  //   return value === undefined ? "0" : value;
  // }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
      basma,
      search_select_value,
      search_input_value
    } = this.state;
    return (
      <div>
        <LoadingAjax />
        <Content
          title="Basma Cards Management"
          browserTitle="Basma Cards Management"
        >
          <Row>
            {error && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border"></div>
                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>
                      {
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"card_number"}>
                              Card Serial Number
                            </option>
                            <option value={"serial_number"}>Card Number</option>
                            <option value={"oomco_id"}>OOMCO ID</option>
                            <option value={"car_plate"}>Car Number</option>
                          </select>
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                          <button
                            className="btn btn-primary cusSearchBtn"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                        </div>
                      }
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>Card Number</th>
                              <th>Card Serial Number</th>

                              {/*<th>Status</th>*/}
                              <th>OOMCO ID</th>
                              <th>Car Number</th>
                              <th>Activate By</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {basma.length > 0 ? (
                              basma.map(data => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.serial_number)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.card_number)}
                                    </td>
                                    {/*<td>{this.rescue_value(data.activate)}</td>*/}
                                    <td>{this.rescue_value(data.oomco_id)}</td>
                                    <td>{this.rescue_value(data.car_plate)}</td>
                                    <td>
                                      {this.rescue_value(data.activatedBy)}
                                    </td>
                                    <td>
                                      {ApiRequest.can_access(
                                        "basma-cards-management",
                                        "can_view_single"
                                      ) && (
                                        <a
                                          class="btn btn-primary btn-xs mr-5"
                                          href={"/card/" + data.id}
                                        >
                                          {" "}
                                          <i class="fa fa-eye" />
                                          {"    "}
                                        </a>
                                      )}
                                      <a
                                        class="btn btn-primary btn-xs"
                                        href={"/card_replace/" + data.id}
                                      >
                                        {"Card Replace"}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="modal fade" id="modal-topup">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Top Up Card</h4>
              </div>
              <form onSubmit={this.topupSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Topup Balance</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="XXXXXX"
                      value={this.state.value}
                      onChange={this.topupChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal-replace">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Replace Card</h4>
              </div>
              <form onSubmit={this.replaceSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Replace</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="XXXXXX"
                      value={this.state.value}
                      onChange={this.replaceChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
