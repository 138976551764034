import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import DatePicker from "react-datepicker";
const loyalty_sub_tiers_url = API.apiEndpoint + API.loyaltySubTiersUsers;

export default class LoyaltySubTiersUsersListing extends React.Component {
  state = {
    loyalty_sub_tiers: [],
    parentTier: null,
    subTier: null,
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    complaint_date_from: "",
    complaint_date_to: "",
    showDate: false,
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let url = loyalty_sub_tiers_url + "/" + this.props.match.params.id  + "?page=" + pageNumber;

    if (this.state.complaint_date_from) {
      url = url + "&date_from=" + this.state.complaint_date_from;
    }
    if (this.state.complaint_date_to) {
      url = url + "&date_to=" + this.state.complaint_date_to;
    }
    /*if (this.state.complaint_date_to) {
      url = url + "&date_to=" + this.state.complaint_date_to;
    }*/
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }

    let options = {
      url:
        url +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        loyalty_sub_tiers: api_response.data.results,
        parentTier: api_response.data.parentTier,
        subTier: api_response.data.subTier,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value || this.state.status_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      status_input_value: "",
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };

  handleSearchClear = async () => {
    this.setState(
      {
        search_clear: true,
        search_input_value: "",
        search_select_value: "",
        status_input_value: "",
        complaint_date_from: "",
        complaint_date_to: "",
        oomco_id: "",
        filter: false,
      },
      async () => {
        await this.getData(1, this.state.limit, false);
      }
    );
  };

  handleChangeOmcoId = async (event) => {
    this.setState({
      oomco_id: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  complaintDateStart = async (date) => {
    this.setState({
      complaint_date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };
  complaintDateEnd = async (date) => {
    this.setState({
      complaint_date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 10,
    });
  };

  downloadData = async (e = {}, limit = 10, filter = true) => {
    let url = loyalty_sub_tiers_url + "?csv=" + 1;

    if (filter) {
      url = url + "&filter=" + filter;
    }

    if (this.state.search_select_value) {
      url = url + "&key=" + this.state.search_select_value;
    }

    if (this.state.search_input_value) {
      url = url + "&value=" + this.state.search_input_value;
    }

    if (this.state.complaint_date_from) {
      url = url + "&date_from=" + this.state.complaint_date_from;
    }

    if (this.state.complaint_date_to) {
      url = url + "&date_to=" + this.state.complaint_date_to;
    }

    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }

    let options = {
      url: url + "&limit=" + limit,
      file_name:
        "Complaint - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
      loyalty_sub_tiers,
      parentTier,
      subTier,
      search_select_value,
      search_input_value,
      status_input_value,
    } = this.state;
    return (
      <Content title={subTier ? `Loyalty Sub Tier Users - ${subTier.name}`: `Loyalty Sub Tier Users`} browserTitle={subTier ? `Loyalty Sub Tier Users - ${subTier.name}`: `Loyalty Sub Tier Users`}>
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div class="box-header with-border box-table-cus">
                <h3 class="box-title">{subTier?.name ?? 'Loyalty Sub Tier Users'}</h3>
                <div class="box-tools text-right">
                  <div className="col-xs-9" style={{ padding: "0" }}></div>

                  <button
                    class="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    <div>
                      {
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"name"}>Name</option>
                            <option value={"complaint_type.type.en"}>
                              Complaint Type
                            </option>
                            <option value={"station.name.en"}>Station</option>
                            <option value={"mobileNumber"}>
                              Mobile Number
                            </option>
                            <option value={"status"}>Status</option>
                          </select>
                          {search_select_value === "status" ? (
                            <select
                              onChange={this.handleStatusChange}
                              value={status_input_value}
                              className="form-control input-sm mr-5 ml-5"
                            >
                              <option value={"open"}>Open</option>
                              <option value={"closed"}>Closed</option>
                            </select>
                          ) : (
                            <input
                              type="search"
                              className="form-control input-sm mr-5 ml-5"
                              value={search_input_value}
                              onChange={this.handleSearchValueChange}
                              placeholder=""
                            />
                          )}
                          <button
                            className="btn btn-primary cusSearchBtn  mr-5"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                        </div>
                      }

                      <div
                        className={`rangeFilter rangeFilterCus  mt-5 ${
                          !this.state.showDate && "hidden"
                        }`}
                      >
                        Date
                        <DatePicker
                          className="filterDate mr-5 ml-5"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.complaint_date_from}
                          selectsStart
                          complaint_date_from={this.state.complaint_date_from}
                          complaint_date_to={this.state.complaint_date_to}
                          onChange={this.complaintDateStart}
                          placeholderText="From Date"
                        />
                        <DatePicker
                          className="filterDate"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.complaint_date_to}
                          selectsEnd
                          complaint_date_from={this.state.complaint_date_from}
                          complaint_date_to={this.state.complaint_date_to}
                          onChange={this.complaintDateEnd}
                          minDate={this.state.complaint_date_from}
                          placeholderText="To Date"
                        />
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => this.getData()}
                        >
                          Submit
                        </button>
                      </div>
                      <div
                        className={`omcoId mt-5 ${
                          !this.state.showDate && "hidden"
                        }`}
                      >
                        <span>Oomco ID</span>
                        <input
                          type="text"
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleChangeOmcoId}
                          value={this.state.value}
                          placeholderText="To Date"
                        />
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => this.getData()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>OOMCO ID</th>
                            <th>Parent Tier</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loyalty_sub_tiers.length > 0 ? (
                            loyalty_sub_tiers.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.name)}</td>
                                  <td>{this.rescue_value(data.mobile_no)}</td>
                                  <td>{this.rescue_value(data.oomcoId)}</td>
                                  <td>{this.rescue_value(parentTier?.CardTypeName ?? '')}</td>
                                  <td>{this.rescue_status(data.status)}</td>
                                  <td>
                                    {ApiRequest.can_access("admin-user", "can_view") && (
                                      <a
                                        className="btn btn-primary btn-xs"
                                        href={"/app_view/" + data.id}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        <i className="fa fa-eye" />{" "}
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="6" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div className="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
