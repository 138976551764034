import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
require("bootstrap/less/bootstrap.less");

const get_survey_report_url = API.apiEndpoint + API.surveyReport;
const get_survey_list_url = API.apiEndpoint + API.survey;
const surveyexport_url = API.apiEndpoint + API.surveyexport;
const surveysummary_url = API.apiEndpoint + API.surveysummary;

export default class SurveyReport extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    surveys: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: false,
    loadingCart: false,
    survey_date_from: "",
    survey_date_to: "",
    survey_list: [],
  };

  async componentDidMount() {
    let survey_options = { url: get_survey_list_url };
    let survey_options_response = await ApiRequest.get(survey_options);

    let options = { url: get_survey_report_url };
    let api_response = await ApiRequest.get(options);

    if (survey_options_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        survey_list: survey_options_response.data.results,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          surveys: api_response.data,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = get_survey_report_url + "?page=" + pageNumber;

    if (this.state.survey_date_from) {
      url = url + "&survey_date_from=" + this.state.survey_date_from;
    }
    if (this.state.survey_date_to) {
      url = url + "&survey_date_to=" + this.state.survey_date_to;
    }

    if (this.state.survey_id) {
      url = url + "&survey_id=" + this.state.survey_id;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);

    if (response.error) {
      this.setState({
        loading: false,
        loadingCart: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        surveys: response.data,
        surveysQuestionsCart: response.data.survey,
        surveysQuestions: response.data.survey.questions,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
        loadingCart: false,
      });
    }
  };
  downloadData = async (pageNumber = 1) => {
    let url = surveyexport_url + "?csv=" + 1;

    if (this.state.survey_date_from) {
      url = url + "&survey_date_from=" + this.state.survey_date_from;
    }
    if (this.state.survey_date_to) {
      url = url + "&survey_date_to=" + this.state.survey_date_to;
    }
    if (this.state.survey_id) {
      url = url + "&survey_id=" + this.state.survey_id;
    }
    
    let options = {
      url: url,
      file_name:
        "Survey Report - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  downloadSummary = async (pageNumber = 1) => {
    let url = surveysummary_url + "?csv=" + 1;

    if (this.state.survey_date_from) {
      url = url + "&survey_date_from=" + this.state.survey_date_from;
    }
    if (this.state.survey_date_to) {
      url = url + "&survey_date_to=" + this.state.survey_date_to;
    }
    if (this.state.survey_id) {
      url = url + "&survey_id=" + this.state.survey_id;
    }
  
    let options = {
      url: url,
      file_name:
        "Survey Summary - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  surveyStart = async (date) => {
    this.setState({
      survey_date_from: date,
      enableBtn: true,
      surveys: [],
      pagination: {},
      limit: 10,
    });
  };
  surveyEnd = async (date) => {
    this.setState({
      survey_date_to: date,
      enableBtn: true,
      surveys: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  surveyListChange = async (event) => {
    this.setState({
      survey_id: event.target.value,
      enableBtn: true,
    });
  };

  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }
  rescue_passed(value) {
    return value === true ? "Winner" : "-";
  }
  noResultText() {
    return "N/A";
  }

  render() {
    const {
      surveys,
      error,
      enableBtn,
      message,
      loadingCart,
      survey_list,
      surveysQuestions,
      surveysQuestionsCart,
    } = this.state;
    var surveyWin = "";
    if (typeof surveys.wins !== "undefined") {
      surveyWin = surveys.wins;
    }
    return (
      <Content title="Survey Report" browserTitle="Survey Report">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Survey Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.survey_date_from}
                      selectsStart
                      survey_date_from={this.state.survey_date_from}
                      survey_date_to={this.state.survey_date_to}
                      onChange={this.surveyStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.survey_date_to}
                      selectsEnd
                      survey_date_from={this.state.survey_date_from}
                      survey_date_to={this.state.survey_date_to}
                      onChange={this.surveyEnd}
                      minDate={this.state.survey_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <label>Survey ID</label>
                  <select
                    className="form-control"
                    id="loyaltyGameId"
                    value={this.state.survey_id}
                    onChange={this.surveyListChange}
                  >
                    <option> Select Game ID </option>
                    {survey_list &&
                      survey_list.map((item, key) => {
                        return <option value={item.id}>{item.name.en}</option>;
                      })}
                  </select>
                </div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Start – End Date
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : surveysQuestionsCart ? (
                          <div>
                            <span className="info-box-number-custom">
                              {ApiRequest.date_format_utc(
                                surveysQuestionsCart.start
                              ) +
                                " - " +
                                ApiRequest.date_format_utc(
                                  surveysQuestionsCart.end
                                )}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Responses
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : surveysQuestionsCart ? (
                          <div>
                            <span className="info-box-number-custom">
                              {surveysQuestionsCart.totalResponses}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">Total Prizes</h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : surveysQuestionsCart ? (
                          <div>
                            <span className="info-box-number-custom">
                              {surveysQuestionsCart.totalPrizes + "pts"}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Questions and Answers</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary mr-5"
                    onClick={this.downloadData}
                  >
                    Export CSV
                  </button>
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadSummary}
                  >
                    Export Summary
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div className="table-responsive simpleTable">
                  <h4>
                    <b>Radio Group and Checkboxes</b>
                  </h4>
                  {surveysQuestions &&
                    surveysQuestions.map((data) => {
                      if (data.type == "mcq" || data.type == "multiselect") {
                        return (
                          <table className="table " id="export-table-inventory">
                            <thead>
                              <tr>
                                <th>{data.questionText.en}</th>
                                <th>Responses</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.options &&
                                data.options.map((answer) => {
                                  return (
                                    <tr>
                                      <td>{answer.text.en}</td>
                                      <td>
                                        {answer.answers
                                          ? answer.answers.length
                                          : 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        );
                      }
                    })}
                </div>

                <div className="table-responsive simpleTable">
                  <h4>
                    <b>Rating</b>
                  </h4>
                  {surveysQuestions &&
                    surveysQuestions.map((data) => {
                      if (data.type == "rating") {
                        return (
                          <table className="table" id="export-table-inventory">
                            <thead>
                              <tr>
                                <th>{data.questionText.en}</th>
                                <th>Responses</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.answers &&
                                data.answers.map((answer) => {
                                  return (
                                    <tr>
                                      <td>{answer.option}</td>
                                      <td>{answer.count}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        );
                      }
                    })}
                </div>

                <div className="table-responsive simpleTable">
                  <h4>
                    <b>Text Question</b>
                  </h4>
                  {surveysQuestions &&
                    surveysQuestions.map((data) => {
                      if (data.type == "input") {
                        return (
                          <table className="table display" id="example">
                            <thead>
                              <tr>
                                <th>{data.questionText.en}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.answers &&
                                data.answers.map((answer) => {
                                  return (
                                    <tr>
                                      <td>{answer}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        );
                      }
                    })}
                </div>

                {/*  {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={(num) =>
                                this.handlePageChange(num, "getReport")
                              }
                            />
                          </div>
                        </div>
                      )}
                </div>*/}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
