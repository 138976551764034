import React from "react";
import { Content } from "adminlte-2-react";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";

const loyalty_sub_tiers_form_url = API.apiEndpoint + API.loyaltySubTiers;
const parent_tiers_url = API.apiEndpoint + API.tierTarget;

export default class LoyaltySubTiersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      action: this.props.edit ? "edit" : "add",
      name: "",
      description: "",
      parent_tier_list: [],
      parentTierCardTypeName: "",
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let options = { url: parent_tiers_url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        parent_tier_list: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
    if (this.state.action === "edit") {
      let url = loyalty_sub_tiers_form_url + "/" + this.props.match.params.id;
      let options = { url: url };
      let api_response = await ApiRequest.get(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        let data = api_response.data;
        this.setState({
          ...this.state,
          name: data.name,
          description: data.description,
          parentTierCardTypeName: data.parentTierCardTypeName,
          loading: false,
          error: false,
          message: api_response.message,
        });
      }
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  descriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };

  parentTierCardTypeNameChange = (event) => {
    this.setState({ parentTierCardTypeName: event.target.value });
  };


  saveSubTier = async (event) => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("parentTierCardTypeName", this.state.parentTierCardTypeName);

    let api_response = {};
    let options = { url: loyalty_sub_tiers_form_url, form_data: formData };
    if (this.state.action === "edit") {
      options.url = loyalty_sub_tiers_form_url + "/" + this.props.match.params.id;
      api_response = await ApiRequest.put(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/loyalty_sub_tiers";
          }.bind(this),
          3000
        );
      }
    } else {
      api_response = await ApiRequest.post(options);
      if (api_response.error) {
        this.setState({
          loading: false,
          error: true,
          message: api_response.message,
        });
      } else {
        this.setState({
          success: true,
          loading: false,
          success_message: api_response.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
            window.location = "/loyalty_sub_tiers";
          }.bind(this),
          3000
        );
      }
    }
  };

  BackToLoyaltySubTiersList = () => {
    window.location = "/loyalty_sub_tiers";
  };

  render() {
    const { parent_tier_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content
        title={`${this.state.action === "edit" ? "Edit" : "Add"} Loyalty Sub Tier`}
        browserTitle={`${this.state.action === "edit" ? "Edit" : "Add"} Loyalty Sub Tier`}>
        <LoadingAjax />
        <div className="row">
          {error && (
            <div class="alert alert-danger alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div class="alert alert-success alert-dismissible" style={{ marginRight: "15px", marginLeft: "15px" }}>
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Loyalty Sub Tier Form</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.nameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="PumpNr">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    value={this.state.description}
                    onChange={this.descriptionChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="parentTierCardTypeName">Parent Tier</label>
                  <select className="form-control" id="parentTierCardTypeName" value={this.state.parentTierCardTypeName} onChange={this.parentTierCardTypeNameChange}>
                    <option>Select Parent Tier</option>
                    {parent_tier_list &&
                      parent_tier_list.map((e, i) => {
                        return <option value={e.CardTypeName}>{`${e.CardTypeName} - (${e.CardTypeArabic})`}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="box-footer clearfix no-border">
                <button onClick={this.saveSubTier} className="btn btn-primary pull-right ">
                  {this.state.action === "edit" ? "Save" : "Submit"}
                </button>
                <button onClick={this.BackToLoyaltySubTiersList} className="btn btn-default pull-right mr-10">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
