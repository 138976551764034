import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
const store_url = API.apiEndpoint + API.store;
const city_url = API.apiEndpoint + API.city;

export default class StoreForm extends React.Component {
  state = {
    action: "add",
    store_name: "",
    store_code: "",
    store_token: "",
    store: {},
    cities: {},
    error_message: "",
    error: false,
    message: "",
    loading: true,
    cityId: "",
    success: false,
    success_message: ""
  };

  async componentDidMount() {
    if (this.props.action === "edit") {
      this.setState({
        action: this.props.action,
        store_name: this.props.store.store_name,
        store_code: this.props.store.store_code,
        store_token: this.props.store.store_token,
        cityId: this.props.store.cityId,
        store: this.props.store
      });
      this.changeToken = this.changeToken.bind(this);
    }

    let optionsCity = { url: city_url };
    let city_response = await ApiRequest.get(optionsCity);

    if (city_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: optionsCity.message
      });
    } else {
      this.setState({
        cities: city_response.data,
        cityId: city_response.data[0].cityId,
        loading: false,
        error: false,
        message: optionsCity.message
      });
    }
  }

  changeToken = () => {
    const rand = Math.floor(10000000 + Math.random() * 9000000000000);
    this.setState({ store_token: rand });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  saveStore = async event => {
    const formData = new FormData();
    formData.append("store_code", this.state.store_code);
    formData.append("store_token", this.state.store_token);
    formData.append("store_name", this.state.store_name);
    formData.append("cityId", this.state.cityId);

    let api_response = {};
    let options = { url: store_url, form_data: formData };

    if (this.state.action === "add") {
      api_response = await ApiRequest.post(options);
    } else {
      options = {
        url: store_url + "/" + this.state.store.id,
        form_data: formData
      };
      api_response = await ApiRequest.put(options);
    }

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message
      });
      setTimeout(
        function() {
          this.setState({ success: false });
          window.location = "/store";
        }.bind(this),
        10000
      );
    }
  };

  // saveStore = async event => {
  //   const formData = new FormData();
  //   formData.append("store_code", this.state.store_code);
  //   formData.append("store_token", this.state.store_token);
  //   formData.append("store_name", this.state.store_name);

  //   if (this.state.action === "add") {
  //     await axios
  //       .post(store_url, formData)
  //       .then(response => {
  //         if (response.status === 200) {
  //           window.location = "/store";
  //         }
  //       })
  //       .catch(error => {
  //         this.setState({
  //           error: true,
  //           error_message: error.response.data.message
  //         });
  //       });
  //   } else {
  //     await axios
  //       .put(store_url + "/" + this.state.store.id, formData)
  //       .then(response => {
  //         if (response.status === 200) {
  //           window.location = "/store";
  //         }
  //       })
  //       .catch(error => {
  //         this.setState({
  //           error: true,
  //           error_message: error.response.data.message
  //         });
  //       });
  //   }
  // };

  backStore = () => {
    window.location = "/store";
  };

  render() {
    let {
      store_code,
      store_token,
      store_name,
      cities,
      error,
      message,
      success,
      success_message
    } = this.state;
    return (
      <Content title="Store" browserTitle="Store Card">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}

          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Store Form</h3>
              </div>
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="store_code">Store Name </label>
                  <input
                    type="text"
                    className="form-control"
                    id="store_name"
                    name="store_name"
                    placeholder="store name "
                    value={store_name}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="store_code">Store Code </label>
                  <input
                    type="text"
                    className="form-control"
                    id="store_code"
                    name="store_code"
                    placeholder="store code "
                    value={store_code}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="store_token">Store Token </label>
                  <input
                    type="text"
                    className="form-control"
                    id="store_token"
                    name="store_token"
                    placeholder="store token "
                    value={store_token}
                    onChange={this.onChange}
                  />
                  <small className="pull-right">
                    <a href="JavaScript:Void(0);" onClick={this.changeToken}>
                      Generate Token
                    </a>
                  </small>
                </div>
                <div className="form-group">
                  <label>Region</label>
                  <select
                    className="form-control"
                    id="cityId"
                    name="cityId"
                    onChange={this.onChange}
                    value={this.state.cityId}
                  >
                    {cities.length > 0 &&
                      cities.map((item, key) => (
                        <option value={item.cityId}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveStore}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.props.viewStore.bind(null, {}, "listing")}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
