import React, { Component } from "react";
import PropTypes from "prop-types";
import { Content, Row, Col } from "adminlte-2-react";
import "./whiteList.css";
import LoadingAjax from "../../components/Loading";

export default class WhiteListView extends Component {
  static propTypes = {
    whiteList: PropTypes.object.isRequired
  };

  render() {
    const { card } = this.props;
    return (
      <Content title={"White List Card"} browserTitle="White List">
        <LoadingAjax />
        <Row>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">White List Info</h3>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>ID</b> <span className="pull-right">{card.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Card Serial Number</b>{" "}
                    <span className="pull-right">{card.card_number}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Card Number</b>{" "}
                    <span className="pull-right">{card.serial_number}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Store Name</b>{" "}
                    <span className="pull-right">{card.store_name}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Store Code</b>{" "}
                    <span className="pull-right">{card.store_code}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
