import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";

const rpsstranview_url = API.apiEndpoint + API.rpsTransaction;

export default class RpsTransactionView extends React.Component {
  state = {
    rps_trans_view: {},
    loading: true,
  };

  async componentDidMount() {
    const response = await axios.get(
      rpsstranview_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      const data = response.data.data;
      this.setState({
        rps_trans_view: data,
        loading: false,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    let { rps_trans_view, loading } = this.state;
    return (
      <Content
        title={"RPS Transaction View"}
        browserTitle="RPS Transaction View"
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>ID</b>{" "}
                      <span className="pull-right">{rps_trans_view.id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Transaction ID</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.transaction_id)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>OOMCO ID</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.group_id)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Card Serial Number</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.card_number)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Card Number</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.serial_number)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Transaction At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(
                          parseInt(rps_trans_view.transaction_at) * 1000
                        )}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Product Code</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.product_code)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Amount</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.amount)}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>Unit Price</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.unit_price)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Volume</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.volume)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Request From</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.request_from)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Store Name</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.store_name)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Store Code</b>{" "}
                      <span className="pull-right">
                        {this.rescue_value(rps_trans_view.store_code)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Created At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(rps_trans_view.createdAt)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Updated At</b>{" "}
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(rps_trans_view.updatedAt)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
