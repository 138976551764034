import React, { Component } from "react";
//import PropTypes from "prop-types";
import { Content, Row, Col } from "adminlte-2-react";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import "./complaint.css";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import API from "./../../constants";
import Pagination from "react-js-pagination";
import $ from "jquery";
const complaint_url = API.apiEndpoint + API.complaintReview;
const comment_url = API.apiEndpoint + API.Comment;

export default class ComplaintView extends Component {
  // static propTypes = {
  //   complaint: PropTypes.object.isRequired,
  // };

  state = {
    complaint: {},
    pagination: {},
    limit: 10,
    activePage: 1,
    loading: true,
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        comment_url +
        "/" +
        this.props.complaint.id +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        commentData: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();

    let url = complaint_url + "/" + this.props.complaint.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        status: api_response.data.status,
        complaint: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  handleStatus = (event) => {
    this.setState({ status: event.target.value });
  };

  handleComment = (event) => {
    this.setState({ comment: event.target.value });
  };

  handlePageChange = async (pageNumber) => {
    await this.getData(pageNumber, this.state.limit, this.state.filter);
  };

  saveComment = async () => {
    const formData = new FormData();
    formData.append("status", this.state.status);
    formData.append("comment", this.state.comment);

    let options = {
      url: complaint_url + "/" + this.state.complaint.id,
      form_data: formData,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.put(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        complaints: api_response.data,
        loading: false,
        error: false,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = window.location.href;
        }.bind(this),
        1000
      );
    }
  };

  render() {
    const { complaint } = this.state;
    const { pagination, limit, commentData } = this.state;

    return (
      <Content title={"Complaint - " + complaint.name} browserTitle="Complaint">
        <LoadingAjax />
        <Row>
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">View</h3>
              </div>
              <div className="box-body">
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                    <b>Complaint ID</b>
                    <span className="pull-right">{complaint.id}</span>
                  </li>
                  <li className="list-group-item">
                    <b>Complaint Name</b>
                    <span className="pull-right">
                      {complaint.userData ? (
                        <a
                          target="_blank"
                          href={"/app_view/" + complaint.userData.id}
                        >
                          {complaint.name}
                        </a>
                      ) : (
                        complaint.name
                      )}
                    </span>
                  </li>
                  {complaint.complaint_type && (
                    <li className="list-group-item">
                      <b>Complaint Type</b>
                      <span className="pull-right">
                        {complaint.complaint_type.type.en}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item">
                    <b>Complaint At</b>{" "}
                    <span className="pull-right">
                      {ApiRequest.date_format_utc(complaint.updatedAt)}
                    </span>
                  </li>
                  {complaint.station && (
                    <li className="list-group-item">
                      <b>Station</b>{" "}
                      <span className="pull-right">
                        {complaint.station.name.en}
                      </span>
                    </li>
                  )}

                  <li className="list-group-item">
                    <b>Phone Number</b>{" "}
                    <span className="pull-right">
                      {complaint.userData ? (
                        <a
                          target="_blank"
                          href={"/app_view/" + complaint.userData.id}
                        >
                          {complaint.mobileNumber}
                        </a>
                      ) : (
                        complaint.mobileNumber
                      )}
                    </span>
                  </li>

                  <li className="list-group-item">
                    <b>Message</b>{" "}
                    <span style={{ display: "block" }}>
                      {complaint.message}
                    </span>
                  </li>

                  {complaint.image && (
                    <li className="list-group-item">
                      <b>Photo</b>
                      <span class="pull-right">
                        <ReactFancyBox
                          image={complaint.image.file}
                          defaultThumbnailWidth={50}
                          defaultThumbnailHeight={50}
                        />
                        {/* <img key={index} src={complaint.image.file} alt="images" /> */}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item clearfix">
                    <b>Complaint Status</b>
                    <span className="pull-right">
                      <select
                        className="form-control"
                        // value={status || ""}
                        value={this.state.status}
                        id="commentStatus"
                        onChange={this.handleStatus}
                      >
                        <option value="">Select</option>
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                      </select>
                    </span>
                  </li>
                  <li className="list-group-item" id="comment_field">
                    <b>Comment </b>
                    <textarea
                      style={{ display: "block" }}
                      value={this.state.comment}
                      onChange={this.handleComment}
                      type="text"
                    ></textarea>
                  </li>
                </ul>
                <div className="box-footer clearfix no-border">
                  <button
                    onClick={this.saveComment}
                    className="btn btn-primary pull-right "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} id="body_comments" style={{ display: "block" }}>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Complaint Comment</h3>
              </div>
              <div className="box-body">
                <div>
                  <div className="table-responsive">
                    <table
                      className="table no-margin no-border-cus"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        {commentData ? (
                          commentData.map((data) => {
                            return (
                              <tr>
                                <td>
                                  <b>
                                    <a
                                      href={"/admin_user_view/" + data.user_id}
                                    >
                                      {ApiRequest.string_humanize_format(
                                        data.userData ? data.userData.role : "-"
                                      )}
                                    </a>
                                  </b>
                                  <span className="pull-right">
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </span>
                                  <div
                                    style={{
                                      display: "block",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {data.comment}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <td colSpan="5" className="noRecord">
                            No Record Found
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
