import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./survey.css";
const view_quiz_form_url = API.apiEndpoint + API.quiz;

export default class QuizView extends React.Component {
  state = {
    admin_user_view: {},
    loading: true,
    error: false,
    message: "",
    base_url: API,
  };

  async componentDidMount() {
    let url = view_quiz_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        quiz_view: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  status_value(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    let { quiz_view, loading } = this.state;
    return (
      <Content title={"Quiz"} browserTitle="Quiz">
        <LoadingAjax />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Quiz Information</h3>
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Name</b>{" "}
                      <span className="pull-right">{quiz_view.name.en} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Description</b>
                      <span className="pull-right">
                        {quiz_view.description.en}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Start</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(quiz_view.start)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>End</b>
                      <span className="pull-right">
                        {ApiRequest.date_format_utc(quiz_view.end)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b>
                      <span className="pull-right">
                        {quiz_view.active == 1 ? "Active" : "Inactive"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Loyalty GameId</b>
                      <span className="pull-right">
                        {quiz_view.loyaltyGameId}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Weightage</b>
                      <span className="pull-right">
                        {quiz_view.passingWeightage}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Trigger</b>
                      <span className="pull-right">{quiz_view.trigger}</span>
                    </li>
                    {quiz_view.triggerValue ? (
                      <li className="list-group-item">
                        <b>Trigger Value</b>
                        <span className="pull-right">
                          {quiz_view.triggerValue}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {quiz_view.sponsor_name ? (
                      <li className="list-group-item">
                        <b>Sponsor Name</b>
                        <span className="pull-right">
                          {quiz_view.sponsor_name}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {quiz_view.sponsor_image ? (
                      <li className="list-group-item sponsor_image_li">
                        <b>Sponsor Image</b>
                        <span className="pull-right">
                          <img
                            className="thumnailImg"
                            src={
                              this.state.base_url.apiEndpoint +
                              "/" +
                              quiz_view.sponsor_image
                            }
                          />
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/*<li className="list-group-item">
                      <b>Question</b>
                      <span className="pull-right">
                        {quiz_view.questions.length > 0 &&
                          quiz_view.questions.map((item, key) => (
                            <div>
                              <div>{item.id}</div> <div>{item.weightage}</div>
                            </div>
                          ))}
                      </span>
                          </li>*/}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Content>
    );
  }
}
