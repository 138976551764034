import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const city_url = API.apiEndpoint + API.city;
const get_report_url = API.apiEndpoint + API.reportInventory;
const inventoryAnalytics_url = API.apiEndpoint + API.analyticsCardInventory;

export default class CardInventory extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    store_code: "",
    serial_number_from: "",
    serial_number_to: "",
    cityId: "",
    stores: [],
    cities: [],
    inventories: [],
    pagination: {},
    activePage: 1,
    error: false,
    message: "",
    loading: false,
    limit: 10,
    callApi: false,
    enableBtn: true,
    search_clear: true,
    loadingCart: false,
    invAnalytics: [],
    registeredCards_non_loyalty: 0,
    unRegisteredCards_non_loyalty: 0,
    registeredCards_loyalty: 0,
    unRegisteredCards_loyalty: 0,
    paginationAnalytics: {},
    card_type: "physical",
    available: 0,
    registered: 0,
    reserved: 0,
  };

  async componentDidMount() {
    let options = { url: store_url };
    let optionsCity = { url: city_url };
    let optionsInventory = { url: get_report_url };

    let inventoryAnalytics = { url: inventoryAnalytics_url };

    let report_response = await ApiRequest.get(optionsInventory);
    let api_response = await ApiRequest.get(options);
    let city_response = await ApiRequest.get(optionsCity);

    let inventoryAnalytics_response = await ApiRequest.get(inventoryAnalytics);

    if (api_response.error || city_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      api_response.data.map((store) => {
        stores.push({ value: store.store_code, label: store.store_name });
      });

      let cities = [];
      city_response.data.map((city) => {
        cities.push({ value: city.cityId, label: city.name });
      });

      this.setState({
        stores: stores,
        cities: cities,
        inventories: report_response.data.results,
        pagination: report_response.data.pagination,
        invAnalytics: inventoryAnalytics_response.data.lowStock.results,
        paginationAnalytics:
        inventoryAnalytics_response.data.lowStock.pagination,
        registeredCards_non_loyalty: inventoryAnalytics_response.data.registeredCards_non_loyalty,
        unRegisteredCards_non_loyalty: inventoryAnalytics_response.data.unRegisteredCards_non_loyalty,
        registeredCards_loyalty: inventoryAnalytics_response.data.registeredCards_loyalty,
        unRegisteredCards_loyalty: inventoryAnalytics_response.data.unRegisteredCards_loyalty,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        this.setState({
          inventories: report_response.data.results,
          pagination: report_response.data.pagination,
        });
      }

      if (this.callApi) {
        await ApiRequest.get(inventoryAnalytics);
        this.setState({
          invAnalytics: inventoryAnalytics_response.data.lowStock.results,
          paginationAnalytics:
            inventoryAnalytics_response.data.lowStock.pagination,
        });
      }
    }
  }
  getReportData = async (pageNumber = 1, paginationType = null) => {
    this.setState({ loading: true, loadingCart: true });

    let url = get_report_url + "?page=" + pageNumber;

    let inventoryAnalyticsReport =
      inventoryAnalytics_url + "?page=" + pageNumber;

    if (this.state.card_type) {
      url = url + "&card_type=" + this.state.card_type;
      inventoryAnalyticsReport =
        inventoryAnalyticsReport + "&card_type=" + this.state.card_type;
    }

    if (this.state.store_code) {
      url = url + "&store_code=" + this.state.store_code;
      inventoryAnalyticsReport =
        inventoryAnalyticsReport + "&store_code=" + this.state.store_code;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
      inventoryAnalyticsReport =
        inventoryAnalyticsReport +
        "&serial_number_from=" +
        this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
      inventoryAnalyticsReport =
        inventoryAnalyticsReport +
        "&serial_number_to=" +
        this.state.serial_number_to;
    }
    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
      inventoryAnalyticsReport =
        inventoryAnalyticsReport + "&cityId=" + this.state.cityId;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);

    let inventoryAnalytics = { url: inventoryAnalyticsReport };
    let analyticsReportResponse = await ApiRequest.get(inventoryAnalytics);

    if (response.error) {
      this.setState({
        loading: false,
        loadingCart: false,
        error: true,
        message: response.message,
      });
    } else {
      this.setState({
        inventories: response.data.results,
        pagination:
          paginationType == null || paginationType === "getReport"
            ? response.data.pagination
            : this.state.pagination,
        invAnalytics:
          analyticsReportResponse.data && analyticsReportResponse.data.lowStock
            ? analyticsReportResponse.data.lowStock.results
            : null,
        paginationAnalytics:
          analyticsReportResponse.data &&
          analyticsReportResponse.data.lowStock &&
          (paginationType == null || paginationType === "getAnalytics")
            ? analyticsReportResponse.data.lowStock.pagination
            : this.state.paginationAnalytics,
        registeredCards_non_loyalty: analyticsReportResponse.data.registeredCards_non_loyalty,
        unRegisteredCards_non_loyalty: analyticsReportResponse.data.unRegisteredCards_non_loyalty,
        registeredCards_loyalty: analyticsReportResponse.data.registeredCards_loyalty,
        unRegisteredCards_loyalty: analyticsReportResponse.data.unRegisteredCards_loyalty,
        available: analyticsReportResponse.data.available,
        registered: analyticsReportResponse.data.registered,
        reserved: analyticsReportResponse.data.reserved,
        loading: false,
        error: false,
        callApi: true,
        loadingCart: false,
      });
    }
  };
  handleCardType = async (event) => {
    this.setState({
      card_type: event.target.value,
      enableBtn: true,
      refuelings: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber, paginationType) => {
    await this.getReportData(pageNumber, paginationType);
  };
  handleChangeStoreName = async (selectedOption) => {
    this.setState({
      store_code: selectedOption.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };
  handleChangeRangeFrom = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        serial_number_from: event.target.value,
      });
    }
  };
  handleChangeRangeTo = (event) => {
    if (event.target.value.length > 16) {
      this.setState({
        error: true,
        message: "Card number limited to 16 digit",
      });
    } else {
      this.setState({
        error: false,
        enableBtn: true,
        serial_number_to: event.target.value,
      });
    }
  };
  handleChangeRegion = async (selectedRegion) => {
    this.setState({
      cityId: selectedRegion.target.value,
      enableBtn: true,
      inventories: [],
      pagination: {},
      limit: 10,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      store_code: "",
      selectedOption: { value: null },
      serial_number_from: "",
      serial_number_to: "",
      selectedRegion: { value: null },
      cityId: "",
    });
  };
  downloadData = async (pageNumber = 1) => {
    let url = get_report_url + "?csv=" + 1;

    if (this.state.card_type) {
      url = url + "&card_type=" + this.state.card_type;
    }

    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }

    if (this.state.cityId) {
      url = url + "&cityId=" + this.state.cityId;
    }
    if (this.state.store_code){
      url = url + "&store_code=" + this.state.store_code;
    }
    let options = {
      url: url,
      file_name:
        "Card Inventory - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }
  noResultText() {
    return "N/A";
  }

  render() {
    const { Select2 } = Inputs;
    const {
      stores,
      cities,
      inventories,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
      invAnalytics,
      registeredCards_non_loyalty,
      unRegisteredCards_non_loyalty,
      registeredCards_loyalty,
      unRegisteredCards_loyalty,
      paginationAnalytics,
      loadingCart,
      available,
      registered,
      reserved,
    } = this.state;

    return (
      <Content title="Card Inventory" browserTitle="Card Inventory">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Card Inventory Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Transaction Type </label>
                    <select
                      className="form-control"
                      value={this.state.card_type}
                      onChange={this.handleCardType}
                    >
                      <option value="virtual"> Virtual Cards</option>
                      <option value="physical">Physical Cards</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={stores}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Card Serial Number
                    </label>
                    <input
                      type="number"
                      value={this.state.serial_number_from}
                      onChange={this.handleChangeRangeFrom}
                      className="form-control"
                      id="card_number"
                      min={0}
                      placeholder="From (0000000000000000)"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.serial_number_to}
                      onChange={this.handleChangeRangeTo}
                      className="form-control"
                      placeholder="To (0000000000000000)"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Region"
                    value={this.state.selectedRegion}
                    onChange={this.handleChangeRegion}
                    options={cities}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                  >
                    Clear
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                {this.state.card_type === "physical" ? (
                  <div>
                    <div className="row">
                      <div className="col-md-2 col-sm-6"></div>
                      <div className="col-md-4 col-sm-6">
                        <div className="info-box-custom">
                          <div className="info-box-content-custom">
                            <h4 className="info-box-text-custom">
                              Unregistered Cards (Loyalty)
                            </h4>
                            {loadingCart ? (
                              <LoadingSpinner />
                            ) : (
                              <div>
                                <span className="info-box-number-custom">
                                  {unRegisteredCards_loyalty}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="info-box-custom">
                          <div className="info-box-content-custom">
                            <h4 className="info-box-text-custom">
                              Registered Cards (Loyalty)
                            </h4>
                            {loadingCart ? (
                              <LoadingSpinner />
                            ) : (
                              <div>
                                <span className="info-box-number-custom">
                                  {registeredCards_loyalty}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 col-sm-6"></div>
                      <div className="col-md-4 col-sm-6">
                        <div className="info-box-custom">
                          <div className="info-box-content-custom">
                            <h4 className="info-box-text-custom">
                              Unregistered Cards (Non Loyalty)
                            </h4>
                            {loadingCart ? (
                              <LoadingSpinner />
                            ) : (
                              <div>
                                <span className="info-box-number-custom">
                                  {unRegisteredCards_non_loyalty}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="info-box-custom">
                          <div className="info-box-content-custom">
                            <h4 className="info-box-text-custom">
                              Registered Cards (Non Loyalty)
                            </h4>
                            {loadingCart ? (
                              <LoadingSpinner />
                            ) : (
                              <div>
                                <span className="info-box-number-custom">
                                  {registeredCards_non_loyalty}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6"></div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <div className="info-box-custom">
                        <div className="info-box-content-custom">
                          <h4 className="info-box-text-custom">Available</h4>
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {available}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="info-box-custom">
                        <div className="info-box-content-custom">
                          <h4 className="info-box-text-custom">Registered</h4>
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {registered}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="info-box-custom">
                        <div className="info-box-content-custom">
                          <h4 className="info-box-text-custom">Reserved</h4>
                          {loadingCart ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {reserved}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          {invAnalytics ? (
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Low Stock Report</h3>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <div>
                        <table
                          className="table no-margin"
                          id="export-table-inventory"
                        >
                          <thead>
                            <tr>
                              <th>Store ID</th>
                              <th>Store Name</th>
                              <th>Stock (Loyalty)</th>
                              <th>Stock (Non Loyalty)</th>
                              <th>Stock (Total)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invAnalytics.length > 0 && invAnalytics ? (
                              invAnalytics.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.store_code)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.store_name)}
                                    </td>
                                    <td>{this.rescue_value(data.cards_loyalty)}</td>
                                    <td>{this.rescue_value(data.cards_non_loyalty)}</td>
                                    <td>{this.rescue_value(data.cards)}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                {callApi ? "No Record Found" : ""}
                              </td>
                            )}
                          </tbody>
                        </table>
                        {paginationAnalytics.size > 0 && (
                          <div className="tableFooter">
                            <div>
                              <div className="dataTables_info">
                                Showing{" "}
                                {(paginationAnalytics.currentPage - 1) * limit +
                                  1}{" "}
                                to {paginationAnalytics.currentPage * limit} of{" "}
                                {paginationAnalytics.size} entries
                              </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <Pagination
                                prevPageText={"<"}
                                nextPageText={">"}
                                activePage={paginationAnalytics.currentPage}
                                itemsCountPerPage={paginationAnalytics.limit}
                                totalItemsCount={paginationAnalytics.size}
                                pageRangeDisplayed={5}
                                onChange={(num) =>
                                  this.handlePageChange(num, "getAnalytics")
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>Card Serial Number</th>
                            <th>Card Number</th>
                            <th>Card Status</th>
                            <th>Store Code</th>
                            <th>Store Name</th>
                            <th>Region</th>
                          </tr>
                        </thead>

                        <tbody>
                          {inventories.length > 0 && inventories ? (
                            inventories.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    <a href={"/card/" + data.card_number}>
                                      {this.rescue_value(data.card_number)}
                                    </a>
                                  </td>

                                  <td>
                                    {this.rescue_value(data.serial_number)}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.activate === 1
                                        ? "Registered"
                                        : "Not registered"
                                    )}
                                  </td>
                                  <td>{this.rescue_value(data.store_code)}</td>
                                  <td>{this.rescue_value(data.store_name)}</td>
                                  <td>{this.rescue_value(data.city)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              {callApi ? "No Record Found" : ""}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={(num) =>
                                this.handlePageChange(num, "getReport")
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
