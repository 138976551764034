import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
// import "./User.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import DatePicker from "react-datepicker";

const campaingGet = API.apiEndpoint + API.campaingGet;
const uploadbulkbarcode_url = API.apiEndpoint + API.uploadbulkbarcode;

export default class Campaign extends Component {
  state = {
    campaign_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    tableData: [],
    totalData: "",
    search_date: "",
    success: "",
    success_message: "",
    error_message: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        campaingGet +
        "?CampaignListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: false,
        //message: "api_response.message",
      });
    } else {
      this.setState({
        campaign_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        // message: "api_response.message 12",
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }
  // openModal = async (data) => {
  //   this.setState({ totalData: data });
  //   this.setState({ tableData: data.data });
  //   this.setState({ modalIsOpen: true });
  // };
  // closeModal = async () => {
  //   this.setState({ modalIsOpen: false });
  // };

  searchDate = async (date) => {
    this.setState({
      search_clear: false,
      search_date: date,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "createdAt", date);
  };
  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      status_input_value: "",
      search_date: "",
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      search_date: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  uploadList = (id) => async () => {
    $("#uploadList_" + id).click();
  };

  onClickHandler = (id) => async (event) => {
    const data = new FormData();
    data.append("campaign_id", id);
    data.append("file", event.target.files[0]);

    this.setState({ loading: true });
    try {
      let bulk_response = await axios.post(uploadbulkbarcode_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (bulk_response.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: bulk_response.data.message,
        });
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          5000
        );
        document.getElementById("uploadList_" + id).value = null;
      } else {
        let message = bulk_response.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
        document.getElementById("uploadList_" + id).value = null;
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };

  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "InActive";
  }
  rescue_mechanic(value) {
    return value == 1 ? "Enable" : "Disable";
  }

  render() {
    const {
      loading,
      error_message,
      message,
      campaign_list,
      pagination,
      limit,
      search_select_value,
      search_input_value,
      status_input_value,
      success,
      success_message,
    } = this.state;

    return (
      <div>
        <Content title="Campaign" browserTitle="Campaign">
          <LoadingAjax />
          <Row>
            {error_message && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {error_message}
              </div>
            )}
            {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}

            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">Campaign </h3>
                  <div className="box-tools">
                    <div>
                      <a
                        class="btn btn-sm btn-primary mr-5"
                        href={"/Campaign_form/"}
                      >
                        Add Campaign Barcode
                      </a>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>

                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"campaign_title"}>Title</option>
                          <option value={"badge_title"}>Badge</option>
                          <option value={"status"}>Status</option>
                          <option value={"mechanic_id"}>Mechanic</option>
                          <option value={"createdAt"}>Date</option>
                        </select>
                        {search_select_value === "status" ? (
                          <select
                            onChange={this.handleStatusChange}
                            value={status_input_value}
                            class="form-control input-sm mr-5 ml-5">
                            <option value={""}>Select </option>
                            <option value={"1"}>Active</option>
                            <option value={"0"}>InActive</option>
                          </select>
                        ) : search_select_value === "createdAt" ? (
                            <DatePicker
                            className="filterDate"
                            dateFormat="MM/dd/yyyy"
                            selected={this.state.search_date}
                            selectsStart
                            onChange={this.searchDate}
                            placeholderText=""
                                />
                        ) : (
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                        )}
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Badge</th>
                              <th>Status</th>
                              <th>Mechanic</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {campaign_list.length > 0 ? (
                              campaign_list.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>
                                      {this.rescue_value(data.campaign_title)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.badge_title)}
                                    </td>
                                    <td>{this.rescue_status(data.status)}</td>
                                    <td>
                                      {data.mechanic_id == 1
                                        ? "Enable"
                                        : "Disable"}
                                    </td>
                                    <td>
                                      {ApiRequest.date_format_utc(
                                        data.createdAt
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        //id="uploadList"
                                        id={"uploadList_" + data.id}
                                        type="file"
                                        name="file"
                                        //ref={(ref) => (this.uploadList = ref)}
                                        onChange={this.onClickHandler(data.id)}
                                        style={{ display: "none" }}
                                        accept=".csv"
                                      />
                                      {ApiRequest.can_access(
                                        "basma-white-list",
                                        "can_upload"
                                      ) && (
                                        <button
                                          type="button"
                                          className="btn btn-xs btn-primary mr-5"
                                          onClick={this.uploadList(data.id)}
                                          // onClick={this.openModal}
                                        >
                                          {"Upload"}
                                        </button>
                                      )}
                                      {ApiRequest.can_access("", "") && (
                                        <a
                                          class="btn btn-primary btn-xs"
                                          href={"/campaign_view/" + data.id}
                                        >
                                          {" "}
                                          <i class="fa fa-eye" />
                                          {"    "}
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>

        {/*<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Cards</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_cards}
              </span>
            </div>
            <div className="info-box-custom" style={{ padding: "15px" }}>
              <label>Total Card Inserted</label>
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {totalData.total_card_proceed}
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Serial Number</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.data.serial_number}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
                </Modal>*/}
      </div>
    );
  }
}
