import React, { Component } from "react";
import { Content, Row, Col } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./Listing.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
//const fuel_url = API.apiEndpoint + API.fuel;
const getFuel_url = API.apiEndpoint + API.getFuelPrice;

export default class SafetyListing extends Component {
  state = {
    fuel_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        getFuel_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        fuel_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  render() {
    const {
      loading,
      error,
      fuel_list,
      message,
      pagination,
      limit,
      //search_select_value,
      //search_input_value
    } = this.state;
    return (
      <Content title="Fuel Prices" browserTitle="Fuel Prices">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Fuel Prices</h3>
                <div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.props.handleClick.bind(null, {}, "add")}
                  >
                    {" "}
                    Add New{" "}
                  </button>
                  {/*ApiRequest.can_access("fuel-prices", "can_view_single") && (
                    <button
                      className="btn btn-sm btn-primary "
                      onClick={this.props.handleClick.bind(null, {}, "add")}
                    >
                      {" "}
                      Add New{" "}
                    </button>
                  )*/}
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    {/*
                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"id"}>ID</option>
                          <option value={"store_name"}>Store Name</option>
                          <option value={"store_code"}>Store Code</option>
                          <option value={"city"}>City</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    */}
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Fuel Type</th>
                            <th>Fuel Price </th>
                            <th>Unit</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fuel_list.length > 0 ? (
                            fuel_list.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>
                                    {this.rescue_value(data.fuel_type.en)}
                                  </td>
                                  <td>{this.rescue_value(data.price)}</td>
                                  <td>{this.rescue_value(data.unit.en)}</td>
                                  <td>
                                    {
                                      <a
                                        class="btn btn-primary btn-xs"
                                        href={"/fuel_prices/edit/" + data.id}
                                      >
                                        <i class="fa fa-edit" />
                                      </a>
                                    }
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
